import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/services/Auth";
import Paginate from "../Paginate";
import LOCATION from "@/constants/Location";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faTrash, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import * as moment from "moment";
function useColumns(filter, deleteRequest) {
    const { t } = useTranslation();

    let columns = [
        {
            dataField: "license_key",
            text: t("pages.license_key.table.title"),
        },
        {
            dataField: "credits",
            text: t("pages.license_key.table.credits"),
        },
        {
            dataField: "status",
            text: t("pages.license_key.table.status"),
            formatter: (status) => t("pages.license_key.table.status_used"),
        },
        {
            dataField: "created_at",
            text: t("pages.license_key.table.used_date"),
            formatter: (datetime) =>
                datetime &&
                moment(datetime).format(t("common.formats.short_date")),
        },
    ];
    /* columns.push({
        dataField: "actions",
        text: "",
        formatter: (cell, row) => (
            <FontAwesomeIcon
                icon={faTrashAlt}
                className="text-danger"
                onClick={() => deleteRequest(row.id)}
                role="button"
            />
        ),
    }); */

    return (filter && columns.filter(filter)) || columns;
}

function LicenseKeyRedeem({
    data: recordsProp,
    paginate,
    url,
    query,
    ...props
}) {
    let auth = useAuth();
    const { t } = useTranslation();
    const perPage = query.limit || 20;
    const exclude = props.exclude || [{ dataField: auth.user.id }];
    const showPagination = paginate == null ? true : paginate;

    const request = useRef(null);
    const [page, setPage] = useState(0);
    const [totalRecords, setTotal] = useState(false);
    const [recordsDownloaded, setRecords] = useState(false);

    const getRecords = () => {
        request?.current?.abort && request.current.abort();

        const critera = { ...(query || {}) };
        critera.take = perPage;
        critera.skip = page * perPage;
        critera.skip = page * perPage;
        critera.page = page + 1;
        request.current = auth.getRequest(
            url || LOCATION.LICENSE_KEY.REDEEM.API,
            critera
        );
        setRecords(false);
        request.current.then((response) => {
            setRecords(response.data.data.list);
            setTotal(response.data.data.total);
        });
    };

    const columns = useColumns(
        (_) => !exclude.includes(_.dataField) && !exclude.includes(_.name),
        props.deleteRequest
    );

    const changePage = (event) => {
        setPage(event.selected);
    };

    const records = recordsProp || recordsDownloaded;

    useEffect(() => {
        if (props.refreshTable) {
            getRecords();
            props.setRefreshTable(false);
        }
    }, [props.refreshTable]);
    useEffect(() => {
        if (!recordsProp) {
            getRecords();
        }

        return () => request?.current?.abort && request.current.abort();
    }, [page, query, url]);

    useEffect(() => {
        props.onData && props.onData(records);
    }, [records]);

    useEffect(() => {
        props.onTotal && props.onTotal(totalRecords);
    }, [totalRecords]);

    return (
        <div className="fra-table">
            <BootstrapTable
                keyField="id"
                responsive
                hover
                columns={columns || []}
                data={records || []}
                noDataIndication={
                    records instanceof Array
                        ? t("common.no_data")
                        : t("common.loading")
                }
                {...props}
            />
            {(showPagination && records && totalRecords && (
                <Paginate {...{ changePage, totalRecords, page, perPage }} />
            )) ||
                ""}
        </div>
    );
}

export default LicenseKeyRedeem;

export { useColumns };
