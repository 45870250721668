import { useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

function RangeField ({
  type,
  register,
  questionIndex,
  question,
  inputNameSuffix,
  errors,
  sectionIndex,
  answer,
  auditMode,
  setValue,
  watch,
  childQuestion,
}) {

  const { t } = useTranslation()

  const rangeValue = watch(`${inputNameSuffix}.value`)
  const otherAnswer = watch(`${inputNameSuffix}.other_answer`)
  useEffect(() => {
    if (answer?.answer) {
      setValue(`${inputNameSuffix}.value`, answer.answer)
      setValue(`${inputNameSuffix}.other_answer`, answer.other_answer)
      setValue(`${inputNameSuffix}.explain_other`, answer.explain_other)
      setValue(`${inputNameSuffix}.user_choice_score`, answer.user_choice_score)
    } else {
      setValue(`${inputNameSuffix}.value`, question.min_slider_value)
      setValue(`${inputNameSuffix}.other_answer`, null)
      setValue(`${inputNameSuffix}.explain_other`, 'false')
      setValue(`${inputNameSuffix}.user_choice_score`, null)
    }
  }, [answer])
  return (
    <>
      <h6 className="my-3 fw-bold">
        {
          !childQuestion &&
          <>
            {sectionIndex + 1}.{questionIndex + 1} -
          </>
        }
        {question.label}
        {question.required ? <span className="text-danger">*</span> : null}
        <span className="mx-3 text-primary">{rangeValue} { rangeValue ? question.description : null }</span>
      </h6>
      {
        question.is_other &&
        <Form.Control
          className="my-2"
          disabled={auditMode}
          {...register(`${inputNameSuffix}.other_answer`)}
          placeholder={question.label}
          isInvalid={!!(errors && errors[questionIndex] && errors[questionIndex].other_answer)}
        />
      }
      <Form.Range
        type="range"
        min={question.min_slider_value}
        max={question.max_slider_value}
        disabled={auditMode}
        {...register(`${inputNameSuffix}.value`)}
        isInvalid={!!(errors && errors[questionIndex] && errors[questionIndex].value)}
      />

      <Form.Control
        type="hidden"
        value={question.is_other}
        {...register(`${inputNameSuffix}.explain_other`)}
      />

      <Form.Control
        type="hidden"
        value={question.is_other && question.has_score}
        {...register(`${inputNameSuffix}.add_own_rating`)}
      />
      {
        question.is_other && question.has_score &&
        <>
          <h6 className="my-3 fw-bold">
            {t('common.form.rating_label', {attribute: otherAnswer ?? question.label}) }
          </h6>
          <Form.Control
            className="my-2"
            type="number"
            min={0}
            max={10}
            disabled={auditMode}
            {...register(`${inputNameSuffix}.user_choice_score`)}
            placeholder={t('common.form.field_custom_rating', { attribute: question.label })}
            isInvalid={!!(errors && errors[questionIndex] && errors[questionIndex].user_choice_score)}
          />
        </>
      }
    </>
  )
}

export default RangeField
