const PricingPlan = {
    title: "Pricing Management",
    new_plan_create: "New Pricing Plan",
    update_plan: " Update Pricing Plan",
    active: "Active",
    in_active: "In-Active",
    table: {
        title: "Pricing Plan Name",
        free_audit: "Free Audit",
        credits: "Credits",
        price: "Cost",
        duration: "Duration",
        plan_type: "Plan Type",

        price_per_service: "Cost Per Service",
        subscription_duration: "Subscription Duration",
        status: "Status",
        status_used: "Used",
        used_date: "Date",
        valid_for: " Valid for",
    },
    button: {
        new_pricing_plan: "New Pricing Plan",
        back: "Back",
        save: "Save",
    },
    form: {
        plan_name: "Plan Name",
        placeholder_plan_name: "Enter Plan Name",
        credits: "Credits",
        placeholder_credits: "Enter Credits",

        price: "Cost",
        placeholder_price: "Enter Cost",

        plan_type: "Plan Type",
        placeholder_plan_type: "Select Plan Type",

        valid_for: "Valid For (Months)",
        placeholder_valid_for: "Enter Valid For (Months)",

        one_time_payment: "One time payment",
        subscription: "Subscription",
    },
    notifications: {
        plan_created: "Plan Created successfully",
        plan_updated: "Plan Updated successfully",
        delete_pricing_plan: {
            title: "Delete Pricing Plan",
            body: "Are you sure you want to this pricing plan?",
        },
        pricing_plan_deleted: {
            title: "Pricing plan key deleted",
        },
    },
};
export default PricingPlan;
