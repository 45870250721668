function HorizontalLine ({className}) {
  return (
    <svg width="20" height="20" className={`${className}`} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.75049 7.75C2.55158 7.75 2.36081 7.82902 2.22016 7.96967C2.07951 8.11032 2.00049 8.30109 2.00049 8.5C2.00049 8.69891 2.07951 8.88968 2.22016 9.03033C2.36081 9.17098 2.55158 9.25 2.75049 9.25H17.2505C17.4494 9.25 17.6402 9.17098 17.7808 9.03033C17.9215 8.88968 18.0005 8.69891 18.0005 8.5C18.0005 8.30109 17.9215 8.11032 17.7808 7.96967C17.6402 7.82902 17.4494 7.75 17.2505 7.75H2.75049ZM2.75049 10.75C2.55158 10.75 2.36081 10.829 2.22016 10.9697C2.07951 11.1103 2.00049 11.3011 2.00049 11.5C2.00049 11.6989 2.07951 11.8897 2.22016 12.0303C2.36081 12.171 2.55158 12.25 2.75049 12.25H17.2505C17.4494 12.25 17.6402 12.171 17.7808 12.0303C17.9215 11.8897 18.0005 11.6989 18.0005 11.5C18.0005 11.3011 17.9215 11.1103 17.7808 10.9697C17.6402 10.829 17.4494 10.75 17.2505 10.75H2.75049Z"
        fill="black"/>
    </svg>
  )

}

export default HorizontalLine
