import { Button, Form, Image } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/services/Auth";
import SectionRecommendationForm from "./../SectionRecommendationForm";

function DropDownMultipleChoiceField({
    type,
    watch,
    register,
    setValue,
    questionIndex,
    question,
    inputNameSuffix,
    errors,
    sectionIndex,
    answer,
    auditMode,
    unregister,
    //selectedChoice,
    //setSelectedChoice
}) {
    const auth = useAuth();
    const { t } = useTranslation();
    const [selectedChoice, setSelectedChoice] = useState();
    const [explainOther, setExplainOther] = useState(false);
    const [userScore, setUserScore] = useState(null);
    const [otherAnswer, setOtherAnswer] = useState(null);

    const rangeValue = watch(`${inputNameSuffix}.range_value`);
    const checkedValue = watch(`${inputNameSuffix}.value`);
    const baseUrl = auth.api.defaults.baseURL;

    useEffect(() => {
        if (answer && !selectedChoice) {
            if (question.select_multiple) {
                let choice = question.question_choices
                    .filter((_) =>
                        answer?.choice?.map((_) => _.answer).includes(_.answer)
                    )
                    ?.map((_) => `${_.id}`);
                setValue(`${inputNameSuffix}.value`, choice);
            } else {
                let choice = question.question_choices.find(
                    (_) => _.answer === answer?.choice?.answer
                );
                if (choice) {
                    setValue(`${inputNameSuffix}.value`, `${choice.id}`);
                }
                setValue(
                    `${inputNameSuffix}.range_value`,
                    answer?.answer?.range_value
                );
            }
        } else {
            setValue(`${inputNameSuffix}.range_value`, null);
        }
    }, [answer]);

    useEffect(() => {
        register(`${inputNameSuffix}.value`);
    });

    useEffect(() => {
        register(`${inputNameSuffix}.explain_other`);
        register(`${inputNameSuffix}.add_own_rating`);

        if (selectedChoice?.answer !== "Other") {
            setValue(`${inputNameSuffix}.explain_other`, "false");
            setValue(`${inputNameSuffix}.add_own_rating`, "false");
        } else if (selectedChoice?.answer !== "Other" && !question.add_other) {
            unregister(`${inputNameSuffix}.other_answer`);
            setExplainOther(false);
        } else if (answer?.answer && answer?.answer?.other_answer) {
            if (answer?.answer?.user_choice_score) {
                setValue(
                    `${inputNameSuffix}.user_choice_score`,
                    answer.answer.user_choice_score
                );
                setUserScore(answer.answer.user_choice_score);
            }

            setValue(
                `${inputNameSuffix}.other_answer`,
                answer.answer.other_answer
            );
            if (selectedChoice?.score === 20) {
                setValue(`${inputNameSuffix}.add_own_rating`, "true");
            }
            setOtherAnswer(answer.answer.other_answer);
            setExplainOther(answer.answer.explain_other);
        } else if (selectedChoice?.answer === "Other" && question.add_other) {
            setValue(`${inputNameSuffix}.explain_other`, "true");

            if (selectedChoice?.score === 20) {
                setValue(`${inputNameSuffix}.add_own_rating`, "true");
            }
        }
        /* const queAnswer = question.question_choices.find(
            (_) => _.id == selectedChoiceId
        ); */
    }, [selectedChoice, question, answer]);

    useEffect(() => {
        if (checkedValue) {
            setSelectedChoice(
                question.question_choices.find((_) => _.id == checkedValue)
            );
        }
    }, [checkedValue]);

    // New function to handle choice selection change
    const handleChoiceChange = (e) => {
        const selectedChoiceId = e.target.value;
        const queAnswer = question.question_choices.find(
            (_) => _.id == selectedChoiceId
        );
        //setSelectedChoice(selectedChoice);
    };

    return (
        <>
            {[13].includes(type) && (
                <div className="w-100 mt-2 mb-2">
                    {question?.image && (
                        <>
                            <Image
                                className="w-100"
                                src={`${baseUrl}/${question?.image}`}
                            />
                        </>
                    )}
                </div>
            )}

            {/*  {[8, 12, 13].includes(type) &&
                question.question_choices.map((choice, index) => (
                    <>
                        <Form.Check
                            disabled={auditMode}
                            key={index}
                            type={
                                question.select_multiple ? "checkbox" : "radio"
                            }
                            name={`${inputNameSuffix}.value`}
                            value={choice.id}
                            {...register(`${inputNameSuffix}.value`)}
                            label={choice.answer}
                        />
                    </>
                ))} */}
            {[8, 12, 13].includes(type) &&
                question.question_choices.map((choice, index) => (
                    <>
                        <Form.Check
                            disabled={auditMode}
                            key={index}
                            type={
                                question.select_multiple ? "checkbox" : "radio"
                            }
                            name={`${inputNameSuffix}.value`}
                            value={choice.id}
                            {...register(`${inputNameSuffix}.value`)}
                            label={choice.answer}
                        />
                    </>
                ))}

            {[8, 12, 13].includes(type) &&
                selectedChoice?.recommendation !== "" &&
                selectedChoice?.recommendation !== undefined &&
                selectedChoice?.recommendation !== null && (
                    <span className="mx-1 text-danger fw-bold">
                        {t("pages.assessments.recommendation_text")}
                    </span>
                )}
            {[12].includes(type) && (
                <>
                    <h6 className="my-3">
                        {question.description}
                        {question.required ? (
                            <span className="text-danger">*</span>
                        ) : null}
                        <span className="mx-2">{rangeValue}</span>
                    </h6>
                    <Form.Range
                        type="range"
                        min={question.min_slider_value}
                        max={question.max_slider_value}
                        disabled={auditMode}
                        {...register(`${inputNameSuffix}.range_value`)}
                        isInvalid={
                            !!(
                                errors &&
                                errors[questionIndex] &&
                                errors[questionIndex].range_value
                            )
                        }
                    />
                </>
            )}
            {type === 9 && (
                <>
                    <Form.Control
                        disabled={auditMode}
                        //value={selectedChoice?.id}
                        value={String(selectedChoice?.id)}
                        as="select"
                        name={`${inputNameSuffix}.value`}
                        /* onChange={(e) => {
                            setValue(`${inputNameSuffix}.value`, e.target.value);
                            handleChoiceChange(e); // Call the handleChoiceChange function here
                        }} */
                        onChange={(e) => {
                            setValue(
                                `${inputNameSuffix}.value`,
                                e.target.value
                            );
                        }}
                        isInvalid={
                            !!(
                                errors &&
                                errors[questionIndex] &&
                                errors[questionIndex].value
                            )
                        }
                        aria-label={question.label}
                    >
                        <option value="">
                            {t("pages.assessments.select_option")}
                        </option>
                        {question.question_choices.map((choice, index) => (
                            <option key={index} value={String(choice.id)}>
                                {choice.answer}
                            </option>
                        ))}
                    </Form.Control>
                    {/* === {watch(`${inputNameSuffix}.recommendation`)} ==== */}
                    <span className="mx-1 text-danger fw-bold">
                        {selectedChoice?.recommendation !== "" &&
                        selectedChoice?.recommendation !== undefined &&
                        selectedChoice?.recommendation !== null
                            ? t("pages.assessments.recommendation_text")
                            : ""}
                    </span>
                </>
            )}

            {errors && errors[questionIndex] && errors[questionIndex].value && (
                <div className="invalid-feedback d-block">
                    {errors[questionIndex].value.message}
                </div>
            )}

            <input
                type="hidden"
                value={
                    selectedChoice?.answer === "Other" && question?.add_other
                        ? "true"
                        : "false"
                }
                {...register(`${inputNameSuffix}.explain_other`)}
            />

            <input
                type="hidden"
                value={
                    selectedChoice?.answer === "Other" &&
                    question?.add_other &&
                    selectedChoice?.score === 20
                        ? "true"
                        : "false"
                }
                {...register(`${inputNameSuffix}.add_own_rating`)}
            />

            {selectedChoice?.answer === "Other" && question.add_other && (
                <>
                    <h6 className="my-3">
                        {t("pages.assessments.explain_other", {
                            questionLabel: question.label,
                        })}
                    </h6>
                    <Form.Control
                        disabled={auditMode}
                        value={otherAnswer}
                        placeholder={t("pages.assessments.explain_other", {
                            questionLabel: question.label,
                        })}
                        {...register(`${inputNameSuffix}.other_answer`)}
                        onChange={(e) => setOtherAnswer(e.target.value)}
                        isInvalid={
                            !!(
                                errors &&
                                errors[questionIndex] &&
                                errors[questionIndex].other_answer
                            )
                        }
                    />
                    {selectedChoice.score === 20 && (
                        <>
                            <Form.Control
                                disabled={auditMode}
                                className="mt-4"
                                type="number"
                                min={1}
                                max={10}
                                value={userScore}
                                placeholder={t(
                                    "pages.assessments.score_your_answer"
                                )}
                                {...register(
                                    `${inputNameSuffix}.user_choice_score`
                                )}
                                onChange={(e) => setUserScore(e.target.value)}
                                isInvalid={
                                    !!(
                                        errors &&
                                        errors[questionIndex] &&
                                        errors[questionIndex].user_choice_score
                                    )
                                }
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
}

export default DropDownMultipleChoiceField;
