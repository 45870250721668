import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoadingButton from "../../../components/LoadingButton";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "@/services/Auth";
import LOCATION from "@/constants/Location";
//import {Helmet} from "react-helmet";
import Safe from "react-safe";
import $ from "jquery";
import { useParams } from "react-router-dom";
function EmergencyLighting(props) {
    const { t } = useTranslation();
    const auth = useAuth();
    const [loading, setLoading] = useState(false);

    const [serverError, setServerError] = useState("");
    const [serverErrors, setServerErrors] = useState([]);
    const baseUrl = auth.api.defaults.baseURL;

    let token = localStorage.getItem("token") || null;
    const [iframeURL, setIframeURL] = useState("");
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const adId = urlParams.get("ad_id");
        let iUrl =
            baseUrl + LOCATION.EMERGENCY_LIGHTING.API.path + "&t=" + token;
        if (adId > 0) {
            iUrl =
                baseUrl +
                LOCATION.EMERGENCY_LIGHTING.API.path +
                "&t=" +
                token +
                "&ad_id=" +
                adId;
        }

        setIframeURL(iUrl);
    }, []);
    return (
        <div className="container h-100 d-flex flex-column align-items-center flex-grow-1">
            <div className="mt-5 p-2">
                {/* <h2 className="fw-bold text-primary">
                    {t("pages.EMERGENCY_LIGHTING.title")}
                </h2 > */}

                <Row>
                    <iframe
                        src={iframeURL}
                        frameborder={"0"}
                        /* style={{
                            position: "fixed",

                            top: "0",
                            right: "0",
                            bottom: "0",
                            left: "0",
                            width: "80%",
                            height: "95%",

                            marginTop: "5%",
                            marginLeft: "15%",
                        }} */
                        className="external-iframe"
                        scrolling="no"
                    ></iframe>
                </Row>
            </div>
        </div>
    );
}

export default EmergencyLighting;
