import { Button, Col, Row } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import LOCATION from "@/constants/Location";
import LoadingCover from "@/components/LoadingCover";
import { useAuth } from "@/services/Auth";
import { Link, useHistory, useParams } from "react-router-dom";
import AssessmentSectionList from "@/views/shared/components/AssessmentSectionList";
import { useTranslation } from "react-i18next";
import PageActionSection from "@/components/PageActionSection";
import AssessmentSectionForm from "./AssessmentSectionForm";
import ArrowRight from "@/components/icons/ArrowRight";
import ArrowLeft from "@/components/icons/ArrowLeft";
import ConfirmModal from "@/components/modals/ConfirmModal";
import { getValidationErrors } from "@/Utility";
import LoadingButton from "@/components/LoadingButton";
import AssessmentSectionFormSignature from "./AssessmentSectionFormSignature";
import AssessmentSectionFormSignatureByAuditor from "./AssessmentSectionFormSignatureByAuditor";
import EditPencil from "@/components/icons/EditPencil";
function AssessmentForm({ auditMode, fromAudit }) {
    const { t } = useTranslation();
    const submitFormRef = useRef(null);
    const auth = useAuth();
    const { template_id, assessment_id } = useParams();

    const history = useHistory();

    const [assessmentTemplate, setAssessmentTemplate] = useState(null);
    const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [firstSlide, setFirstSlide] = useState(true);
    const [assessment, setAssessment] = useState(null);
    const [referenceNumber, setReferenceNumber] = useState(null);
    const [address, setAddress] = useState(null);
    const [responseError, setResponseError] = useState(null);
    const [validationErrors, setValidationErrors] = useState([]);

    const [maxSectionIndex, setMaxSectionIndex] = useState(0);

    const [saveAsDraft, setSaveAsDraft] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalBody, setModalBody] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    const [listRecommendation, setListRecommendation] = useState(null);
    const [adminComment, setAdminComment] = useState(null);
    const [address_line_1, setAddressLine1] = useState(null);
    const [address_line_2, setAddressLine2] = useState(null);
    const [town_city, setTownCity] = useState(null);
    const [country, setCountry] = useState(null);
    const [postcode, setPostcode] = useState(null);
    const [editIndex, setEditIndex] = useState(null);
    const [editedRecommendation, setEditedRecommendation] = useState(null);
    const [submittingRecomm, setSubmittingRecomm] = useState(false);

    const [assessorName, setAssessorName] = useState(null);
    const [assessorRole, setAssessorRole] = useState(null);
    const [localAcceptenceDate, setLocalAcceptenceDate] = useState(null);
    const [finalSignOffDate, setFinalSignOffDate] = useState(null);
    const [forceRedirect, setForceRedirect] = useState(false);

    const baseUrl = auth.api.defaults.baseURL;
    const [assessorSignUrl, setAssessorSignUrl] = useState(
        auth.user.user_signature
            ? `${baseUrl}/${auth.user.user_signature}`
            : "/assets/images/sign-placeholder.png"
    );
    const handleEditClick = (index, recommendation) => {
        setEditedRecommendation(recommendation);
        setEditIndex(index);
    };

    const handleSaveClick = (index, row) => {
        row.editedRecommendation =
            editedRecommendation != null
                ? editedRecommendation
                : row.recommendation;

        row.assessment_id = `${assessment_id}`;
        auth.postRequest(
            `${LOCATION.ASSESSMENTS.RECOMMENDATION_UPDATE.API.path}`,
            row
        )
            .then((response) => {
                //setShowSuccess(true);
                setEditIndex(null);
                if (assessment_id) {
                    let request = getAssessment(false);
                    return () => request?.abort && request.abort();
                }
            })
            .catch((error) => {
                setValidationErrors(getValidationErrors(error));
            });
    };
    const handleDeleteClick  = (index, row) => {
        row.editedRecommendation = '';
        row.assessment_id = `${assessment_id}`;
        auth.postRequest(
            `${LOCATION.ASSESSMENTS.RECOMMENDATION_UPDATE.API.path}`,
            row
        )
            .then((response) => {
                setEditIndex(null);
                if (assessment_id) {
                    let request = getAssessment(false);
                    return () => request?.abort && request.abort();
                }
            })
            .catch((error) => {
                setValidationErrors(getValidationErrors(error));
            });
    };

    const handleCancelClick = () => {
        setEditIndex(null);
    };

    const isLastSection = () => {
        return (
            assessmentTemplate?.assessment_sections.length - 1 ===
            currentSectionIndex
        );
    };
    const isRecommendationSection = () => {
        //return currentSectionIndex === "-1";
        return currentSectionIndex === "-1";
    };

    const saveSection = (values) => {
        console.log("saveSection ", values);
        if (!submitting) {
            setSubmitting(true);
            let lastSection = isLastSection(); //|| isRecommendationSection();
            values.assessment_template_id = assessmentTemplate.id;
            values.assessment_template_section_id =
                assessmentTemplate.assessment_sections[currentSectionIndex]?.id;
            values.current_section_index = currentSectionIndex;
            values.last_section = lastSection;
            setResponseError(null);
            if (values.questions) {
                values.questions.forEach((question) => {
                    if (
                        question.select_multiple === "true" &&
                        !question.value
                    ) {
                        question.value = [];
                    }
                });
            }
            values.from_audit = false;
            if (fromAudit) {
                values.from_audit = true;
            }
            console.log("values SaceSection", values); //return false;
            if (currentSectionIndex === "-2") {
                //return true;
            }
            let request;

            if (assessment != null) {
                if (auditMode) {
                    request = auth.putRequest(
                        `${LOCATION.ASSESSMENTS.API.path}/audit/${assessment.id}`,
                        values
                    );
                } else {
                    if (currentSectionIndex === "-2") {
                        if (fromAudit) {
                            request = auth.putRequest(
                                `${LOCATION.ASSESSMENTS.AUDITOR_AUDIT.API.path}/${assessment.id}`,
                                values
                            );
                        } else {
                            request = auth.putRequest(
                                `${LOCATION.ASSESSMENTS.INTERNAL_AUDIT.API.path}/${assessment.id}`,
                                values
                            );
                        }
                    } else {
                        request = auth.putRequest(
                            `${LOCATION.ASSESSMENTS.API.path}/${assessment.id}`,
                            values
                        );
                    }
                }
            } else {
                request = auth.postRequest(
                    `${LOCATION.ASSESSMENTS.API.path}`,
                    values
                );
                setForceRedirect(true);
            }

            request
                .then((response) => {
                    setAssessment(response.data);

                    setSubmitting(false);
                    if (auditMode || fromAudit) {
                        setModalTitle(
                            t("pages.new_assessment.dialogs.titles.audit_saved")
                        );
                        setModalBody(null);
                    } else if (!lastSection) {
                        if (currentSectionIndex === "-2") {
                            setModalTitle(
                                t(
                                    "pages.new_assessment.dialogs.titles.audit_saved"
                                )
                            );
                            setModalBody(
                                t(
                                    "pages.new_assessment.dialogs.body.audit_sign_section"
                                )
                            );
                        } else {
                            setModalTitle(
                                t(
                                    "pages.new_assessment.dialogs.titles.section_saved"
                                )
                            );
                            setModalBody(
                                t(
                                    "pages.new_assessment.dialogs.body.section_saved"
                                )
                            );
                        }
                    } else {
                        setModalTitle(
                            t(
                                "pages.new_assessment.dialogs.titles.assessment_completed"
                            )
                        );
                        setModalBody(
                            t(
                                "pages.new_assessment.dialogs.body.assessment_completed"
                            )
                        );
                    }
                    setShowSuccess(true);
                    getAssessment(lastSection);
                })
                .catch((error) => {
                    if (
                        error?.response &&
                        error?.response?.status === 422 &&
                        error?.response?.data?.message
                    ) {
                        setResponseError(error.response.data.message);
                    }

                    setValidationErrors(getValidationErrors(error));
                });
        }
    };

    const handleConfirm = () => {
        setShowSuccess(false);
        let lastSection = isLastSection() || isRecommendationSection();

        setModalTitle(null);
        setModalBody(null);

        if (saveAsDraft) {
            if (fromAudit) {
                history.replace(`${LOCATION.ASSESSMENTS_AUDIT.LIST.path}`);
            } else {
                history.replace(`${LOCATION.ASSESSMENTS.LIST.path}`);
            }
        } else {
            if (auditMode) {
                getAssessment(lastSection, false);
            } else if (!lastSection) {
                if (currentSectionIndex === "-2") {
                    if (fromAudit) {
                        history.replace(
                            `${LOCATION.ASSESSMENTS.LIST.path}/${assessment?.id}/1/report`
                        );
                    } else {
                        history.replace(
                            `${LOCATION.ASSESSMENTS.LIST.path}/${assessment?.id}/report`
                        );
                    }
                }
                if (forceRedirect) { //redirect forcefully on edit assessment section due to save issue.
                    history.replace(
                        `${LOCATION.ASSESSMENTS.CREATE.path}/${assessmentTemplate.id}/${assessment?.id}`
                    );
                }

                setCurrentSectionIndex(currentSectionIndex + 1);
                setMaxSectionIndex(currentSectionIndex + 1);
            } else {
                if (lastSection) {
                    if(assessmentTemplate?.assessment_template_type === 2){
                        history.replace(
                            `${LOCATION.ASSESSMENTS.LIST.path}/${assessment?.id}/report`
                        );
                    }else{
                        setCurrentSectionIndex("-2");
                    }

                    //setMaxSectionIndex("-2");
                } else {
                    history.replace(
                        `${LOCATION.ASSESSMENTS.LIST.path}/${assessment?.id}/report`
                    );
                }
            }
        }
    };

    const changeSection = (sectionIndex) => {
        setCurrentSectionIndex(sectionIndex);
        setEditIndex(null);
    };

    const submitSection = (checked) => {
        console.log(" submitSection ", checked);
        submitFormRef.current.click();
    };

    const submitSectionAudit = (audit, sectionId) => {
        let lastSection = isLastSection();
        setModalTitle(t("pages.new_assessment.dialogs.titles.processing"));
        setModalBody(t("common.loading"));
        setShowSuccess(true);
        let request = auth.putRequest(
            `${LOCATION.ASSESSMENTS.API.path}/audit/${assessment_id}`,
            {
                section_id: sectionId,
            }
        );
        request
            .then((response) => {
                setModalTitle(null);
                setModalBody(null);
                setShowSuccess(false);
                getAssessment(lastSection);
            })
            .catch((error) => {});
    };
    const getListRecommendation = async (data) => {
        let userRecommendation = [];
        try {
            if (assessment_id) {
                const response = await auth.getRequest(
                    `${LOCATION.ASSESSMENTS.RECOMMENDATION_LIST.API.path}/${assessment_id}`
                );

                if (response && response.data) {
                    userRecommendation = response.data;
                }
            }
        } catch (error) {
            console.error("Error fetching recommendation list:", error);
        }

        var recArray = [];
        var selectedAnswerIds = [];
        if (data.answers.length) {
            for (
                let answerRow = 0;
                answerRow < data.answers.length;
                answerRow++
            ) {
                //console.log(data.answers[answerRow]);
                if (data.answers[answerRow].question_choice_id !== null) {
                    selectedAnswerIds.push(
                        parseInt(data.answers[answerRow].question_choice_id)
                    );
                }
                //console.log(answerRow, " ==>  ", data.answers[answerRow]);
            }
        }
        if (data.assessment_template) {
            if (data.assessment_template.assessment_sections) {
                /* console.log(
                    " getListRecommendation ",
                    data.assessment_template.assessment_sections.length,
                    data.assessment_template.assessment_sections
                ); */
                for (
                    let sections = 0;
                    sections <
                    data.assessment_template.assessment_sections.length;
                    sections++
                ) {
                    if (
                        data.assessment_template.assessment_sections[sections]
                            .questions.length > 0
                    ) {
                        for (
                            let rowQuestion = 0;
                            rowQuestion <
                            data.assessment_template.assessment_sections[
                                sections
                            ].questions.length;
                            rowQuestion++
                        ) {
                            for (
                                let rawQusChoice = 0;
                                rawQusChoice <
                                data.assessment_template.assessment_sections[
                                    sections
                                ].questions[rowQuestion].question_choices
                                    .length;
                                rawQusChoice++
                            ) {
                                let isFound = selectedAnswerIds.includes(
                                    data.assessment_template
                                        .assessment_sections[sections]
                                        .questions[rowQuestion]
                                        .question_choices[rawQusChoice].id
                                );
                                if (
                                    isFound === true &&
                                    data.assessment_template
                                        .assessment_sections[sections]
                                        .questions[rowQuestion]
                                        .question_choices[rawQusChoice]
                                        .recommendation != null
                                ) {
                                    let recommendationData =
                                        data.assessment_template
                                            .assessment_sections[sections]
                                            .questions[rowQuestion]
                                            .question_choices[rawQusChoice]
                                            .recommendation;
                                    if (userRecommendation) {
                                        let matchingRecommendation =
                                            userRecommendation.find(
                                                (urItem) =>
                                                    urItem.answer ==
                                                    data.assessment_template
                                                        .assessment_sections[
                                                        sections
                                                    ].questions[rowQuestion]
                                                        .question_choices[
                                                        rawQusChoice
                                                    ].id
                                            );
                                        if (matchingRecommendation) {
                                            recommendationData =
                                                matchingRecommendation
                                                    ? matchingRecommendation.recommendation
                                                    : null;
                                        }
                                    }
                                    recArray.push({
                                        que_no:
                                            sections +
                                            1 +
                                            "." +
                                            (rowQuestion + 1),
                                        name: data.assessment_template
                                            .assessment_sections[sections]
                                            .questions[rowQuestion].label,
                                        parent_id:
                                            data.assessment_template
                                                .assessment_sections[sections]
                                                .id,
                                        parent_name:
                                            data.assessment_template
                                                .assessment_sections[sections]
                                                .name,
                                        answer: data.assessment_template
                                            .assessment_sections[sections]
                                            .questions[rowQuestion]
                                            .question_choices[rawQusChoice]
                                            .answer,
                                        recommendation: recommendationData,
                                        question_id:
                                            data.assessment_template
                                                .assessment_sections[sections]
                                                .questions[rowQuestion]
                                                .question_choices[rawQusChoice]
                                                .question_id,
                                        id: data.assessment_template
                                            .assessment_sections[sections]
                                            .questions[rowQuestion]
                                            .question_choices[rawQusChoice].id,
                                    });
                                }
                            }
                        }
                    }
                }
            }
        }

        await setListRecommendation(recArray);
        console.log(" recArray ", recArray);
    };
    const getAssessment = (lastSection, initial) => {
        if (assessment_id) {
            let request = auth.getRequest(
                `${LOCATION.ASSESSMENTS.API.path}/${assessment_id}`
            );
            request
                .then((response) => {
                    setAssessment(response.data);
                    getListRecommendation(response.data);
                    console.log(" ======== ",response.data);
                    if (!fromAudit) {
                        if (initial) {
                            if (
                                response.data?.assessment_status === "completed"
                            ) {
                                setCurrentSectionIndex(
                                    response.data.current_section_index
                                );
                                setMaxSectionIndex(
                                    response.data.assessment_template
                                        ?.assessment_sections?.length
                                );
                            } else {
                                setCurrentSectionIndex(
                                    response.data.current_section_index + 1
                                );
                                setMaxSectionIndex(
                                    response.data.current_section_index + 1
                                );
                            }
                        }
                    }
                    setReferenceNumber(response.data.fra_reference_number);
                    setAssessmentTemplate(response.data.assessment_template);
                    setAddress(response.data.address);

                    setAddressLine1(response.data.address_line_1);
                    setAddressLine2(response.data.address_line_2);
                    setTownCity(response.data.town_city);
                    setCountry(response.data.country);
                    setPostcode(response.data.postcode);

                    if (fromAudit) {
                        let adminName =
                            (auth?.user?.first_name ?? "") +
                            (auth?.user?.last_name
                                ? ` ${auth.user.last_name}`
                                : "");
                        if (response.data.admin_assessor_name) {
                            setAssessorName(response.data.admin_assessor_name);
                        } else {
                            setAssessorName(adminName);
                        }

                        if (response.data.final_sign_off_date) {
                            setFinalSignOffDate(
                                response.data.final_sign_off_date
                            );
                        }
                        setAssessorRole("");
                    } else {
                        if (response.data.assessor_name) {
                            setAssessorName(response.data.assessor_name);
                        } else {
                            setAssessorName(
                                response.data.user.first_name +
                                    " " +
                                    response.data.user.last_name
                            );
                        }
                        if (response.data.assessor_role) {
                            setAssessorRole(response.data.assessor_role);
                        } else {
                            setAssessorRole("");
                        }

                        if (response.data.local_acceptence_date) {
                            setLocalAcceptenceDate(
                                response.data.local_acceptence_date
                            );
                        }
                    }
                    setLoading(false);
                })
                .catch((error) => {});

            return request;
        }
    };

    const saveDraft = () => {
        if (submitFormRef.current) {
            setSaveAsDraft(true);
            submitFormRef.current.click();
        } else {
            setModalTitle(
                t("pages.new_assessment.dialogs.titles.assessment_completed")
            );
            setModalBody(
                t("pages.new_assessment.dialogs.body.assessment_completed")
            );
            setShowSuccess(true);
            setSaveAsDraft(true);
        }
    };

    const clickRef = () => {
        //submitFormRef.current.click();
        if (submitFormRef.current) {
            submitFormRef.current.click();
        } else {
            setModalTitle(
                t("pages.new_assessment.dialogs.titles.assessment_completed")
            );
            setModalBody(
                t("pages.new_assessment.dialogs.body.assessment_completed")
            );
            setShowSuccess(true);
            //setSaveAsDraft(true);
        }
    };
    useEffect(() => {
        if (!fromAudit) {
            let request = auth.getRequest(
                `${LOCATION.ASSESSMENT_TEMPLATES.API.path}/${template_id}`
            );

            request
                .then((response) => {
                    setAssessmentTemplate(response.data);
                    setLoading(false);
                })
                .catch((error) => {});

            return () => request?.abort && request.abort();
        }
    }, []);

    useEffect(() => {
        if (assessment_id) {
            let request = getAssessment(false, true);
            return () => request?.abort && request.abort();
        }
    }, []);

    useEffect(() => {
        if (!auditMode && !assessment_id) {
            let request = auth.getRequest(
                `${LOCATION.ASSESSMENTS.API.path}/reference-number`
            );

            request
                .then((response) => {
                    setReferenceNumber(response.data);
                })
                .catch((error) => {});

            return () => request?.abort && request.abort();
        }
    }, []);

    useEffect(() => {
        if (currentSectionIndex === 0) {
            setFirstSlide(true);
        } else if (currentSectionIndex === "-2") {
            setFirstSlide(true);
        } else {
            setFirstSlide(false);
        }
    }, [currentSectionIndex]);

    if (loading || (assessment_id != null && !assessment)) {
        return <LoadingCover />;
    }

    return (
        <>
            <ConfirmModal
                show={showSuccess}
                title={modalTitle}
                body={modalBody}
                confirmText={t("common.dialogs.actions.okay")}
                handleConfirm={handleConfirm}
            />

            <PageActionSection
                prepend={[
                    auditMode ? (
                        <Link
                            to={`${LOCATION.ASSESSMENTS_AUDIT.LIST.path}`}
                            className="btn btn-primary btn-sm"
                        >
                            <ArrowLeft />
                            <span className="ms-1">
                                {t("pages.new_assessment.save_draft")}
                            </span>
                        </Link>
                    ) : (
                        <Button
                            variant="dark"
                            size="sm"
                            onClick={() => saveDraft()}
                        >
                            <ArrowLeft />
                            <span className="ms-1">
                                {t("pages.new_assessment.save_draft")}
                            </span>
                        </Button>
                    ),
                ]}
                append={[
                    fromAudit ? (
                        <Link
                            to={`${LOCATION.ASSESSMENTS.LIST.path}/${assessment?.id}/1/report`}
                            className="btn btn-light btn-sm"
                        >
                            <span className="me-1">
                                {t(
                                    "pages.assessments.buttons.got_to_ratings_report"
                                )}
                            </span>
                            <ArrowRight fill="primary" />
                        </Link>
                    ) : (
                        <LoadingButton
                            variant={auditMode ? "light" : "success"}
                            onSubmit={clickRef}
                            size="sm"
                            type="button"
                            loading={submitting}
                            titleTranslationKey={
                                isLastSection() || isRecommendationSection()
                                    ? t(
                                          "pages.assessments.buttons.got_to_ratings_report"
                                      )
                                    : t(
                                          "pages.assessments.buttons.continue_next_step"
                                      )
                            }
                            icon={<ArrowRight />}
                        />
                    ),
                ]}
                title={assessmentTemplate.title}
                type={auditMode ? "primary" : "dark"}
            />
            <Row>
                <Col className="col-12 col-lg-4">
                    <div className="sections-list-panel">
                        <div className="d-flex p-3 fw-bold">
                            <small>{t("common.ratings")}:</small>
                            <small className="mx-1 text-success">
                                {t("common.high")}
                            </small>
                            <small className="mx-1 text-warning">
                                {t("common.medium")}
                            </small>
                            <small className="mx-1 text-danger">
                                {t("common.low")}
                            </small>
                        </div>
                        <AssessmentSectionList
                            auditMode={auditMode}
                            titleSection={false}
                            disableOthers={!auditMode}
                            changeSection={changeSection}
                            submitSection={submitSection}
                            submitSectionAudit={submitSectionAudit}
                            sections={assessmentTemplate.assessment_sections}
                            sectionScores={assessment?.section_scores}
                            currentSectionIndex={currentSectionIndex}
                            maxSectionIndex={maxSectionIndex}
                            listRecommendation={listRecommendation}
                            fromAudit={fromAudit}
                            assessmentTemplateType={assessmentTemplate?.assessment_template_type}
                        />
                    </div>
                </Col>
                <Col className="col-12 col-lg-8 p-4">
                    {assessmentTemplate.assessment_sections.map(
                        (section, index) => {
                            return index === currentSectionIndex ? (
                                <AssessmentSectionForm
                                    key={section.id}
                                    assessment={assessment}
                                    assessmentTemplate={assessmentTemplate}
                                    answers={assessment?.answers}
                                    referenceNumber={referenceNumber}
                                    address={address}
                                    adminComment={assessment?.admin_comment}
                                    responsiblePerson={
                                        assessment?.responsible_person
                                    }
                                    firstSlide={firstSlide}
                                    lastSection={
                                        index + 1 ===
                                        assessmentTemplate.assessment_sections
                                            .length
                                    }
                                    sectionIndex={index}
                                    section={section}
                                    auditMode={auditMode}
                                    fromAudit={fromAudit}
                                    saveSection={saveSection}
                                    submitFormRef={submitFormRef}
                                    responseError={responseError}
                                    validationErrors={validationErrors}
                                    saveDraft={saveDraft}
                                    submitting={submitting}
                                    listRecommendation={listRecommendation}
                                    // setName={setName}
                                    address_line_1={address_line_1}
                                    address_line_2={address_line_2}
                                    town_city={town_city}
                                    country={country}
                                    postcode={postcode}
                                />
                            ) : (
                                <>
                                    {currentSectionIndex === "-1" &&
                                        index === 0 && (
                                            <>
                                                <div
                                                    className="border-0 radius-0 section-form mx-auto mx-lg-5 card"
                                                    key={"a_" + index}
                                                >
                                                    <div className="client card-body">
                                                        <div className="py-2">
                                                            <div className="d-flex justify-content-start text-dark fw-bold fst-italic1 btn btn-light1">
                                                                {t(
                                                                    "pages.assessments.recommendations"
                                                                )}
                                                            </div>
                                                            <div className="align-items-center bg-light d-flex1 p-3 mlp-7">
                                                                {listRecommendation.map(
                                                                    (
                                                                        row,
                                                                        indexLr
                                                                    ) => (
                                                                        <div
                                                                            className="d-grid1 gap-2"
                                                                            key={
                                                                                indexLr
                                                                            }
                                                                        >
                                                                            <span className="fw-bold1 d-grid">
                                                                                {
                                                                                    row.que_no
                                                                                }{" "}
                                                                                {
                                                                                    row.name
                                                                                }
                                                                            </span>
                                                                            {editIndex ===
                                                                            indexLr ? (
                                                                                // Edit mode
                                                                                <>
                                                                                    <div
                                                                                        className="d-grid gap-2"
                                                                                        key={
                                                                                            "D_" +
                                                                                            indexLr
                                                                                        }
                                                                                    >
                                                                                        <textarea
                                                                                            defaultValue={
                                                                                                row.recommendation
                                                                                            }
                                                                                            onChange={(
                                                                                                e
                                                                                            ) => {
                                                                                                setEditedRecommendation(
                                                                                                    e
                                                                                                        .target
                                                                                                        .value
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                        <button
                                                                                            className="btn btn-success"
                                                                                            onClick={() =>
                                                                                                handleSaveClick(
                                                                                                    indexLr,
                                                                                                    row
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            {t(
                                                                                                "common.save"
                                                                                            )}
                                                                                        </button>

                                                                                        <Button
                                                                                            variant="light"
                                                                                            size="sm"
                                                                                            className="edit_recomm_icon"
                                                                                            onClick={
                                                                                                handleCancelClick
                                                                                            }
                                                                                        >
                                                                                            {t(
                                                                                                "common.cancel"
                                                                                            )}
                                                                                        </Button>
                                                                                        <Button
                                                                                            variant="danger"
                                                                                            size="sm"
                                                                                            className="delete_recomm_icon"
                                                                                            onClick={() => handleDeleteClick(indexLr, row)}
                                                                                        >
                                                                                            {t("common.buttons.delete")}
                                                                                        </Button>
                                                                                    </div>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <p>
                                                                                        <span>
                                                                                            <Button
                                                                                                variant="light"
                                                                                                size="sm"
                                                                                                className="edit_recomm_icon"
                                                                                                onClick={(
                                                                                                    row
                                                                                                ) => {
                                                                                                    setEditedRecommendation(
                                                                                                        row.recommendation
                                                                                                    );
                                                                                                    handleEditClick(
                                                                                                        indexLr,
                                                                                                        row.recommendation
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                <EditPencil />
                                                                                            </Button>
                                                                                        </span>
                                                                                        {row.recommendation ? row.recommendation : t("common.noRecommendation")}
                                                                                    </p>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        
                                    {currentSectionIndex === "-2" &&
                                        index === 0 && assessmentTemplate?.assessment_template_type !== 2 && (
                                            <>
                                                {fromAudit ? (
                                                    <>
                                                        <AssessmentSectionFormSignatureByAuditor
                                                            key={
                                                                currentSectionIndex
                                                            }
                                                            assessment={
                                                                assessment
                                                            }
                                                            assessmentTemplate={
                                                                assessmentTemplate
                                                            }
                                                            firstSlide={
                                                                firstSlide
                                                            }
                                                            lastSection={
                                                                index + 1 ===
                                                                assessmentTemplate
                                                                    .assessment_sections
                                                                    .length
                                                            }
                                                            //sectionIndex={index}
                                                            section={section}
                                                            auditMode={
                                                                auditMode
                                                            }
                                                            fromAudit={
                                                                fromAudit
                                                            }
                                                            saveSection={
                                                                saveSection
                                                            }
                                                            submitFormRef={
                                                                submitFormRef
                                                            }
                                                            responseError={
                                                                responseError
                                                            }
                                                            validationErrors={
                                                                validationErrors
                                                            }
                                                            saveDraft={
                                                                saveDraft
                                                            }
                                                            submitting={
                                                                submitting
                                                            }
                                                            assessorName={
                                                                assessorName
                                                            }
                                                            assessorSignUrl={
                                                                assessorSignUrl
                                                            }
                                                            assessorRole={
                                                                assessorRole
                                                            }
                                                            finalSignOffDate={
                                                                finalSignOffDate
                                                            }
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <AssessmentSectionFormSignature
                                                            key={
                                                                currentSectionIndex
                                                            }
                                                            assessment={
                                                                assessment
                                                            }
                                                            assessmentTemplate={
                                                                assessmentTemplate
                                                            }
                                                            firstSlide={
                                                                firstSlide
                                                            }
                                                            lastSection={
                                                                index + 1 ===
                                                                assessmentTemplate
                                                                    .assessment_sections
                                                                    .length
                                                            }
                                                            //sectionIndex={index}
                                                            section={section}
                                                            auditMode={
                                                                auditMode
                                                            }
                                                            fromAudit={
                                                                fromAudit
                                                            }
                                                            saveSection={
                                                                saveSection
                                                            }
                                                            submitFormRef={
                                                                submitFormRef
                                                            }
                                                            responseError={
                                                                responseError
                                                            }
                                                            validationErrors={
                                                                validationErrors
                                                            }
                                                            saveDraft={
                                                                saveDraft
                                                            }
                                                            submitting={
                                                                submitting
                                                            }
                                                            assessorName={
                                                                assessorName
                                                            }
                                                            assessorSignUrl={
                                                                assessorSignUrl
                                                            }
                                                            assessorRole={
                                                                assessorRole
                                                            }
                                                            localAcceptenceDate={
                                                                localAcceptenceDate
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </>
                                        )}
                                </>
                            );
                        }
                    )}
                </Col>
            </Row>
        </>
    );
}

export default AssessmentForm;
