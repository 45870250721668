function PageActionSection({title, prepend, append, type}) {
    return (
        <div
          className={`page-actions-section d-flex justify-content-between flex-column flex-md-row ${type === 'dark' ? 'bg-dark' : 'bg-primary'} text-white`}
        >
          <div className="d-flex flex-column flex-md-row">
            <div className="d-grid gap-2 order-1 order-md-0 pt-md-0 pt-3">
              {
                prepend ?
                  prepend.map((prependItem, index) => <span key={index}>{prependItem}</span>)
                  : <span>&nbsp;</span>
              }
            </div>
            <h5 className={`order-0 order-md-1 mb-0 mt-1 ${prepend ? 'ms-0 ms-md-5 text-center text-md-start': 'ml-0'}`}>{title}</h5>
          </div>
          <div className="d-flex flex-column flex-md-row d-grid gap-2 pt-md-0 pt-3">
            {
              append ?
                append.map((appendItem, index) =><span key={index}>{appendItem}</span>)
                : <span>&nbsp;</span>
            }
          </div>
        </div>
    )
}

export default PageActionSection;