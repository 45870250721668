import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import * as moment from "moment";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/services/Auth";
import Paginate from "../Paginate";
import LOCATION from "@/constants/Location";
import { getScoreColor } from "@/Utility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrash,
    faTrashAlt,
    faDownload,
} from "@fortawesome/free-solid-svg-icons";

function useColumns(filter, assessmentAudit, source, deleteRequest) {
    let auth = useAuth();
    const { t } = useTranslation();

    const IdRowFormatter = (cell, row) => {
        if (row?.id) {
            if (assessmentAudit) {
                return (
                    <Link
                        className="table-links"
                        to={`${LOCATION.ASSESSMENTS_AUDIT.LIST.path}/${row?.assessment_template_id}/${row?.id}`}
                    >
                        {cell.title}
                    </Link>
                );
            } else if (row.status === 1) {
                return (
                    <Link
                        className="table-links"
                        to={`${LOCATION.ASSESSMENTS.CREATE.path}/${row.assessment_template_id}/${row.id}`}
                    >
                        {cell.title}
                    </Link>
                );
            } else {
                return (
                    <Link
                        className="table-links"
                        to={`${LOCATION.ASSESSMENTS.LIST.path}/${row.id}/report`}
                    >
                        {cell.title}
                    </Link>
                );
            }
        }
        return cell.title;
    };

    const statusStyle = (cell, row) => {
        let backgroundColor = "#198754";
        const status = assessmentAudit ? row.audit_status : row.status;
        if (status === 1) {
            backgroundColor = assessmentAudit ? "#dc3545" : "#ffc107";
        } else if (status === 2) {
            backgroundColor = assessmentAudit ? "#ffc107" : "#dc3545";
        } else if (status === 4) {
            backgroundColor = "#ffc107";
        }

        return {
            color: "#ffffff",
            backgroundColor: backgroundColor,
        };
    };

    let columns = [
        {
            dataField: "assessment_template",
            text: t("common.tables.assessment_name"),
            formatter: IdRowFormatter,
        },
        assessmentAudit
            ? {
                  dataField: "responsible_person",
                  text: t("common.form.name"),
              }
            : {
                  dataField: "address_line_1",
                  text: t("common.form.address"),
              },
        {
            dataField: assessmentAudit
                ? "assessment_audit_status"
                : "assessment_status",
            text: t("common.status"),
            style: statusStyle,
            formatter: (cell, row) =>
                row?.id && (
                    <span className="text-white status-field">
                        {t(`common.status_list.${cell}`)}
                    </span>
                ),
        },
    ];
    if (source !== "users") {
        columns.push({
            dataField: "fra_reference_number",
            text: t("common.form.fra_reference_number"),
        });
    }
    columns = [
        ...columns,
        ...[
            {
                dataField: "created_at",
                text: t("common.tables.assessment_date"),
                formatter: (datetime) =>
                    datetime &&
                    moment(datetime).format(t("common.formats.short_date")),
            },
            {
                dataField: "score",
                text: t("common.score"),
                formatter: (score) => "", //<span className={`${getScoreColor(score)}`}>{score} {score !== 'N/A' ? '/10' : null}</span>
            },
        ],
    ];

    /* if (source !== 'users') {
    columns.push(
      {
        dataField: 'actions',
        text: '',
        formatter: (cell, row) => <FontAwesomeIcon icon={faTrashAlt} className="text-danger" onClick={() => deleteRequest(row.id)} role="button"/>
      }
    )
  } */
    if (source !== "users") {
        if (auth && auth.user && auth.user.branch_user !== true) {
            columns.push({
                dataField: "actions",
                text: "",
                formatter: (cell, row) => (
                    <>
                        <FontAwesomeIcon
                            icon={faTrashAlt}
                            className="text-danger"
                            onClick={() => deleteRequest(row.id)}
                            role="button"
                            style={{ marginRight: "10px" }}
                        />
                        <Link
                            to={`${LOCATION.ASSESSMENTS.LIST.path}/${row.id}/full-report`}
                            className=" text-primary"
                        >
                            <FontAwesomeIcon
                                icon={faDownload}
                                className="text-primary"
                                role="button"
                            />
                        </Link>
                    </>
                ),
            });
        }
    }

    return (filter && columns.filter(filter)) || columns;
}

function Assessments({ data: recordsProp, paginate, url, query, ...props }) {
    let auth = useAuth();
    const { t } = useTranslation();

    const perPage = query.limit || 20;
    const exclude = props.exclude || [];
    const showPagination = paginate == null ? true : paginate;

    const request = useRef(null);
    const [page, setPage] = useState(0);
    const [totalRecords, setTotal] = useState(false);
    const [recordsDownloaded, setRecords] = useState(false);

    const getRecords = () => {
        request?.current?.abort && request.current.abort();

        const critera = { ...(query || {}) };

        critera.take = perPage;
        critera.skip = page * perPage;
        critera.audit_requests = props.auditRequests;
        critera.assessment_audit = props.assessmentAudit;

        request.current = auth.getRequest(
            url || LOCATION.ASSESSMENTS.API.path,
            critera
        );

        setRecords(false);

        request.current.then((response) => {
            setRecords(response.data.data);
            setTotal(response.data.length);
            if (props.updateStats) {
                props.updateStats(response.data.data);
            }
        });
    };

    const columns = useColumns(
        (_) => !exclude.includes(_?.dataField) && !exclude.includes(_?.name),
        props.assessmentAudit,
        props.source,
        props.deleteRequest
    );

    const changePage = (event) => {
        setPage(event.selected);
    };

    const records = recordsProp || recordsDownloaded;

    useEffect(() => {
        if (!recordsProp) {
            getRecords();
        }

        return () => request?.current?.abort && request.current.abort();
    }, [page, query, url]);

    useEffect(() => {
        props.onData && props.onData(records);
    }, [records]);

    useEffect(() => {
        props.onTotal && props.onTotal(totalRecords);
    }, [totalRecords]);

    return (
        <div className="fra-table">
            <BootstrapTable
                keyField="id"
                responsive
                hover
                columns={columns || []}
                data={records || []}
                noDataIndication={
                    records instanceof Array
                        ? t("common.no_data")
                        : t("common.loading")
                }
                {...props}
            />
            {(showPagination && records && totalRecords && (
                <Paginate {...{ changePage, totalRecords, page, perPage }} />
            )) ||
                ""}
        </div>
    );
}

export default Assessments;

export { useColumns };
