import PageActionSection from "@/components/PageActionSection";
import { useTranslation } from "react-i18next";
import React, { useState, useRef } from "react";
import { useAuth } from "@/services/Auth";
import LOCATION from "@/constants/Location";
//import FormBuilder from './Forms/FormBuilder'
import { useHistory, useParams } from "react-router-dom";
import ArrowRight from "@/components/icons/ArrowRight";
import ArrowLeft from "@/components/icons/ArrowLeft";
import ConfirmModal from "@/components/modals/ConfirmModal";
import { getValidationErrors } from "@/Utility";
import { Button, Card, Form, Image } from "react-bootstrap";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
function Create(props) {
    const { t } = useTranslation();
    const auth = useAuth();
    const history = useHistory();

    const query = new URLSearchParams(props.location.search);
    const [validationErrors, setValidationErrors] = useState([]);

    const [showSuccess, setShowSuccess] = useState(false);
    const [title, setTitle] = useState("");

    const submitFormRef = useRef(null);

    const goToBack = () => {
        history.push(`${LOCATION.ADMIN_USERS.LIST.path}`);
    };

    const handleConfirm = () => {
        setShowSuccess(false);
        setTitle(null);
        history.push(`${LOCATION.ADMIN_USERS.LIST.path}`);
    };
    const requestSave = (values) => {
        setValidationErrors([]);
        auth.postRequest(LOCATION.ADMIN_USERS.CREATE.API, values)
            .then((response) => {
                setTitle(t("pages.admin_users.form.admin_user_created"));
                setShowSuccess(true);
            })
            .catch((error) => {
                setValidationErrors(getValidationErrors(error));
            });
    };

    const schema = yup.object().shape({
        first_name: yup.string().required(
            t("common.form_validation.is_required", {
                attribute: t("pages.admin_users.form.first_name"),
            })
        ),
        last_name: yup.string().required(
            t("common.form_validation.is_required", {
                attribute: t("pages.admin_users.form.last_name"),
            })
        ),
        password: yup.string().required(
            t("common.form_validation.is_required", {
                attribute: t("pages.admin_users.form.password"),
            })
        ),
        password_confirmation: yup
            .string()
            .oneOf(
                [yup.ref("password"), null],
                t("common.form_validation.password_is_must_match")
            )
            .required(t("common.form_validation.password_confirm_required")),
        email: yup
            .string()
            .email(t("common.form_validation.invalid_email_address"))
            .required(
                t("common.form_validation.is_required", {
                    attribute: t("pages.admin_users.form.email"),
                })
            ),
    });
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm({
        defaultValues: {
            first_name: "",
            last_name: "",
            email: "",
            password: "",
        },
        resolver: yupResolver(schema),
    });

    const onSave = (values) => {
        requestSave(values);
    };
    return (
        <>
            <ConfirmModal
                show={showSuccess}
                title={title}
                confirmText={t("common.dialogs.actions.okay")}
                handleConfirm={handleConfirm}
            />

            <PageActionSection
                prepend={[
                    <Button variant="dark" size="sm" onClick={() => goToBack()}>
                        <ArrowLeft />
                        <span className="ms-1">
                            {t("pages.admin_users.buttons.back")}
                        </span>
                    </Button>,
                ]}
                title={t("pages.admin_users.add")}
                type="dark"
            />
            <div className="content">
                <Form noValidate onSubmit={handleSubmit(onSave)}>
                    <Card className="border-0 radius-0 section-form mx-auto mx-lg-5">
                        <Card.Body>
                            <Form.Group
                                className="mb-4 mt-4"
                                controlId="formBasicTitle"
                            >
                                <Form.Label>
                                    {t("pages.admin_users.form.first_name")}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t(
                                        "pages.admin_users.form.first_name"
                                    )}
                                    {...register("first_name")}
                                    isInvalid={!!errors.first_name}
                                />

                                <Form.Control.Feedback type="invalid">
                                    {errors.first_name &&
                                        errors.first_name.message}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group
                                className="mb-4 mt-4"
                                controlId="formBasicTitle"
                            >
                                <Form.Label>
                                    {t("pages.admin_users.form.last_name")}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t(
                                        "pages.admin_users.form.last_name"
                                    )}
                                    {...register("last_name")}
                                    isInvalid={!!errors.last_name}
                                />

                                <Form.Control.Feedback type="invalid">
                                    {errors.last_name &&
                                        errors.last_name.message}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group
                                className="mb-4 mt-4"
                                controlId="formBasicTitle"
                            >
                                <Form.Label>
                                    {t("pages.admin_users.form.email")}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t(
                                        "pages.admin_users.form.email"
                                    )}
                                    {...register("email")}
                                    isInvalid={!!errors.email}
                                />

                                <Form.Control.Feedback type="invalid">
                                    {errors.email && errors.email.message}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group
                                className="mb-4 mt-4"
                                controlId="formBasicTitle"
                            >
                                <Form.Label>
                                    {t("pages.admin_users.form.password")}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder={t(
                                        "pages.admin_users.form.password"
                                    )}
                                    {...register("password")}
                                    isInvalid={!!errors.password}
                                />

                                <Form.Control.Feedback type="invalid">
                                    {errors.password && errors.password.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                className="mb-4"
                                controlId="formBasicPassword"
                            >
                                <Form.Label>
                                    {t("common.form.re_enter_password")}
                                </Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder={t(
                                        "common.form.re_enter_password"
                                    )}
                                    {...register("password_confirmation")}
                                    isInvalid={!!errors.password_confirmation}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.password_confirmation &&
                                        errors.password_confirmation.message}
                                </Form.Control.Feedback>
                            </Form.Group>

                            {validationErrors.length !== 0 && (
                                <div className="form-group mb-2">
                                    {validationErrors.map((error, index) => (
                                        <p
                                            className="text-danger font-weight-bold"
                                            key={index}
                                        >
                                            {t(error)}
                                        </p>
                                    ))}
                                </div>
                            )}

                            <Form.Group
                                className="mb-4 mt-4"
                                controlId="formBasicTitle"
                            >
                                <div className="d-flex justify-content-end mt-4 section-form  mx-auto mx-lg-5">
                                    <Button
                                        variant="secondary"
                                        size="sm"
                                        onClick={() => goToBack()}
                                    >
                                        <span className="ms-1">
                                            {t(
                                                "pages.admin_users.buttons.back"
                                            )}
                                        </span>
                                    </Button>
                                    <Button
                                        ref={submitFormRef}
                                        variant="success"
                                        size="sm"
                                        type="submit"
                                        className="text-white ms-4"
                                    >
                                        <span className="me-1">
                                            {t(
                                                "pages.admin_users.buttons.save"
                                            )}
                                        </span>
                                    </Button>
                                </div>
                            </Form.Group>
                        </Card.Body>
                    </Card>
                </Form>
            </div>
        </>
    );
}

export default Create;
