const Transactions = {
    title: "Transactions",
    one_time: "One Time",
    subscription: "Subscription",
    table: {
        name: "Name",
        plan_name: "Plan Name",
        price: "Price",
        credits: "Credits",
        plan_type: "Plan Type",
        status: "Status",
        buy_date: "Purchase date",
    },
    button: {
        new_license_key: "New License Key",
        back: "Back",
        save: "Save",
        redeem_credits: "Redeem Credit",
    },
};
export default Transactions;
