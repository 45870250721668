import { useHistory, useLocation, useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useAuth } from "@/services/Auth";
import LOCATION from "@/constants/Location";
import LoadingCover from "@/components/LoadingCover";
import AssessmentFilters from "@/views/components/AssessmentFilters";
import Table, { useColumns } from "@/views/components/Tables/Assessments";
import { Card, Col, Row, Button } from "react-bootstrap";
//import UpdateTokens from "./Forms/UpdateTokens";
import moment from "moment";

function DirectoryListing() {
    const history = useHistory();
    const route = useLocation();
    const auth = useAuth();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);

    const [allDirectory, setAllDirectory] = useState(null);
    const [myCourses, setMyCourses] = useState(null);

    let token = localStorage.getItem("token") || null;

    const [activeTab, setActiveTab] = useState("all_course");

    const getDefaultImage = (e) => {
        e.target.src = `/assets/images/placeholder.png`;
    };
    const getAllDirectory = () => {
        let request = auth.getRequest(
            `${LOCATION.DIRECTORY.LIST.ALL_DIRECTORY.API}`
        );
        request
            .then((response) => {
                setAllDirectory(response.data);
            })
            .catch((error) => {});
        return request;
    };

    useEffect(() => {
        let request = getAllDirectory();
    }, []);

    useEffect(() => {
        setLoading(false);
    }, []);

    //`https://stagingdirectory.frawizard.co.uk/wp-json/custom/v1/login-sso?t=${btoa(
    const src = btoa(
        `https://stagingdirectory.frawizard.co.uk/wp-json/custom/v1/login-sso`
    );
    
    if (loading) return <LoadingCover />;

    return (
        <>
            <Row className="h-100">
                <Col className="col-md-12 col-12 p-4">
                    <Row>
                        {allDirectory?.data.length === 0 ? (
                            <>
                                <div className="d-grid gap-2 col-md-3">
                                    {/* <a
                                        href={
                                            `${process.env.REACT_DIRECTORY_APP_API_WP}/wp-json/custom/v1/login-sso?t=` +
                                            btoa(auth.user.email)
                                        }
                                        className="btn btn-primary text-white"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {t(
                                            "pages.directory.button.add_directory"
                                        )}
                                    </a> */}

                                    <Link
                                        to={`/directory/` + src}
                                        className="btn btn-primary text-white"
                                    >
                                        {t(
                                            "pages.directory.button.add_directory"
                                        )}
                                    </Link>
                                </div>
                            </>
                        ) : (
                            <>
                                {allDirectory?.data.map((directory) => {
                                    return (
                                        <Col
                                            className="col-12 col-md-6 col-lg-4 mb-5"
                                            key={directory.id}
                                        >
                                            <Card
                                                style={{
                                                    width: "100%",
                                                }}
                                            >
                                                <Card.Img
                                                    className="template-featured-image"
                                                    variant="top"
                                                    src={`${directory.full_image_url}`}
                                                    onError={(e) =>
                                                        getDefaultImage(e)
                                                    }
                                                />
                                                <Card.Body>
                                                    <Card.Title className="fw-bold my-3">
                                                        {directory.post_title}
                                                    </Card.Title>
                                                    <Card.Text>
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: directory.post_content,
                                                            }}
                                                        />
                                                    </Card.Text>
                                                    <div className="d-grid gap-2">
                                                         <a
                                                            href={
                                                                directory.post_url
                                                            }
                                                            className="btn btn-primary text-white"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {t(
                                                                "common.buttons.view_details"
                                                            )}
                                                        </a>
                                                        <Link
                                                            to={
                                                                `/directory/` +
                                                                src
                                                            }
                                                            className="btn btn-primary text-white"
                                                        >
                                                            {t(
                                                                "common.buttons.edit_details"
                                                            )}
                                                        </Link>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    );
                                })}
                            </>
                        )}
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default DirectoryListing;
