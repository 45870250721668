import Question from "./Question";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import Plus from "@/components/icons/Plus";
import { ordinalSuffixOf, getAnswer } from "@/Utility";
function RepeatableQuestionField({
    question,
    errors,
    register,
    unregister,
    questionIndex,
    inputNameSuffix,
    sectionIndex,
    watch,
    setValue,
    resetField,
    answers,
    auditMode,
}) {
    const { t } = useTranslation();

    const [questionCounter, setQuestionCounter] = useState(1);
    const [questionChildren, setQuestionChildren] = useState([]);
    const [groupShowOther, setGroupShowOther] = useState(false);
    const [otherQuestion, setOtherQuestion] = useState(null);
    const [otherQuestions, setOtherQuestions] = useState([]);
    const [repeat, setRepeat] = useState(1);

    const addQuestionRepeat = (x) => {
        //console.log("questionChildren ", questionChildren);
        let children = questionChildren;
        children.push(question.children);
        setQuestionChildren([].concat(children));

        /* const specificQuestionId = question.children[0]?.id; 
        console.log("specificQuestionId ",specificQuestionId);
        const filteredAnswers = getAnswersByQuestionId(
            answers,
            specificQuestionId
        );
        console.log("TOT ANSWER :", filteredAnswers, filteredAnswers.length);
         */
    };

    const removeQuestionRepeat = () => {
        let children = questionChildren;

        unregister(`${inputNameSuffix}.children[${children.length - 1}]`);
        children.pop();
        setQuestionChildren([].concat(children));
    };

    const addOtherQuestion = () => {
        let questions = otherQuestions;
        questions.push(otherQuestion);
        setOtherQuestions([].concat(questions));
    };

    useEffect(() => {
        //addQuestionRepeat();

        if (!question || !question.children || question.children.length === 0) {
            // addQuestionRepeat();
        } else {
            /* const specificQuestionId = (question.children[0]) ? question.children[0]?.id : 0; 
                const filteredAnswers = getAnswersByQuestionId(answers, specificQuestionId);

                if (filteredAnswers.length > 0) {
                    filteredAnswers.forEach(answer => {
                        // Log the ID if needed
                        console.log('Answer ID:', answer.id);
                        addQuestionRepeat();
                    });
                } */
            const specificQuestionId = question.children[0]?.id || 0;

            const getAnswersByQuestionId = (answers, questionId) => {
                return answers
                    ? answers.filter(
                          (answer) => answer.question_id === questionId
                      )
                    : [];
            };

            const filteredAnswers = getAnswersByQuestionId(
                answers,
                specificQuestionId
            );

            if (filteredAnswers.length > 0) {
                filteredAnswers.forEach((answer) => {
                    addQuestionRepeat();
                });
            } else {
                addQuestionRepeat();
            }
        }

        let otherQuestion = question.children.find((_) => _.is_other);
        setOtherQuestion(otherQuestion);
    }, [question, answers]);

    useEffect(() => {
        if (otherQuestion && answers) {
            let otherQuestionAnswers = answers.filter(
                (_) => _.question_id === otherQuestion.id
            );
            let questions = [];
            otherQuestionAnswers.forEach((_) => {
                questions.push(otherQuestion);
            });
            setOtherQuestions([].concat(questions));
        }
    }, [answers, otherQuestion]);

    return (
        <>
            {questionChildren.map((children, repeatIndex) => {
                return (
                    <div key={repeatIndex}>
                        {children.map((childQuestion, childIndex) => {
                            /* let childQueAnswer = getAnswer(answers, repeatIndex, childQuestion)
                            let typeOfQA = childQueAnswer === null || (typeof childQueAnswer === 'object' && Object.keys(childQueAnswer).length === 0);
                            console.log(" answers" ,childQueAnswer, typeOfQA);
                            if (typeOfQA) {
                                return null;
                            } */

                            if (childQuestion.is_other && !groupShowOther) {
                                return null;
                            }
                            return (
                                <Question
                                    key={childIndex}
                                    repeatIndex={
                                        question.type === 3 ? repeatIndex : null
                                    }
                                    childQuestion={question}
                                    question={childQuestion}
                                    errors={
                                        errors &&
                                        errors[questionIndex] &&
                                        errors[questionIndex].children &&
                                        errors[questionIndex].children[
                                            repeatIndex
                                        ]
                                            ? errors[questionIndex].children[
                                                  repeatIndex
                                              ]
                                            : {}
                                    }
                                    register={register}
                                    unregister={unregister}
                                    index={childIndex}
                                    watch={watch}
                                    resetField={resetField}
                                    inputNameSuffix={`${inputNameSuffix}.children[${repeatIndex}][${childIndex}]`}
                                    sectionIndex={sectionIndex}
                                    setValue={setValue}
                                    answers={answers}
                                    auditMode={auditMode}
                                />
                            );
                        })}
                        {otherQuestions.map(
                            (otherChildQuestion, OtherIndex) => (
                                <Question
                                    key={OtherIndex + children.length}
                                    repeatIndex={OtherIndex}
                                    childQuestion={question}
                                    question={otherChildQuestion}
                                    errors={
                                        errors &&
                                        errors[questionIndex] &&
                                        errors[questionIndex].children &&
                                        errors[questionIndex].children[
                                            repeatIndex
                                        ]
                                            ? errors[questionIndex].children[
                                                  repeatIndex
                                              ]
                                            : {}
                                    }
                                    register={register}
                                    unregister={unregister}
                                    index={OtherIndex + children.length}
                                    watch={watch}
                                    resetField={resetField}
                                    inputNameSuffix={`${inputNameSuffix}.children[${repeatIndex}][${
                                        children.length + OtherIndex
                                    }]`}
                                    sectionIndex={sectionIndex}
                                    setValue={setValue}
                                    answers={answers}
                                    auditMode={auditMode}
                                />
                            )
                        )}
                    </div>
                );
            })}
            {questionChildren.length > 1 &&
                !auditMode &&
                question.type !== 6 && (
                    <Button
                        variant="link"
                        className="p-0 mb-1 mt-3"
                        onClick={removeQuestionRepeat}
                    >
                        <span className="text-danger">
                            {t("common.remove", { attribute: question.label })}
                        </span>
                    </Button>
                )}
            {!auditMode && !question.add_other && question.type !== 6 && (
                <div className="d-grid gap-2 mt-3">
                    <Button onClick={addQuestionRepeat}>
                        <span className="text-white">
                            {t("common.add", { attribute: question.label })}
                        </span>
                        <Plus />
                    </Button>
                </div>
            )}
            {question.add_other && otherQuestion && (
                <div className="d-grid gap-2 mt-3">
                    <Button
                        variant="success"
                        onClick={() => addOtherQuestion()}
                    >
                        <span className="text-white">
                            {t("common.add", {
                                attribute: otherQuestion.label,
                            })}
                        </span>
                        <Plus />
                    </Button>
                </div>
            )}
        </>
    );
}

export default RepeatableQuestionField;
