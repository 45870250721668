import LOCATION from "@/constants/Location";

import TransactionPage from "@/views/pages/Transactions";

const Transactions = [
    {
        path: LOCATION.TRANSACTIONS.LIST.path,
        component: TransactionPage,
        exact: true,
        translation_key: "pages.transactions.title",
        main: true,
        gate: "transactions",
    },
];

export default Transactions;
