import { Card, Col, Row } from 'react-bootstrap'
import React from 'react'
import { useTranslation } from 'react-i18next'

function PaymentMethodInformation ({ user, setPaymentMethod, selectedPaymentMethod }) {
  const { t } = useTranslation()
  return (
    <>
      {
        user.payment_methods.map(paymentMethod =>
          <Card role="button" className={`my-2 payment-method-card ${ selectedPaymentMethod?.id === paymentMethod.id ? 'active' : ''}`}
                onClick={() => setPaymentMethod ? setPaymentMethod(paymentMethod) : null}>
            <Card.Body>
              <Row key={paymentMethod.id}>
                <Col className="col-6">
                  <h6 className="my-2">{t('common.card')}</h6>
                  <h6 className="fw-bold">{t('pages.plans.card', {
                    brand: paymentMethod.brand,
                    cardNumber: paymentMethod.card_number
                  })}</h6>
                </Col>
                <Col className="col-6">
                  <h6 className="my-2">{t('common.expires')}</h6>
                  <h6 className="fw-bold">{paymentMethod.pm_expire}</h6>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        )
      }
    </>

  )
}

export default PaymentMethodInformation