import { useHistory, useLocation, useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useAuth } from "@/services/Auth";
import LOCATION from "@/constants/Location";
import LoadingCover from "@/components/LoadingCover";
import AssessmentFilters from "@/views/components/AssessmentFilters";
import Table, { useColumns } from "@/views/components/Tables/Assessments";
import { Card, Col, Row } from "react-bootstrap";

//import UpdateTokens from "./Forms/UpdateTokens";
import moment from "moment";
//import CancelSubscription from "./components/CancelSubscription";
//import PromotionButton from "./components/PromotionButton";
import TableReport, {
    useColumnsReport,
} from "@/views/components/Tables/TableReport";
function UsersIndex() {
    const history = useHistory();
    const route = useLocation();
    const auth = useAuth();
    const { t } = useTranslation();
    let { type } = useParams();
    //console.log("type ", type, window.decodeURIComponent(window.atob(type)));
    const [assessmentTemplates, setAssessmentTemplates] = useState([]);
    const [assessmentStatus, setAssessmentStatus] = useState([]);
    const [loading, setLoading] = useState(true);
    const [subscription, setSubscription] = useState(null);

    const { id } = useParams();
    let token = localStorage.getItem("token") || null;
    const [user, setUser] = useState(null);

    const [query, setQuery] = useState(
        (route.state && route.state.query) || { page: 0, skip: 0, limit: 15 }
    );

    const [searching, setSearching] = useState(false);
    const [activeTab, setActiveTab] = useState("all_course");
    const [assessmentReports, setAssessmentReports] = useState([]);
    const getSubscription = () => {
        let request = auth.getRequest(
            `${LOCATION.SUBSCRIPTIONS.API.path}/${id}`
        );

        request
            .then((response) => {
                setSubscription(response.data);
            })
            .catch((error) => {});

        return request;
    };

    useEffect(() => {
        // let request = getSubscription();
    }, []);

    useEffect(() => {
        /* let request = auth.getRequest(LOCATION.ASSESSMENT_TEMPLATES.API.path);

        request
            .then((response) => {
                setAssessmentTemplates(response.data.data);
            })
            .catch((error) => {});

        return () => request?.abort && request.abort(); */
    }, []);

    useEffect(() => {
        /* let request = auth.getRequest(`${LOCATION.ASSESSMENT_STATUS.API.path}`);

        request
            .then((response) => {
                setAssessmentStatus(response.data.assessment_status);
            })
            .catch((error) => {});

        return () => request?.abort && request.abort(); */
    }, []);

    useEffect(() => {
        setLoading(false);
        /* let request = auth.getRequest(`${LOCATION.USERS.API.path}/${id}`);

        request
            .then((response) => {
                setUser(response.data);
                setLoading(false);
                let searchQuery = { ...query };
                searchQuery.user_id = response.data.id;
                setQuery(searchQuery);
            })
            .catch((error) => {});

        return () => request?.abort && request.abort(); */
    }, []);

    useEffect(() => {
        history.replace(route.pathname, {
            ...route.state,
            query,
        });
    }, [query]);

    useEffect(() => {
        const assessmentReportArray = [
            { id: "all", title: "All" },
            { id: "firesafety", title: "Fire Safety" },
            { id: "healthsafety", title: "Health Safety" },
            { id: "firedoor", title: "Fire Door" },
        ];
        setAssessmentReports(assessmentReportArray);
    }, []);

    const decodedType = window.decodeURIComponent(window.atob(type));

    const hasMyCourses = decodedType.includes("my_courses");

    const src = hasMyCourses
        ? decodedType
        : decodedType + "&t=" + window.btoa(auth.user.email);
    if (loading) return <LoadingCover />;

    return (
        <>
            <Row className="h-100">
                <Col className="col-md-12 col-12 p-4">
                    <div className="my-4 bg-white">
                        <iframe
                            src={src}
                            frameborder={"0"}
                            style={{
                                position: "fixed",

                                top: "0",
                                right: "0",
                                bottom: "0",
                                left: "0",
                                width: "85%",
                                height: "95%",

                                marginTop: "3%",
                                marginLeft: "15%",
                            }}
                        ></iframe>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default UsersIndex;
