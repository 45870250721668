import { Editor } from "@tinymce/tinymce-react";

function TinyMceEditor({
    value,
    onEditorChange,
    initialValue,
    textareaName,
    register,
}) {
    return (
        <Editor
            apiKey={process.env.REACT_APP_TINY_MCE_KEY}
            value={value}
            onEditorChange={(e) => onEditorChange(e)}
            initialValue={initialValue}
            textareaName={textareaName ? textareaName : ""}
            register
        />
    );
}
export default TinyMceEditor;
