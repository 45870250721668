import { Card, Form } from "react-bootstrap";
import { Elements } from "@stripe/react-stripe-js";
import StripeSetupForm from "./StripeSetupForm";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/services/Auth";
import PaymentMethodInformation from "../components/PaymentMethodInformation";
// import PaymentMethodInformationSquare from "../components/PaymentMethodInformationSquare";
import LoadingButton from "../../../../components/LoadingButton";

import SquareSetupForm from "./SquareSetupForm";

//import styles from '../styles/Home.module.css'
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
// import { SquarePaymentForm, SquarePaymentFormLoader } from 'react-square-payment-form';

function PaymentMethodForm({
    processSubmit,
    savePaymentMethod,
    stripePromise,
    stripeSetupFormRef,
    errors,
    loading,
    source,
    makePayment,
    plan,
    assessment,
    AuditPrice,
}) {
    const auth = useAuth();
    const { t } = useTranslation();
    const [paymentFormLoaded, setPaymentFormLoaded] = useState(false);

    const [paymentMethod, setPaymentMethod] = useState(
        auth.user.payment_method
    );
    const requestBuyCredits = () => {
        makePayment({
            plan_id: plan?.id,
            payment_method_id: paymentMethod?.id,
        });
    };

    const requestBuyAudit = () => {
        makePayment({
            assessment_id: assessment?.id,
            payment_method_id: paymentMethod?.id,
        });
    };

    return (
        <div className="container  mt-5" style={{ maxWidth: "600px" }}>
            <Card className="mt-2">
                <Form noValidate onSubmit={processSubmit(savePaymentMethod)}>
                    <Card.Body>
                        {source === "by-credit" ? (
                            <Card.Title>
                                {t("pages.payments.trip_method_form")}
                            </Card.Title>
                        ) : (
                            <Card.Title>
                                {t("pages.payments.payment_method_form")}
                            </Card.Title>
                        )}
                        <div className="my-3">
                            {!auth.user.payment_method ? (
                                <>
                                    {/* <PaymentMethodInformationSquare
                                        user={auth.user}
                                        setPaymentMethod={setPaymentMethod}
                                        selectedPaymentMethod={paymentMethod}
                                    /> */}
                                    <PaymentMethodInformation
                                        user={auth.user}
                                        setPaymentMethod={setPaymentMethod}
                                        selectedPaymentMethod={paymentMethod}
                                    />
                                </>
                            ) : (
                                <>
                                    <div className="Sqpay">
                                        {/* <PaymentForm
                                            applicationId="sandbox-sq0idb-m--4XXu4kiyjZVs3CfYQuA" //DEV ACCOUNT
                                            locationId="LRKXQ3JJN25QN" //DEV ACCOUNT VM
                                            cardTokenizeResponseReceived={(
                                                token,
                                                buyer
                                            ) => {
                                                console.info({ token, buyer });
                                                console.info("TOKENM ", token);
                                                console.info("BUYER ", buyer);
                                                //  savePaymentMethod(token, buyer);
                                                // verifyBuyer(payments,token);
                                            }}
                                            ccreateVerificationDetails={() => ({
                                                // You can avoid amount and currency if the intent is `STORE`
                                                amount: "1.00",
                                                currencyCode: "GBP",
                                                // `CHARGE` or `STORE`
                                                intent: "STORE",
                                                billingContact: {
                                                    addressLines: [
                                                        "123 Main Street",
                                                        "Apartment 1",
                                                    ],
                                                    familyName: "Doe",
                                                    givenName: "John",
                                                    email: "jondoe@gmail.com",
                                                    country: "GB",
                                                    phone: "3214563987",
                                                    region: "LND",
                                                    city: "London",
                                                },
                                            })}

                                        >
                                            <CreditCard
                                                buttonProps={{
                                                    className:
                                                        "btn-primary auth-btn text-uppercase fw-bold text-white heading-text",
                                                    style: {
                                                        width: "100%",
                                                        backgroundColor:
                                                            "#ff5d17",
                                                        color: "#fff",
                                                    },
                                                }}
                                            >
                                                {t(
                                                    "pages.payments.buttons.save_payment_method"
                                                )}
                                            </CreditCard>
                                        </PaymentForm> */}
                                        <hr></hr>
                                        <hr></hr>

                                        <PaymentForm
                                            applicationId="sandbox-sq0idb-m--4XXu4kiyjZVs3CfYQuA" //DEV ACCOUNT
                                            // applicationId="sandbox-sq0idb-wmEpXIMiE40-9QHlVM2D6g" //loc  ation id : LD8CZ9ABZHBDV
                                            ref={stripeSetupFormRef}
                                            /* createPaymentRequest={() => ({
                                                countryCode: "GB", // Set the country code to UK (GB)
                                                currencyCode: "GBP", // Set the currency code to GBP for UK
                                                total: {
                                                    amount: "1.00",
                                                    label: "Total",
                                                },
                                                requestShippingAddress: true, // Request shipping address including the postcode
                                            })} */
                                            locationId="LRKXQ3JJN25QN" //DEV ACCOUNT VM
                                            //locationId="LD8CZ9ABZHBDV"
                                            cardTokenizeResponseReceived={(
                                                token,
                                                buyer
                                            ) => {
                                                console.info({ token, buyer });
                                                console.info("TOKENM ", token);
                                                console.info("BUYER ", buyer);
                                                savePaymentMethod(token, buyer);
                                                // verifyBuyer(payments,token);
                                            }}
                                            /**
                                             * This function enable the Strong Customer Authentication (SCA) flow
                                             *
                                             * We strongly recommend use this function to verify the buyer and reduce
                                             * the chance of fraudulent transactions.
                                             */
                                            createVerificationDetails={() => ({
                                                amount: (
                                                    plan.price / 100
                                                ).toFixed(2),
                                                // collected from the buyer
                                                billingContact: {
                                                    /* addressLines: [
                                                        "123 Main Street",
                                                        "Apartment 1",
                                                    ],
                                                    familyName: "Doe", */
                                                    givenName: "John",
                                                    countryCode: "GB",
                                                    // city: "London",
                                                },
                                                currencyCode: "GBP",
                                                intent: "CHARGE", //// `CHARGE` or `STORE`
                                            })}
                                            verifyBuyer={async (
                                                payments,
                                                paymentVerificationDetails
                                            ) => {
                                                try {
                                                    const buyerVerificationResult =
                                                        await payments.verifyBuyer(
                                                            paymentVerificationDetails
                                                        );
                                                    console.log(
                                                        "Buyer verification result: ",
                                                        buyerVerificationResult
                                                    );
                                                    return buyerVerificationResult;
                                                } catch (error) {
                                                    console.error(
                                                        "Error verifying buyer: ",
                                                        error
                                                    );
                                                    throw error;
                                                }
                                            }}
                                        >
                                            <CreditCard
                                                buttonProps={{
                                                    className:
                                                        "btn-primary auth-btn text-uppercase fw-bold text-white heading-text",
                                                    style: {
                                                        width: "100%",
                                                        backgroundColor:
                                                            "#ff5d17",
                                                        color: "#fff",
                                                    }, // Make the button full-width
                                                }}
                                            >
                                                {/* {t(
                                                    "pages.payments.buttons.save_payment_method"
                                                )} */}
                                                {t(
                                                    "pages.payments.buttons.buy_one_credit",
                                                    {
                                                        price: (
                                                            plan.price / 100
                                                        ).toFixed(2),
                                                        currency:
                                                            auth.user.currency,
                                                        credits: plan.credits,
                                                    }
                                                )}
                                            </CreditCard>
                                        </PaymentForm>
                                    </div>
                                </>
                            )}
                        </div>
                    </Card.Body>
                    {false ? (
                    <Card.Footer className="d-grid gap-2 " >
                        {source === "buy-credits" &&
                        auth.user.payment_method &&
                        plan ? (
                            <LoadingButton
                                className="auth-btn text-uppercase fw-bold text-white heading-text"
                                loading={loading}
                                variant="primary"
                                size="lg"
                                onSubmit={requestBuyCredits}
                                titleTranslationKey={t(
                                    "pages.payments.buttons.buy_one_credit",
                                    {
                                        price: (plan.price / 100).toFixed(2),
                                        currency: auth.user.currency,
                                        credits: plan.credits,
                                    }
                                )}
                                type="button"
                            />
                        ) : source === "audit-request" &&
                          auth.user.payment_method &&
                          assessment ? (
                            <LoadingButton
                                className="auth-btn text-uppercase fw-bold text-white heading-text"
                                loading={loading}
                                variant="primary"
                                size="lg"
                                onSubmit={requestBuyAudit}
                                titleTranslationKey={t(
                                    "pages.payments.buttons.request_audit",
                                    {
                                        price: (AuditPrice / 100).toFixed(2),
                                        currency: auth.user.currency,
                                    }
                                )}
                                type="button"
                            />
                        ) : (
                            <>
                                {/* <LoadingButton
                                className="auth-btn text-uppercase fw-bold text-white heading-text"
                                loading={loading}
                                variant="primary"
                                titleTranslationKey={
                                    source === "buy-trip"
                                        ? t(
                                              "pages.payments.buttons.save_payment_method_and_submit",
                                              {
                                                  price: plan
                                                      ? (
                                                            plan.price / 100
                                                        ).toFixed(2)
                                                      : (
                                                            AuditPrice / 100
                                                        ).toFixed(2),
                                                  currency: auth.user.currency,
                                              }
                                          )
                                        : t(
                                              "pages.payments.buttons.save_payment_method"
                                          )
                                }
                                type="submit"
                            /> */}
                            </>
                        )}
                    </Card.Footer>
                    ) : null }

                </Form>
            </Card>
        </div>
    );
}

export default PaymentMethodForm;

/*

$card = new \Square\Models\Card();
$card->setExpMonth(11);
$card->setExpYear(2025);
$card->setCardholderName('Smith Sen');
$card->setCustomerId('ZM4CXQPZWYB9CRV83S7SWZ03V0');
$card->setReferenceId('101');
$card->setVersion(1);

$body = new \Square\Models\CreateCardRequest(
    'ff9c2783-d3f3-4363-976d-204db9067b4c',
    'cnon:CBASEFdtuPq-wAchXlWlFsagDMs',
    $card
);
$body->setVerificationToken('verf:CA4SEFYpeAgIUPRrWxm9jZ-QhgggACgB');

$api_response = $client->getCardsApi()->createCard($body);

if ($api_response->isSuccess()) {
    $result = $api_response->getResult();
} else {
    $errors = $api_response->getErrors();
}

{
  "card": {
    "id": "ccof:CBASELTVVHl5t0vroBUdGp6h9MU",
    "card_brand": "MASTERCARD",
    "last_4": "0026",
    "exp_month": 11,
    "exp_year": 2025,
    "cardholder_name": "Smith Sen",
    "billing_address": {
      "postal_code": "cf51qd"
    },
    "fingerprint": "sq-1-TfLPvb48j0HiwHHwvmxnrw2FSlcqf6xUpTTHR4j90Si73m2vlByvgjig7N2yFe8jKg",
    "customer_id": "ZM4CXQPZWYB9CRV83S7SWZ03V0",
    "merchant_id": "ML1YEQEKAEQSM",
    "reference_id": "101",
    "enabled": true,
    "card_type": "CREDIT",
    "prepaid_type": "NOT_PREPAID",
    "bin": "524848",
    "created_at": "2024-03-29T12:08:32Z",
    "version": 1
  }
}


-----------------------------
$amount_money = new \Square\Models\Money();
$amount_money->setAmount(2499);
$amount_money->setCurrency('GBP');

$body = new \Square\Models\CreatePaymentRequest('cnon:CBASEHWof9XylBJd4NTYR3sK8D8', '8732f041-10f6-4e79-8733-0e1f6af0bd73');
$body->setAmountMoney($amount_money);
$body->setCustomerId('ZM4CXQPZWYB9CRV83S7SWZ03V0');
$body->setVerificationToken('verf:CA4SEGNfWESUJqUSdHCiim5fF6EgACgB');
$body->setBuyerEmailAddress('bbdev83@yopmail.com');

$api_response = $client->getPaymentsApi()->createPayment($body);

if ($api_response->isSuccess()) {
    $result = $api_response->getResult();
} else {
    $errors = $api_response->getErrors();
}


{
  "payment": {
    "id": "lgRfLbS4DSZhRKFl5UkevMyeTFVZY",
    "created_at": "2024-03-29T12:19:18.159Z",
    "updated_at": "2024-03-29T12:19:18.436Z",
    "amount_money": {
      "amount": 2499,
      "currency": "GBP"
    },
    "status": "COMPLETED",
    "delay_duration": "PT168H",
    "source_type": "CARD",
    "card_details": {
      "status": "CAPTURED",
      "card": {
        "card_brand": "VISA",
        "last_4": "1019",
        "exp_month": 11,
        "exp_year": 2025,
        "fingerprint": "sq-1-jVWDCeCVGT8NKv2yTOKq8G2gJRleXKthd1xV3VTiLMkS2HpivT-L8v81mOBCYyfMJQ",
        "card_type": "CREDIT",
        "prepaid_type": "NOT_PREPAID",
        "bin": "431000"
      },
      "entry_method": "KEYED",
      "cvv_status": "CVV_ACCEPTED",
      "avs_status": "AVS_ACCEPTED",
      "statement_description": "SQ *DEFAULT TEST ACCOUNT",
      "card_payment_timeline": {
        "authorized_at": "2024-03-29T12:19:18.275Z",
        "captured_at": "2024-03-29T12:19:18.436Z"
      }
    },
    "location_id": "LRKXQ3JJN25QN",
    "order_id": "vuCKQ6lKfVW4l1mI4oUQIRthoxSZY",
    "risk_evaluation": {
      "created_at": "2024-03-29T12:19:18.275Z",
      "risk_level": "NORMAL"
    },
    "buyer_email_address": "bbdev83@yopmail.com",
    "customer_id": "ZM4CXQPZWYB9CRV83S7SWZ03V0",
    "total_money": {
      "amount": 2499,
      "currency": "GBP"
    },
    "approved_money": {
      "amount": 2499,
      "currency": "GBP"
    },
    "receipt_number": "lgRf",
    "receipt_url": "https://squareupsandbox.com/receipt/preview/lgRfLbS4DSZhRKFl5UkevMyeTFVZY",
    "delay_action": "CANCEL",
    "delayed_until": "2024-04-05T12:19:18.159Z",
    "application_details": {
      "square_product": "ECOMMERCE_API",
      "application_id": "sandbox-sq0idb-m--4XXu4kiyjZVs3CfYQuA"
    },
    "version_token": "yYsBguFaaqTFhMnzigs4Q2DwaDn69WK59gqiblsCPVa6o"
  }
}

*/
