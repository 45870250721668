const MyAssessments = {
    title: "My Fire Risk Assessments",
    new_assessment: "New Fire Risk Assessment",
    new_assessment_begin: "Begin",
    services: "Service Credits",
    credits: "Credits",
    update_credit: "Update Credit",
    form: {
        credit: "Credit",
        placeholder_credit: "Enter Credit",
    },
    credit_updated_success: "Credit update successfully",
};
export default MyAssessments;
