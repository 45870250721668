import {ProSidebar, SidebarContent} from "react-pro-sidebar";
import {useAuth} from "@/services/Auth";
import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import LoadingButton from "../../components/LoadingButton";
import {useTranslation} from "react-i18next";
import Logout from "../../components/icons/Logout";
import Private from "../../router/Private";


function Aside({collapsed, toggled, handleToggleSidebar}) {
    const auth = useAuth();
    const {t} = useTranslation();
    const location = useLocation();

    let privateRoutes = Private;

    const [loading, setLoading] = useState(false);
    const [routes, setRoutes] = useState([]);

    const logout = () => {
        setLoading(true);
        auth.logout()
            .then(response => {
                setLoading(false);
                window.location.href="/login";
            })
            .catch(error => {
                setLoading(false);
            })
    }

    useEffect(() => {
        setRoutes(() => privateRoutes.filter(_ => _.main))
    }, [])
    return (
        <ProSidebar
            collapsed={collapsed}
            toggled={toggled}
            breakPoint="xl"
            onToggle={handleToggleSidebar}
        >
            <SidebarContent>
                <div className="d-flex flex-column sidebar-content text-white">
                    <ul className="list-group p-4">
                        {
                            routes.map((route, index) =>
                              auth.user.abilities[route.gate] || !route.gate ?
                                <Link
                                    to={route.path}
                                    className={`list-group-item text-white border-0 ${route.path === location.pathname ? 'active-link' : ''}`}
                                    key={index}
                                >
                                    {t(route.translation_key)}
                                </Link> : null)
                        }
                    </ul>
                    <div className="content-footer mt-auto p-4">
                        <Link to="/terms-and-condition" className="text-white">{t('common.terms_and_condition')}</Link>
                        <p className="mt-2">© {new Date().getFullYear()} FRA Wizard</p>
                        <div className="d-grid gap-2">
                            <LoadingButton
                                size="sm"
                                icon={<Logout/>}
                                className="text-white"
                                onSubmit={logout}
                                titleTranslationKey='common.logout'
                                loading={loading}
                            />
                        </div>
                        {/* <p className="h6 mt-3">SITE BY STAXO</p> */}
                    </div>
                </div>
            </SidebarContent>
        </ProSidebar>
    )
}

export default Aside;
