import LOCATION from '@/constants/Location'
import AssessmentAuditIndex from '@/views/pages/AssessmentAudit'
import Audit from '@/views/pages/AssessmentAudit/Audit'

const AssessmentAudit = [
  {
    path: LOCATION.ASSESSMENTS_AUDIT.LIST.path,
    component: AssessmentAuditIndex,
    exact: true,
    translation_key: 'pages.assessment_audit.title',
    icon: '',
    main: true,
    gate: 'assessment_audit',
  },
  {
    path: LOCATION.ASSESSMENTS_AUDIT.AUDIT.path,
    component: Audit,
    exact: true,
    translation_key: 'pages.assessment_audit.title',
    icon: '',
    main: false,
    gate: 'assessment_audit',
  },
]
export default AssessmentAudit