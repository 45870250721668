import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/services/Auth";
import Paginate from "../Paginate";
import LOCATION from "@/constants/Location";
import * as moment from "moment";
function useColumns(filter, deleteRequest) {
    const { t } = useTranslation();

    let columns = [
        {
            dataField: "user.name",
            text: t("pages.transactions.table.name"),
        },
        {
            dataField: "plan.plan_name",
            text: t("pages.transactions.table.plan_name"),
        },

        {
            dataField: "plan.price",
            text: t("pages.transactions.table.price"),
            formatter: (cell, row) => {
                return row.plan && "£" + (row.plan.price / 100).toFixed(2);
            },
        },
        {
            dataField: "plan.credits",
            text: t("pages.transactions.table.credits"),
        },
        {
            dataField: "plan.plan_type",
            text: t("pages.transactions.table.plan_type"),
            formatter: (cell, row) => {
                return row.plan && row.plan.plan_type === 1
                    ? t("pages.transactions.one_time")
                    : t("pages.transactions.subscription");
            },
        },
        {
            dataField: "created_at",
            text: t("pages.transactions.table.buy_date"),
            formatter: (datetime) =>
                datetime &&
                moment(datetime).format(t("common.formats.short_date")),
        },

        {
            dataField: "status_name",
            text: t("pages.transactions.table.status"),
        },
    ];
    /* columns.push({
        dataField: "actions",
        text: "",
        formatter: (cell, row) => (
            <FontAwesomeIcon
                icon={faTrashAlt}
                className="text-danger"
                onClick={() => deleteRequest(row.id)}
                role="button"
            />
        ),
    }); */

    return (filter && columns.filter(filter)) || columns;
}

function LicenseKey({ data: recordsProp, paginate, url, query, ...props }) {
    let auth = useAuth();
    const { t } = useTranslation();

    const perPage = query.limit || 20;
    const exclude = props.exclude || [{ dataField: auth.user.id }];
    const showPagination = paginate == null ? true : paginate;

    const request = useRef(null);
    const [page, setPage] = useState(0);
    const [totalRecords, setTotal] = useState(false);
    const [recordsDownloaded, setRecords] = useState(false);

    const getRecords = () => {
        request?.current?.abort && request.current.abort();

        const critera = { ...(query || {}) };
        critera.take = perPage;
        critera.skip = page * perPage;
        critera.skip = page * perPage;
        critera.page = page + 1;
        request.current = auth.getRequest(
            url || LOCATION.TRANSACTIONS.API.path,
            critera
        );
        setRecords(false);
        request.current.then((response) => {
            setRecords(response.data.data);
            setTotal(response.data.length);
        });
    };

    const columns = useColumns(
        (_) => !exclude.includes(_.dataField) && !exclude.includes(_.name),
        props.deleteRequest
    );

    const changePage = (event) => {
        setPage(event.selected);
    };

    const records = recordsProp || recordsDownloaded;

    useEffect(() => {
        if (!recordsProp) {
            getRecords();
        }

        return () => request?.current?.abort && request.current.abort();
    }, [page, query, url]);

    useEffect(() => {
        props.onData && props.onData(records);
    }, [records]);

    useEffect(() => {
        props.onTotal && props.onTotal(totalRecords);
    }, [totalRecords]);

    return (
        <div className="fra-table">
            <BootstrapTable
                keyField="id"
                responsive
                hover
                columns={columns || []}
                data={records || []}
                noDataIndication={
                    records instanceof Array
                        ? t("common.no_data")
                        : t("common.loading")
                }
                {...props}
            />
            {(showPagination && records && totalRecords && (
                <Paginate {...{ changePage, totalRecords, page, perPage }} />
            )) ||
                ""}
        </div>
    );
}

export default LicenseKey;

export { useColumns };
