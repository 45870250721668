import LOCATION from "@/constants/Location";

import FireExtinguiserPage from "@/views/pages/FireExtinguiser/FireExtinguiser";
//import HealthSafetyPolicy from "@/views/pages/HealthSafetyPolicy/HealthSafetyPolicy";

const FireExtinguiser = [
    {
        path: LOCATION.FIRE_EXTINGUISER.LIST.path,
        component: FireExtinguiserPage,
        exact: true,
        translation_key: "pages.fire_extinguiser.title",
        main: true,
        //gate: "my_account",
        order: 4,
    },
];
export default FireExtinguiser;
