import React, { useState } from "react";
import Envelope from "@/components/icons/Envelope";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/services/Auth";
import { Modal, Button, Form } from "react-bootstrap";
import LoadingButton from "../../../components/LoadingButton";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import LOCATION from "@/constants/Location";
import ConfirmModal from "@/components/modals/ConfirmModal";
function SendLinkModal({ elementName, id, is_display }) {
    const { t } = useTranslation();
    const auth = useAuth();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [errors, setErrors] = useState({});
    const [showSuccess, setShowSuccess] = useState(false);
    const [title, setTitle] = useState("");
    const [loading, setLoading] = useState(false);
    const [serverError, setServerError] = useState("");
    const [serverErrors, setServerErrors] = useState([]);

    const [formData, setFormData] = useState({
        email: "",
    });
    const showModal = () => {
        setErrors({});
        setServerError("");
        setServerErrors([]);
        setFormData({
            email: "",
        });
        setIsModalVisible(true);
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const handleConfirm = () => {
        setShowSuccess(false);
        //setTitle(null);
        //history.push(`${LOCATION.ADMIN_USERS.LIST.path}`);
    };

    const validationSchema = yup.object().shape({
        email: yup
            .string()
            .email(t("common.form_validation.invalid_email_address"))
            .required(
                t("common.form_validation.is_required", {
                    attribute: t("pages.admin_users.form.email"),
                })
            ),
    });

    const handleSendLink = async () => {
        try {
            setServerError("");
            await validationSchema.validate(formData, { abortEarly: false });
            setLoading(true);
            auth.postRequest(
                `${LOCATION.ASSESSMENTS.API.path}/${id}/send-share-link`,
                formData
            )
                .then((response) => {
                    setLoading(false);
                    if (response.data.message === "OK") {
                        setIsModalVisible(false);
                        setTitle(
                            t("common.full_report_link_send")
                        );
                        setShowSuccess(true);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    if (error?.response?.status === 401) {
                        setServerError(error.response.data.message);
                    }

                    if (error.response.status === 422) {
                        let serverErrors = [];
                        for (const key in error.response.data.errors) {
                            serverErrors.push(
                                error.response.data.errors[key][0]
                            );
                        }
                        setServerErrors(serverErrors);
                    }
                });
        } catch (error) {
            const validationErrors = {};
            error.inner.forEach((e) => {
                validationErrors[e.path] = e.message;
            });
            setErrors(validationErrors);
        }
    };
    return (
        <>
            <ConfirmModal
                show={showSuccess}
                title={title}
                confirmText={t("common.dialogs.actions.okay")}
                handleConfirm={handleConfirm}
            />
            <Button
                onClick={() => showModal()}
                variant="light"
                size="sm"
                className="text-primary"
            >
                <span className="me-1">{t("common.send_link")}</span>
                <Envelope color="#FF5D17" />
            </Button>

            <Modal show={isModalVisible} onHide={handleCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("common.send_link")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-4 mt-4" controlId="formEmail">
                        <Form.Label>
                            {t("pages.admin_users.form.email")}
                            <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t("pages.admin_users.form.email")}
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.email}
                        </Form.Control.Feedback>
                    </Form.Group>

                    {serverError !== "" && (
                        <div className="form-group mb-2">
                            <p className="text-danger py-1 mb-0">
                                {t(serverError)}
                            </p>
                        </div>
                    )}
                    {serverErrors.length !== 0 && (
                        <div className="form-group mb-2">
                            {serverErrors.map((error, index) => (
                                <p
                                    className="text-danger font-weight-bold"
                                    key={index}
                                >
                                    {t(error)}
                                </p>
                            ))}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCancel}>
                        {t("common.cancel")}
                    </Button>

                    <LoadingButton
                        className="auth-btn text-white px-5"
                        loading={loading}
                        variant="primary"
                        titleTranslationKey="common.send_link"
                        type="button"
                        onSubmit={handleSendLink}
                    />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default SendLinkModal;
