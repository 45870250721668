import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/services/Auth";
import Paginate from "../Paginate";
import LOCATION from "@/constants/Location";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrash,
    faTrashAlt,
    faPencil,
} from "@fortawesome/free-solid-svg-icons";

function useColumns(filter, deleteRequest) {
    const { t } = useTranslation();
    /* const PlanNameRowFormatter = (cell, row) => {
        return (
            <div
                dangerouslySetInnerHTML={{
                    __html: t(`pages.plans.names.${cell}`),
                }}
            />
        );
    };
    const PlanDurationRowFormatter = (cell, row) => {
        return (
            <div
                dangerouslySetInnerHTML={{
                    __html: t(`pages.plans.durations.${cell}`),
                }}
            />
        );
    }; */

    const PlanPriceRowFormatter = (cell, row) => {
        return (
            <div
                dangerouslySetInnerHTML={{
                    __html: "£" + cell / 100,
                }}
            />
        );
    };
    const PlanTypeRowFormatter = (cell, row) => {
        return (
            <div
                dangerouslySetInnerHTML={{
                    __html:
                        row.plan_type === 1
                            ? t("pages.pricing_plan.form.one_time_payment")
                            : t("pages.pricing_plan.form.subscription"),
                }}
            />
        );
    };
    const PlanStatusRowFormatter = (cell, row) => {
        return (
            <div
                dangerouslySetInnerHTML={{
                    __html:
                        row.status === 1
                            ? t("pages.pricing_plan.active")
                            : t("pages.pricing_plan.in_active"),
                }}
            />
        );
    };
    let columns = [
        {
            dataField: "plan_name",
            text: t("pages.pricing_plan.table.title"),
            // formatter: PlanNameRowFormatter,
        },
        {
            dataField: "price",
            text: t("pages.pricing_plan.table.price"),
            formatter: PlanPriceRowFormatter,
        },
        {
            dataField: "credits",
            text: t("pages.pricing_plan.table.credits"),
        },

        {
            dataField: "plan_type",
            text: t("pages.pricing_plan.table.plan_type"),
            formatter: PlanTypeRowFormatter,
        },

        {
            dataField: "valid_for",
            text: t("pages.pricing_plan.table.valid_for"),
        },
        /*  {
            dataField: "duration",
            text: t("pages.pricing_plan.table.duration"),
            formatter: PlanDurationRowFormatter,
        },

        {
            dataField: "price_per_service",
            text: t("pages.pricing_plan.table.price_per_service"),
        },
        {
            dataField: "subscription_duration",
            text: t("pages.pricing_plan.table.subscription_duration"),
        },*/
        /*  {
            dataField: "status",
            text: t("pages.pricing_plan.table.status"),
            formatter: PlanStatusRowFormatter,
        }, */
    ];
    columns.push({
        dataField: "actions",
        text: "Actions",
        formatter: (cell, row) => (
            <>
                <Link
                    to={`${LOCATION.PRICING_PLANS.API.path}/${row.id}`}
                    className="text-info"
                >
                    <FontAwesomeIcon icon={faPencil} role="button" />
                </Link>
                &nbsp;
                <FontAwesomeIcon
                    icon={faTrashAlt}
                    className="text-danger"
                    onClick={() => deleteRequest(row.id)}
                    role="button"
                />
            </>
        ),
    });

    return (filter && columns.filter(filter)) || columns;
}

function PricingPlan({ data: recordsProp, paginate, url, query, ...props }) {
    let auth = useAuth();
    const { t } = useTranslation();

    const perPage = query.limit || 20;
    const exclude = props.exclude || [{ dataField: auth.user.id }];
    const showPagination = paginate == null ? true : paginate;

    const request = useRef(null);
    const [page, setPage] = useState(0);
    const [totalRecords, setTotal] = useState(false);
    const [recordsDownloaded, setRecords] = useState(false);

    const getRecords = () => {
        request?.current?.abort && request.current.abort();

        const critera = { ...(query || {}) };
        critera.take = perPage;
        critera.skip = page * perPage;
        //critera.page = page + 1;
        request.current = auth.getRequest(
            url || LOCATION.PRICING_PLANS.API.path,
            critera
        );
        setRecords(false);
        request.current.then((response) => {
            setRecords(response.data.data);
            setTotal(response.data.length);
        });
    };

    const columns = useColumns(
        (_) => !exclude.includes(_.dataField) && !exclude.includes(_.name),
        props.deleteRequest
    );

    const changePage = (event) => {
        setPage(event.selected);
    };

    const records = recordsProp || recordsDownloaded;

    useEffect(() => {
        if (!recordsProp) {
            getRecords();
        }

        return () => request?.current?.abort && request.current.abort();
    }, [page, query, url]);

    useEffect(() => {
        props.onData && props.onData(records);
    }, [records]);

    useEffect(() => {
        props.onTotal && props.onTotal(totalRecords);
    }, [totalRecords]);

    return (
        <div className="fra-table">
            <BootstrapTable
                keyField="id"
                responsive
                hover
                columns={columns || []}
                data={records || []}
                noDataIndication={
                    records instanceof Array
                        ? t("common.no_data")
                        : t("common.loading")
                }
                {...props}
            />
            {(showPagination && records && totalRecords && (
                <Paginate {...{ changePage, totalRecords, page, perPage }} />
            )) ||
                ""}
        </div>
    );
}

export default PricingPlan;

export { useColumns };
