import LOCATION from "@/constants/Location";

import FireSafetyPolicyDocumentPage from "@/views/pages/FireSafetyPolicyDocument/FireSafetyPolicyDocument";
//import HealthSafetyPolicy from "@/views/pages/HealthSafetyPolicy/HealthSafetyPolicy";

const FireSafetyPolicyDocument = [
    {
        path: LOCATION.FIRE_SAFETY_POLICY_DOCUMENT.LIST.path,
        component: FireSafetyPolicyDocumentPage,
        exact: true,
        translation_key: "pages.fire_safety_policy_documents.title",
        main: true,
        //gate: "my_account",
        order: 2,
    },
    /*
    {
        path: LOCATION.HEALTH_SAFETY_POLICY.LIST.path,
        component: HealthSafetyPolicy,
        exact: true,
        translation_key: "pages.health_safety_policy.title",
        main: true,
        //gate: "my_account",
    }, */
];
export default FireSafetyPolicyDocument;
