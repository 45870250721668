import React, { useState } from "react";
import { Col, Form, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/services/Auth";
import LOCATION from "@/constants/Location";
import LoadingCover from "@/components/LoadingCover";

function LogoUploads() {
    const { t } = useTranslation();
    const auth = useAuth();
    const baseUrl = auth.api.defaults.baseURL;

    const [companyLogoUrl, setCompanyLogoUlr] = useState(
        auth.user.organization?.logo
            ? `${baseUrl}/${auth.user.organization?.logo}`
            : "/assets/images/fra-wizard-logo.png"
    );
    const [accreditationLogo, setAccreditationLogo] = useState(
        auth.user.organization?.accreditation_logo
            ? `${baseUrl}/${auth.user.organization?.accreditation_logo}`
            : "/assets/images/logo-placeholder.png"
    );
    const [userSign, setUserSing] = useState(
        auth.user.user_signature
            ? `${baseUrl}/${auth.user.user_signature}`
            : "/assets/images/sign-placeholder.png"
    );
    const [accreditationLogoOther, setAccreditationLogoOther] = useState(
        auth.user.organization?.accreditation_logo_other
            ? `${baseUrl}/${auth.user.organization?.accreditation_logo_other}`
            : "/assets/images/logo-placeholder.png"
    );

    const [uploading, setUploading] = useState({
        logo: false,
        accreditation_logo: false,
        user_signature: false,
        accreditation_logo_other: false,
    });

    const [serverErrors, setServerErrors] = useState({
        logo: [],
        accreditation_logo: [],
        user_signature: [],
        accreditation_logo_other: [],
    });

    const uploadImage = (data, type) => {
        setLoadingState(type, true);
        setServerErrorsState(null, []);

        let imageData = data.target.files[0];
        let form = new FormData();
        form.append("image", imageData);
        form.append("type", type);

        auth.postRequest(`${LOCATION.USERS.API.path}/upload-images`, form)
            .then((response) => {
                let uploadedLink = `${baseUrl}/${response.data}`;
                if (type === "logo") {
                    setCompanyLogoUlr(uploadedLink);
                } else if (type === "accreditation_logo") {
                    setAccreditationLogo(uploadedLink);
                } else if (type === "user_signature") {
                    setUserSing(uploadedLink);
                } else if (type === "accreditation_logo_other") {
                    setAccreditationLogoOther(uploadedLink);
                }
                setLoadingState(type, false);
            })
            .catch((error) => {
                setLoadingState(type, false);

                if (error.response.status === 422) {
                    let serverErrors = [];
                    for (const key in error.response.data.errors) {
                        serverErrors.push(error.response.data.errors[key][0]);
                    }
                    setServerErrorsState(type, serverErrors);
                }
            });
    };

    const setLoadingState = (type, value) => {
        let uploadingHolder = { ...uploading };
        uploadingHolder[type] = value;
        setUploading(uploadingHolder);
    };

    const setServerErrorsState = (type, newErrors) => {
        let errors = { ...serverErrors };
        errors.logo = [];
        errors.accreditation_logo = [];
        errors.user_signature = [];
        errors.accreditation_logo_other = [];

        if (type != null) {
            errors[type] = newErrors;
        }
        setServerErrors(errors);
    };

    return (
        <>
            {!auth?.user?.branch_user && (
                <Row className="mt-2 mb-4">
                    <Col className="col-9">
                        <p className="my-4">
                            {t("pages.my_account.logo_uploads_message")}
                        </p>
                    </Col>
                </Row>
            )}
            {!auth?.user?.branch_user && (
                <>
                    <Row className="mt-2 mb-4">
                        <Col className="col-8">
                            <h5 className="">
                                {t("pages.my_account.update_company_logo")}
                            </h5>
                            <p>{t("pages.my_account.logo_minimum_size")}</p>
                            <Form.Control
                                disabled={
                                    !auth.user.organization_id ||
                                    auth.user.branch_user
                                }
                                className="mb-2"
                                onChange={(e) => uploadImage(e, "logo")}
                                type="file"
                                accept="image/*,.jpg,.png,.jpeg"
                            />
                            {serverErrors.logo.length !== 0 && (
                                <div className="form-group mt-4">
                                    {serverErrors.logo.map((error, index) => (
                                        <p
                                            className="text-danger font-weight-bold"
                                            key={index}
                                        >
                                            {error}
                                        </p>
                                    ))}
                                </div>
                            )}
                        </Col>
                        <Col className="col-4">
                            {uploading.logo ? (
                                <div className="logo-uploads">
                                    <LoadingCover />
                                </div>
                            ) : (
                                <Image
                                    className="company-logo logo-uploads"
                                    src={companyLogoUrl}
                                />
                            )}
                        </Col>
                    </Row>
                    <Row className="my-4 pt-4">
                        <Col className="col-8">
                            <h5 className="">
                                {t(
                                    "pages.my_account.update_accreditation_logo"
                                )}
                            </h5>
                            <p>{t("pages.my_account.logo_minimum_size")}</p>
                            <Form.Control
                                disabled={
                                    !auth.user.organization_id ||
                                    auth.user.branch_user
                                }
                                className="mb-2"
                                onChange={(e) =>
                                    uploadImage(e, "accreditation_logo")
                                }
                                type="file"
                                accept="image/*,.jpg,.png,.jpeg"
                            />
                            {serverErrors.accreditation_logo.length !== 0 && (
                                <div className="form-group mt-4">
                                    {serverErrors.accreditation_logo.map(
                                        (error, index) => (
                                            <p
                                                className="text-danger font-weight-bold"
                                                key={index}
                                            >
                                                {error}
                                            </p>
                                        )
                                    )}
                                </div>
                            )}
                        </Col>
                        <Col className="col-4">
                            <Col className="col-4">
                                {uploading.accreditation_logo ? (
                                    <div className="logo-uploads">
                                        <LoadingCover />
                                    </div>
                                ) : (
                                    <Image
                                        className="company-logo logo-uploads-other"
                                        src={accreditationLogo}
                                    />
                                )}
                            </Col>
                        </Col>
                    </Row>
                </>
            )}
            <Row className="my-4 pt-4">
                <Col className="col-8">
                    <h5 className="">
                        {t("pages.my_account.user_signature_for_certification")}
                    </h5>
                    <p>{t("pages.my_account.signature_minimum_size")}</p>
                    <Form.Control
                        className="mb-2"
                        onChange={(e) => uploadImage(e, "user_signature")}
                        type="file"
                        accept="image/*,.jpg,.png,.jpeg"
                    />
                    {serverErrors.user_signature.length !== 0 && (
                        <div className="form-group mt-4">
                            {serverErrors.user_signature.map((error, index) => (
                                <p
                                    className="text-danger font-weight-bold"
                                    key={index}
                                >
                                    {error}
                                </p>
                            ))}
                        </div>
                    )}
                </Col>
                <Col className="col-4">
                    <Col className="col-4">
                        {uploading.user_signature ? (
                            <div className="sign-uploads">
                                <LoadingCover />
                            </div>
                        ) : (
                            <Image
                                className="company-logo sign-uploads"
                                src={userSign}
                            />
                        )}
                    </Col>
                </Col>
            </Row>
            {!auth?.user?.branch_user && (
                <Row className="my-4 pt-4">
                    <Col className="col-8">
                        <h5 className="">
                            {t(
                                "pages.my_account.update_accreditation_logo_other"
                            )}
                        </h5>
                        <p>{t("pages.my_account.logo_minimum_size")}</p>
                        <Form.Control
                            disabled={
                                !auth.user.organization_id ||
                                auth.user.branch_user
                            }
                            className="mb-2"
                            onChange={(e) =>
                                uploadImage(e, "accreditation_logo_other")
                            }
                            type="file"
                            accept="image/*,.jpg,.png,.jpeg"
                        />
                        {serverErrors.accreditation_logo_other.length !== 0 && (
                            <div className="form-group mt-4">
                                {serverErrors.accreditation_logo_other.map(
                                    (error, index) => (
                                        <p
                                            className="text-danger font-weight-bold"
                                            key={index}
                                        >
                                            {error}
                                        </p>
                                    )
                                )}
                            </div>
                        )}
                    </Col>
                    <Col className="col-4">
                        <Col className="col-4">
                            {uploading.accreditation_logo_other ? (
                                <div className="logo-uploads">
                                    <LoadingCover />
                                </div>
                            ) : (
                                <Image
                                    className="company-logo logo-uploads-other"
                                    src={accreditationLogoOther}
                                />
                            )}
                        </Col>
                    </Col>
                </Row>
            )}
        </>
    );
}

export default LogoUploads;
