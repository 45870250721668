const Assessments = {
    title: "Assessments",
    create_new_assessment: "Create New Assessment",
    edit_assessment: "Edit",
    request_audit: "Request Audit",
    send_updated_audit: "Send Updated Audit",
    send_message_with_completed_audit:
        "Send a message with the completed audit.",
    free_audit: "Free Audit",
    explain_other: "Input own option",
    score_your_answer: "Add score for your answer (from 1 to 10)",
    duplicate_assessment: "Duplicate",
    duplicate_assessment_statement:
        "Do you want to duplicate {{attribute}} template?",
    send_audit_report_to_client: "Send Updated Certificate to Client ",
    help_text: "Help Text",
    recommendation_text: "Recommendation added to report",
    reports: "Reports",
    supportive_documents: "Supportive Documents",
    supportive_document_viewer: "Supporting Document Viewer",
    recommendations: "Recommendations",
    view: "View",
    select_option: "Select an option",
    buttons: {
        add_question: "Add Question",
        delete_section: "Delete Section",
        remove_question: "Remove",
        add_choice: "Add Choice",
        add_field_type: "Add Field Type",
        continue_next_step: "Continue to Next Step",
        go_to_ratings_report: "Go to Ratings Report",
        request_audit_for_this_assessment: "Request Audit of this Assessment",
        view_full_report: "View Full Report",
        view_certificate: "View Certificate",
        back_to_my_assessments: "Back to my Assessments",
        back_to_assessments: "Back to Assessments",
        got_to_ratings_report: "Go to Ratings Report",
        save_recommendation: "Save Recommendation",
        continue_view_certificate: "Continue & View Certificate",
        edit_assessment: "Edit Assessment",
        send_audit_report_to_client: "Send Updated Certificate to Client ",
        duplicate: "Duplicate",
        cancel: "Cancel",
        your_competency_report: "Report",
        generate_share_link: "Generate Share Link",
        copy_share_link: "Copy Share Link",
        add_pdf: "Add another PDF to this Category",
        add_category: "Add New Category",
        complete_report: "Complete Report",
        sent_to_audit: "Send to Audit",
    },
    report: {
        title: "Rating Report",
        audit_rating_report: "Audit Rating Report",
        full_report: "Full Report",
        report_certificate: "Report Certificate",
        your_rating_is: "Your Rating is ",
        your_updated_rating_is: "Your Updated Rating is ",
        their_updated_rating_is: "Their Updated Rating is ",
        rated: "Rated ",
        see_how_it_can_be_improved_below: "See how it can be improved below.",
        show_how_it_can_be_improved_below: "Show how it can be improved below.",
        individual_ratings: "Individual Ratings",
        recommendations_for_improvement: "Recommendations for Improvement",
        high: "High",
        medium: "Medium",
        low: "Low",
        no: "No Risk",
        no_recommendations: "No Recommendations",
        fra_assessment_has_been_carried_out_on:
            "The Fire Risk Assessment has been carried out on {{date}}",
        responsible_person: "Responsive Person",
        assessor: "Assessor",
        assessor_signature: "Assessor's Signature",
        fra_signature: "FRA signature",
        add_section_recommendation: "Add Section recommendation",
        how_score_work: "How The Scores Work",
        score_green_text: "A score of 0 indicates no risk.",
        score_yellow_text: "Scores between 1-2 suggest a low risk.",
        score_orange_text: "Scores between 3-6 signify a moderate risk.",
        score_red_text: "Scores between 7-10 indicate a high risk.",

        your_likelihood_rating_is: "Your Likelihood Rating is ",
        your_updated_likelihood_rating_is: "Your Updated Likelihood Rating is ",

        your_severity_rating_is: "Your Severity Rating is ",
        your_updated_severity_rating_is: "Your Updated Severity Rating is ",
        individual_scores: "Individual Scores",
        section_name: "Section Name",
        likelihood: "Likelihood",
        severity: "Severity",
        overall_risk: "Overall Risk",
        risk: "Risk",
    },
    notifications: {
        share_link_copied: "Link Copied",
        delete_assessment: {
            title: "Delete Assessment",
            body: "Are you sure you want to this assessment?",
        },
        assessment_deleted: {
            title: "Assessment Deleted",
        },
    },
    audit: {
        report_sent: "Audit Report has been sent.",
        request_sent: "Audit Request has been sent.",
        assessor_will_be_in_touch:
            "The assessor will be in touch within {{hours}} hours.",
    },
    pdf_report: {
        fire_door_compliance: {
            menu: "My Fire Door Compliance Reports",
            title: "My Fire Door Compliance Reports",
        },
        fire_safety_policy_document: {
            menu: "My Fire Policies",
            title: "My Fire Policies",
        },
        health_and_safety_policy: {
            menu: "My Health & Safety Policies",
            title: "My Health & Safety Policies",
        },

        emergency_lighting: {
            menu: "Emergency Lighting Reports",
            title: "Emergency Lighting Reports",
        },
        fire_extinguiser: {
            menu: "Fire Extinguiser Reports",
            title: "Fire Extinguiser Reports",
        },
    },
    recommendations_text:
        "These recommendations are tailored to ensure the property and its occupants align with safety regulations and standards. Following these guidelines ensures legal compliance and enhances the safety and well-being of all individuals within the premises",
    dialogs: {
        titles: {
            recommendation_saved: "Your Recommendation is saved",
        },
    },
    fra_signature_section: "FRA Signature section",
    local_manag_acceptance: "LOCAL MANAGEMENT ACCEPTANCE",
    local_manag_acceptance_text:
        "I have examined the fire risk assessment and acknowledge its accuracy in detailing the property’s hazards and control measures. I commit to fulfilling any actions under my control that have been identified",
    audited_by    :"AUDITED BY",
    audited_by_text    :"I have reviewed this fire risk assessment and confirm the completed audit. It is now the responsibility of the designated party to ensure that the actions identified as required are implemented.",
    final_sign_off_date: "Final Sign-Off Date",
};
export default Assessments;
