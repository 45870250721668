import { Spinner } from "react-bootstrap";
const LoadingOverlay = () => {
    return (
        <div className="bb-loader-overlay">
            <div className="bb-loader"></div>
            <p className="bb-loader-message">Please wait, generating PDF report</p>
        </div>
    );
};

export default LoadingOverlay;
