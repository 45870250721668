import { Button, Form, Image } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/services/Auth";
import LOCATION from "@/constants/Location";
import LoadingCover from "@/components/LoadingCover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faFilePdf } from "@fortawesome/free-solid-svg-icons";
function PDFUploadField({
    inputNameSuffix,
    register,
    watch,
    question,
    errors,
    questionIndex,
    setValue,
    resetField,
    answer,
    auditMode,
    fieldName,
    assessment,
    pdf, // Updated prop name
}) {
    const auth = useAuth();
    const baseUrl = auth.api.defaults.baseURL;
    const baseUrlPDf = process.env.REACT_APP_API_PDF_URL;
    const { t } = useTranslation();

    const [pdfUrl, setPdfUrl] = useState(null); // Updated state variable name
    const [uploadingPDF, setUploadingPDF] = useState(false); // Updated state variable name

    useEffect(() => {
        register(`${inputNameSuffix}.${fieldName ?? "value"}`);
        if (resetField) {
            resetField(`${inputNameSuffix}.${fieldName ?? "value"}`);
        }
    }, []);

    const uploadPDF = (pdfFile) => {
        setUploadingPDF(true);
        let pdfData = pdfFile.target.files[0];
        let form = new FormData();
        form.append("image", pdfData); // Updated field name

        auth.postRequest(`${LOCATION.ASSESSMENTS.API.path}/upload-pdf`, form) // Updated endpoint
            .then((response) => {
                setValue(
                    `${inputNameSuffix}.${fieldName ?? "value"}`,
                    response.data
                );
                setPdfUrl(response.data); // Updated state variable name
                setUploadingPDF(false);
            })
            .catch((error) => {
                // Handle error
            });
    };

    const removePDF = () => {
        setValue(`${inputNameSuffix}.${fieldName ?? "value"}`, "");
        setPdfUrl(null); // Updated state variable name
    };

    useEffect(() => {
        //console.log(" answer.answer ",answer?.answer);
        if (answer?.answer) {
            setPdfUrl(answer.answer);
            setValue(
                `${inputNameSuffix}.${fieldName ?? "value"}`,
                answer.answer
            );
        }
        if (pdf) {
            pdf = pdf.replace("public/", "");
            setPdfUrl(pdf);
            setValue(`${inputNameSuffix}.${fieldName ?? "value"}`, pdf);
        }
        //console.log("selectedChoice ", answer);
    }, [answer]);

    return (
        <>
            {!auditMode && pdfUrl == null && (
                <>
                    <Form.Control
                        isInvalid={
                            !!(
                                errors &&
                                errors[questionIndex] &&
                                errors[questionIndex][`${fieldName ?? "value"}`]
                            )
                        }
                        className="mb-2"
                        name={`${inputNameSuffix}.${fieldName ?? "value"}`}
                        onChange={(e) => uploadPDF(e)} // Updated function name
                        type="file"
                        accept=".pdf" // Accept only PDF files
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors &&
                            errors[questionIndex] &&
                            errors[questionIndex][`${fieldName ?? "value"}`] &&
                            errors[questionIndex][`${fieldName ?? "value"}`]
                                .message}
                    </Form.Control.Feedback>
                    {!assessment?.id === 2 && (
                        <small>{question.description}</small>
                    )}
                </>
            )}
            {uploadingPDF && (
                <div className="w-100 mt-2 mb-2" style={{ height: "200px" }}>
                    <LoadingCover />
                </div>
            )}
            <div className="w-100 mt-2 mb-2">
                {pdfUrl && (
                    <>
                        {/* Display the PDF */}
                        <a
                            href={`${baseUrlPDf}${pdfUrl}`}
                            target="_blank"
                            className="text-success"
                        >
                            {t("pages.assessments.view")} &nbsp;
                            <FontAwesomeIcon icon={faFilePdf} />
                        </a>
                        {/* <Button
                            variant="link"
                            className="p-0 mb-1 mt-3"
                            onClick={() => removePDF()}
                        >
                            <span className="text-danger">
                                {t("common.buttons.remove")}
                            </span>
                        </Button> */}
                        {/* {!auditMode && !assessment?.id === 2 && (
                            <Button
                                variant="link"
                                className="p-0 mb-1 mt-3"
                                onClick={() => removePDF()}
                            >
                                <span className="text-danger">
                                    {t("common.buttons.remove")}
                                </span>
                            </Button>
                        )} */}
                    </>
                )}
            </div>
        </>
    );
}

export default PDFUploadField;
