import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Button, Card, Form, Row } from "react-bootstrap";
import Plus from "@/components/icons/Plus";
import LoadingCover from "@/components/LoadingCover";

function BranchUsersForm({
    setValue,
    watch,
    register,
    organization,
    errors,
    branch,
    namePrefix,
    unregister,
}) {
    const { t } = useTranslation();

    const [branchUsers, setBranchUsers] = useState([]);

    const user = {
        id: null,
        first_name: null,
        last_name: null,
        email: null,
        phone_number: null,
        password: null,
        status: 1,
    };

    const addUser = () => {
        let users = branchUsers;
        users.push(user);
        setBranchUsers([].concat(users));
    };

    const removeUser = (indexToRemove) => {
        let users = branchUsers;
        users[indexToRemove] = null;
        setBranchUsers([].concat(users));
        unregister(`${namePrefix}[${indexToRemove}]`);
    };

    useEffect(() => {
        if (branch?.users && branch?.users.length !== 0) {
            setBranchUsers(branch.users);
        } else {
            setBranchUsers([user]);
        }
    }, []);

    if (branchUsers.length === 0) {
        return <LoadingCover />;
    }

    return (
        <>
            <Card className="mt-4">
                <Card.Header>
                    <h6 className="">{t("pages.my_account.branch_users")}</h6>
                </Card.Header>
                <Card.Body>
                    {branchUsers.map((user, index) => {
                        return user ? (
                            <div key={index}>
                                <Row className="mt-2">
                                    <input
                                        type="hidden"
                                        name="id"
                                        value={user.id}
                                        {...register(
                                            `${namePrefix}[${index}].id`
                                        )}
                                    />

                                    <Form.Group
                                        className="col col-sm-6 col-md-3 col-12 mt-3"
                                        controlId="formBasicFirstName"
                                    >
                                        <Form.Label>
                                            {t("common.form.first_name")}
                                        </Form.Label>
                                        <Form.Control
                                            placeholder={t(
                                                "common.form.placeholder_enter",
                                                {
                                                    attribute: t(
                                                        "common.form.first_name"
                                                    ),
                                                }
                                            )}
                                            {...register(
                                                `${namePrefix}[${index}].first_name`
                                            )}
                                            isInvalid={
                                                !!(
                                                    errors &&
                                                    errors[index] &&
                                                    errors[index].first_name
                                                )
                                            }
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors &&
                                                errors[index] &&
                                                errors[index].first_name &&
                                                errors[index].first_name
                                                    .message}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group
                                        className="col col-sm-6 col-md-3 col-12 mt-3"
                                        controlId="formBasicLastName"
                                    >
                                        <Form.Label>
                                            {t("common.form.last_name")}
                                        </Form.Label>
                                        <Form.Control
                                            placeholder={t(
                                                "common.form.placeholder_enter",
                                                {
                                                    attribute: t(
                                                        "common.form.last_name"
                                                    ),
                                                }
                                            )}
                                            {...register(
                                                `${namePrefix}[${index}].last_name`
                                            )}
                                            isInvalid={
                                                !!(
                                                    errors &&
                                                    errors[index] &&
                                                    errors[index].last_name
                                                )
                                            }
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors &&
                                                errors[index] &&
                                                errors[index].last_name &&
                                                errors[index].last_name.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group
                                        className="col col-sm-6 col-md-3 col-12 mt-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>
                                            {t("common.form.email")}
                                        </Form.Label>
                                        <Form.Control
                                            placeholder={t(
                                                "common.form.placeholder_enter",
                                                {
                                                    attribute:
                                                        t("common.form.email"),
                                                }
                                            )}
                                            {...register(
                                                `${namePrefix}[${index}].email`
                                            )}
                                            isInvalid={
                                                !!(
                                                    errors &&
                                                    errors[index] &&
                                                    errors[index].email
                                                )
                                            }
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors &&
                                                errors[index] &&
                                                errors[index].email &&
                                                errors[index].email.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group
                                        className="col col-sm-6 col-md-3 col-12 mt-3"
                                        controlId="formBasicPhoneNumber"
                                    >
                                        <Form.Label>
                                            {t("common.form.phone_number")}
                                        </Form.Label>
                                        <Form.Control
                                            placeholder={t(
                                                "common.form.placeholder_enter",
                                                {
                                                    attribute: t(
                                                        "common.form.phone_number"
                                                    ),
                                                }
                                            )}
                                            {...register(
                                                `${namePrefix}[${index}].phone_number`
                                            )}
                                            isInvalid={
                                                !!(
                                                    errors &&
                                                    errors[index] &&
                                                    errors[index].phone_number
                                                )
                                            }
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors &&
                                                errors[index] &&
                                                errors[index].phone_number &&
                                                errors[index].phone_number
                                                    .message}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group
                                        className="col col-sm-6 col-md-3 col-12 mt-3"
                                        controlId="formBasicPassword"
                                    >
                                        <Form.Label>
                                            {t("common.form.password")}
                                        </Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder={t(
                                                "common.form.placeholder_enter",
                                                {
                                                    attribute: t(
                                                        "common.form.password"
                                                    ),
                                                }
                                            )}
                                            {...register(
                                                `${namePrefix}[${index}].password`
                                            )}
                                            isInvalid={
                                                !!(
                                                    errors &&
                                                    errors[index] &&
                                                    errors[index].password
                                                )
                                            }
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors &&
                                                errors[index] &&
                                                errors[index].password &&
                                                errors[index].password.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group
                                        className="col col-sm-6 col-md-3 col-12 mt-3"
                                        controlId="formBasicPassword"
                                    >
                                        <Form.Label>
                                            {t("common.form.user_status")}
                                        </Form.Label>
                                        <div className="d-flex align-items-center ">
                                            <span className="ms-2">
                                                {t("common.form.yes")} &nbsp;
                                            </span>
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                defaultChecked={
                                                    user.status === 1
                                                        ? true
                                                        : false
                                                }
                                                id="custom-switch"
                                                {...register(
                                                    `${namePrefix}[${index}].status`
                                                )}
                                            />
                                            <span className="ms-2">
                                                {" "}
                                                {t("common.form.no")}
                                            </span>
                                        </div>
                                    </Form.Group>
                                </Row>

                                {branchUsers.length > 1 && (
                                    <Button
                                        variant="link"
                                        onClick={() => removeUser(index)}
                                        className="mx-0 px-0 my-2"
                                    >
                                        {t("common.remove", {
                                            attribute: t("common.user"),
                                        })}
                                    </Button>
                                )}
                            </div>
                        ) : null;
                    })}
                </Card.Body>

                <Card.Footer>
                    <div className="d-grid gap-2 mt-4">
                        <Button
                            className="text-white"
                            onClick={() => addUser()}
                        >
                            <span className="me-1">
                                {t("pages.my_account.buttons.add_new_user")}
                            </span>
                            <Plus />
                        </Button>
                    </div>
                </Card.Footer>
            </Card>
        </>
    );
}

export default BranchUsersForm;
