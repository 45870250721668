const LicenseKey = {
    title: "License Key",
    new_licensekey_title: "New License Key",
    redeem_license_key_title: "Redeem License Key Credit",
    redeemed_list: "Redeemed credit list",

    table: {
        title: "License Key",
        status: "Status",
        credits: "Credits",
        status_used: 'Used',
        used_date: 'Date'
    },
    button: {
        new_license_key: "New License Key",
        back: "Back",
        save: "Save",
        redeem_credits: "Redeem Credit",
    },
    form: {
        license_key_no: "License Key Number",
        placeholder_license_key_no: "Enter License Key Number",
        credits: "Credits",
        placeholder_credits: "Enter Credits",
        license_key_created: "License key(s) Created",
        license_key: "License Key",
        redeem_license_key: "License Key",
        license_key_redeem: "License key credit added into your account",
        license_key_redeem_success :  '{{credits}} credit added to your account',
    },
    notifications: {
        delete_license_key: {
            title: "Delete license key",
            body: "Are you sure you want to this license key?",
        },
        license_key_deleted: {
            title: "License key Deleted",
        },
    },
};
export default LicenseKey;
