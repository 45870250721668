function AssessmentScoreHtml({
    score,
    suffix,
    lineSize,
    size,
    color,
    fontSize,
    lineHeight,
    className,
}) {
    const borderStyle = {
        borderColor: `${color}`,
        border: `${lineSize}px solid ${color}`,
        borderRight: "0",
    };
    const progressStyle = {
        width: `${size}px`,
        height: `${size}px`,
        fontSize: `${fontSize}px`,
        lineHeight: `${lineHeight}px`,
       /* borderColor: `${color}`,*/
    };

    return (
        <>
            <div className={"progress "+className} style={progressStyle}>
                <span
                    className="title timer"
                    data-from="0"
                    data-to="100"
                    data-speed="1800"
                >
                    {suffix ? `${score}${suffix}` : score}
                </span>
                <div className="overlay"></div>
                <div className="left" style={borderStyle}></div>
                <div className="right" style={borderStyle}></div>
            </div>
        </>
    );
}

export default AssessmentScoreHtml;
