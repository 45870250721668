import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoadingButton from "../../../components/LoadingButton";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "@/services/Auth";
import LOCATION from "@/constants/Location";
//import {Helmet} from "react-helmet";
import Safe from "react-safe";
import $ from "jquery";
function FireSafetyPolicyDocument(props) {
    const { t } = useTranslation();
    const auth = useAuth();
    const [loading, setLoading] = useState(false);

    const [serverError, setServerError] = useState("");
    const [serverErrors, setServerErrors] = useState([]);
    const baseUrl = auth.api.defaults.baseURL;

    let token = localStorage.getItem("token") || null;

    const [iframeURL, setIframeURL] = useState("");
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const adId = urlParams.get("ad_id");
        let iUrl =
        baseUrl + LOCATION.FIRE_SAFETY_POLICY_DOCUMENT.API.path + "&t=" + token;
        if (adId > 0) {
            iUrl =
                baseUrl +
                LOCATION.FIRE_SAFETY_POLICY_DOCUMENT.API.path +
                "&t=" +
                token +
                "&ad_id=" +
                adId;
        }

        setIframeURL(iUrl);
    }, []);
    return (
        <div className="container h-100 d-flex flex-column align-items-center flex-grow-1">
            <div className="mt-5 p-2">
                <h2 className="fw-bold text-primary">
                    {t("pages.fire_safety_policy_documents.title")}
                </h2>

                <Row>
                    {/* <iframe
                        src={
                            "https://project.frawizard.co.uk/lv/public/api/page-detail?id=24&type=i&uId=" +
                            auth.user.id
                        }
                        width={"1500px"}
                        height={"800px"}
                        style={{ width: "1800px", height: "800px" }}
                    ></iframe> */}
                    {/* "https://project.frawizard.co.uk/lv/public/api/page-detail?id=24&type=i&uId=" + */}
                    <iframe
                        src={
                            iframeURL
                        }
                        frameborder={"0"}
                        style={{
                            position: "fixed",

                            top: "0",
                            right: "0",
                            bottom: "0",
                            left: "0",
                            width: "80%",
                            height: "95%",

                            marginTop: "5%",
                            marginLeft: "15%",
                        }}
                    ></iframe>
                </Row>
            </div>
        </div>
    );
}

export default FireSafetyPolicyDocument;
