const Payments = {
    payment_method: "Payment Method",
    subscribe: "Subscribe",
    subscribe_to_travel_max: "Subscribe to Travel Max",
    buy_trip_plans: "Buy Trip Plans",
    payment_method_form: "Fill/Choose your card below",
    trip_method_form: "Fill your information below to purchase a credit",
    card: "{{brand}} ending in {{cardNumber}}",
    canceled_at: "Canceled on {{date}}",
    subscription_price_per_year: "{{price}} per year",
    subscription_price_per_month: "{{price}} every 3 months",
    buttons: {
        save_payment_method: "Save Payment Method",
        pay_and_subscribe: "Subscribe for {{currency}}{{price}}",
        buy_one_credit: "Buy {{credits}} Credit for {{currency}}{{price}}",
        request_audit: "Request Audit for {{currency}}{{price}}",
        purchase_audit: "Pay {{currency}}{{price}} and submit",
        save_payment_method_and_submit: "Submit to Pay {{currency}}{{price}}",
    },
    form: {
        card_number: "Card Number",
        expire: "Expire Date",
        ccv: "CCV",
    },
    labels: {
        choose_subscription_duration: "Choose Subscription duration",
        yearly_subscription: "Yearly subscription at {{currency}}{{price}}",
        every_three_months_subscription:
            "3 months subscription at {{currency}}{{price}}",
    },
    notifications: {
        payment_method_saved: {
            title: "Payment Method Updated",
        },
        delete_payment_method: {
            title: "Delete Payment method?",
        },
        payment_method_deleted: {
            title: "Payment method deleted",
        },
        cancel_subscription: {
            title: "Cancel Subscription?",
            body: "Are you sure you want to cancel Travel Max Subscription?",
        },
        refund_subscription: {
            title: "Refund Subscription?",
            body: "Are you sure you want to refund Travel Max Subscription?",
        },
        subscription_canceled: {
            title: "Subscription Canceled",
        },
        subscription_refunded: {
            title: "Subscription refunded",
        },
        credit_purchased: {
            title: "Credit Purchased",
            //body: 'Thank you for your purchase, You have received 1 credit to use on any service',
            body: "Thank you for your purchase, You have received {{credits}} credit to use on any service",
        },
        assessment_requested: {
            title: "Audit Requested",
            body: "Your audit request has been received",
        },
    },
};

export default Payments;
