const Directory = {
    title: "Directory",
    all_directory: "All Directory",
    my_directory: "My Directory",
    no_record: "No records found.",
    table: {
        title: "Directory Name",
        status: "Status",
    },
    button: {
        view: "View Detail",
        edit: "Edit",
        add_directory: 'Create my Listing',
    },
};
export default Directory;
