import LOCATION from "@/constants/Location";
import Create from "@/views/pages/Assessments/Create";
import Assessments from "@/views/pages/Assessments";
import AssessmentForm from "@/views/pages/Assessments/Forms/AssessmentForm";
import Report from "@/views/pages/Assessments/Report";
import RequestAudit from "@/views/pages/Assessments/RequestAudit/RequestAudit";
import Certificate from "@/views/pages/Assessments/Certificate";
import FullReport from "@/views/pages/Assessments/FullReport";
import SendAuditReport from "@/views/pages/AssessmentAudit/SendAuditReport";
import AuditPaymentScreen from "@/views/pages/Assessments/RequestAudit/AuditPaymentScreen";
import AuditRequestSuccess from "@/views/pages/Assessments/RequestAudit/AuditRequestSuccess";
import PdfReportFDC from "@/views/pages/AssessmentPdfReport";
import PdfReportFSPD from "@/views/pages/AssessmentPdfReport/FireSafetyPolicyReport";
import PdfReportHSP from "@/views/pages/AssessmentPdfReport/HealthSafetyPolicyReport";
import PdfReportEL from "@/views/pages/AssessmentPdfReport/EmergencyLightingReport";
import PdfReportFE from "@/views/pages/AssessmentPdfReport/FireExtinguiserReport";

const AssessmentRoutes = [
    {
        path: LOCATION.ASSESSMENTS.LIST.path,
        component: Assessments,
        exact: true,
        translation_key: "pages.my_assessments.title",
        main: true,
        gate: "my_assessments",
        order: 4,
    },

    {
        path: LOCATION.ASSESSMENTS.CREATE.path,
        component: Create,
        exact: true,
        translation_key: "pages.my_assessments.new_assessment",
        main: true,
        gate: "my_assessments",
        order: 0,
    },
    {
        path: LOCATION.ASSESSMENTS.FORM.path,
        component: AssessmentForm,
        exact: true,
        translation_key: "pages.my_assessments.new_assessment",
        main: false,
        gate: "my_assessments",
    },
    {
        path: LOCATION.ASSESSMENTS.EDIT_FORM.path,
        component: AssessmentForm,
        exact: true,
        translation_key: "pages.my_assessments.new_assessment",
        main: false,
        gate: "my_assessments",
    },
    {
        path: LOCATION.ASSESSMENTS.REPORT.path,
        component: Report,
        exact: true,
        translation_key: "pages.my_assessments.new_assessment",
        main: false,
        gate: "assessment_report",
    },
    {
        path: LOCATION.ASSESSMENTS.REPORT_AUDIT.path,
        component: Report,
        exact: true,
        translation_key: "pages.my_assessments.new_assessment",
        main: false,
        gate: "assessment_report",
    },
    {
        path: LOCATION.ASSESSMENTS.AUDIT_REQUEST.path,
        component: RequestAudit,
        exact: true,
        translation_key: "pages.my_assessments.new_assessment",
        main: false,
        gate: "my_assessments",
    },
    {
        path: LOCATION.ASSESSMENTS.AUDIT_REQUEST_PAYMENT.path,
        component: AuditPaymentScreen,
        exact: true,
        translation_key: "pages.my_assessments.new_assessment",
        main: false,
        gate: "my_assessments",
    },
    {
        path: LOCATION.ASSESSMENTS.AUDIT_REQUEST_SUCCESS.path,
        component: AuditRequestSuccess,
        exact: true,
        translation_key: "pages.my_assessments.new_assessment",
        main: false,
        gate: "my_assessments",
    },
    {
        path: LOCATION.ASSESSMENTS.SEND_AUDIT_REPORT.path,
        component: SendAuditReport,
        exact: true,
        translation_key: "pages.my_assessments.new_assessment",
        main: false,
        gate: "assessment_report",
    },
    {
        path: LOCATION.ASSESSMENTS.CERTIFICATE.path,
        component: Certificate,
        exact: true,
        translation_key: "pages.my_assessments.new_assessment",
        main: false,
        gate: "assessment_report",
    },
    {
        path: LOCATION.ASSESSMENTS.CERTIFICATE_AUDIT.path,
        component: Certificate,
        exact: true,
        translation_key: "pages.my_assessments.new_assessment",
        main: false,
        gate: "assessment_report",
    },
    {
        path: LOCATION.ASSESSMENTS.FULL_REPORT.path,
        component: FullReport,
        exact: true,
        translation_key: "pages.my_assessments.new_assessment",
        main: false,
        gate: "assessment_report",
    },
    {
        path: LOCATION.ASSESSMENTS.PDF_REPORTS.FIRE_DOOR_COMPLIANCE.path,
        component: PdfReportFDC,
        exact: true,
        translation_key:
            "pages.assessments.pdf_report.fire_door_compliance.menu",
        main: true,
        gate: "my_assessments",
        order: 5,
    },
    {
        path: LOCATION.ASSESSMENTS.PDF_REPORTS.FIRE_SAFETY_POLICY_DOCUMENT.path,
        component: PdfReportFSPD,
        exact: true,
        translation_key:
            "pages.assessments.pdf_report.fire_safety_policy_document.menu",
        main: true,
        gate: "my_assessments",
        order: 6,
    },
    {
        path: LOCATION.ASSESSMENTS.PDF_REPORTS.HEALTH_AND_SAFETY_POLICY.path,
        component: PdfReportHSP,
        exact: true,
        translation_key:
            "pages.assessments.pdf_report.health_and_safety_policy.menu",
        main: true,
        gate: "my_assessments",
        order: 7,
    },
    {
        path: LOCATION.ASSESSMENTS.PDF_REPORTS.EMERGENCY_LIGHTING.path,
        component: PdfReportEL,
        exact: true,
        translation_key: "pages.assessments.pdf_report.emergency_lighting.menu",
        main: true,
        gate: "my_assessments",
        order: 8,
    },
    {
        path: LOCATION.ASSESSMENTS.PDF_REPORTS.FIRE_EXTINGUISER.path,
        component: PdfReportFE,
        exact: true,
        translation_key: "pages.assessments.pdf_report.fire_extinguiser.menu",
        main: true,
        gate: "my_assessments",
        order: 9,
    },
];
export default AssessmentRoutes;
