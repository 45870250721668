import LOCATION from "@/constants/Location";

import Templates from "@/views/pages/CompetencyTests";
import Certificate from "@/views/pages/CompetencyTests/Certificate";

const CompetencyTests = [
    {
        path: LOCATION.COMPETENCY_TESTS.LIST.path,
        component: Templates,
        exact: true,
        translation_key: "pages.competency_templates.test",
        main: true,
        gate: "my_assessments",
        order: 8,
    },
    {
        path: LOCATION.COMPETENCY_TESTS.CERTIFICATE.path,
        component: Certificate,
        exact: true,
        translation_key: "pages.competency_templates.test",
        gate: "my_assessments",
    },
];

export default CompetencyTests;
