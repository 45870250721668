import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { Col, Form, Row } from 'react-bootstrap'
import LoadingButton from '@/components/LoadingButton'

function UserFilters({
  searching,
  values,
  loadUsers,
  requestExport,
  exporting,
}){

  const { t } = useTranslation()
  const {
          resetField,
          handleSubmit,
          register,
          setValue,
          watch,
          unregister,
          formState: { errors },
        } = useForm({
    defaultValues: {
      ...values
    }
  })

  const search = watch('search');
  const subscription = watch('subscription');

  const exportData = () => {
    requestExport({
      search,
      subscription,
    });
  }

  const onSubmit = (values) => {
    loadUsers(values)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Form.Group className="col col-sm-6 col-md-3 col-12">
          <Form.Label>
            {t('common.search')}
          </Form.Label>
          <Form.Control
            {...register('search')}
            placeholder={t('common.search_for_anything')}
            name="search"
          />
        </Form.Group>

        <Form.Group className="col col-sm-6 col-md-3 col-12">
          <Form.Group>
            <Form.Label>
              {t('common.status')}
            </Form.Label>

            <Form.Control
              as="select"
              name="status"
              {...register('subscription')}
              aria-label={t('pages.users.labels.subscription')}
            >
              <option value="" hidden>{t('common.choose')}</option>
              <option value="all">{t('pages.users.subscription_status.all')}</option>
              <option value="active">{t('pages.users.subscription_status.active')}</option>
              <option value="inactive">{t('pages.users.subscription_status.inactive')}</option>
            </Form.Control>
          </Form.Group>
        </Form.Group>
        <Col className="col-sm-6 col-md-3 col-12 d-flex align-items-end">
          <div className="d-grid gap-2 w-100">
            <LoadingButton
              type="submit"
              onlySpinner={true}
              loading={searching}
              className="text-white mt-3 mt-md-0"
              titleTranslationKey="common.apply"
            />
          </div>
        </Col>
        <Col className="col-sm-6 col-md-3 col-12 d-flex align-items-end">
          <div className="d-grid gap-2 w-100">
            <LoadingButton
              type="button"
              variant="success"
              onSubmit={exportData}
              onlySpinner={true}
              loading={exporting}
              className="text-white mt-3 mt-md-0"
              titleTranslationKey="common.export"
            />
          </div>
        </Col>
      </Row>
    </form>
  )

}
export default UserFilters;