import { Card } from 'react-bootstrap'
import FaceFrown from '@/components/icons/FaceFrown'
import { Link } from 'react-router-dom'
import LOCATION from '@/constants/Location'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'

function Error404(){
  const {t} = useTranslation();
  let auth = useAuth();


  return (
    <div className="container request-sent d-flex justify-content-center align-items-center h-100">
      <Card className="border-0  p-5 bg-white">
        <Card.Body className="text-center">
          <FaceFrown/>
          <h3 className="mt-4">{t('common.errors.titles.error404')}</h3>
          <p className="my-4">{t('common.errors.body.error404')}</p>
          <a className="btn btn-primary text-white" href={`${LOCATION.ROOT.path}`}>
            {t('common.go_home')}
          </a>
        </Card.Body>

      </Card>
    </div>
  )
}
export default Error404;