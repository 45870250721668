import { useForm } from 'react-hook-form'
import { Col, Form, InputGroup, Row } from 'react-bootstrap'
import LoadingButton from '@/components/LoadingButton'
import React, { useState } from 'react'
import { useAuth } from '@/services/Auth'
import { useTranslation } from 'react-i18next'
import LOCATION from '@/constants/Location'
import ConfirmModal from '@/components/modals/ConfirmModal'

function UpdateTokens ({user}) {
  const auth = useAuth();
  const {t} = useTranslation();

  const [showSuccess, setShowSuccess] = useState(false);

  const [updating, setUpdating] = useState(false);
  const {
          resetField,
          handleSubmit,
          register,
          setValue,
          watch,
          unregister,
          formState: { errors },
        } = useForm({
    defaultValues: {
     tokens: user.remaining_tokens,
    }
  })

  const onSubmit = (values) => {
    setUpdating(true);
    auth.postRequest(`${LOCATION.USERS.API.path}/update-tokens/${user.id}`, values)
      .then(response => {
        setUpdating(false);
        setShowSuccess(true);
      })
      .catch(error => {

      })

  }
  const handleConfirm = () =>{
    setShowSuccess(false);
  }

  return (
    <>
      <ConfirmModal
        show={showSuccess}
        title={t('pages.users.dialogs.titles.token_updated')}
        confirmText={t('common.dialogs.actions.okay')}
        handleConfirm={handleConfirm}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <InputGroup className="mb-3">
            <Form.Control
              {...register('tokens')}
              placeholder={t('common.user.tokens')}
              name="tokens"
            />
            <LoadingButton
              variant="success"
              type="submit"
              onlySpinner={true}
              loading={updating}
              className="text-white"
              titleTranslationKey="common.update"
            />
          </InputGroup>
        </Row>
      </form>
    </>

  )
}

export default UpdateTokens;