import LOCATION from "@/constants/Location";

import CoursePage from "@/views/pages/Course";
import CoursePageALL from "@/views/pages/Course/CourseListing";

const Course = [
    {
        path: LOCATION.COURSE.LIST.COURSE.path,
        component: CoursePageALL,
        exact: true,
        translation_key: 'pages.course.title',
        main: true,
        gate: 'user_course',
        order: 10,
      },
    {
        path: LOCATION.COURSE.LIST.ALL_COURSE.path,
        component: CoursePage,
        exact: true,
        translation_key: "pages.course.title",
        main: false,
        gate: "user_course",
    },
    {
        path: LOCATION.COURSE.PAGE.ALL_COURSE.path,
        component: CoursePage,
        exact: true,
        translation_key: "pages.course.title",
        main: false,
        gate: "user_course",
    },
];

export default Course;
