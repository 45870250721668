import { Col, Form, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import XIcon from "@/components/icons/XIcon";
import ChevronUpIcon from "@/components/icons/ArrowLeft";
import ChevronDownIcon from "@/components/icons/ArrowRight";
import TinyMceEditor from "@/components/TinyMceEditor";

function ChoicesFormBuilder({
    errors,
    questionIndex,
    choiceIndex,
    register,
    removeChoice,
    setValue,
    answerChoice,
    inputSuffix,
    question,
    watch,
    questionSuffix,
    assessment,
    recommendation,
    recommendations = [], // Array of existing recommendation values
    setRecommendations, // Function to update the recommendations array
}) {
    const { t } = useTranslation();

    const [showChoices, setShowChoices] = useState(false);

    const [choiceScores, setChoiceScores] = useState([]);
    const [choiceScoresLikelihood, setChoiceScoresLikelihood] = useState([]);
    const [choiceScoresSeverity, setChoiceScoresSeverity] = useState([]);

    let userOwnScoreLabel = t("pages.assessment_templates.user_input");

    const answer = watch(`${inputSuffix}.answer`);
    const addOther = watch(`${questionSuffix}.add_other`);
    const readyOnly = watch(`${inputSuffix}.read_only`);

    const [showRecommendations, setShowRecommendations] = useState(true);

    const toggleRecommendations = () => {
        setShowRecommendations(!showRecommendations);
    };
    //const recommendation = watch(`${inputSuffix}.recommendation`);
    useEffect(() => {
        setValue(`${inputSuffix}.answer`, answerChoice?.answer);
        setValue(
            `${inputSuffix}.score`,
            assessment?.assessment_template_type === 2 ? 0 : answerChoice?.score
        );
        /* console.log( "recommendation",
            question?.question_choices?.recommendation,
            answerChoice?.recommendation
        ); */
        setValue(`${inputSuffix}.recommendation`, answerChoice?.recommendation);

        setValue(
            `${inputSuffix}.score_likelihood`,
            assessment?.assessment_template_type === 2
                ? 0
                : answerChoice?.score_likelihood
        );
        setValue(
            `${inputSuffix}.score_severity`,
            assessment?.assessment_template_type === 2
                ? 0
                : answerChoice?.score_severity
        );
    }, [answerChoice]); //watch(`${inputSuffix}.recommendation`)

    useEffect(() => {
        let scores = [];
        let score_likelihood = [];
        let score_severity = [];

        for (let i = 0; i <= 10; i++) {
            scores.push({ label: i, value: i });
        }

        for (let i = 0; i <= 10; i++) {
            score_likelihood.push({ label: i, value: i });
        }
        for (let i = 0; i <= 10; i++) {
            score_severity.push({ label: i, value: i });
        }
        if (addOther && answer === "Other") {
            scores.push({ label: userOwnScoreLabel, value: 20 });
        }
        setChoiceScores(scores);
        setChoiceScoresLikelihood(score_likelihood);
        setChoiceScoresSeverity(score_severity);
        setShowChoices(true);
    }, [question, answer, addOther]);

    if (!showChoices) {
        return null;
    }

    return (
        <>
            <Row className="my-3 w-100">
                <Col className="col-2 col-md-1 d-flex">
                    <div className="counter-space choices py-2 px-2 px-md-3 me-0 me-md-3 d-flex align-items-start">
                        {choiceIndex + 1}
                    </div>
                </Col>
                <Col className="col-9 col-md-10 d-flex flex-column flex-md-row">
                    <input
                        type="hidden"
                        value={answerChoice?.ready_only ?? 0}
                        {...register(`${inputSuffix}.read_only`)}
                    />
                    <Form.Group className="choice-answer mx-0 mx-md-2 mb-2 mb-md-0">
                        <Form.Control
                            disabled={readyOnly && readyOnly != "0"}
                            placeholder={t("common.form.placeholder_enter", {
                                attribute: t("common.form.answer"),
                            })}
                            {...register(`${inputSuffix}.answer`)}
                            isInvalid={
                                !!(
                                    errors &&
                                    errors[choiceIndex] &&
                                    errors[choiceIndex].answer
                                )
                            }
                        />

                        <Form.Control.Feedback type="invalid">
                            {errors &&
                                errors[choiceIndex] &&
                                errors[choiceIndex].answer &&
                                errors[choiceIndex].answer.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    {assessment?.assessment_template_type === 2 ? null : (
                        <Form.Group className="choice-score">
                            <Form.Select
                                type="number"
                                aria-label={t(
                                    "common.form.placeholder_choose",
                                    {
                                        attribute: t("common.form.score"),
                                    }
                                )}
                                placeholder={t(
                                    "common.form.placeholder_choose",
                                    {
                                        attribute: t("common.form.score"),
                                    }
                                )}
                                {...register(`${inputSuffix}.score`)}
                                isInvalid={
                                    !!(
                                        errors &&
                                        errors[choiceIndex] &&
                                        errors[choiceIndex].score
                                    )
                                }
                            >
                                {choiceScores.map((score) => (
                                    <option
                                        value={score.value}
                                        key={score.value}
                                    >
                                        {score.label}
                                    </option>
                                ))}
                            </Form.Select>

                            <Form.Control.Feedback type="invalid">
                                {errors &&
                                    errors[choiceIndex] &&
                                    errors[choiceIndex].score &&
                                    errors[choiceIndex].score.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    )}
                </Col>

                <Col className="col-1 p-0 d-flex align-items-center">
                    <span
                        className="text-danger x-icon mx-3"
                        role="button"
                        onClick={() => removeChoice(choiceIndex)}
                    >
                        <XIcon />
                    </span>
                </Col>
            </Row>
            <Row className="my-3 w-100">
                {assessment?.assessment_template_type === 2 ? null : (
                    <>
                        <Col className="col-2 col-md-1 d-flex"></Col>
                        <Col className="col-9 col-md-10 d-flex flex-column flex-md-row">
                            <input
                                type="hidden"
                                value={answerChoice?.ready_only ?? 0}
                                {...register(`${inputSuffix}.read_only`)}
                            />
                            <Form.Group className="choice-answer mx-0 mx-md-2 mb-2 mb-md-0">
                                <Col className="col-5 v4">
                                    {t("common.score_likelihood")}
                                </Col>
                                <Form.Select
                                    type="number"
                                    aria-label={t(
                                        "common.form.placeholder_choose",
                                        {
                                            attribute: t(
                                                "common.form.score_likelihood"
                                            ),
                                        }
                                    )}
                                    placeholder={t(
                                        "common.form.placeholder_choose",
                                        {
                                            attribute: t(
                                                "common.form.score_likelihood"
                                            ),
                                        }
                                    )}
                                    {...register(
                                        `${inputSuffix}.score_likelihood`
                                    )}
                                    isInvalid={
                                        !!(
                                            errors &&
                                            errors[choiceIndex] &&
                                            errors[choiceIndex].score_likelihood
                                        )
                                    }
                                >
                                    {choiceScoresLikelihood.map((score) => (
                                        <option
                                            value={score.value}
                                            key={score.value}
                                        >
                                            {score.label}
                                        </option>
                                    ))}
                                </Form.Select>

                                <Form.Control.Feedback type="invalid">
                                    {errors &&
                                        errors[choiceIndex] &&
                                        errors[choiceIndex].score_likelihood &&
                                        errors[choiceIndex].score_likelihood
                                            .message}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="choice-score">
                                <Col className="col-5 v4">
                                    {t("common.score_severity")}
                                </Col>
                                <Form.Select
                                    type="number"
                                    aria-label={t(
                                        "common.form.placeholder_choose",
                                        {
                                            attribute: t(
                                                "common.form.score_severity"
                                            ),
                                        }
                                    )}
                                    placeholder={t(
                                        "common.form.placeholder_choose",
                                        {
                                            attribute: t(
                                                "common.form.score_severity"
                                            ),
                                        }
                                    )}
                                    {...register(
                                        `${inputSuffix}.score_severity`
                                    )}
                                    isInvalid={
                                        !!(
                                            errors &&
                                            errors[choiceIndex] &&
                                            errors[choiceIndex].score_severity
                                        )
                                    }
                                >
                                    {choiceScoresSeverity.map((score) => (
                                        <option
                                            value={score.value}
                                            key={score.value}
                                        >
                                            {score.label}
                                        </option>
                                    ))}
                                </Form.Select>

                                <Form.Control.Feedback type="invalid">
                                    {errors &&
                                        errors[choiceIndex] &&
                                        errors[choiceIndex].score_severity &&
                                        errors[choiceIndex].score_severity
                                            .message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </>
                )}
                {/* New code */}
                {showRecommendations && (
                    <Col className="col-12">
                        <Form.Group className="recommendation">
                            <Form.Label>
                                {t("common.form.recommendation")}{" "}
                            </Form.Label>

                            {/* <TinyMceEditor
                            //value={recommendation}
                            onEditorChange={(e) => {
                                setValue(`${inputSuffix}.recommendation`, e);
                            }}
                            textareaName={`${inputSuffix}.recommendation`}
                            initialValue={`${inputSuffix}.recommendation`}
                            {...register(`${inputSuffix}.recommendation`)}
                        /> */}

                            {/* <TinyMceEditor
                            value={watch(`${inputSuffix}.recommendation`)}
                            onEditorChange={(e) => {
                                setValue(`${inputSuffix}.recommendation`, e);
                            }}
                            textareaName={`${inputSuffix}.recommendation`}
                            //initialValue={`${inputSuffix}.recommendation`}
                             //initialValue={watch(`${inputSuffix}.recommendation`)} // Updated line
                            {...register(`${inputSuffix}.recommendation`)}
                        /> */}

                            {/* <TinyMceEditor
                            onEditorChange={(e) => {
                                setValue(`${inputSuffix}.recommendation`, e);
                            }}
                            textareaName={`${inputSuffix}.recommendation`}
                            ref={editorRef} // Pass the editorRef as a prop
                            {...register(`${inputSuffix}.recommendation`)}
                        /> */}

                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder={t(
                                    "common.form.placeholder_enter",
                                    {
                                        attribute: t(
                                            "common.form.recommendation"
                                        ),
                                    }
                                )}
                                {...register(`${inputSuffix}.recommendation`)}
                                defaultValue={recommendation} // Set the default value
                            />
                        </Form.Group>
                    </Col>
                )}
                {/* New code */}
                <Col className="col-1 p-0 d-flex align-items-center">
                    <span
                        className="text-primary mx-3"
                        role="button"
                        onClick={toggleRecommendations}
                    >
                        {showRecommendations ? (
                            <ChevronUpIcon />
                        ) : (
                            <ChevronDownIcon />
                        )}
                    </span>
                </Col>
            </Row>
        </>
    );
}

export default ChoicesFormBuilder;
