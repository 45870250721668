import Dashboard from "@/views/Dashboard";
import LOCATION from "@/constants/Location";

import AssessmentTemplateRoutes from "./pages/AssessmentTemplateRoutes";
import AssessmentRoutes from "./pages/AssessmentRoutes";
import Account from "./pages/Account";
import AssessmentAudit from "./pages/AssessmentAudit";
import Users from "./pages/Users";
import Payments from "./pages/Payments";
import CompetencyTemplates from "./pages/CompetencyTemplates";
import CompetencyTests from "./pages/CompetencyTests";
import OurNetwork from "./pages/OurNetwork";
import FireSafetyPolicyDocument from "./pages/FireSafetyPolicyDocument";
import HealthSafetyPolicy from "./pages/HealthSafetyPolicy";
import OccupancyCalculator from "./pages/OccupancyCalculator";
import FireDoorCompliance from "./pages/FireDoorComplaince";
import DocumentTemplate from "./pages/DocumentTemplate";
import Customise from "./pages/Customise";
import LicenseKey from "./pages/LicenseKeyRoutes";
import Course from "./pages/Course";
import Directory from "./pages/Directory";
import AdminUsers from "./pages/AdminUsers";
import PricingPlan from "./pages/PricingPlan";
import Transactions from "./pages/Transactions";
import EmergencyLighting from "./pages/EmergencyLighting";
import FireExtinguiser from "./pages/FireExtinguiser";

const Private = [
    {
        path: LOCATION.ROOT,
        component: Dashboard,
        exact: true,
        translation_key: "common.dashboard",
        main: false,
    },
    ...FireDoorCompliance,
    ...FireSafetyPolicyDocument,
    ...HealthSafetyPolicy,
    ...EmergencyLighting,
    ...FireExtinguiser,

    ...AssessmentRoutes,
    ...AssessmentTemplateRoutes,
    // ...CompetencyTests,
    ...Course,
    ...OccupancyCalculator,
    ...LicenseKey,

    ...Account,
    ...AssessmentAudit,
    ...Users,
    ...Payments,
    ...CompetencyTemplates,

    ...OurNetwork,
    ...DocumentTemplate,
    // ...Customise,

    ...Directory,
    ...AdminUsers,
    ...PricingPlan,
    ...Transactions,
];
Private.sort((a, b) => a.order - b.order);
export default Private;
