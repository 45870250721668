import LOCATION from '@/constants/Location'
import OurNetworkIndex from '@/views/pages/OurNetwork'
import SingleListing from '@/views/pages/OurNetwork/SingleListing'

const OurNetwork = [
  {
    path: LOCATION.OUR_NETWORK.LIST.path,
    component: OurNetworkIndex,
    public: true,
    authVisit: true,
    exact: true,
  },
  {
    path: LOCATION.OUR_NETWORK.SHOW.path,
    component: SingleListing,
    public: true,
    authVisit: true,
    exact: true,
  },
]
export default OurNetwork