const MyAccount = {
    title: "My Account",
    licence_details: "License Details",
    organisation_details: "Organisation Details",
    logo_uploads: "Form Logos",
    logo_uploads_message:
        "Please upload your company logo, accreditation logo, and digital signature via the designated sections below. These elements will be incorporated into your forms to ensure they reflect your brand's identity and demonstrate compliance with relevant accreditations. Ensure the images are clear and high-resolution and represent your current branding and certification status.",
    business_listing: "My Business Listing",
    promote_listing: "Promote Listing",
    promote_listing_message:
        "If you’d like to promote your listing on the ‘Our Network’ page, please contact us below.",
    page_public_details: "Public Page Details",
    assessor_details: "Assessor Details",
    organisation_branches: "Organisation Branches",
    subscription_details: "Subscription Details",
    branch_users: "Branch Users",
    services: "Services",
    customising_the_forms: "Form Colours",
    customising_the_forms_message:
        "Customise the colour scheme of your forms to match your company's branding. Select a primary and secondary colour that best represents your corporate identity. These colours will be applied to form headers, footers, and other key elements, providing a consistent and professional look across all your documents",
    buttons: {
        update_details: "Update Details",
        save_organisation_details: "Save Organisation Details",
        save_business_listing: "Save My Business Listing",
        add_new_branch: "Add New Branch",
        add_new_user: "Add New User",
        add_new_company: "Add New Company",
        remove_company: "Remove Company",
        remove_service: "Remove Service",
        add_new_service: "Add New Service",
        refresh: "Refresh",
        save_user:" Save User",
        add_organization_users: "Add Organisation User",
    },
    update_company_logo: "Update Company Logo",
    update_accreditation_logo: "Update Accreditation Logo (If Required)",
    logo_minimum_size: "Minimum size: 400 x 400px",
    signature_minimum_size: "Minimum size: 1600 x 400px",
    user_signature_for_certification: "User's Signature for Certificate",
    update_accreditation_logo_other:
        "Update Accreditation Logo (Second) (If Required)",
    rates: {
        per_hour: "Per Hour",
        per_day: "Per Day",
    },
    notifications: {
        titles: {
            promotion_request_sent: "Your Promotion request was sent",
            business_listing_missing: "Business listing Missing",
        },
        body: {
            promotion_request_sent:
                "Please wait and we will contact you for more information",
            business_listing_missing:
                "We couldn't find your business listing, please add business listing to promote",
        },
    },
    organization_users: "Organisation User",
    organization_users_created: "Organisation user created successfully",
    organization_user_status_updated:" User status updated successfully",
    organization_users_update:  "Organisation user updated successfully",

};
export default MyAccount;
