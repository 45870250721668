import { useHistory, useLocation, useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useAuth } from "@/services/Auth";
import LOCATION from "@/constants/Location";
import LoadingCover from "@/components/LoadingCover";
import AssessmentFilters from "@/views/components/AssessmentFilters";
import Table, { useColumns } from "@/views/components/Tables/Assessments";
import { Card, Col, Row, Button } from "react-bootstrap";
//import UpdateTokens from "./Forms/UpdateTokens";
import moment from "moment";

function CourseListing() {
    const history = useHistory();
    const route = useLocation();
    const auth = useAuth();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);

    const [allCourses, setAllCourses] = useState(null);
    const [myCourses, setMyCourses] = useState(null);

    let token = localStorage.getItem("token") || null;

    const [activeTab, setActiveTab] = useState("all_course");

    const getDefaultImage = (e) => {
        e.target.src = `/assets/images/placeholder.png`;
    };
    const getAllCourses = () => {
        let request = auth.getRequest(`${LOCATION.COURSE.LIST.ALL_COURSE.API}`);
        request
            .then((response) => {
                setAllCourses(response.data);
            })
            .catch((error) => {});
        return request;
    };

    useEffect(() => {
        let request = getAllCourses();
    }, []);

    const getMyCourses = () => {
        let request = auth.getRequest(`${LOCATION.COURSE.LIST.MY_COURSE.API}`);
        request
            .then((response) => {
                setMyCourses(response.data);
            })
            .catch((error) => {});
        return request;
    };

    useEffect(() => {
        let request = getMyCourses();
    }, []);

    useEffect(() => {
        setLoading(false);
    }, []);

    if (loading) return <LoadingCover />;

    return (
        <>
            <div className="content">
                <div className="section-header d-flex justify-content-between mb-4">
                    <h4>{t("pages.course.title")}</h4>
                </div>
                <div className="tab-content">
                    <Row className="h-100">
                        <Col className="col-md-12 col-12 p-4">
                            <div className="mb-4">
                                <button
                                    className={`tab-button ${
                                        activeTab === "all_course"
                                            ? "active"
                                            : ""
                                    }`}
                                    onClick={(_) => {
                                        setActiveTab("all_course");

                                        getAllCourses();
                                    }}
                                >
                                    {t("pages.course.all_course")}
                                </button>
                                <button
                                    className={`tab-button ${
                                        activeTab === "my_course"
                                            ? "active"
                                            : ""
                                    }`}
                                    onClick={(_) => {
                                        setActiveTab("my_course");
                                        getMyCourses();
                                    }}
                                >
                                    {t("pages.course.my_course")}
                                </button>
                            </div>

                            {activeTab === "all_course" && (
                                <>
                                    <Row>
                                        {allCourses?.data?.total_result ===
                                        0 ? (
                                            <p>{t("pages.course.no_record")}</p>
                                        ) : (
                                            <>
                                                {allCourses?.data?.courses.map(
                                                    (course) => {
                                                        return (
                                                            <Col
                                                                className="col-12 col-md-6 col-lg-4 mb-5"
                                                                key={course.id}
                                                            >
                                                                <Card
                                                                    style={{
                                                                        width: "100%",
                                                                    }}
                                                                >
                                                                    <Card.Img
                                                                        className="template-featured-image"
                                                                        variant="top"
                                                                        src={`${course.full_image_url}`}
                                                                        onError={(
                                                                            e
                                                                        ) =>
                                                                            getDefaultImage(
                                                                                e
                                                                            )
                                                                        }
                                                                    />
                                                                    <Card.Body>
                                                                        <Card.Title className="fw-bold my-3">
                                                                            {
                                                                                course.title
                                                                            }
                                                                        </Card.Title>
                                                                        <Card.Text>
                                                                            {
                                                                                course.short_description
                                                                            }
                                                                        </Card.Text>
                                                                        <div className="row my-3 d-grid gap-2">
                                                                            <table className="text-center">
                                                                                <thead className="mt-3">
                                                                                    <tr>
                                                                                        <th className="fw-bold my-3">
                                                                                            {t(
                                                                                                "pages.course.price"
                                                                                            )}
                                                                                        </th>
                                                                                        <th className="fw-bold my-3">
                                                                                            {t(
                                                                                                "pages.course.lessons"
                                                                                            )}
                                                                                        </th>
                                                                                        <th className="fw-bold my-3">
                                                                                            {t(
                                                                                                "pages.course.duration"
                                                                                            )}
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>
                                                                                            {course.discount_flag ? (
                                                                                                <>
                                                                                                    <p>
                                                                                                        {
                                                                                                            course.course_price_discounted
                                                                                                        }
                                                                                                    </p>
                                                                                                    <span>
                                                                                                        {
                                                                                                            course.course_price
                                                                                                        }
                                                                                                    </span>
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {
                                                                                                        course.course_price
                                                                                                    }
                                                                                                </>
                                                                                            )}
                                                                                        </td>
                                                                                        <td>
                                                                                            {
                                                                                                course.lessons
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                            {
                                                                                                course.course_duration
                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>

                                                                        <div className="d-grid gap-2">
                                                                            {/* <a
                                                                         href={
                                                                            `${course.course_url}&t=` +
                                                                            token
                                                                        }
                                                                        className="btn btn-primary text-white"
                                                                        rel="noopener noreferrer"
                                                                    >
                                                                        {t(
                                                                            "common.buttons.enroll"
                                                                        )}
                                                                    </a> */}
                                                                            <Link
                                                                                to={`/courses/${btoa(
                                                                                    course.course_url
                                                                                )}`}
                                                                                className="btn btn-primary text-white"
                                                                            >
                                                                                {t(
                                                                                    "common.buttons.enroll"
                                                                                )}
                                                                            </Link>
                                                                        </div>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Col>
                                                        );
                                                    }
                                                )}
                                            </>
                                        )}

                                        {/* <iframe
                                    src={
                                        "https://stagingcourses.frawizard.co.uk/login/course_login?ref=home/courses&t=" +
                                        token
                                    }
                                    frameborder={"0"}
                                    style={{
                                        position: "fixed",

                                        top: "0",
                                        right: "0",
                                        bottom: "0",
                                        left: "0",
                                        width: "80%",
                                        height: "95%",

                                        marginTop: "5%",
                                        marginLeft: "15%",
                                    }}
                                ></iframe> */}
                                    </Row>
                                </>
                            )}

                            {activeTab === "my_course" && (
                                <>
                                    <Row>
                                        {myCourses?.data?.total_result === 0 ? (
                                            <p>{t("pages.course.no_record")}</p>
                                        ) : (
                                            <>
                                                {myCourses?.data?.courses.map(
                                                    (course) => {
                                                        return (
                                                            <Col
                                                                className="col-12 col-md-6 col-lg-4 mb-5"
                                                                key={course.id}
                                                            >
                                                                <Card
                                                                    style={{
                                                                        width: "100%",
                                                                    }}
                                                                >
                                                                    <Card.Img
                                                                        className="template-featured-image"
                                                                        variant="top"
                                                                        src={`${course.course_details.full_image_url}`}
                                                                        onError={(
                                                                            e
                                                                        ) =>
                                                                            getDefaultImage(
                                                                                e
                                                                            )
                                                                        }
                                                                    />
                                                                    <Card.Body>
                                                                        <Card.Title className="fw-bold my-3">
                                                                            {
                                                                                course
                                                                                    .course_details
                                                                                    .title
                                                                            }
                                                                        </Card.Title>
                                                                        <Card.Text>
                                                                            {
                                                                                course
                                                                                    .course_details
                                                                                    .short_description
                                                                            }
                                                                        </Card.Text>
                                                                        <div className="row my-3 d-grid gap-2">
                                                                            <table className="text-center">
                                                                                <thead className="mt-3">
                                                                                    <tr>
                                                                                        <th className="fw-bold my-3">
                                                                                            {t(
                                                                                                "pages.course.quizze"
                                                                                            )}
                                                                                        </th>
                                                                                        <th className="fw-bold my-3">
                                                                                            {t(
                                                                                                "pages.course.lessons"
                                                                                            )}
                                                                                        </th>
                                                                                        <th className="fw-bold my-3">
                                                                                            {t(
                                                                                                "pages.course.duration"
                                                                                            )}
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>
                                                                                            {
                                                                                                course.quizzes
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                            {
                                                                                                course.lectures
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                            {
                                                                                                course.course_duration
                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        <div className="d-grid gap-2">
                                                                            {/* <a
                                                                        href={
                                                                            `${course.course_details.course_url}&t=` +
                                                                            token
                                                                        }
                                                                        className="btn btn-primary text-white"
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                    >
                                                                        {t(
                                                                            "common.buttons.view_details"
                                                                        )}
                                                                    </a> */}

                                                                            <Link
                                                                                to={`/courses/${btoa(
                                                                                    course
                                                                                        .course_details
                                                                                        .course_url
                                                                                )}`}
                                                                                className="btn btn-primary text-white"
                                                                            >
                                                                                {t(
                                                                                    "common.buttons.view_details"
                                                                                )}
                                                                            </Link>
                                                                        </div>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Col>
                                                        );
                                                    }
                                                )}
                                            </>
                                        )}
                                    </Row>
                                </>
                            )}
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default CourseListing;
