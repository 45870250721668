import LOCATION from '@/constants/Location'
import PricingAndPlans from '@/views/pages/Pricing/PricingAndPlans'
import Subscribe from '@/views/pages/Pricing/Subscribe'
import BuyCredits from '@/views/pages/Pricing/BuyCredits'

const Payments = [
  {
    path: LOCATION.PAYMENTS.PRICING_AND_PLANS.path,
    component: PricingAndPlans,
    public: true,
    authVisit: true,
    exact: true,
  },
  {
    path: LOCATION.PAYMENTS.PRICING_AND_PLANS_OUT_OF_TOKENS.path,
    component: PricingAndPlans,
    public: true,
    authVisit: true,
    exact: true,
  },
  {
    path: LOCATION.PLANS.SUBSCRIBE.path,
    component: Subscribe,
    public: true,
    authVisit: true,
    exact: true,
  },
  {
    path: LOCATION.PLANS.BUY_CREDIT.path,
    component: BuyCredits,
    public: true,
    authVisit: true,
    exact: true,
  },
]

export default Payments
