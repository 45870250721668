import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Form, Row } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import LoadingButton from '@/components/LoadingButton'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAuth } from '@/services/Auth'
import LOCATION from '@/constants/Location'

function Login (props) {
  const { t } = useTranslation()
  const auth = useAuth()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [serverError, setServerError] = useState('')
  const [serverErrors, setServerErrors] = useState([])

  let passwordMinLength = 6

  const schema = yup.object().shape({
    email: yup.string()
      .email(t('common.form_validation.invalid_email_address'))
      .required(t('common.form_validation.email_address_is_required')),
    password: yup.string()
      .min(passwordMinLength, t('common.form_validation.password_invalid_min_length', { length: passwordMinLength }))
      .required(t('common.form_validation.password_is_required')),
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const signIn = (values) => {
    setServerError('')
    setLoading(true)
    auth.signIn(values)
      .then(response => {
        setLoading(false)
        history.push('/')
      })
      .catch(error => {
        setLoading(false)
        if (error?.response?.status === 401) {
          setServerError(error.response.data.message)
        }

        if (error.response.status === 422) {
          // let serverErrors = []
          for (const key in error.response.data.errors) {
            serverErrors.push(
              error.response.data.errors[key][0]
            )
          }
          setServerErrors(serverErrors)
        }
      })
  }

  return (
    <div className="row bg-white" style={{ height: '100%' }}>
      <div className="col-sm-8 col-12 border-0 d-flex align-items-center justify-content-center">
        <div className="login-container p-4">
          <Row>
            <span className="mb-5 text-primary fw-bold auth-header">{t('pages.login.title')}</span>
          </Row>
          <Row className="auth-form">
            <Form noValidate onSubmit={handleSubmit(signIn)}>
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <Form.Label className="fw-bold">{t('common.form.email')}</Form.Label>
                <Form.Control
                  type="email"
                  placeholder={t('common.form.email_placeholder')}
                  {...register('email')}
                  isInvalid={!!errors.email}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.email && errors.email.message}
                </Form.Control.Feedback>
              </Form.Group>
              {
                serverError !== '' &&
                <div className="form-group mb-2">
                  <p className="text-danger py-1 mb-0">{t(serverError)}</p>
                </div>
              }
              {
                (serverErrors.length !== 0) &&
                <div className="form-group mb-2">
                  {
                    serverErrors.map((error, index) =>
                      <p className="text-danger font-weight-bold" key={index}>{t(error)}</p>)
                  }
                </div>
              }

              <Form.Group className="mb-4" controlId="formBasicPassword">
                <Form.Label className="fw-bold">{t('common.form.password')}</Form.Label>
                <Form.Control
                  type="password"
                  placeholder={t('common.form.password')}
                  {...register('password')}
                  isInvalid={!!errors.password}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password && errors.password.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-4">
                <Link
                  to={LOCATION.AUTH.FORGOT_PASSWORD.path}
                  className="mx-0 px-0"
                >
                  {t('pages.login.forgot_password')}
                </Link>
              </Form.Group>
              <Form.Group className="py-4" controlId="formBasicCheckbox">
                <Form.Check
                  {...register('remember')}
                  type="checkbox"
                  label={t('common.form.keep_me_signed_in')}
                />
              </Form.Group>
              <LoadingButton
                className="auth-btn text-white px-5 btn-lg"
                loading={loading}
                variant="primary"
                titleTranslationKey="pages.login.title"
                type="submit"
              />


            </Form>
          </Row>

        </div>
      </div>
      <div className="col-4 d-none d-sm-block login-background"></div>
    </div>
  )
}

export default Login
