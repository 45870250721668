import Printer from '@/components/icons/Printer'
import { Button } from 'react-bootstrap'
import React from 'react'
import { useTranslation } from 'react-i18next'

function PrintButton ({ elementName }) {

  const { t } = useTranslation()

  const print = () => {
    const prtHtml = document.getElementById(elementName).innerHTML

    // Get all stylesheets HTML
    let stylesHtml = ''
    for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
      stylesHtml += node.outerHTML
    }
    const WinPrint = window.open('', '_blank', 'left=0,top=0,width=1000,height=800,toolbar=0,scrollbars=0,status=0')

    WinPrint.document.write(
      `<!DOCTYPE html>
        <html dir="ltr">
            <head>
                ${stylesHtml}
            </head>
            <body class="print_body">
                ${prtHtml}
            </body>
        </html>`
    )

    WinPrint.document.close()
    WinPrint.focus()
    setTimeout(() => {
      WinPrint.print()
      WinPrint.onfocus = function () {
        WinPrint.close()
      }
    }, 500)
    // WinPrint.print();
    // setTimeout(() => WinPrint.close(), 500);
    // WinPrint.close();
  }

  return (
    <Button onClick={() => print()} variant="light" size="sm" className="text-primary">
      <span className="me-1">{t('common.print')}</span>
      <Printer/>
    </Button>
  )
}

export default PrintButton