import {
    Button,
    Card,
    Toast,
    ToastContainer,
    Col,
    Image,
} from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import BackButton from "@/views/components/Buttons/BackButton";
import { useParams } from "react-router-dom";
import { useAuth } from "@/services/Auth";
import LoadingCover from "@/components/LoadingCover";
import LOCATION from "@/constants/Location";
import ReportSection from "@/views/components/assessments/ReportSection";
import PrintButton from "@/views/components/Buttons/PrintButton";
import DownloadButton from "@/views/components/Buttons/DownloadButton";
import PageActionSection from "@/components/PageActionSection";
import LoadingButton from "../../../components/LoadingButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faShare, faShareAlt } from "@fortawesome/free-solid-svg-icons";
import AssessmentScoreHtml from "@/views/components/AssessmentScoreHtml";
import Certificate from "@/views/pages/Assessments/Certificate";

import {
    getScoreColorValue,
    getSectionScore,
    getScoreColorCompetency,
    getScoreColorValueCompetency,
    getSectionScoreLikelihood,
    getSectionScoreSeverity,
    getScoreColorClassName,
} from "@/Utility";
import SendLinkModal from "@/views/components/Buttons/SendLinkModal";
import * as moment from "moment";
function FullReport() {
    const { t } = useTranslation();
    const auth = useAuth();
    let { assessment_id, reference_number, share_code, id } = useParams();

    const [loading, setLoading] = useState(true);
    const [generatingLink, setGeneratingLink] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [assessment, setAssessment] = useState(null);

    const reportTemplateRef = useRef(null);
    const [displayAnswerPdfArray, setDisplayAnswerPdfArray] = useState([]);
    const [displayAnswerPdf, setDisplayAnswerPdf] = useState(null);

    const [listRecommendation, setListRecommendation] = useState(null);
    const [headerLogo, setHeaderLogo] = useState(null);
    const baseUrl = auth.api.defaults.baseURL;

    useEffect(() => {
        setHeaderLogo("/assets/images/headeroriginal.png");
    }, []);
    const getListRecommendation = async (data) => {
        let userRecommendation = [];
        try {
            if (assessment_id) {
                const response = await auth.getRequest(
                    `${LOCATION.ASSESSMENTS.RECOMMENDATION_LIST.API.path}/${assessment_id}`
                );

                if (response && response.data) {
                    userRecommendation = response.data;
                }
            }
        } catch (error) {
            console.error("Error fetching recommendation list:", error);
        }
        var recArray = [];
        var selectedAnswerIds = [];
        if (data.answers.length) {
            for (
                let answerRow = 0;
                answerRow < data.answers.length;
                answerRow++
            ) {
                if (data.answers[answerRow].question_choice_id !== null) {
                    selectedAnswerIds.push(
                        parseInt(data.answers[answerRow].question_choice_id)
                    );
                }
            }
        }
        if (data.assessment_template) {
            if (data.assessment_template.assessment_sections) {
                for (
                    let sections = 0;
                    sections <
                    data.assessment_template.assessment_sections.length;
                    sections++
                ) {
                    if (
                        data.assessment_template.assessment_sections[sections]
                            .questions.length > 0
                    ) {
                        for (
                            let rowQuestion = 0;
                            rowQuestion <
                            data.assessment_template.assessment_sections[
                                sections
                            ].questions.length;
                            rowQuestion++
                        ) {
                            for (
                                let rawQusChoice = 0;
                                rawQusChoice <
                                data.assessment_template.assessment_sections[
                                    sections
                                ].questions[rowQuestion].question_choices
                                    .length;
                                rawQusChoice++
                            ) {
                                let isFound = selectedAnswerIds.includes(
                                    data.assessment_template
                                        .assessment_sections[sections]
                                        .questions[rowQuestion]
                                        .question_choices[rawQusChoice].id
                                );
                                if (
                                    isFound === true &&
                                    data.assessment_template
                                        .assessment_sections[sections]
                                        .questions[rowQuestion]
                                        .question_choices[rawQusChoice]
                                        .recommendation != null
                                ) {
                                    let recommendationData =
                                        data.assessment_template
                                            .assessment_sections[sections]
                                            .questions[rowQuestion]
                                            .question_choices[rawQusChoice]
                                            .recommendation;
                                    if (userRecommendation) {
                                        let matchingRecommendation =
                                            userRecommendation.find(
                                                (urItem) =>
                                                    urItem.answer ==
                                                    data.assessment_template
                                                        .assessment_sections[
                                                        sections
                                                    ].questions[rowQuestion]
                                                        .question_choices[
                                                        rawQusChoice
                                                    ].id
                                            );
                                        if (matchingRecommendation) {
                                            recommendationData =
                                                matchingRecommendation
                                                    ? matchingRecommendation.recommendation
                                                    : null;
                                        }
                                    }
                                    recArray.push({
                                        que_no:
                                            sections +
                                            1 +
                                            "." +
                                            (rowQuestion + 1),
                                        name: data.assessment_template
                                            .assessment_sections[sections]
                                            .questions[rowQuestion].label,
                                        parent_id:
                                            data.assessment_template
                                                .assessment_sections[sections]
                                                .id,
                                        parent_name:
                                            data.assessment_template
                                                .assessment_sections[sections]
                                                .name,
                                        answer: data.assessment_template
                                            .assessment_sections[sections]
                                            .questions[rowQuestion]
                                            .question_choices[rawQusChoice]
                                            .answer,
                                        recommendation: recommendationData,
                                    });
                                }
                            }
                        }
                    }
                }
            }
        }

        await setListRecommendation(recArray);
    };
    const getAnswer = async () => {
        //let answers = assessment?.answers;
        let answers = assessment?.answers || [];
        let question =
            assessment?.assessment_template?.assessment_sections?.questions;
        let returnedAnswerPDF = null;

        //const questions = assessment?.assessment_template?.assessment_sections.flatMap((section) => section.questions).filter((question) => question); // Filter out any potential null or undefined values

        const questions =
            assessment?.assessment_template?.assessment_sections
                ?.flatMap((section) => section.questions)
                .filter((question) => question) || [];

        let PdfList1 = [];
        let PdfList = {};
        if (!questions || !answers) {
            // Handle the case where questions or answers are undefined
            console.error("Questions or answers are undefined.");
            return;
        } else {
            questions.forEach((question, questionIndex) => {
                // console.log("Question ", questionIndex + 1, question);

                // Iterate through answers
                answers.forEach((answer, answerIndex) => {
                    //console.log("Answer ", answerIndex + 1, answer);
                    if (answer?.question_choice_id != null) {
                        const choice = question.question_choices.find(
                            (choice) => choice.id == answer?.question_choice_id
                        );
                        if (choice) {
                            returnedAnswerPDF = choice?.pdf;
                            if (returnedAnswerPDF) {
                                console.log(" choice ", choice);
                                returnedAnswerPDF = returnedAnswerPDF.replace(
                                    "public/",
                                    ""
                                );
                                PdfList1.push(returnedAnswerPDF);
                                PdfList[choice.id] = {
                                    name: choice?.answer,
                                    path: returnedAnswerPDF,
                                };
                            }
                        }
                    }
                });
            });
        }
        console.log(" PdfList ", PdfList, PdfList1);
        //setDisplayAnswerPdfArray(PdfList);
        const pdfListJSON = JSON.stringify(PdfList);
        const pdfListPathJSON = JSON.stringify(PdfList1);
        // Set the cookie with the serialized JSON string and an expiration date
        const date = new Date();
        date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000); // One day
        const expires = `expires=${date.toUTCString()}`;
        document.cookie = `pdfList=${pdfListJSON};${expires};path=/`;
        document.cookie = `pdfListOnlyPath=${pdfListPathJSON};${expires};path=/`;
    };

    const generateLink = () => {
        setGeneratingLink(true);
        auth.getRequest(
            `${LOCATION.ASSESSMENTS.API.path}/${assessment_id}/share-link`
        )
            .then((response) => {
                setAssessment(response.data);
                setGeneratingLink(false);
            })
            .catch((error) => {});
    };

    const copyShareLink = () => {
        const shareLink = `${process.env.REACT_APP_URL}${LOCATION.ASSESSMENTS.API.path}/${assessment?.fra_reference_number}/${assessment.share_code}`;
        navigator.clipboard.writeText(shareLink);
        setShowToast(true);
    };

    const getAssessmentById = () => {
        let request = auth.getRequest(
            `${LOCATION.ASSESSMENTS.API.path}/${assessment_id}`
        );
        request
            .then((response) => {
                setAssessment(response.data);
                getListRecommendation(response.data);
                setLoading(false);
            })
            .catch((error) => {});

        return request;
    };

    const getShareAssessment = () => {
        let request = auth.getRequest(
            `${LOCATION.ASSESSMENTS.API.path}/shared/${reference_number}/${share_code}`
        );
        request
            .then((response) => {
                setAssessment(response.data);
                setLoading(false);
            })
            .catch((error) => {});

        return request;
    };

    useEffect(() => {
        let request;
        assessment_id = assessment_id || id;
        if (assessment_id) {
            request = getAssessmentById();
        } else if (reference_number && share_code) {
            request = getShareAssessment();
        }
        getAnswer();
        return () => request?.abort && request.abort();
    }, []);
    getAnswer();
    if (loading || !assessment) {
        return <LoadingCover />;
    }

    return (
        <>
            <Toast
                bg="info"
                className="position-fixed"
                style={{ top: "10px", right: "10px", zIndex: "9999" }}
                show={showToast}
                autohide
                onClose={() => setShowToast(false)}
                delay={3000}
            >
                <Toast.Body>
                    {t("pages.assessments.notifications.share_link_copied")}
                </Toast.Body>
            </Toast>

            {id ? null : (
                <PageActionSection
                    prepend={[
                        auth.user ? <BackButton fillColor="white" /> : null,
                    ]}
                    append={[
                        assessment?.share_code && auth.user ? (
                            <Button
                                size="sm"
                                variant="light"
                                className="text-primary"
                                onClick={() => copyShareLink()}
                            >
                                <span className="me-1">
                                    {t(
                                        "pages.assessments.buttons.copy_share_link"
                                    )}
                                </span>
                                <FontAwesomeIcon size="sm" icon={faCopy} />
                            </Button>
                        ) : auth.user ? (
                            <LoadingButton
                                loading={generatingLink}
                                onSubmit={generateLink}
                                variant="light"
                                className="text-primary btn-sm"
                                titleTranslationKey="pages.assessments.buttons.generate_share_link"
                                type="button"
                                icon={
                                    <FontAwesomeIcon size="sm" icon={faShare} />
                                }
                                size="sm"
                            />
                        ) : null,
                        <SendLinkModal
                        elementName="full-report"
                        id={assessment.id}
                        is_display={assessment?.share_code}
                        />,
                        <PrintButton elementName="full-report" />,
                        <DownloadButton
                            element="full-report"
                            multiplePages={true}
                            fileName={assessment?.assessment_template.title}
                            adminComment={assessment?.admin_comment}
                            pdfWatermark={assessment?.user?.userImageBanner}
                            scale={0.5}
                            id={assessment.id}
                        />,
                    ]}
                    title={t("pages.assessments.report.full_report")}
                    type="primary"
                />
            )}

            <div
                className="container full-report-container my-4"
                id="full-report"
            >
                {/* <Card
                    id="full-report1"
                    className="border-0 shadow"
                    ref={reportTemplateRef}
                >
                    <Card.Body className="certificate-card1 d-flex flex-column p-0">
                        <div style={{ marginBottom: "10px" }}>
                            <h6> &nbsp;</h6>
                            <div className="watermark_header"></div>
                        </div>
                        </Card.Body>
                        </Card> */}
                <Card
                    id="full-report"
                    className="border-0 shadow"
                    ref={reportTemplateRef}
                >
                    <Card.Body className="">
                        {/* <div style={{ marginBottom: "10px" }}>
                            <h6> &nbsp;</h6>
                            <div className="watermark_header"></div>
                        </div> */}
                        {/* <div className="bt-10"> */}
                        <h3
                            className="my-4"
                            style={{ fontFamily: "Arial, sans-serif" }}
                        >
                            {assessment?.assessment_template?.title}
                        </h3>
                        {assessment.assessment_template.assessment_sections.map(
                            (assessmentSection, index) => (
                                <>
                                    <ReportSection
                                        key={assessmentSection.id}
                                        assessment={assessment}
                                        sectionIndex={index}
                                        assessmentSection={assessmentSection}
                                    />
                                </>
                            )
                        )}
                        <>
                            <div
                                style={{ pageBreakBefore: "always" }}
                                className="divBreak"
                            ></div>
                            <table className="table table-bordered">
                                <tbody>
                                    <tr style={{ background: "#ffe9e0" }}>
                                        <td
                                            colspan="2"
                                            className="w-100 height-border"
                                        >
                                            <div
                                                style={{
                                                    fontWeight: "bold",
                                                    textAlign: "left",
                                                    marginBottom: "15px",
                                                }}
                                            >
                                                RISK RATINGS
                                            </div>
                                            <p style={{ textAlign: "left" }}>
                                                The FRA Wizard scoring system
                                                consists of three key
                                                components: the{" "}
                                                <b>Overall Risk Score</b>, which
                                                comprehensively assesses the
                                                specific question, considering
                                                potential fire hazards and their
                                                consequences; the{" "}
                                                <b> Likelihood Score</b>, which
                                                gauges the probability of hazard
                                                occurrence based on activity
                                                frequency; and the{" "}
                                                <b>Severity Score</b>, which
                                                evaluates the potential harm to
                                                people, property, and the
                                                environment in the event of a
                                                hazard.
                                            </p>
                                            <p style={{ textAlign: "left" }}>
                                                These scores combine to
                                                comprehensively evaluate fire
                                                risks, aiding informed
                                                decision-making and risk
                                                mitigation.
                                            </p>

                                            <p style={{ textAlign: "left" }}>
                                                In FRA Wizard's recommendation
                                                section, users will discover
                                                practical steps to improve fire
                                                safety. Implementing these
                                                recommendations directly reduces
                                                overall risk, likelihood, and
                                                severity scores. Following these
                                                guidelines decreases the chances
                                                of fire hazards, lessens
                                                potential harm, and ultimately
                                                leads to safer conditions.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr style={{ background: "#ccc" }}>
                                        <td
                                            colspan="2"
                                            className="w-100 height-border"
                                        >
                                            <div
                                                style={{
                                                    fontWeight: "bold",
                                                    textAlign: "left",
                                                    marginBottom: "5px",
                                                }}
                                            >
                                                Scoring System Legend
                                            </div>
                                            <p
                                                style={{
                                                    textAlign: "left",
                                                    marginBottom: "5px",
                                                }}
                                            >
                                                This colour-coded system makes
                                                it easy to assess the risk
                                                associated with each score
                                                quickly
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th
                                            className="w-50 bg-tabel-details1 height-border bg-no"
                                            style={{
                                                fontFamily: "Arial, sans-serif",
                                                backgroundColor: "#28A745",
                                                color: "#fff",
                                            }}
                                        >
                                            Green
                                        </th>
                                        <td
                                            className="w-50 height-border"
                                            style={{
                                                textAlign: "left !important",
                                            }}
                                        >
                                            A score of 0 indicates no risk
                                        </td>
                                    </tr>
                                    <tr>
                                        <th
                                            className="w-50 bg-tabel-details1 height-border bg-low"
                                            style={{
                                                fontFamily: "Arial, sans-serif",
                                                background: "#FFC107",
                                                color: "#fff",
                                            }}
                                        >
                                            Yellow
                                        </th>
                                        <td
                                            className="w-50 height-border"
                                            style={{
                                                textAlign: "left !important",
                                            }}
                                        >
                                            Scores between 1-2 suggest a low
                                            risk.
                                        </td>
                                    </tr>
                                    <tr>
                                        <th
                                            className="w-50 bg-tabel-details1 height-border bg-high"
                                            style={{
                                                fontFamily: "Arial, sans-serif",
                                                background: "#E67F23",
                                                color: "#fff",
                                            }}
                                        >
                                            Orange
                                        </th>
                                        <td
                                            className="w-50 height-border"
                                            style={{
                                                textAlign: "left !important",
                                            }}
                                        >
                                            Scores between 3-6 signify a
                                            moderate risk.
                                        </td>
                                    </tr>
                                    <tr>
                                        <th
                                            className="w-50 bg-tabel-details1 height-border"
                                            style={{
                                                fontFamily: "Arial, sans-serif",
                                                background: "#DC3545",
                                                color: "#fff",
                                            }}
                                        >
                                            Red
                                        </th>
                                        <td
                                            className="w-50 height-border"
                                            style={{
                                                textAlign: "left !important",
                                            }}
                                        >
                                            Scores between 7-10 indicate a high
                                            risk.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </>
                        <>
                            <div
                                style={{ pageBreakBefore: "always" }}
                                className="divBreak"
                            ></div>
                            <div>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th
                                                colspan="4"
                                                className="title-bg"
                                            >
                                                <h5
                                                    className="m-0"
                                                    style={{
                                                        fontFamily:
                                                            "Arial, sans-serif",
                                                    }}
                                                >
                                                    {t(
                                                        "pages.assessments.report.individual_scores"
                                                    )}
                                                </h5>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th
                                                className="w-25 bg-tabel-details height-border"
                                                style={{
                                                    fontFamily:
                                                        "Arial, sans-serif",
                                                }}
                                            >
                                                {t(
                                                    "pages.assessments.report.section_name"
                                                )}
                                            </th>
                                            <th
                                                className="w-25 bg-tabel-details height-border"
                                                style={{
                                                    fontFamily:
                                                        "Arial, sans-serif",
                                                }}
                                            >
                                                {t(
                                                    "pages.assessments.report.likelihood"
                                                )}
                                            </th>
                                            <th
                                                className="w-25 bg-tabel-details height-border"
                                                style={{
                                                    fontFamily:
                                                        "Arial, sans-serif",
                                                }}
                                            >
                                                {t(
                                                    "pages.assessments.report.severity"
                                                )}
                                            </th>
                                            <th
                                                className="w-25 bg-tabel-details height-border"
                                                style={{
                                                    fontFamily:
                                                        "Arial, sans-serif",
                                                }}
                                            >
                                                {t(
                                                    "pages.assessments.report.overall_risk"
                                                )}
                                            </th>
                                        </tr>

                                        {/* <tr>
                                            <th
                                                className="w-25 bg-tabel-details height-border"
                                                style={{
                                                    fontFamily:
                                                        "Arial, sans-serif",
                                                    verticalAlign: "middle",
                                                }}
                                            >
                                                Fire Warden
                                            </th>
                                            <td className="w-25 height-border">
                                                <div className="progress">
                                                    100
                                                </div>
                                            </td>
                                            <td className="w-25 height-border">
                                                <div className="progress">
                                                    100
                                                </div>
                                            </td>
                                            <td className="w-25 height-border">
                                                <div className="progress">
                                                    100
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th
                                                className="w-25 bg-tabel-details height-border"
                                                style={{
                                                    fontFamily:
                                                        "Arial, sans-serif",
                                                    verticalAlign: "middle",
                                                }}
                                            >
                                                Fire Warden @@@@
                                            </th>
                                            <td className="w-25 height-border">
                                                <div className="progress1x">
                                                    50
                                                </div>
                                            </td>
                                            <td className="w-25 height-border">
                                                <div className="progress1x">
                                                    100
                                                </div>
                                            </td>
                                            <td className="w-25 height-border">
                                                <div className="progress1x">
                                                    70
                                                </div>
                                            </td>
                                        </tr> */}
                                        {assessment.section_scores.map(
                                            (sectionScore, index) => {
                                                if (
                                                    sectionScore
                                                        ?.assessment_template_section
                                                        ?.section_type ===
                                                    "normal"
                                                ) {
                                                    return (
                                                        <>
                                                            <tr>
                                                                <th
                                                                    key={index}
                                                                    className="w-25 bg-tabel-details height-border"
                                                                    style={{
                                                                        fontFamily:
                                                                            "Arial, sans-serif",
                                                                        verticalAlign:
                                                                            "middle",
                                                                    }}
                                                                >
                                                                    {
                                                                        sectionScore
                                                                            .assessment_template_section
                                                                            .name
                                                                    }
                                                                </th>
                                                                <td className="w-25 height-border">
                                                                    <div className="progress1xx centered-progress">
                                                                        <AssessmentScoreHtml
                                                                            color={getScoreColorValue(
                                                                                sectionScore.score_likelihood
                                                                            )}
                                                                            score={
                                                                                sectionScore.score_likelihood
                                                                            }
                                                                            size={
                                                                                40
                                                                            }
                                                                            lineSize={
                                                                                4
                                                                            }
                                                                            fontSize={
                                                                                14
                                                                            }
                                                                            lineHeight={
                                                                                40
                                                                            }
                                                                            className={getScoreColorClassName(
                                                                                sectionScore.score_likelihood
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="w-25 height-border">
                                                                    <div className="progress1x centered-progress">
                                                                        <AssessmentScoreHtml
                                                                            color={getScoreColorValue(
                                                                                sectionScore.score_severity
                                                                            )}
                                                                            score={
                                                                                sectionScore.score_severity
                                                                            }
                                                                            size={
                                                                                40
                                                                            }
                                                                            lineSize={
                                                                                4
                                                                            }
                                                                            fontSize={
                                                                                14
                                                                            }
                                                                            lineHeight={
                                                                                40
                                                                            }
                                                                            className={getScoreColorClassName(
                                                                                sectionScore.score_severity
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="w-25 height-border fr-progress">
                                                                    <div className="progress1x centered-progress">
                                                                        <AssessmentScoreHtml
                                                                            color={getScoreColorValue(
                                                                                sectionScore.score
                                                                            )}
                                                                            score={
                                                                                sectionScore.score
                                                                            }
                                                                            size={
                                                                                40
                                                                            }
                                                                            lineSize={
                                                                                4
                                                                            }
                                                                            fontSize={
                                                                                14
                                                                            }
                                                                            lineHeight={
                                                                                40
                                                                            }
                                                                            className={getScoreColorClassName(
                                                                                sectionScore.score
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    );
                                                }
                                                return null;
                                            }
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            <div>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th
                                                colspan="4"
                                                className="title-bg"
                                            >
                                                <h5
                                                    className="m-0"
                                                    style={{
                                                        fontFamily:
                                                            "Arial, sans-serif",
                                                    }}
                                                >
                                                    Overall Scores
                                                </h5>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th
                                                className="w-25 bg-tabel-details height-border"
                                                style={{
                                                    fontFamily:
                                                        "Arial, sans-serif",
                                                    verticalAlign: "middle",
                                                }}
                                            >
                                                {t(
                                                    "pages.assessments.report.risk"
                                                )}
                                            </th>
                                            <td
                                                colspan="3"
                                                className="w-75 height-border"
                                            >
                                                <div className="centered-progress">
                                                    <AssessmentScoreHtml
                                                        color={getScoreColorValue(
                                                            getSectionScore(
                                                                assessment,
                                                                false
                                                            )
                                                        )}
                                                        score={getSectionScore(
                                                            assessment,
                                                            false
                                                        )}
                                                        size={50}
                                                        lineSize={5}
                                                        fontSize={19}
                                                        lineHeight={50}
                                                        className={getScoreColorClassName(
                                                            getSectionScore(
                                                                assessment,
                                                                false
                                                            )
                                                        )}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th
                                                className="w-25 bg-tabel-details height-border"
                                                style={{
                                                    fontFamily:
                                                        "Arial, sans-serif",
                                                    verticalAlign: "middle",
                                                }}
                                            >
                                                {t(
                                                    "pages.assessments.report.likelihood"
                                                )}
                                            </th>
                                            <td
                                                colspan="3"
                                                className="w-75 height-border"
                                            >
                                                <div className="centered-progress">
                                                    <AssessmentScoreHtml
                                                        color={getScoreColorValue(
                                                            getSectionScoreLikelihood(
                                                                assessment,
                                                                false
                                                            )
                                                        )}
                                                        score={getSectionScoreLikelihood(
                                                            assessment,
                                                            false
                                                        )}
                                                        size={50}
                                                        lineSize={5}
                                                        fontSize={19}
                                                        lineHeight={50}
                                                        className={getScoreColorClassName(
                                                            getSectionScoreLikelihood(
                                                                assessment,
                                                                false
                                                            )
                                                        )}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th
                                                className="w-25 bg-tabel-details height-border"
                                                style={{
                                                    fontFamily:
                                                        "Arial, sans-serif",
                                                    verticalAlign: "middle",
                                                }}
                                            >
                                                {t(
                                                    "pages.assessments.report.severity"
                                                )}
                                            </th>
                                            <td
                                                colspan="3"
                                                className="w-75 height-border"
                                            >
                                                <div className="centered-progress">
                                                    <AssessmentScoreHtml
                                                        color={getScoreColorValue(
                                                            getSectionScoreSeverity(
                                                                assessment,
                                                                false
                                                            )
                                                        )}
                                                        score={getSectionScoreSeverity(
                                                            assessment,
                                                            false
                                                        )}
                                                        size={50}
                                                        lineSize={5}
                                                        fontSize={19}
                                                        lineHeight={50}
                                                        className={getScoreColorClassName(
                                                            getSectionScoreSeverity(
                                                                assessment,
                                                                false
                                                            )
                                                        )}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </>

                        {/* <>
                                <div>
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th
                                                    colspan="4"
                                                    className="title-bg"
                                                >
                                                    <h5
                                                        className="m-0"
                                                        style={{
                                                            fontFamily:
                                                                "Arial, sans-serif",
                                                        }}
                                                    >
                                                        individual Scores
                                                    </h5>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th
                                                    className="w-25 bg-tabel-details height-border"
                                                    style={{
                                                        fontFamily:
                                                            "Arial, sans-serif",
                                                    }}
                                                >
                                                    Section Name
                                                </th>
                                                <th
                                                    className="w-25 bg-tabel-details height-border"
                                                    style={{
                                                        fontFamily:
                                                            "Arial, sans-serif",
                                                    }}
                                                >
                                                    Likelihood
                                                </th>
                                                <th
                                                    className="w-25 bg-tabel-details height-border"
                                                    style={{
                                                        fontFamily:
                                                            "Arial, sans-serif",
                                                    }}
                                                >
                                                    Severity
                                                </th>
                                                <th
                                                    className="w-25 bg-tabel-details height-border"
                                                    style={{
                                                        fontFamily:
                                                            "Arial, sans-serif",
                                                    }}
                                                >
                                                    Overall Risk
                                                </th>
                                            </tr>
                                            <tr>
                                                <th
                                                    className="w-25 bg-tabel-details height-border"
                                                    style={{
                                                        fontFamily:
                                                            "Arial, sans-serif",
                                                        verticalAlign: "middle",
                                                    }}
                                                >
                                                    Fire Warden
                                                </th>

                                                <td className="w-25 height-border">
                                                    <div className="progress1x">
                                                        100
                                                    </div>
                                                </td>
                                                <td className="w-25 height-border">
                                                    <div className="progress1x">
                                                        100
                                                    </div>
                                                </td>
                                                <td className="w-25 height-border">
                                                    <div className="progress1x">
                                                        100
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th
                                                    className="w-25 bg-tabel-details height-border"
                                                    style={{
                                                        fontFamily:
                                                            "Arial, sans-serif",
                                                        verticalAlign: "middle",
                                                    }}
                                                >
                                                    Fire Warden
                                                </th>
                                                <td className="w-25 height-border">
                                                    <div className="progress1x">
                                                        100
                                                    </div>
                                                </td>
                                                <td className="w-25 height-border">
                                                    <div className="progress1x">
                                                        100
                                                    </div>
                                                </td>
                                                <td className="w-25 height-border">
                                                    <div className="progress1x">
                                                        100
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </> */}
                        {listRecommendation &&
                            listRecommendation.length > 0 && (
                                <>
                                    <div
                                        style={{ pageBreakBefore: "always" }}
                                        className="divBreak"
                                    ></div>
                                    <table
                                        className="table table-bordered"
                                        id="table_recommendations"
                                    >
                                        <thead>
                                            <tr>
                                                <th
                                                    colSpan="2"
                                                    className="title-bg"
                                                >
                                                    <h5 className="m-0">
                                                        Section{" "}
                                                        {
                                                            assessment
                                                                .assessment_template
                                                                .assessment_sections
                                                                .length
                                                        }
                                                        :{" "}
                                                        {t(
                                                            "pages.assessments.recommendations"
                                                        )}
                                                    </h5>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td
                                                    colSpan="2"
                                                    className="w-100 bg-tabel-details height-border"
                                                >
                                                    {t(
                                                        "pages.assessments.recommendations_text"
                                                    )}
                                                </td>
                                            </tr>

                                            {listRecommendation.map(
                                                (row, indexLr) => (
                                                    <tr key={indexLr}>
                                                        <th className="w-50 bg-tabel-details height-border">
                                                            {row.que_no}{" "}
                                                            {row.name}
                                                        </th>
                                                        <td className="w-50 height-border bg-recommendation">
                                                            {row.recommendation}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </>
                            )}
                        {/* </div> */}
                        {assessment.audit_by > 0 && (
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th colSpan="2" className="title-bg">
                                            <h5
                                                className="m-0"
                                                style={{
                                                    fontFamily:
                                                        "Arial, sans-serif",
                                                }}
                                            >
                                                <>
                                                    {assessment.audit_by ===
                                                    1 ? (
                                                        <>
                                                            {t(
                                                                "pages.assessments.local_manag_acceptance"
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {t(
                                                                "pages.assessments.audited_by"
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            </h5>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {assessment.audit_by === 1 ? (
                                        <>
                                            <tr>
                                                <td
                                                    colSpan="2"
                                                    className="w-100 bg-tabel-details height-border"
                                                >
                                                    {t(
                                                        "pages.assessments.local_manag_acceptance_text"
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th
                                                    className="w-50 bg-tabel-details height-border"
                                                    style={{
                                                        fontFamily:
                                                            "Arial, sans-serif",
                                                    }}
                                                >
                                                    {t(
                                                        "common.form.assessor_name"
                                                    )}
                                                </th>
                                                <td className="w-50 height-border">
                                                    {assessment.assessor_name}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th
                                                    className="w-50 bg-tabel-details height-border"
                                                    style={{
                                                        fontFamily:
                                                            "Arial, sans-serif",
                                                    }}
                                                >
                                                    {t("common.form.role")}
                                                </th>
                                                <td className="w-50 height-border">
                                                    {assessment.assessor_role}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th
                                                    className="w-50 bg-tabel-details height-border"
                                                    style={{
                                                        fontFamily:
                                                            "Arial, sans-serif",
                                                    }}
                                                >
                                                    {t("common.form.signed")}
                                                </th>
                                                <td className="w-50 height-border">
                                                    {assessment.assessor_sign_url && (
                                                        <Image
                                                            className="company-logo sign-uploads"
                                                            src={`${assessment.assessor_sign_url}`}
                                                        />
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th
                                                    className="w-50 bg-tabel-details height-border"
                                                    style={{
                                                        fontFamily:
                                                            "Arial, sans-serif",
                                                    }}
                                                >
                                                    {t(
                                                        "common.form.local_acceptence_date"
                                                    )}
                                                </th>
                                                <td className="w-50 height-border">
                                                    {
                                                        moment(assessment.local_acceptence_date).format(t("common.formats.short_date"))
                                                    }
                                                </td>
                                            </tr>
                                        </>
                                    ) : (
                                        <>
                                            <tr>
                                                <td
                                                    colSpan="2"
                                                    className="w-100 bg-tabel-details height-border"
                                                >
                                                    {t(
                                                        "pages.assessments.audited_by_text"
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th
                                                    className="w-50 bg-tabel-details height-border"
                                                    style={{
                                                        fontFamily:
                                                            "Arial, sans-serif",
                                                    }}
                                                >
                                                    {t(
                                                        "common.form.assessor_name"
                                                    )}
                                                </th>
                                                <td className="w-50 height-border">
                                                    {
                                                        assessment.admin_assessor_name
                                                    }
                                                </td>
                                            </tr>

                                            <tr>
                                                <th
                                                    className="w-50 bg-tabel-details height-border"
                                                    style={{
                                                        fontFamily:
                                                            "Arial, sans-serif",
                                                    }}
                                                >
                                                    {t("common.form.signed")}
                                                </th>
                                                <td className="w-50 height-border">
                                                    {assessment.admin_assessor_sign_url && (
                                                        <Image
                                                            className="company-logo sign-uploads"
                                                            src={`${assessment.admin_assessor_sign_url}`}
                                                        />
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th
                                                    className="w-50 bg-tabel-details height-border"
                                                    style={{
                                                        fontFamily:
                                                            "Arial, sans-serif",
                                                    }}
                                                >
                                                    {t(
                                                        "common.form.final_sign_off_date"
                                                    )}
                                                </th>
                                                <td className="w-50 height-border">
                                                    {
                                                        assessment.final_sign_off_date
                                                    }
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                </tbody>
                            </table>
                        )}
                    </Card.Body>
                </Card>
            </div>
            <div
                className="container full-report-container my-4"
                id="full-report-html"
            ></div>
        </>
    );
}

export default FullReport;
