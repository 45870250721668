import React, {createContext, useContext, useEffect, useCallback, useState, useRef} from "react";
import {useRollbarPerson, useRollbar} from '@rollbar/react';
import api, {
    setToken, setHandler,
    postRequest,
    getRequest,
    putRequest,
    deleteRequest
} from '../services/Api';
import {useTranslation} from "react-i18next";

const authContext = createContext();
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({children}) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
    return useContext(authContext);
};

function useProvideAuth() {

    const {t, i18n} = useTranslation();
    const rollbar = useRollbar();

    let localUser = localStorage.getItem('user') || null;
    let localLang = localStorage.getItem('lang') || 'en';
    let token = localStorage.getItem('token') || null;


    const [user, setUser] = useState(JSON.parse(localUser));
    const [language, setLanguage] = useState(localLang);
    const [tokenChecked, setTokenChecked] = useState(false);
    const [errorPage, setErrorPage] = useState(false);

    const updateUser = (user) => {
        setUser(user);
    }

    const updateErrorPage = (errorPage) => {
        setErrorPage(errorPage);
    }

    const handleResponse = (response) => {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user));
        setUser(response.data.user);
        setToken(response.data.token);
    }

    const signIn = (payload) => {
        return new Promise((resolve, reject) => {
            postRequest('/login', payload)
                .then(response => {
                    handleResponse(response);
                    resolve(response)
                })
                .catch(error => {
                    reject(error);
                });
        })
    };
    const signUp = (payload) => {
        return new Promise((resolve, reject) => {
            postRequest('/sign-up', payload)
                .then(response => {
                    handleResponse(response);
                    resolve(response)
                })
                .catch(error => {
                    reject(error);
                });
        })
    };

    const logout = () => {
        return new Promise((resolve, reject) => {
            postRequest('/logout')
                .then(response => {
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');
                    resolve(true)
                })
                .catch(error => {
                    reject(error);
                });
        })
    };
    const handleApiErrorResponse = useCallback((error) => {
        if (error?.response)
            switch (error.response.status || 0) {
                case 401:
                    setUser(null);
                    setToken(null);
                    localStorage.removeItem('user');
                    localStorage.removeItem('token');
                    setTokenChecked(true);
                    return;
                    break;

                case 403:
                    setErrorPage(403);
                    return;
                    break;

                case 422:
                    return;
                    break;

                case 0:
                case 404:
                    setErrorPage(error.response.status || 0);
                    return;
                    break;

                default:
                    setErrorPage(500);
            }

        rollbar.error(error);
        console.error(error);
    });

    const checkToken = useCallback(() => {
        return new Promise((resolve, reject) => {
            getRequest('/handshake')
                .then(response => {
                    let data = response.data;
                    localStorage.setItem('user', JSON.stringify(data.user));
                    setUser(data.user);
                    setTokenChecked(true);
                    resolve(response)
                })
                .catch(error => {
                    handleApiErrorResponse(error);
                    reject(error);
                });
        })
    }, []);

    setToken(token);
    useRollbarPerson(user);

    useEffect(() => {
        setHandler(handleApiErrorResponse);
        setToken(token);
    }, []);

    // Return the user object and auth methods
    return {
        user,
        tokenChecked,
        errorPage,
        updateUser,
        language,
        setLanguage,
        signIn,
        signUp,
        logout,
        checkToken,
        postRequest,
        getRequest,
        putRequest,
        deleteRequest,
        updateErrorPage,
        api,
    };
}
