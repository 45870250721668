import { Link, useHistory, useLocation } from "react-router-dom";
import LOCATION from "@/constants/Location";
import { useTranslation } from "react-i18next";
import Plus from "@/components/icons/Plus";
import { useEffect, useState } from "react";
import { useAuth } from "@/services/Auth";
import LoadingCover from "@/components/LoadingCover";
import Table, {
    useColumns,
} from "@/views/components/Tables/PdfReportEnergencyLighting";

function EmergencyLightingReport() {
    const history = useHistory();
    const route = useLocation();
    const auth = useAuth();
    const { t } = useTranslation();

    //const [assessments, setAssessments] = useState(null);
    //const [assessmentTemplates, setAssessmentTemplates] = useState([]);
    //const [assessmentStatus, setAssessmentStatus] = useState([]);
    const [loading, setLoading] = useState(true);

    const [query, setQuery] = useState(
        (route.state && route.state.query) || { page: 0, skip: 0, limit: 15 }
    );

    useEffect(() => {
        setLoading(false);
    }, []);

    useEffect(() => {
        query.form_id = 37;
        history.replace(route.pathname, {
            ...route.state,

            query,
        });
    }, [query]);

    if (loading) return <LoadingCover />;

    return (
        <>
            <div className="content">
                <div className="section-header d-flex justify-content-between flex-md-row flex-column mb-4">
                    <h4>
                        {t(
                            "pages.assessments.pdf_report.emergency_lighting.title"
                        )}{" "}

                    </h4>
                </div>
                <div className="my-4">
                    <Table query={query} showPagination={true} />
                </div>
            </div>
        </>
    );
}

export default EmergencyLightingReport;
