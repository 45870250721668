import AssessmentForm from '../Assessments/Forms/AssessmentForm'

export function Audit () {

  let auditMode = false;

  return (
    <AssessmentForm
      auditMode={auditMode}
      fromAudit={true}
    />
  )
}
export default Audit;
