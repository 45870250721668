import LOCATION from '../../constants/Location'

import Templates from '@/views/pages/CompetencyTemplates'

const CompetencyTemplates = [
  {
    path: LOCATION.COMPETENCY_TEMPLATES.LIST.path,
    component: Templates,
    exact: true,
    translation_key: 'pages.competency_templates.title',
    main: false,
    gate: 'assessment_templates',
  },
]

export default CompetencyTemplates
