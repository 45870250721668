import PageActionSection from "@/components/PageActionSection";
import { useTranslation } from "react-i18next";
import React, { useState, useRef, useEffect } from "react";
import { useAuth } from "@/services/Auth";
import LOCATION from "@/constants/Location";
//import FormBuilder from './Forms/FormBuilder'
import { useHistory, useParams, useLocation } from "react-router-dom";
import ArrowRight from "@/components/icons/ArrowRight";
import ArrowLeft from "@/components/icons/ArrowLeft";
import ConfirmModal from "@/components/modals/ConfirmModal";
import { getValidationErrors } from "@/Utility";
import { Button, Card, Form, Image } from "react-bootstrap";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import LoadingCover from "@/components/LoadingCover";
import TableLicenseKeyRedeem from "@/views/components/Tables/LicenseKeyRedeem";
function Redeem(props) {
    const { t } = useTranslation();
    const route = useLocation();
    const auth = useAuth();
    const history = useHistory();

    const [validationErrors, setValidationErrors] = useState([]);

    const [showSuccess, setShowSuccess] = useState(false);
    const [title, setTitle] = useState("");

    const submitFormRef = useRef(null);

    const [loading, setLoading] = useState(true);
    const [licenseKeys, setLicenseKeys] = useState([]);

    const [query, setQuery] = useState(
        (route.state && route.state.query) || { page: 0, skip: 0, limit: 15 }
    );

    const [searching, setSearching] = useState(false);
    //const [licenseKeyToDelete, setLicenseKeyToDelete] = useState(null);
    //const [showModal, setShowModal] = useState(false);
    const [refreshTable, setRefreshTable] = useState(false);

    /* const deleteRequest = (licenseKeyToDelete) => {
        setLicenseKeyToDelete(licenseKeyToDelete);
        setShowModal(true);
    }; */

    const handleConfirm = () => {
        setShowSuccess(false);
        setTitle(null);
        //history.push(`${LOCATION.LICENSE_KEY.LIST.path}`);
    };
    // Function to set refreshTable to false
    const setRefreshTableFalse = () => {
        setRefreshTable(false);
    };
    const requestSave = (values) => {
        setValidationErrors([]);
        setSearching(false);
        auth.postRequest(LOCATION.LICENSE_KEY.REDEEM.API, values)
            .then((response) => {
                if (response.data.status === true) {
                    setTitle(
                        t("pages.license_key.form.license_key_redeem_success", {
                            credits: response.data.credits,
                        })
                    );
                    setShowSuccess(true);
                    setRefreshTable(true);
                } else {
                    let validationErrors = [];
                    for (const key in response.data.errors) {
                        validationErrors.push(response.data.errors[key]);
                    }
                    setValidationErrors(validationErrors);
                }
            })
            .catch((error) => {
                setValidationErrors(getValidationErrors(error));
            });
    };

    const schema = yup.object().shape({
        license_key: yup.string().required(
            t("common.form_validation.is_required", {
                attribute: t("pages.license_key.form.license_key"),
            })
        ),
        /* .min(
                16,
                t("common.form_validation.must_be_equal_to", {
                    attribute: t("pages.license_key.form.license_key"),
                    value: 16,
                })
            )
            .max(
                16,
                t("common.form_validation.must_be_equal_to", {
                    attribute: t("pages.license_key.form.license_key"),
                    value: 16,
                })
            ), */
    });
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm({
        defaultValues: {
            license_key: "",
        },
        resolver: yupResolver(schema),
    });

    // Function to handle form submission
    const onSave = (values) => {
        requestSave(values);
    };

    useEffect(() => {setLoading(false);}, []);

    if (loading) return <LoadingCover />;

    return (
        <>
            <ConfirmModal
                show={showSuccess}
                title={title}
                confirmText={t("common.dialogs.actions.okay")}
                handleConfirm={handleConfirm}
            />
            <PageActionSection
                title={t("pages.license_key.redeem_license_key_title")}
                type="dark"
            />

            <div className="content">
                <Form noValidate onSubmit={handleSubmit(onSave)}>
                    <Card className="border-0 radius-0 section-form mx-auto mx-lg-5">
                        <Card.Body>
                            <Form.Group
                                className="mb-4 mt-4"
                                controlId="formBasicTitle"
                            >
                                <Form.Label>
                                    {t(
                                        "pages.license_key.form.redeem_license_key"
                                    )}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    placeholder={t(
                                        "pages.license_key.form.redeem_license_key"
                                    )}
                                    {...register("license_key")}
                                    isInvalid={!!errors.license_key}
                                    min="0"
                                    max="100"
                                />

                                <Form.Control.Feedback type="invalid">
                                    {errors.license_key &&
                                        errors.license_key.message}
                                </Form.Control.Feedback>
                            </Form.Group>

                            {validationErrors.length !== 0 && (
                                <div className="form-group mb-2">
                                    {validationErrors.map((error, index) => (
                                        <p
                                            className="text-danger font-weight-bold"
                                            key={index}
                                        >
                                            {t(error)}
                                        </p>
                                    ))}
                                </div>
                            )}

                            <div className="d-flex justify-content-center mt-4 section-form  mx-auto mx-lg-5">
                                {/* <Button
                            variant="secondary"
                            size="sm"
                            onClick={() => goToBack()}
                        >
                            <span className="ms-1">
                                {t("pages.license_key.button.back")}
                            </span>
                        </Button> */}
                                <Button
                                    ref={submitFormRef}
                                    variant="success"
                                    size="sm"
                                    type="submit"
                                    className="text-white ms-4"
                                >
                                    <span className="me-1">
                                        {t(
                                            "pages.license_key.button.redeem_credits"
                                        )}
                                    </span>
                                </Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Form>
                <div className="my-4">
                    <div className="section-header d-flex justify-content-between flex-md-row flex-column mb-4">
                        <h4>{t("pages.license_key.redeemed_list")}</h4>
                    </div>
                    <TableLicenseKeyRedeem
                        query={query}
                        showPagination={true}
                        onData={(_) => setSearching(!_)}
                        // deleteRequest={deleteRequest}
                        setRefreshTable={setRefreshTableFalse}
                        refreshTable={refreshTable}
                    />
                </div>
            </div>
        </>
    );
}

export default Redeem;
