function Logout(){
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M17.969 17.031C17.8992 16.9613 17.8437 16.8785 17.8059 16.7874C17.7681 16.6963 17.7487 16.5986 17.7487 16.5C17.7487 16.4013 17.7681 16.3036 17.8059 16.2125C17.8437 16.1214 17.8992 16.0386 17.969 15.969L21.9395 12L17.969 8.03097C17.8282 7.89014 17.7491 7.69913 17.7491 7.49997C17.7491 7.30081 17.8282 7.1098 17.969 6.96897C18.1098 6.82814 18.3008 6.74902 18.5 6.74902C18.6992 6.74902 18.8902 6.82814 19.031 6.96897L23.531 11.469C23.6008 11.5386 23.6563 11.6214 23.6941 11.7125C23.7319 11.8036 23.7513 11.9013 23.7513 12C23.7513 12.0986 23.7319 12.1963 23.6941 12.2874C23.6563 12.3785 23.6008 12.4613 23.531 12.531L19.031 17.031C18.9613 17.1008 18.8786 17.1562 18.7874 17.194C18.6963 17.2318 18.5986 17.2513 18.5 17.2513C18.4013 17.2513 18.3037 17.2318 18.2125 17.194C18.1214 17.1562 18.0387 17.1008 17.969 17.031Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M7.25 12C7.25 11.8011 7.32902 11.6103 7.46967 11.4697C7.61032 11.329 7.80109 11.25 8 11.25H21.5C21.6989 11.25 21.8897 11.329 22.0303 11.4697C22.171 11.6103 22.25 11.8011 22.25 12C22.25 12.1989 22.171 12.3897 22.0303 12.5303C21.8897 12.671 21.6989 12.75 21.5 12.75H8C7.80109 12.75 7.61032 12.671 7.46967 12.5303C7.32902 12.3897 7.25 12.1989 7.25 12Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M3.5 20.25C2.90326 20.25 2.33097 20.0129 1.90901 19.591C1.48705 19.169 1.25 18.5967 1.25 18V6C1.25 5.40326 1.48705 4.83097 1.90901 4.40901C2.33097 3.98705 2.90326 3.75 3.5 3.75H14C14.5967 3.75 15.169 3.98705 15.591 4.40901C16.0129 4.83097 16.25 5.40326 16.25 6V8.25C16.25 8.44891 16.171 8.63968 16.0303 8.78033C15.8897 8.92098 15.6989 9 15.5 9C15.3011 9 15.1103 8.92098 14.9697 8.78033C14.829 8.63968 14.75 8.44891 14.75 8.25V6C14.75 5.80109 14.671 5.61032 14.5303 5.46967C14.3897 5.32902 14.1989 5.25 14 5.25H3.5C3.30109 5.25 3.11032 5.32902 2.96967 5.46967C2.82902 5.61032 2.75 5.80109 2.75 6V18C2.75 18.1989 2.82902 18.3897 2.96967 18.5303C3.11032 18.671 3.30109 18.75 3.5 18.75H14C14.1989 18.75 14.3897 18.671 14.5303 18.5303C14.671 18.3897 14.75 18.1989 14.75 18V15.75C14.75 15.5511 14.829 15.3603 14.9697 15.2197C15.1103 15.079 15.3011 15 15.5 15C15.6989 15 15.8897 15.079 16.0303 15.2197C16.171 15.3603 16.25 15.5511 16.25 15.75V18C16.25 18.5967 16.0129 19.169 15.591 19.591C15.169 20.0129 14.5967 20.25 14 20.25H3.5Z" fill="white"/>
        </svg>
    )
}

export default Logout;