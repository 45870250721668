import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { ordinalSuffixOf } from "@/Utility";
import { Image } from "react-bootstrap";
import { useAuth } from "@/services/Auth";
import { getAddessmentAddress } from "@/Utility";

function ReportSection({ sectionIndex, assessmentSection, assessment }) {

    const { t } = useTranslation();
    if (assessmentSection.section_type !== "supporting-document") {
        return (
            <>
                {sectionIndex > 0 && (
                    <div
                        style={{ pageBreakBefore: "always" }}
                        className="divBreak"
                    ></div>
                )}

                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th colSpan="2" className="title-bg">
                                <h5
                                    className="m-0"
                                    style={{ fontFamily: "Arial, sans-serif" }}
                                >
                                    Section {sectionIndex + 1}:{" "}
                                    {assessmentSection.name}
                                </h5>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {sectionIndex === 0 && (
                            <>
                                <tr>
                                    <th
                                        className="w-50 bg-tabel-details height-border"
                                        style={{
                                            fontFamily: "Arial, sans-serif",
                                        }}
                                    >
                                        {t("common.form.fra_reference_number")}
                                    </th>
                                    <td className="w-50 height-border">
                                        {assessment.fra_reference_number}
                                    </td>
                                </tr>
                                <tr>
                                    <th
                                        className="w-50 bg-tabel-details height-border"
                                        style={{
                                            fontFamily: "Arial, sans-serif",
                                        }}
                                    >
                                        {t("common.form.address")}
                                    </th>
                                    <td className="w-50 height-border">
                                        {getAddessmentAddress(assessment)}
                                    </td>
                                </tr>
                            </>
                        )}
                        {assessmentSection.questions.map(
                            (question, questionIndex) => (
                                <Question
                                    key={question.id}
                                    sectionIndex={sectionIndex}
                                    question={question}
                                    answers={assessment.answers}
                                    questionIndex={questionIndex}
                                />
                            )
                        )}
                    </tbody>
                </table>
            </>
        );
    } else {
        const date = new Date();
        date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000); // One day
        const expires = `expires=${date.toUTCString()}`;
        document.cookie = `sdName=${assessmentSection.name}; ${expires}; path=/`;

        return null; // You may want to return something specific or null when it's a "supporting-document" section
    }
    /* return (
        <>
            {assessmentSection.section_type !== "supporting-document" ? (
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th colSpan="2" className="title-bg">
                                <h5
                                    className="m-0"
                                    style={{ fontFamily: "Arial, sans-serif" }}
                                >
                                    Section {sectionIndex + 1}:{" "}
                                    {assessmentSection.name}
                                </h5>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {sectionIndex === 0 && (
                            <>
                                <tr>
                                    <th
                                        className="w-50 bg-tabel-details height-border"
                                        style={{
                                            fontFamily: "Arial, sans-serif",
                                        }}
                                    >
                                        {t("common.form.fra_reference_number")}
                                    </th>
                                    <td className="w-50 height-border">
                                        {assessment.fra_reference_number}
                                    </td>
                                </tr>
                                <tr>
                                    <th
                                        className="w-50 bg-tabel-details height-border"
                                        style={{
                                            fontFamily: "Arial, sans-serif",
                                        }}
                                    >
                                        {t("common.form.address")}
                                    </th>
                                    <td className="w-50 height-border">
                                        {assessment.address}
                                    </td>
                                </tr>
                            </>
                        )}
                        {assessmentSection.questions.map((question) => (
                            <Question
                                key={question.id}
                                sectionIndex={sectionIndex}
                                question={question}
                                answers={assessment.answers}
                            />
                        ))}
                    </tbody>
                </table>
            ) : null}
        </>
    ); */
}

function Question({
    question,
    answers,
    sectionIndex,
    groupIndex,
    showCounter,
    childQuestion,
    otherGroupCounter,
    questionIndex,
}) {
    const auth = useAuth();
    const baseUrl = auth.api.defaults.baseURL;

    const [answer, setAnswer] = useState(null);
    const [displayAnswer, setDisplayAnswer] = useState(null);
    const [displayAnswerPdf, setDisplayAnswerPdf] = useState(null);
    const [displayRecommendation, setDisplayRecommendation] = useState(null);
    const [displayAnswerPdfArray, setDisplayAnswerPdfArray] = useState([]);
    const getAnswer = () => {
        let returnedAnswer = null;
        let returnedRecommendation = null;
        let returnedAnswerPDF = null;
        returnedAnswer = answer?.answer;
        returnedAnswerPDF = answer?.pdf;
        returnedRecommendation = answer?.recommendation;

        if (question.select_multiple) {
            let choices = question.question_choices.filter((_) =>
                answers.map((_) => _.question_choice_id).includes(_.id)
            );

            returnedAnswer = choices
                .map((_) => {
                    if (_?.other_answer) {
                        return `${_?.answer} - ${_?.other_answer}`;
                    }
                    return _?.answer;
                })
                .join(", ");
        } else if (answer?.question_choice_id != null) {
            let choice = question.question_choices.find(
                (_) => _.id == answer?.question_choice_id
            );
            if (choice) {
                returnedAnswer = choice?.answer;
                returnedAnswerPDF = choice?.pdf;
                if (answer?.other_answer) {
                    returnedAnswer = `${choice?.answer} - ${answer?.other_answer}`;
                }
                returnedRecommendation = choice?.recommendation;
            }
        }

        if ([11].includes(question.type)) {
            returnedAnswer = `${returnedAnswer} ${question.description}`;
        } else if ([12].includes(question.type)) {
            returnedAnswer = `${returnedAnswer} | ${question.description} - ${answer?.range_value}`;
        } else if ([14].includes(question.type)) {
            //returnedAnswerPDF = returnedAnswer;
        }
        //console.log("returnedAnswerPDF ", returnedAnswerPDF);
        setDisplayAnswerPdf(returnedAnswerPDF);
        //await setDisplayAnswerPdfArray(returnedAnswerPDF);
        setDisplayAnswer(returnedAnswer);
        setDisplayRecommendation(returnedRecommendation);
    };

    useEffect(() => {
        let currentAnswer = answers.find((_) => _.question_id === question.id);
        if (otherGroupCounter) {
            let answersList = answers.filter(
                (_) => _.question_id === question.id
            );
            if (answersList && answersList[otherGroupCounter]) {
                currentAnswer = answersList[otherGroupCounter];
            }
        } else if (groupIndex) {
            currentAnswer = answers.find(
                (_) =>
                    _.question_id === question.id &&
                    _.question_index === groupIndex
            );
        }
        setAnswer(currentAnswer);
    }, [otherGroupCounter]);

    useEffect(() => {
        getAnswer();
    }, [answer]);

    return (
        <>
            {[3, 6].includes(question.type) ? (
                <>
                    <tr>
                        <th className="w-50 bg-tabel-details height-border">
                            {showCounter ? (
                                <>
                                    {sectionIndex + 1}.{questionIndex + 1}.{" "}
                                    {ordinalSuffixOf(groupIndex + 1)}{" "}
                                    {question.label}
                                </>
                            ) : !childQuestion ? (
                                <>
                                    {sectionIndex + 1}.{questionIndex + 1}.{" "}
                                    {question.label}
                                </>
                            ) : (
                                <>
                                    {sectionIndex + 1}.{questionIndex + 1}.{" "}
                                    {`${
                                        otherGroupCounter != null
                                            ? answer?.other_answer
                                            : question.label
                                    }`}
                                </>
                            )}
                        </th>
                        <td className="w-50 height-border"></td>
                    </tr>
                    {GroupQuestion(question, answers, sectionIndex)}
                </>
            ) : (
                <>
                    {question.type !== 10 ? (
                        <tr>
                            <th className="w-50 bg-tabel-details height-border">
                                {showCounter ? (
                                    <>
                                        {sectionIndex + 1}.{questionIndex + 1}.{" "}
                                        {ordinalSuffixOf(groupIndex + 1)}{" "}
                                        {question.label}
                                    </>
                                ) : !childQuestion ? (
                                    <>
                                        {sectionIndex + 1}.{questionIndex + 1}.{" "}
                                        {question.label}
                                    </>
                                ) : (
                                    <>
                                        {sectionIndex + 1}.{questionIndex + 1}.{" "}
                                        {`${
                                            otherGroupCounter != null
                                                ? answer?.other_answer
                                                : question.label
                                        }`}
                                    </>
                                )}
                            </th>
                            {/* <td className="w-50 height-border"> */}
                            {[0].includes(question.type) ? (
                                <>
                                    {GroupQuestion(
                                        question,
                                        answers,
                                        sectionIndex
                                    )}
                                </>
                            ) : (
                                <>
                                    <td className="w-50 height-border">
                                        {[14].includes(question.type) ? (
                                            <>
                                                {question.type === 10 ? (
                                                    displayAnswer != null ? (
                                                        <>
                                                            <h5>&nbsp;</h5>
                                                            <p className="text-center">
                                                                <Image
                                                                    className="wi-100  img-fluid"
                                                                    src={`${baseUrl}/${displayAnswer}`}
                                                                />
                                                            </p>
                                                        </>
                                                    ) : null
                                                ) : (
                                                    <>{displayAnswer}</>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {question.type === 10 ? (
                                                    displayAnswer != null ? (
                                                        <>
                                                            <h5>&nbsp;</h5>
                                                            <p className="text-center">
                                                                <Image
                                                                    className="wi-100  img-fluid"
                                                                    src={`${baseUrl}/${displayAnswer}`}
                                                                />
                                                            </p>
                                                        </>
                                                    ) : null
                                                ) : (
                                                    <>{displayAnswer}</>
                                                )}
                                            </>
                                        )}
                                    </td>
                                </>
                            )}
                            {/* </td> */}
                        </tr>
                    ) : (
                        <></>
                    )}
                    {question.type === 10 ? (
                        <>
                            <tr>
                                <th
                                    className="w-100 bg-tabel-details height-border"
                                    colSpan="2"
                                >
                                    {showCounter ? (
                                        <>
                                            {sectionIndex + 1}.
                                            {questionIndex + 1}.{" "}
                                            {ordinalSuffixOf(groupIndex + 1)}{" "}
                                            {question.label}
                                        </>
                                    ) : !childQuestion ? (
                                        <>
                                            {sectionIndex + 1}.
                                            {questionIndex + 1}.{" "}
                                            {question.label}{" "}
                                        </>
                                    ) : (
                                        <>
                                            {sectionIndex + 1}.
                                            {questionIndex + 1}.{" "}
                                            {`${
                                                otherGroupCounter != null
                                                    ? answer?.other_answer
                                                    : question.label
                                            }`}{" "}
                                        </>
                                    )}
                                </th>
                            </tr>
                            <tr>
                                <td className="w-50 height-border" colSpan="2" style={{ display: !displayAnswer ? 'none' : 'table-cell' }}>
                                    {[3, 6].includes(question.type) ? (
                                        GroupQuestion(
                                            question,
                                            answers,
                                            sectionIndex
                                        )
                                    ) : (
                                        <>
                                            {[14].includes(question.type) ? (
                                                <>
                                                    {question.type === 10 ? (
                                                        displayAnswer !=
                                                        null ? (
                                                            <>
                                                                <h5>&nbsp;</h5>
                                                                <p className="text-center">
                                                                    <Image
                                                                        className="wi-100  img-fluid"
                                                                        src={`${baseUrl}/${displayAnswer}`}
                                                                    />
                                                                </p>
                                                            </>
                                                        ) : null
                                                    ) : (
                                                        <>{displayAnswer}</>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {question.type === 10 ? (
                                                        displayAnswer !=
                                                        null ? (
                                                            <>
                                                                <h5>&nbsp;</h5>
                                                                <p className="text-center">
                                                                    <Image
                                                                        className="wi-100  img-fluid"
                                                                        src={`${baseUrl}/${displayAnswer}`}
                                                                    />
                                                                </p>
                                                            </>
                                                        ) : null
                                                    ) : (
                                                        displayAnswer
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </td>
                            </tr>
                        </>
                    ) : (
                        <></>
                    )}
                </>
            )}

            {/* <tr>
                <th className="w-50 bg-tabel-details height-border">
                    {showCounter ? (
                        <>
                            {ordinalSuffixOf(groupIndex + 1)} {question.label}
                        </>
                    ) : !childQuestion ? (
                        <>{question.label}</>
                    ) : (
                        <>{`${
                            otherGroupCounter != null
                                ? answer?.other_answer
                                : question.label
                        }`}</>
                    )}
                </th>
                <td className="w-50 height-border">
                    {[3, 6].includes(question.type) ? (
                        GroupQuestion(question, answers, sectionIndex)
                    ) : (
                        <>
                            {[14].includes(question.type) ? (
                                <>
                                    {question.type === 10 ? (
                                        displayAnswer != null ? (
                                            <>
                                                <h6>&nbsp;</h6>
                                                <p>
                                                    <Image
                                                        className="w-100"
                                                        src={`${baseUrl}/${displayAnswer}`}
                                                    />
                                                </p>
                                            </>
                                        ) : null
                                    ) : (
                                        <>{displayAnswer}</>
                                    )}
                                </>
                            ) : (
                                <>
                                    {question.type === 10 ? (
                                        displayAnswer != null ? (
                                            <>
                                                <h6>&nbsp;</h6>
                                                <p>
                                                    <Image
                                                        className="w-100"
                                                        src={`${baseUrl}/${displayAnswer}`}
                                                    />
                                                </p>
                                            </>
                                        ) : null
                                    ) : (
                                        displayAnswer
                                    )}
                                </>
                            )}
                        </>
                    )}
                </td>
            </tr> */}
        </>
    );
}

function QuestionMultiple({
    question,
    answers,
    sectionIndex,
    groupIndex,
    showCounter,
    childQuestion,
    otherGroupCounter,
    questionIndex,
}) {
    const auth = useAuth();
    const baseUrl = auth.api.defaults.baseURL;

    const [answer, setAnswer] = useState(null);
    const [displayAnswer, setDisplayAnswer] = useState(null);
    const [displayAnswerPdf, setDisplayAnswerPdf] = useState(null);
    const [displayRecommendation, setDisplayRecommendation] = useState(null);
    const [displayAnswerPdfArray, setDisplayAnswerPdfArray] = useState([]);
    const getAnswer = () => {
        let returnedAnswer = null;
        let returnedRecommendation = null;
        let returnedAnswerPDF = null;
        returnedAnswer = answer?.answer;
        returnedAnswerPDF = answer?.pdf;
        returnedRecommendation = answer?.recommendation;

        if (question.select_multiple) {
            let choices = question.question_choices.filter((_) =>
                answers.map((_) => _.question_choice_id).includes(_.id)
            );

            returnedAnswer = choices
                .map((_) => {
                    if (_?.other_answer) {
                        return `${_?.answer} - ${_?.other_answer}`;
                    }
                    return _?.answer;
                })
                .join(", ");
        } else if (answer?.question_choice_id != null) {
            let choice = question.question_choices.find(
                (_) => _.id == answer?.question_choice_id
            );
            if (choice) {
                returnedAnswer = choice?.answer;
                returnedAnswerPDF = choice?.pdf;
                if (answer?.other_answer) {
                    returnedAnswer = `${choice?.answer} - ${answer?.other_answer}`;
                }
                returnedRecommendation = choice?.recommendation;
            }
        }

        if ([11].includes(question.type)) {
            returnedAnswer = `${returnedAnswer} ${question.description}`;
        } else if ([12].includes(question.type)) {
            returnedAnswer = `${returnedAnswer} | ${question.description} - ${answer?.range_value}`;
        } else if ([14].includes(question.type)) {
            //returnedAnswerPDF = returnedAnswer;
        }
        //console.log("returnedAnswerPDF ", returnedAnswerPDF);
        setDisplayAnswerPdf(returnedAnswerPDF);
        //await setDisplayAnswerPdfArray(returnedAnswerPDF);
        setDisplayAnswer(returnedAnswer);
        setDisplayRecommendation(returnedRecommendation);
    };

    useEffect(() => {
        let currentAnswer = answers.find((_) => _.question_id === question.id);
        if (otherGroupCounter) {
            let answersList = answers.filter(
                (_) => _.question_id === question.id
            );
            if (answersList && answersList[otherGroupCounter]) {
                currentAnswer = answersList[otherGroupCounter];
            }
        } else if (groupIndex) {
            currentAnswer = answers.find(
                (_) =>
                    _.question_id === question.id &&
                    _.question_index === groupIndex
            );
        }
        setAnswer(currentAnswer);
    }, [otherGroupCounter]);

    useEffect(() => {
        getAnswer();
    }, [answer]);

    return (
        <>
            {question.type !== 10 ? (
                <tr
                    className={`${
                        question.label
                            .toLowerCase()
                            .includes("image name".toLowerCase())
                            ? "hasImages"
                            : ""
                    }`}
                >
                    <th
                        className={`w-50 bg-tabel-details height-border pd-left`}
                    >
                        {/* {question.label.toLowerCase().includes("image name".toLowerCase()) && (
                             <h6 style={{ display: "none" }}>&nbsp;</h6>
                        )} */}{" "}
                        {showCounter ? (
                            <>
                                {sectionIndex + 1}.{questionIndex + 1}.{" "}
                                {ordinalSuffixOf(groupIndex + 1)}{" "}
                                {question.label}
                            </>
                        ) : !childQuestion ? (
                            <>
                                {sectionIndex + 1}.{questionIndex + 1}.{" "}
                                {question.label}
                            </>
                        ) : (
                            <>
                                {sectionIndex + 1}.{questionIndex + 1}.{" "}
                                {`${
                                    otherGroupCounter != null
                                        ? answer?.other_answer
                                        : question.label
                                }`}
                            </>
                        )}
                    </th>
                    <td className="w-50 height-border">
                        {[3, 6].includes(question.type) ? (
                            GroupQuestion(question, answers, sectionIndex)
                        ) : (
                            <>
                                {[14].includes(question.type) ? (
                                    <>
                                        {question.type === 10 ? (
                                            displayAnswer != null ? (
                                                <>

                                                    <h5>&nbsp;</h5>
                                                    <p className="text-center">
                                                        <Image
                                                            className="wi-100  img-fluid"
                                                            src={`${baseUrl}/${displayAnswer}`}
                                                        />
                                                    </p>
                                                </>
                                            ) : null
                                        ) : (
                                            <>{displayAnswer}</>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {question.type === 10 ? (
                                            displayAnswer != null ? (
                                                <>
                                                    <h5>&nbsp;</h5>
                                                    <p className="text-center">
                                                        <Image
                                                            className="wi-100  img-fluid"
                                                            src={`${baseUrl}/${displayAnswer}`}
                                                        />
                                                    </p>
                                                </>
                                            ) : null
                                        ) : (
                                            displayAnswer
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </td>
                </tr>
            ) : (
                <></>
            )}
            {question.type === 10 ? (
                <>
                    <tr className="hasImage">
                        <th
                            className="w-100 bg-tabel-details height-border pd-left"
                            colSpan="2"
                        >
                            {showCounter ? (
                                <>
                                    {sectionIndex + 1}.{questionIndex + 1}.{" "}
                                    {ordinalSuffixOf(groupIndex + 1)}{" "}
                                    {question.label}
                                </>
                            ) : !childQuestion ? (
                                <>
                                    {sectionIndex + 1}.{questionIndex + 1}.{" "}
                                    {question.label}
                                </>
                            ) : (
                                <>
                                    {sectionIndex + 1}.{questionIndex + 1}.{" "}
                                    {`${
                                        otherGroupCounter != null
                                            ? answer?.other_answer
                                            : question.label
                                    }`}
                                </>
                            )}
                        </th>
                    </tr>
                    <tr>
                        <td className="w-50 height-border" colSpan="2" style={{ display: !displayAnswer ? 'none' : 'table-cell' }}>
                            {[3, 6].includes(question.type) ? (
                                GroupQuestion(question, answers, sectionIndex)
                            ) : (
                                <>
                                    {[14].includes(question.type) ? (
                                        <>
                                            {question.type === 10 ? (
                                                displayAnswer != null ? (
                                                    <>
                                                        <h5>&nbsp;</h5>
                                                        <p className="text-center">
                                                            <Image
                                                                className="wi-100  img-fluid"
                                                                src={`${baseUrl}/${displayAnswer}`}
                                                            />
                                                        </p>
                                                    </>
                                                ) : null
                                            ) : (
                                                <>{displayAnswer}</>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {question.type === 10 ? (
                                                displayAnswer != null ? (
                                                    <>
                                                        <h5>&nbsp;</h5>
                                                        <p className="text-center">
                                                            <Image
                                                                className="wi-100  img-fluid"
                                                                src={`${baseUrl}/${displayAnswer}`}
                                                            />
                                                        </p>
                                                    </>
                                                ) : null
                                            ) : (
                                                displayAnswer
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </td>
                    </tr>
                </>
            ) : (
                <></>
            )}

            {/*  2 ND YPE  */}
            {/* {question.type !== 10 ? (
                <>
                    <div>
                        <strong>
                            {showCounter ? (
                                <>
                                    {ordinalSuffixOf(groupIndex + 1)}{" "}
                                    {question.label}
                                </>
                            ) : !childQuestion ? (
                                <>{question.label}</>
                            ) : (
                                <>{`${
                                    otherGroupCounter != null
                                        ? answer?.other_answer
                                        : question.label
                                }`}</>
                            )}
                        </strong>
                    </div>

                    <div>
                        {[3, 6].includes(question.type) ? (
                            GroupQuestion(question, answers, sectionIndex)
                        ) : (
                            <>
                                {[14].includes(question.type) ? (
                                    <>
                                        {question.type === 10 ? (
                                            displayAnswer != null ? (
                                                <>
                                                    <h6>&nbsp;</h6>
                                                    <p>
                                                        <Image
                                                            className="w-100  img-fluid"
                                                            src={`${baseUrl}/${displayAnswer}`}
                                                        />
                                                    </p>
                                                </>
                                            ) : null
                                        ) : (
                                            <>{displayAnswer}</>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {question.type === 10 ? (
                                            displayAnswer != null ? (
                                                <>
                                                    <h6>&nbsp;</h6>
                                                    <p>
                                                        <Image
                                                            className="w-100  img-fluid"
                                                            src={`${baseUrl}/${displayAnswer}`}
                                                        />
                                                    </p>
                                                </>
                                            ) : null
                                        ) : (
                                            displayAnswer
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </>
            ) : (
                <></>
            )} */}

            {/* {question.type === 10 ? (
                <>
                    <tr>
                        <th
                            className="w-100 bg-tabel-details height-border"
                            colSpan="2"
                        >
                            {showCounter ? (
                                <>
                                    {ordinalSuffixOf(groupIndex + 1)}{" "}
                                    {question.label}
                                </>
                            ) : !childQuestion ? (
                                <>{question.label}</>
                            ) : (
                                <>{`${
                                    otherGroupCounter != null
                                        ? answer?.other_answer
                                        : question.label
                                }`}</>
                            )}
                        </th>
                    </tr>
                    <tr>
                        <td className="w-50 height-border" colSpan="2">
                            {[3, 6].includes(question.type) ? (
                                GroupQuestion(question, answers, sectionIndex)
                            ) : (
                                <>
                                    {[14].includes(question.type) ? (
                                        <>
                                            {question.type === 10 ? (
                                                displayAnswer != null ? (
                                                    <>
                                                        <h6>&nbsp;XX2</h6>
                                                        <p>
                                                            <Image
                                                                className="w-100  img-fluid"
                                                                src={`${baseUrl}/${displayAnswer}`}
                                                            />
                                                        </p>
                                                    </>
                                                ) : null
                                            ) : (
                                                <>{displayAnswer}</>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {question.type === 10 ? (
                                                displayAnswer != null ? (
                                                    <>
                                                        <h6>&nbsp; XX1</h6>
                                                        <p>
                                                            <Image
                                                                className="w-100  img-fluid"
                                                                src={`${baseUrl}/${displayAnswer}`}
                                                            />
                                                        </p>
                                                    </>
                                                ) : null
                                            ) : (
                                                displayAnswer
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </td>
                    </tr>
                </>
            ) : (
                <></>
            )} */}

            {/*  1st type    */}
            {/*   <div>
                <strong>
                    {showCounter ? (
                        <>
                            {ordinalSuffixOf(groupIndex + 1)} {question.label}
                        </>
                    ) : !childQuestion ? (
                        <>{question.label}</>
                    ) : (
                        <>{`${
                            otherGroupCounter != null
                                ? answer?.other_answer
                                : question.label
                        }`}</>
                    )}
                </strong>
            </div>

            <div>
                {[3, 6].includes(question.type) ? (
                    GroupQuestion(question, answers, sectionIndex)
                ) : (
                    <>
                        {[14].includes(question.type) ? (
                            <>
                                {question.type === 10 ? (
                                    displayAnswer != null ? (
                                        <>
                                            <h6>&nbsp;</h6>
                                            <p>
                                                <Image
                                                    className="w-100  img-fluid"
                                                    src={`${baseUrl}/${displayAnswer}`}
                                                />
                                            </p>
                                        </>
                                    ) : null
                                ) : (
                                    <>{displayAnswer}</>
                                )}
                            </>
                        ) : (
                            <>
                                {question.type === 10 ? (
                                    displayAnswer != null ? (
                                        <>
                                            <h6>&nbsp;</h6>
                                            <p>
                                                <Image
                                                    className="w-100  img-fluid"
                                                    src={`${baseUrl}/${displayAnswer}`}
                                                />
                                            </p>
                                        </>
                                    ) : null
                                ) : (
                                    displayAnswer
                                )}
                            </>
                        )}
                    </>
                )}
            </div> */}
        </>
    );
}

function GroupQuestion(question, answers, sectionIndex) {
    const [groupCounter, setGroupCounter] = useState(1);
    const [otherQuestion, setOtherQuestion] = useState(null);
    const [otherQuestions, setOtherQuestions] = useState([]);

    const getQuestions = () => {
        let questions = [];
        for (let counter = 0; counter < groupCounter; counter++) {
            question.children.map((childQuestion, index) => {
                const isLastChild = index === question.children.length - 1;
                if (!childQuestion.is_other) {
                    questions.push(
                        <QuestionMultiple
                            key={counter}
                            showCounter={question.type === 3}
                            question={childQuestion}
                            groupIndex={counter}
                            answers={answers}
                            sectionIndex={sectionIndex}
                            childQuestion={true}
                            questionIndex={index}
                        />
                    );
                    if (!isLastChild) {
                        //questions.push(<hr key={`hr-${counter}`} />);
                    }
                }
            });

            otherQuestions.map((otherQuestion, index) => {
                const isLastChildOther = index === question.children.length - 1;
                questions.push(
                    <Question
                        key={index + question.children.length}
                        showCounter={question.type === 3}
                        question={otherQuestion}
                        groupIndex={index}
                        otherGroupCounter={index}
                        answers={answers}
                        sectionIndex={sectionIndex}
                        childQuestion={true}
                        questionIndex={index}
                    />
                );
                if (!isLastChildOther) {
                    //questions.push(<hr key={`hr-${counter}`} />);
                }
            });
        }

        return questions;
    };

    useEffect(() => {
        let otherQuestion = question.children.find((_) => _.is_other);
        setOtherQuestion(otherQuestion);

        if ([3].includes(question.type)) {
            question.children.forEach((childQuestion) => {
                let questionAnswers = answers.filter(
                    (_) => _.question_id === childQuestion.id
                );
                if (questionAnswers && questionAnswers.length > groupCounter) {
                    setGroupCounter((_) => questionAnswers.length);
                }
            });
        }
    }, [question]);

    useEffect(() => {
        if (otherQuestion) {
            let otherQuestionAnswers = answers.filter(
                (_) => _.question_id === otherQuestion.id
            );

            let questions = [];
            otherQuestionAnswers.forEach((_) => {
                questions.push(otherQuestion);
            });

            setOtherQuestions([].concat(questions));
        }
    }, [otherQuestion]);

    return <>{getQuestions()}</>;
}

export default ReportSection;
