import { Button, Spinner } from 'react-bootstrap'
import React from 'react'
import { useTranslation } from 'react-i18next'

function LoadingButton ({
  id,
  className,
  loading,
  onSubmit,
  titleTranslationKey,
  type,
  variant,
  disabled,
  icon,
  size,
  onlySpinner,
  titleSuffix,
  refName,
}) {
  const { t } = useTranslation()
  return (
    !loading ?
      <Button
        ref={refName}
        id={id ?? null }
        size={size ? null : size}
        className={className}
        onClick={onSubmit}
        type={type}
        disabled={disabled}
        variant={variant == null ? 'primary' : variant}
      >
        <span className={icon ? 'me-3' : ''}>{t(titleTranslationKey)}</span>
        {
          titleSuffix && <span>&nbsp;{titleSuffix}</span>
        }
        {
          icon && icon
        }
      </Button>
      :
      <Button
        className={className}
        size={size ? null : size}
        variant={variant == null ? 'primary' : variant}
        disabled
      >
        <Spinner
          className={`${onlySpinner ? 'p-2' : ''}`}
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        {!onlySpinner && <span className={`mx-2 ${className}`}>{t('common.please_wait')}</span>}

      </Button>
  )
}

export default LoadingButton
