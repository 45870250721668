
import LOCATION from '@/constants/Location'
import MyAccount from '@/views/pages/MyAccount'
import PricingAndPlans from '@/views/pages/Pricing/PricingAndPlans'

const Account = [
  {
    path: LOCATION.My_ACCOUNT.LIST.path,
    component: MyAccount,
    exact: true,
    translation_key: 'pages.my_account.title',
    main: true,
    gate: 'my_account',
    order: 13,
  },
  {
    path: LOCATION.PAYMENTS.PRICING_AND_PLANS.path,
    component: PricingAndPlans,
    exact: true,
    translation_key: 'common.pricing_and_plan',
    main: true,
    gate: 'pricing_plans',
    order: 11,
  },
  //pricing-and-plans
]
export default Account;
