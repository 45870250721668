import LOCATION from '@/constants/Location'
import UsersIndex from '@/views/pages/AdminUsers'
import Create from "@/views/pages/AdminUsers/Create";

const AdminUsers = [
  {
    path: LOCATION.ADMIN_USERS.LIST.path,
    component: UsersIndex,
    exact: true,
    translation_key: 'pages.admin_users.title',
    main: true,
    gate: 'admin_users',
  },
  {
    path: LOCATION.ADMIN_USERS.CREATE.path,
    component: Create,
    exact: true,
    translation_key: 'pages.admin_users.title',
    main: false,
    gate: 'admin_users',
  },
]
export default AdminUsers
