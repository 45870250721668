import Login from "@/views/auth/Login";
import SignUp from "@/views/auth/SignUp";
import LOCATION from "../constants/Location";
import ForgotPassword from "@/views/auth/ForgotPassword";
import ResetPasswordForm from "@/views/auth/ResetPasswordForm";
import FullReport from "../views/pages/Assessments/FullReport";
import FireSafetyPolicyDocument from "../views/pages/FireSafetyPolicyDocument/FireSafetyPolicyDocument";
import HealthSafetyPolicy from "../views/pages/HealthSafetyPolicy/HealthSafetyPolicy";
import OccupancyCalculator from "../views/pages/OccupancyCalculator/OccupancyCalculator";
import VerifyEmailPage from "@/views/auth/VerifyEmailPage";
const Public = [
    {
        path: "/login",
        component: Login,
        public: true,
        authVisit: false,
        exact: true,
    },
    {
        path: "/sign-up",
        component: SignUp,
        public: true,
        authVisit: false,
        exact: true,
    },
    {
        path: LOCATION.AUTH.FORGOT_PASSWORD.path,
        component: ForgotPassword,
        public: true,
        authVisit: false,
        exact: true,
    },
    {
        path: LOCATION.AUTH.RESET_PASSWORD.path,
        component: ResetPasswordForm,
        public: true,
        authVisit: false,
        exact: true,
    },
    {
        path: LOCATION.ASSESSMENTS.FULL_REPORT_SHARED.path,
        component: FullReport,
        exact: true,
        public: true,
        authVisit: true,
    },
    {
        path: LOCATION.AUTH.VERIFY_EMAIL.path,
        component: VerifyEmailPage,
        public: true,
        authVisit: true,
        exact: true,
    },
    /* {
        path: "/fire-safety-policy-document",
        component: FireSafetyPolicyDocument,
        public: true,
        authVisit: false,
        exact: true,
    }, */

    /*  {
        path: "/health-safety-policy",
        component: HealthSafetyPolicy,
        public: true,
        authVisit: false,
        exact: true,
    },

    {
        path: "/occupancy-calculator",
        component: OccupancyCalculator,
        public: true,
        authVisit: false,
        exact: true,
    }, */
];

export default Public;
