import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Form, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import LoadingButton from '../../components/LoadingButton'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAuth } from '../../services/Auth'

function SignUp (props) {
  const auth = useAuth()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const [serverError, setServerError] = useState('')
  const [serverErrors, setServerErrors] = useState([])

  let passwordMinLength = 6

  const schema = yup.object().shape({
    first_name: yup.string()
      .required(t('common.form_validation.first_name_is_required')),
    last_name: yup.string()
      .required(t('common.form_validation.last_name_is_required')),
    business_name: yup.string()
      .required(t('common.form_validation.business_name_is_required')),

    phone_number: yup.string()
      .required(t('common.form_validation.phone_number_is_required')),
    email: yup.string()
      .email(t('common.form_validation.invalid_email_address'))
      .required(t('common.form_validation.email_address_is_required')),
    password: yup.string()
      .min(passwordMinLength, t('common.form_validation.password_invalid_min_length', { length: passwordMinLength }))
      .required(t('common.form_validation.password_is_required')),
    password_confirmation: yup.string().oneOf([yup.ref('password'), null], t('common.form_validation.password_is_must_match'))
      .required(t('common.form_validation.password_confirm_required')),
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const signUp = (values) => {
    setServerError('')
    setLoading(true)
    auth.signUp(values)
      .then(response => {
        setLoading(false)
        window.location.href = '/'
      })
      .catch(error => {
        setLoading(false)
        if (error?.response?.status === 401) {
          setServerError(error.response.data.message)
        }

        if (error.response.status === 422) {
          let serverErrors = []
          for (const key in error.response.data.errors) {
            serverErrors.push(
              error.response.data.errors[key][0]
            )
          }
          setServerErrors(serverErrors)
        }
      })
  }

  return (
    <div className="row bg-white" style={{ height: '100%' }}>
      <div className="col-sm-8 col-12 border-0 d-flex align-items-center justify-content-center">
        <div className="login-container p-4">
          <Row>
            <span className="mb-5 text-primary fw-bold auth-header">{t('pages.signup.title')}</span>
          </Row>
          <Row className="auth-form">
            <Form noValidate onSubmit={handleSubmit(signUp)}>
              <Row>
                <Col className="col-12 col-sm-6">
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Label className="fw-bold">{t('common.form.first_name')}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t('common.form.first_name_placeholder')}
                      {...register('first_name')}
                      isInvalid={!!errors.first_name}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.first_name && errors.first_name.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col className="col-12 col-sm-6">
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Label className="fw-bold">{t('common.form.last_name')}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t('common.form.last_name_placeholder')}
                      {...register('last_name')}
                      isInvalid={!!errors.last_name}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.last_name && errors.last_name.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <Form.Label className="fw-bold">{t('common.form.business_name')}</Form.Label>
                <Form.Control
                  type="email"
                  placeholder={t('common.form.business_name_placeholder')}
                  {...register('business_name')}
                  isInvalid={!!errors.business_name}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.business_name && errors.business_name.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <Form.Label className="fw-bold">{t('common.form.email')}</Form.Label>
                <Form.Control
                  type="email"
                  placeholder={t('common.form.email_placeholder')}
                  {...register('email')}
                  isInvalid={!!errors.email}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.email && errors.email.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-4" controlId="formBasicPhoneNumber">
                <Form.Label className="fw-bold">{t('common.form.phone_number')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('common.form.phone_number_placeholder')}
                  {...register('phone_number')}
                  isInvalid={!!errors.phone_number}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.phone_number && errors.phone_number.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-4" controlId="formBasicPassword">
                <Form.Label className="fw-bold">{t('common.form.password')}</Form.Label>
                <Form.Control
                  type="password"
                  placeholder={t('common.form.password')}
                  {...register('password')}
                  isInvalid={!!errors.password}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password && errors.password.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-4" controlId="formBasicPassword">
                <Form.Label className="fw-bold">{t('common.form.re_enter_password')}</Form.Label>
                <Form.Control
                  type="password"
                  placeholder={t('common.form.re_enter_password')}
                  {...register('password_confirmation')}
                  isInvalid={!!errors.password_confirmation}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password_confirmation && errors.password_confirmation.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="py-4">
                <Link
                  to="/login"
                  className="mx-0 px-0 fw-bold small"
                >
                  {t('pages.signup.already_have_an_account_login')}
                </Link>
              </Form.Group>
              {
                serverError !== '' &&
                <div className="form-group mb-4">
                  <p className="text-danger py-1 mb-0">{t(serverError)}</p>
                </div>
              }
              {
                (serverErrors.length !== 0) &&
                <div className="form-group mb-4">
                  {
                    serverErrors.map((error, index) =>
                      <p className="text-danger font-weight-bold"
                         key={index}>{t(error)}</p>)
                  }
                </div>
              }
              <Form.Group>
                <LoadingButton
                  className="auth-btn text-white px-5"
                  loading={loading}
                  variant="primary"
                  titleTranslationKey="pages.signup.title"
                  type="submit"
                />
              </Form.Group>
            </Form>
          </Row>
        </div>
      </div>
      <div className="col-4 d-none d-sm-block login-background"></div>
    </div>
  )
}

export default SignUp
