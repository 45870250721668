import { Col, Form, Row } from "react-bootstrap";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import XIcon from "@/components/icons/XIcon";
import ChevronUpIcon from "@/components/icons/ArrowLeft";
import ChevronDownIcon from "@/components/icons/ArrowRight";
import TinyMceEditor from "@/components/TinyMceEditor";
import PdfUploadField from "../../Assessments/Forms/components/PdfUploadField";

function ChoicesPdfFormBuilder({
    errors,
    questionIndex,
    choiceIndex,
    register,
    removePdfChoice,
    setValue,
    answerChoice,
    inputSuffix,
    question,
    watch,
    questionSuffix,
    assessment,
    recommendation,
    recommendations = [], // Array of existing recommendation values
    setRecommendations, // Function to update the recommendations array
    index,
    fieldName,
}) {
    const { t } = useTranslation();

    const [showChoices, setShowChoices] = useState(false);

    const [choiceScores, setChoiceScores] = useState([]);

    let userOwnScoreLabel = t("pages.assessment_templates.user_input");

    const answer = watch(`${inputSuffix}.answer`);
    const addOther = watch(`${questionSuffix}.add_other`);
    const readyOnly = watch(`${inputSuffix}.read_only`);
    const pdf = watch(`${inputSuffix}.pdf`);

    const [showRecommendations, setShowRecommendations] = useState(true);

    const toggleRecommendations = () => {
        setShowRecommendations(!showRecommendations);
    };
    //const recommendation = watch(`${inputSuffix}.recommendation`);
    const editorRef = useRef(null);

    useEffect(() => {
        setValue(`${inputSuffix}.answer`, answerChoice?.answer);
        setValue(
            `${inputSuffix}.score`,
            assessment?.assessment_template_type === 2 ? 0 : answerChoice?.score
        );
        setValue(
            `${inputSuffix}.recommendation`,
            question?.question_choices?.recommendation
        ); // Set the recommendation value
        //setValue(`${inputSuffix}.pdf`, question?.question_choices?.pdf); // Set the recommendation value
        setValue(`${inputSuffix}.pdf`, answerChoice?.pdf); // Set the recommendation value
    }, [answerChoice]); //watch(`${inputSuffix}.recommendation`)

    useEffect(() => {
        let scores = [];
        for (let i = 0; i <= 10; i++) {
            scores.push({ label: i, value: i });
        }
        if (addOther && answer === "Other") {
            scores.push({ label: userOwnScoreLabel, value: 20 });
        }
        setChoiceScores(scores);
        setShowChoices(true);
    }, [question, answer, addOther]);

    if (!showChoices) {
        return null;
    }

    return (
        <Row className="my-3 w-100">
            <Col className="col-2 col-md-1 d-flex">
                <div className="counter-space choices py-2 px-2 px-md-3 me-0 me-md-3 d-flex align-items-start">
                    {choiceIndex + 1}
                </div>
            </Col>
            <Col className="col-9 col-md-10 d-flex flex-column flex-md-row">
                <input
                    type="hidden"
                    value={answerChoice?.ready_only ?? 0}
                    {...register(`${inputSuffix}.read_only`)}
                />
                <Form.Group className="choice-answer mx-0 mx-md-2 mb-2 mb-md-0">
                    <Form.Control
                        disabled={readyOnly && readyOnly != "0"}
                        placeholder={t("common.form.placeholder_enter", {
                            attribute: t("common.form.pdf_name"),
                        })}
                        {...register(`${inputSuffix}.answer`)}
                        isInvalid={
                            !!(
                                errors &&
                                errors[choiceIndex] &&
                                errors[choiceIndex].answer
                            )
                        }
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors &&
                            errors[choiceIndex] &&
                            errors[choiceIndex].answer &&
                            errors[choiceIndex].answer.message}
                    </Form.Control.Feedback>
                </Form.Group>

                {/* <PdfUploadField
                    watch={watch}
                    errors={errors}
                    questionIndex={index}
                    question={question}
                    setValue={setValue}
                    inputNameSuffix={inputSuffix}
                    register={register}
                    fieldName="image"
                    assessment={assessment}
                    pdf={question?.image}
                /> */}

                {assessment?.assessment_template_type === 2 ? null : (
                    <Form.Group className="choice-score">
                        {/* <Form.Select
                            type="number"
                            aria-label={t("common.form.placeholder_choose", {
                                attribute: t("common.form.score"),
                            })}
                            placeholder={t("common.form.placeholder_choose", {
                                attribute: t("common.form.score"),
                            })}
                            {...register(`${inputSuffix}.score`)}
                            isInvalid={
                                !!(
                                    errors &&
                                    errors[choiceIndex] &&
                                    errors[choiceIndex].score
                                )
                            }
                        >
                            {choiceScores.map((score) => (
                                <option value={score.value} key={score.value}>
                                    {score.label}
                                </option>
                            ))}
                        </Form.Select> */}
                        <PdfUploadField
                            watch={watch}
                            errors={errors}
                            questionIndex={choiceIndex}
                            question={question}
                            setValue={setValue}
                            inputNameSuffix={inputSuffix}
                            register={register}
                            fieldName="pdf"
                            assessment={assessment}
                            pdf={answerChoice?.pdf}
                        />
                        {/* *** {choiceIndex} === {index} ****
                        <hr />
                        {watch(`${inputSuffix}.answer`)} **
                        {answerChoice?.pdf}
                        <hr /> */}
                        {/* {question?.question_choices[choiceIndex].pdf} */}
                        <Form.Control.Feedback type="invalid">
                            {errors &&
                                errors[choiceIndex] &&
                                errors[choiceIndex].pdf &&
                                errors[choiceIndex].pdf.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                )}
            </Col>
            {true && ( /* choiceIndex > 0 */
                <>
                    <Col className="col-1 p-0 d-flex align-items-center">
                        <span
                            className="text-danger x-icon mx-3"
                            role="button"
                            onClick={() => removePdfChoice(choiceIndex)}
                        >
                            <XIcon />
                        </span>
                    </Col>
                </>
            )}

            <Col className="col-1 p-0 d-flex align-items-center">
                <span
                    className="text-primary mx-3"
                    role="button"
                    onClick={toggleRecommendations}
                >
                    {showRecommendations ? (
                        <ChevronUpIcon />
                    ) : (
                        <ChevronDownIcon />
                    )}
                </span>
            </Col>
        </Row>
    );
}

export default ChoicesPdfFormBuilder;
