import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Button, Card, Form, Row, Modal } from "react-bootstrap";
import Plus from "@/components/icons/Plus";
import LoadingCover from "@/components/LoadingCover";
import { useAuth } from "@/services/Auth";
import LOCATION from "@/constants/Location";
import ConfirmModal from "@/components/modals/ConfirmModal";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useForm } from "react-hook-form";
import LoadingButton from "../../../../../components/LoadingButton";
import { event } from "jquery";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
function OrganizationUsersForm({
    setValue,
    watch,
    register,
    organization,
    // errors,
    branch,
    //namePrefix,
    unregister,
}) {
    const auth = useAuth();
    const { t } = useTranslation();

    const [userList, setUserList] = useState([]);

    const user = {
        id: null,
        first_name: null,
        last_name: null,
        email: null,
        phone_number: null,
        password: null,
    };
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [errors, setErrors] = useState({});
    const [showSuccess, setShowSuccess] = useState(false);
    const [title, setTitle] = useState("");
    const [loading, setLoading] = useState(false);
    const [serverError, setServerError] = useState("");
    const [serverErrors, setServerErrors] = useState([]);

    const [formData, setFormData] = useState({
        id: "",
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        password: "",
    });

    const addMainUser = () => {
        setErrors({});
        setServerError("");
        setServerErrors([]);
        setFormData({
            id: "",
            first_name: "",
            last_name: "",
            email: "",
            phone_number: "",
            password: "",
        });
        setIsModalVisible(true);
    };

    const updateUser = (user) => {
        setErrors({});
        setServerError("");
        setServerErrors([]);
        setFormData({
            id: "",
            first_name: "",
            last_name: "",
            email: "",
            phone_number: "",
            password: "",
        });

        const { id, first_name, last_name, email, phone_number } = user;

        // Update only first_name and last_name fields of formData
        setFormData({
            ...formData,
            id,
            first_name,
            last_name,
            email,
            phone_number,
        });

        //setFormData({ ...user });
        console.log("formData ", formData);
        setIsModalVisible(true);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const handleConfirm = () => {
        setShowSuccess(false);
        //setTitle(null);
        //history.push(`${LOCATION.ADMIN_USERS.LIST.path}`);
    };
    const handleSaveMainUser = async () => {
        try {
            setServerError("");
            /* let apiUrl =
                formData.id == ""
                    ? `${LOCATION.ORGANIZATIONS.USERS.CREATE.API.path}`
                    : `${LOCATION.ORGANIZATIONS.USERS.UPDATE.API.path}/` +
                      formData.id;
            alert(apiUrl); */

            await validationSchema.validate(formData, { abortEarly: false });
            setLoading(true);
            //return false;

            if (formData.id != "") {
                auth.putRequest(
                    `${LOCATION.ORGANIZATIONS.USERS.UPDATE.API.path}/` +
                        formData.id,
                    formData
                )
                    .then((response) => {
                        setLoading(false);
                        if (response.data.message === "OK") {
                            setIsModalVisible(false);
                            setTitle(
                                t("pages.my_account.organization_users_update")
                            );
                            setShowSuccess(true);
                            getOrgMainUserList();
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        if (error?.response?.status === 401) {
                            setServerError(error.response.data.message);
                        }

                        if (error.response.status === 422) {
                            let serverErrors = [];
                            for (const key in error.response.data.errors) {
                                serverErrors.push(
                                    error.response.data.errors[key][0]
                                );
                            }
                            setServerErrors(serverErrors);
                        }
                    });
            } else {
                auth.postRequest(
                    `${LOCATION.ORGANIZATIONS.USERS.CREATE.API.path}`,
                    formData
                )
                    .then((response) => {
                        setLoading(false);
                        if (response.data.message === "OK") {
                            setIsModalVisible(false);
                            setTitle(
                                t("pages.my_account.organization_users_created")
                            );
                            setShowSuccess(true);
                            getOrgMainUserList();
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        if (error?.response?.status === 401) {
                            setServerError(error.response.data.message);
                        }

                        if (error.response.status === 422) {
                            let serverErrors = [];
                            for (const key in error.response.data.errors) {
                                serverErrors.push(
                                    error.response.data.errors[key][0]
                                );
                            }
                            setServerErrors(serverErrors);
                        }
                    });
            }
        } catch (error) {
            const validationErrors = {};
            error.inner.forEach((e) => {
                validationErrors[e.path] = e.message;
            });
            setErrors(validationErrors);
        }
    };

    const validationSchema = yup.object().shape({
        first_name: yup.string().required(
            t("common.form_validation.is_required", {
                attribute: t("pages.admin_users.form.first_name"),
            })
        ),
        last_name: yup.string().required(
            t("common.form_validation.is_required", {
                attribute: t("pages.admin_users.form.last_name"),
            })
        ),
        password: yup.string().required(
            t("common.form_validation.is_required", {
                attribute: t("pages.admin_users.form.password"),
            })
        ),
        password_confirmation: yup
            .string()
            .oneOf(
                [yup.ref("password"), null],
                t("common.form_validation.password_is_must_match")
            )
            .required(t("common.form_validation.password_confirm_required")),
        email: yup
            .string()
            .email(t("common.form_validation.invalid_email_address"))
            .required(
                t("common.form_validation.is_required", {
                    attribute: t("pages.admin_users.form.email"),
                })
            ),
        phone_number: yup
            .string()
            .required(t("common.form_validation.phone_number_is_required")),
    });

    const getOrgMainUserList = () => {
        let request = auth.getRequest(
            LOCATION.ORGANIZATIONS.USERS.LIST.API.path
        );
        request
            .then((response) => {
                if (response.data.data.length > 0) {
                    setUserList(response.data.data);
                } else {
                    setUserList([]);
                }
                //setLoading(false);
            })
            .catch((error) => {});
        return () => request?.abort && request.abort();
    };

    const updateUserStatus = (status, id, main) => {
        try {
            setServerError("");
            setLoading(true);
            auth.postRequest(
                `${LOCATION.ORGANIZATIONS.USERS.STATUS.API.path}`,
                { status: status, id: id }
            )
                .then((response) => {
                    setLoading(false);
                    if (response.data.message === "OK") {
                        setTitle(
                            t(
                                "pages.my_account.organization_user_status_updated"
                            )
                        );
                        setShowSuccess(true);
                        //getOrgMainUserList();
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    if (error?.response?.status === 401) {
                        setServerError(error.response.data.message);
                    }

                    if (error.response.status === 422) {
                        let serverErrors = [];
                        for (const key in error.response.data.errors) {
                            serverErrors.push(
                                error.response.data.errors[key][0]
                            );
                        }
                        setServerErrors(serverErrors);
                    }
                });
        } catch (error) {
            const validationErrors = {};
            error.inner.forEach((e) => {
                validationErrors[e.path] = e.message;
            });
            setErrors(validationErrors);
        }
    };
    const handleChange = (event, id, main) => {
        const isChecked = event.target.checked;
        updateUserStatus(isChecked, id, main);
    };
    useEffect(() => {
        let request = getOrgMainUserList();
    }, []);
    useEffect(() => {
        //let request = getOrgMainUserList();
        /* let request = auth.getRequest(LOCATION.ORGANIZATIONS.USERS.API.path);
        request
            .then((response) => {
                if (response.data.data.length > 0) {
                    setUserList(response.data.data);
                } else {
                    setUserList([user]);
                }
                //setLoading(false);
            })
            .catch((error) => {});
        return () => request?.abort && request.abort(); */
    }, []);

    if (userList.length === 0) {
        //return <LoadingCover />;
    }
    return (
        <>
            <ConfirmModal
                show={showSuccess}
                title={title}
                confirmText={t("common.dialogs.actions.okay")}
                handleConfirm={handleConfirm}
            />
            <div className="mt-4">
                <Card className="mt-4">
                    <Card.Header>
                        <div className="d-flex justify-content-between">
                            <h6 className="text-bold">
                                {t("pages.my_account.organization_users")}
                            </h6>
                            <Button
                                className="text-white"
                                onClick={() => addMainUser()}
                            >
                                <span className="me-1">
                                    {t(
                                        "pages.my_account.buttons.add_organization_users"
                                    )}
                                </span>
                                <Plus />
                            </Button>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        {userList.length > 0 && (
                            <table className="table table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th>
                                            {t("pages.admin_users.form.no")}
                                        </th>
                                        <th>
                                            {t(
                                                "pages.admin_users.form.full_name"
                                            )}
                                        </th>
                                        <th>
                                            {t("pages.admin_users.form.email")}
                                        </th>
                                        <th>
                                            {t(
                                                "pages.admin_users.form.phone_number"
                                            )}
                                        </th>
                                        <th>{t("common.form.user_status")}</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userList.map((user, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                                {user.first_name}{" "}
                                                {user.last_name}
                                            </td>
                                            <td>{user.email}</td>
                                            <td>{user.phone_number}</td>
                                            {/* <td>{user.status ? t("common.form.active") : t("common.form.in_active")}</td> */}
                                            <td>
                                                <div className="d-flex align-items-center ">
                                                    <span className="ms-2">
                                                        {t("common.form.yes")}{" "}
                                                        &nbsp;
                                                    </span>
                                                    <Form.Check
                                                        type="switch"
                                                        //id="custom-switch"
                                                        defaultChecked={
                                                            user.status === 1
                                                        }
                                                        {...register(`status`)}
                                                        id={`custom-switch-${user.id}`}
                                                        //onChange={handleChange}
                                                        onChange={(e) =>
                                                            handleChange(
                                                                e,
                                                                user.id,
                                                                1
                                                            )
                                                        }
                                                    />

                                                    <span className="ms-2">
                                                        {t("common.form.no")}
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <Link
                                                    to="#"
                                                    className="text-info"
                                                    onClick={() =>
                                                        updateUser(user)
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faPencil}
                                                        role="button"
                                                    />
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                        {userList.length === 0 && (
                            <p className="text-center">{t("common.no_data")}</p>
                        )}
                    </Card.Body>
                    {/*  <Card.Footer>
                        <div className="d-flex justify-content-between1">
                            <Button
                                className="text-white"
                                onClick={() => addMainUser()}
                            >
                                <span className="me-1">
                                    {t("pages.my_account.buttons.add_new_user")}
                                </span>
                                <Plus />
                            </Button>
                            <Button
                                className="text-white btn-success mx-md-2"
                                onClick={() => addMainUser()}
                            >
                                <span className="me-11">
                                    {t("pages.my_account.buttons.save_user")}
                                </span>
                            </Button>
                        </div>
                    </Card.Footer> */}
                </Card>
            </div>
            <Modal show={isModalVisible} onHide={handleCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("pages.admin_users.add")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <input type="hidden" name="id" value={formData.id} />

                        <Form.Group
                            className="mb-4 mt-4"
                            controlId="formFirstName"
                        >
                            <Form.Label>
                                {t("pages.admin_users.form.first_name")}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t(
                                    "pages.admin_users.form.first_name"
                                )}
                                name="first_name"
                                value={formData.first_name}
                                onChange={handleInputChange}
                                isInvalid={!!errors.first_name}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.first_name}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                            className="mb-4 mt-4"
                            controlId="formLastName"
                        >
                            <Form.Label>
                                {t("pages.admin_users.form.last_name")}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t(
                                    "pages.admin_users.form.last_name"
                                )}
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleInputChange}
                                isInvalid={!!errors.last_name}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.last_name}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-4 mt-4" controlId="formEmail">
                            <Form.Label>
                                {t("pages.admin_users.form.email")}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t("pages.admin_users.form.email")}
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                isInvalid={!!errors.email}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.email}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                            className="mb-4 mt-4"
                            controlId="formPhoneNumber"
                        >
                            <Form.Label>
                                {t("pages.admin_users.form.phone_number")}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t(
                                    "pages.admin_users.form.phone_number"
                                )}
                                name="phone_number"
                                value={formData.phone_number}
                                onChange={handleInputChange}
                                isInvalid={!!errors.phone_number}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.phone_number}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                            className="mb-4 mt-4"
                            controlId="formPassword"
                        >
                            <Form.Label>
                                {t("pages.admin_users.form.password")}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="password"
                                placeholder={t(
                                    "pages.admin_users.form.password"
                                )}
                                name="password"
                                value={formData.password}
                                onChange={handleInputChange}
                                isInvalid={!!errors.password}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.password}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                            className="mb-4 mt-4"
                            controlId="formCPassword"
                        >
                            <Form.Label>
                                {t(
                                    "pages.admin_users.form.password_confirmation"
                                )}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="password"
                                placeholder={t(
                                    "pages.admin_users.form.password_confirmation"
                                )}
                                name="password_confirmation"
                                value={formData.password_confirmation}
                                onChange={handleInputChange}
                                isInvalid={!!errors.password_confirmation}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.password_confirmation}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form>

                    {serverError !== "" && (
                        <div className="form-group mb-2">
                            <p className="text-danger py-1 mb-0">
                                {t(serverError)}
                            </p>
                        </div>
                    )}
                    {serverErrors.length !== 0 && (
                        <div className="form-group mb-2">
                            {serverErrors.map((error, index) => (
                                <p
                                    className="text-danger font-weight-bold"
                                    key={index}
                                >
                                    {t(error)}
                                </p>
                            ))}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCancel}>
                        {t("common.cancel")}
                    </Button>
                    {/* <Button variant="primary" onClick={handleSaveMainUser}>
                        {t(
                                    "common.save"
                                )}

                    </Button> */}

                    <LoadingButton
                        className="auth-btn text-white px-5"
                        loading={loading}
                        variant="primary"
                        titleTranslationKey="common.save"
                        type="button"
                        //onClick={handleSaveMainUser}
                        onSubmit={handleSaveMainUser}
                    />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default OrganizationUsersForm;
