import { Card, Col, Row } from "react-bootstrap";
import moment from "moment/moment";
import { React, useEffect, useState } from "react";
import AssessmentScoreIcon from "../AssessmentScoreIcon";
import AssessmentScoreHtml from "../AssessmentScoreHtml";
import { getScoreColor } from "@/Utility";
import Logo from "@/components/Logo";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/services/Auth";
import {
    getScoreColorValue,
    getSectionScore,
    getScoreColorCompetency,
    getScoreColorValueCompetency,
    getSectionScoreLikelihood,
    getSectionScoreSeverity,
    getAddessmentAddress,
} from "@/Utility";
//import certificateImage from "https://firesafety.project.frawizard.co.uk/assets/uploads/banners/650d7e2858b8b.png";
import LOCATION from "@/constants/Location";
function AssessmentCertificate({
    assessment,
    small,
    reportTemplateRef,
    ...params
}) {
    const auth = useAuth();
    const baseUrl = auth.api.defaults.baseURL;
    const [headerLogo, setHeaderLogo] = useState(null);
    let assessorSignature = auth.user.user_signature
        ? `${baseUrl}/${auth.user.user_signature}`
        : "/assets/images/signature.png";
    let accreditationLogo = auth.user.organization?.accreditation_logo
        ? `${baseUrl}/${auth.user.organization.accreditation_logo}`
        : "/assets/images/logo-placeholder.png";

    let companyLogo = auth.user.organization?.logo
        ? `${baseUrl}/${auth.user.organization.logo}`
        : "/assets/images/logo-placeholder.png";

    const { t } = useTranslation();
    useEffect(() => {
        setHeaderLogo("/assets/images/headeroriginal.png");
        if (params.pdfWatermark) {
            if (params.pdfWatermark.banner !== "") {
                // setHeaderLogo(params.pdfWatermark.banner);
            }
            if (params.pdfWatermark.title !== "") {
                // footerText = "Powered by " + params.pdfWatermark.title;
            }
        }
    }, []);
    let token = localStorage.getItem("token") || null;
    const [iframeURL, setIframeURL] = useState("");
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        // const adId = urlParams.get("ad_id");
        let iUrl = baseUrl + LOCATION.ASSESSMENTS.CERTIFICATE.API.path + "&t=" +token+"&ad_id="+assessment.id;

        //let iUrl = "https://firesafety.project.frawizard.co.uk/Post/gen_pdf/1/302/html/38/d0b2b482445b68b188532122eb0d019fb17d3c688740f6a41d91e6a5854d7021?path=fracertificate";
        //https://project.frawizard.co.uk/lv/public/api/assessment-certificate?type=i&t=1691|6LdE2W3q2ACsKAKmkV44lvXMmPv2aCMRcoygOeNX&aid=298
        //https://project.frawizard.co.uk/lv/public/api/page-detail?id=36&type=i&t=1691|6LdE2W3q2ACsKAKmkV44lvXMmPv2aCMRcoygOeNX

        //"https://firesafety.project.frawizard.co.uk/Post/gen_pdf/1/"+assessment.id+"/html/38/"+token+"?path=fracertificate";
        //"https://firesafety.project.frawizard.co.uk/Post/gen_pdf/1/302/html/38/d0b2b482445b68b188532122eb0d019fb17d3c688740f6a41d91e6a5854d7021?path=fracertificate";
        // baseUrl + LOCATION.FIRE_DOOR_COMPLIANCE.API.path + "&t=" + token;

        //https://firesafety.project.frawizard.co.uk/Post/gen_pdf/1/{id}/pdf/38/d0b2b482445b68b188532122eb0d019fb17d3c688740f6a41d91e6a5854d7021?path=fracertificate
        /*  if (adId > 0) {
            iUrl =
                baseUrl +
                LOCATION.FIRE_DOOR_COMPLIANCE.API.path +
                "&t=" +
                token +
                "&ad_id=" +
                adId;
        } */

        setIframeURL(iUrl);
    }, []);
    return (
        <div
            className="container certificate-holder p-1 my-5"
            ref={reportTemplateRef}
        >
            <Card
                className="border-0 shadow assessment-certificate"
                id="assessment-certificate"
            >
                <Card.Body className="certificate-card d-flex flex-column p-0">
                    <iframe
                        src={iframeURL}
                        frameborder={"0"}
                        /* style={{
                            top: "0",
                            right: "0",
                            bottom: "0",
                            left: "0",
                            width: "80%",
                            height: "95%",

                            marginTop: "5%",
                            marginLeft: "15%",
                        }} */
                        className="external-iframe-cert"
                        scrolling="no"
                    ></iframe>
                </Card.Body>
            </Card>
        </div>
    );
}

export default AssessmentCertificate;
