import { Button, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../../services/Auth";
import { useTranslation } from "react-i18next";

import LOCATION from "../../../../constants/Location";
import { Link } from "react-router-dom";

function PlanCard({ plan, hideButton }) {
    const auth = useAuth();

    const { t } = useTranslation();

    return (
        <div
            className={`card plan-card bg-transparent mb-5 mb-lg-0 border-0 mx-auto pay_monthly`}
            key={plan.id}
        >
            <div className="card-body p-0">
                <div className="pricing-head">
                    <h6 className="card-title mb-4">{plan.plan_name}</h6>
                    <div className="tier-details my-4">
                        <h4 className="price gap-1">
                            <i className="pound-sign me-1">£</i>
                            {(plan.price / 100).toFixed(2)}
                            <span className="period ms-1 fw-normal">
                                /credit
                                {/* {t(`pages.plans.durations.${plan.duration}`)} */}
                            </span>
                        </h4>
                        {/* {plan.subscription_duration === 1 && (
                            <>
                                <small className="">
                                    {plan.credits}{" "}
                                    {t("pages.plans.credit_plans.pay_as_you_go")}
                                </small>
                                <small className="">
                                    {t("pages.plans.credit_plans.month", {
                                        credits: plan.credits,
                                    })}
                                </small>
                            </>
                        )}
                        {plan.subscription_duration === 2 && (
                            <small className="">
                                {t("pages.plans.credit_plans.month", {
                                    credits: plan.credits,
                                })}
                            </small>
                        )}
                        {plan.subscription_duration === 3 && (
                            <small className="">
                                {t("pages.plans.credit_plans.yearly", {
                                    credits: plan.credits,
                                    freeAudit: plan.free_audit,
                                })}
                            </small>
                        )} */}
                    </div>
                </div>
                <p className="intro-benefits">
                    {plan.plan_type === 1 && (
                        <>
                            {/* <strong>
                            {t("pages.plans.credit_use.pay_as_you_go")}
                        </strong> */}
                            <strong>
                                {t("pages.plans.credit_use.subscription", {
                                    credits: plan.credits,
                                })}
                            </strong>
                        </>
                    )}
                    {plan.plan_type === 2 && (
                        <strong>
                            {t("pages.plans.credit_use.subscription", {
                                credits: plan.credits,
                            })}
                        </strong>
                    )}
                </p>
                <p className="intro-benefits">
                    {plan.plan_type === 1 && (
                        <strong>
                            {t(
                                "pages.plans.credit_use.pay_as_you_go_per_service"
                            )}
                        </strong>
                    )}
                    {plan.plan_type === 2 && (
                        <strong>
                            {t("pages.plans.credit_use.price_per_service", {
                                pricePerService: plan.price_per_service / 100,
                            })}
                        </strong>
                    )}
                </p>
                <div className="features">
                    <h5>{t("common.features")}</h5>
                    <ul className="my-3">
                        <li className="my-2">
                            <FontAwesomeIcon
                                icon={faCircleCheck}
                                size="sm"
                                className="me-2"
                            />
                            <strong>
                                {t("pages.plans.credits", {
                                    count: plan.credits,
                                })}
                            </strong>
                        </li>
                        {plan.plan_type === 1 && (
                            <li className="my-2">
                                <FontAwesomeIcon
                                    icon={faCircleCheck}
                                    size="sm"
                                    className="me-2"
                                />
                                <strong>
                                    {t("pages.plans.credit_use.pay_as_you_go")}
                                </strong>
                            </li>
                        )}
                        <li className="my-2">
                            <FontAwesomeIcon
                                icon={faCircleCheck}
                                size="sm"
                                className="me-2"
                            />
                            <strong>
                                {t("pages.plans.fra_wizard_chatbox")}
                            </strong>
                        </li>
                        <li className="my-2">
                            <FontAwesomeIcon
                                icon={faCircleCheck}
                                size="sm"
                                className="me-2"
                            />
                            <strong>
                                {t("pages.plans.fra_wizard_toolbox")}
                            </strong>
                        </li>
                        {plan.subscription_duration === 2 && (
                            <li className="my-2">
                                <FontAwesomeIcon
                                    icon={faCircleCheck}
                                    size="sm"
                                    className="me-2"
                                />
                                <strong>
                                    {t("pages.plans.cancel_any_time")}
                                </strong>
                            </li>
                        )}
                        {plan.subscription_duration === 3 && (
                            <li className="my-2">
                                <FontAwesomeIcon
                                    icon={faCircleCheck}
                                    size="sm"
                                    className="me-2"
                                />
                                <strong>{t("pages.plans.free_audit")}</strong>
                            </li>
                        )}
                    </ul>
                </div>
                {!hideButton && (
                    <div className="d-grid">
                        {(auth?.user?.subscription_status === "active" &&
                            plan.plan_type !== 1) ||
                        auth?.user?.admin ? (
                            <Button
                                disabled={true}
                                variant="black"
                                className="text-white btn-dark"
                            >
                                {" "}
                                {plan.plan_type !== 1
                                    ? t("pages.plans.buttons.subscribe")
                                    : t("pages.plans.buttons.buy_credit")}
                            </Button>
                        ) : (
                            <Link
                                to={
                                    auth.user
                                        ? plan.plan_type !== 1
                                            ? `${LOCATION.PLANS.API.path}/${plan.id}/subscribe`
                                            : `${LOCATION.PLANS.API.path}/${plan.id}/buy-credit`
                                        : LOCATION.AUTH.SIGN_IN.path
                                }
                                className="btn btn-black btn-dark text-white"
                            >
                                {plan.plan_type !== 1
                                    ? t("pages.plans.buttons.subscribe")
                                    : t("pages.plans.buttons.buy_credit")}
                            </Link>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default PlanCard;
