import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import BootstrapTable from 'react-bootstrap-table-next'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import Paginate from '../Paginate'
import LOCATION from '@/constants/Location'

function useColumns (filter) {
  const { t } = useTranslation()

  let columns = [
    {
      dataField: 'id',
      text: t('common.form.name'),
      formatter: (cell, row) => {
        return row.name
      },
    },
    {
      dataField: 'email',
      text: t('common.form.email'),
    },

  ]

  return filter && columns.filter(filter) || columns
}

function AdminUsers ({ data: recordsProp, paginate, url, query, ...props }) {
  let auth = useAuth()
  const { t } = useTranslation()

  const perPage = query.limit || 20
  const exclude = props.exclude || [{ dataField: auth.user.id }]
  const showPagination = paginate == null ? true : paginate

  const request = useRef(null)
  const [page, setPage] = useState(0)
  const [totalRecords, setTotal] = useState(false)
  const [recordsDownloaded, setRecords] = useState(false)

  const getRecords = () => {
    request?.current?.abort && request.current.abort()

    const critera = { ...(query || {}) }

    critera.take = perPage
    critera.skip = page * perPage
    critera.is_admin = 1;

    request.current = auth.getRequest(url || LOCATION.ADMIN_USERS.API.path, critera)

    setRecords(false)

    request.current
      .then(response => {
        setRecords(response.data.data)
        setTotal(response.data.length)
      })
  }

  const columns = useColumns(_ => !exclude.includes(_.dataField) && !exclude.includes(_.name))

  const changePage = (event) => {
    setPage(event.selected)
  }

  const records = recordsProp || recordsDownloaded

  useEffect(() => {
    if (!recordsProp) {
      getRecords()
    }

    return () => request?.current?.abort && request.current.abort()
  }, [page, query, url])

  useEffect(() => {
    props.onData && props.onData(records)
  }, [records])

  useEffect(() => {
    props.onTotal && props.onTotal(totalRecords)
  }, [totalRecords])

  return (
    <div className="fra-table">
      <BootstrapTable
        keyField="id"
        responsive
        hover
        columns={columns || []}
        data={records || []}
        noDataIndication={records instanceof Array ? t('common.no_data') : t('common.loading')}
        {...props}
      />
      {
        showPagination && records && totalRecords &&
        <Paginate {...{ changePage, totalRecords, page, perPage }} />
        || ''
      }
    </div>
  )
}

export default AdminUsers

export {
  useColumns
}
