import { Link, useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import LoadingCover from '@/components/LoadingCover'
import LOCATION from '@/constants/Location'
import AssessmentFilters from '@/views/components/AssessmentFilters'

import Table, { useColumns } from '@/views/components/Tables/Assessments'
import TableOutLandex from '@/views/components/Tables/AssessmentsOutLandex'
import { useAuth } from '@/services/Auth'
import Plus from '@/components/icons/Plus'
import ConfirmModal from '@/components/modals/ConfirmModal'
function AssessmentAuditIndex () {
  const history = useHistory()
  const route = useLocation()
  const auth = useAuth()
  const { t } = useTranslation()

  const [assessmentTemplates, setAssessmentTemplates] = useState([])
  const [assessmentStatus, setAssessmentStatus] = useState([])
  const [loading, setLoading] = useState(true)


  const [auditCompleted, setAuditCompleted] = useState(0);
  const [totalToBeAudited, setTotalToBeAudited] = useState(0);

  const [query, setQuery] = useState(route.state && route.state.query || { page: 0, skip: 0, limit: 15 })

  const [searching, setSearching] = useState(false)
  const [assessmentToDelete, setAssessmentToDelete] = useState(null)
  const [showModal, setShowModal] = useState(false)

  const deleteRequest = (assessmentToDelete) => {
    setAssessmentToDelete(assessmentToDelete);
    setShowModal(true)
  }

  const handleCancel = () => {
    setShowModal(false)
    setAssessmentToDelete(null);
  }

  const handleConfirm = () => {
    setShowModal(false)
    setLoading(true);
    auth.deleteRequest(`${LOCATION.ASSESSMENTS.API.path}/${assessmentToDelete}`)
      .then(response => {
        setLoading(false);
        setAssessmentToDelete(null);
      })
      .catch(error => {
      })
  }

  const updateStats = (assessments) => {
    let audited = 0;
    assessments.forEach( _ => {
     if(  _.assessment_audit_status === 'completed' ){
       audited +=1;
     }
    });
    setAuditCompleted(audited);
    setTotalToBeAudited(assessments.length);

  }

  useEffect(() => {
    let request = auth.getRequest(LOCATION.ASSESSMENT_TEMPLATES.API.path)

    request
      .then(response => {
        setAssessmentTemplates(response.data.data)
        setLoading(false)
      })
      .catch(error => {
      })

    return () => request?.abort && request.abort()

  }, [])

  useEffect(() => {
    let request = auth.getRequest(`${LOCATION.ASSESSMENT_AUDI_STATUS.API.path}`)

    request
      .then(response => {
        setAssessmentStatus(response.data.assessment_status)
      })
      .catch(error => {
      })

    return () => request?.abort && request.abort()

  }, [])

  useEffect(() => {
    history.replace(
      route.pathname,
      {
        ...route.state,

        query,
      }
    )
  }, [query])

  if (loading) return <LoadingCover/>

  return (
    <>
    <ConfirmModal
        show={showModal}
        title={t('pages.assessments.notifications.delete_assessment.title')}
         body={t('pages.assessments.notifications.delete_assessment.body')}
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        cancelText={t('common.dialogs.actions.cancel')}
        confirmText={t('common.dialogs.actions.delete')}
      />
    <div className="content">
      <div className="section-header d-flex justify-content-between mb-4">
        <h4>{t('pages.assessment_audit.title')}</h4>
      </div>
      <AssessmentFilters
        searching={searching}
        values={query}
        loadAssessments={setQuery}
        assessmentStatus={assessmentStatus}
        assessmentTemplates={assessmentTemplates}
      />
      <h6 className="mt-4">{t('pages.assessment_audit.requested_assessments')}</h6>
      <div className="mb-4 bg-white">
        <Table query={query} showPagination={true} onData={_ => setSearching(!_)} auditRequests={true} assessmentAudit={true} deleteRequest={deleteRequest}/>
      </div>


      <h6 className="mt-4">{t('pages.assessment_audit.automated_assessments_for_audit', { progress: `${auditCompleted}/${totalToBeAudited}` })}</h6>
      <div className="bg-white">
        <Table query={query} showPagination={true} onData={_ => setSearching(!_)} assessmentAudit={true} updateStats={updateStats} deleteRequest={deleteRequest}/>
      </div>

      <h6 className="mt-4">{t('pages.assessment_audit.requested_assessments')}</h6>
      <div className="mb-4 bg-white">
        <TableOutLandex query={query} showPagination={true} onData={_ => setSearching(!_)} auditRequests={true} assessmentAudit={true} deleteRequest={deleteRequest}/>
      </div>
    </div>
    </>
  )
}

export default AssessmentAuditIndex
