import LOCATION from "@/constants/Location";

import HealthSafetyPolicyPage from "@/views/pages/HealthSafetyPolicy/HealthSafetyPolicy";

const HealthSafetyPolicy = [
    {
        path: LOCATION.HEALTH_SAFETY_POLICY.LIST.path,
        component: HealthSafetyPolicyPage,
        exact: true,
        translation_key: "pages.health_safety_policy.title",
        main: true,
        //gate: "my_account",
        order: 3,
    },
];
export default HealthSafetyPolicy;
