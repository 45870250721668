const OccupancyCalculator = {
    title: "Occupancy Calculator",
    description: "This calculator will help you estimate the maximum number of people or exhibition stands you can host in different room configurations given the area in either sq feet or metres.",
    titel_1: 'Quick Calculator',
    titel_2: 'Measure by',
    titel_3: 'People',
    titel_4: 'Room Size',
    titel_5: 'Enter amount of people',
};
export default OccupancyCalculator;
