import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import React, { useEffect, useState } from 'react'
import LoadingCover from '@/components/LoadingCover'
import { Card, Col, Row } from 'react-bootstrap'
import LOCATION from '@/constants/Location'
import { useParams } from 'react-router-dom'
import PaymentMethod from '../../Payments/PaymentMethod'

function AuditPaymentScreen () {

  const auth = useAuth()
  const { t } = useTranslation()

  let baseUrl = auth.api.defaults.baseURL

  const [assessment, setAssessment] = useState(null)
  const [loading, setLoading] = useState(true)

  const auditPrice = 6900

  const { id } = useParams()

  const getDefaultImage = (e) => {
    e.target.src = `/assets/images/placeholder.png`
  }

  useEffect(() => {
    let request = auth.getRequest(`${LOCATION.ASSESSMENTS.API.path}/${id}`)
    request
      .then(response => {
        setAssessment(response.data)
        setLoading(false)
      })
      .catch(error => {
      })

  }, [])

  if (loading || assessment == null) {
    return (
      <LoadingCover/>
    )
  }

  return (
    <div className="payment-screen-container container">
      <Card className="mt-5 border-0">
        <Card.Body>
          <Row>
            <Col className="col-md-6 col-12 p-lg-5 p-2">
              <Card.Img
                width="200"
                variant="top"
                src={`${baseUrl}/${assessment.assessment_template.featured_image}`}
                onError={(e) => getDefaultImage(e)}
              />
              <Card.Title className="mt-2 fw-bold">
                {t('pages.assessment_audit.payments.title', { attribute: assessment.assessment_template.title })}
              </Card.Title>
              <Card.Title className="my-3 fw-bold"><span
                className="me-2 text-primary">{auth.user.currency}{(auditPrice / 100).toFixed(2)}</span>
              </Card.Title>
            </Col>
            <Col className="col-md-6 col-12 p-2 d-flex">
              <PaymentMethod
                assessment={assessment}
                source='audit-request'
                AuditPrice={auditPrice}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  )
}

export default AuditPaymentScreen
