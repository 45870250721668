import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import * as moment from "moment";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/services/Auth";
import Paginate from "../Paginate";
import LOCATION from "@/constants/Location";
import { getScoreColor } from "@/Utility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrash,
    faTrashAlt,
    faDownload,
} from "@fortawesome/free-solid-svg-icons";

function useColumns(filter, assessmentAudit, source, deleteRequest, userToken) {
    let auth = useAuth();
    const { t } = useTranslation();

    const getFormType = (form_id) => {
        return {
            24: {"slug" :"fire-safety-policy-document", "name" : "Fire Safety Policy Document"},
            26: {"slug" :"health-and-safety-policy", "name" : "Health And Safety Policy"},
            27: {"slug" :"fire-door-compliance", "name" : "Fire door Compliance"},
            36: {"slug" :"fire-extinguiser", "name" : "Fire Extinguiser"},
            37: {"slug" :"emergency-lighting", "name" : "Emergency Lighting"},
        }[form_id] || "";
    };
    const IdRowFormatter = (cell, row) => {
        /* const formTypeLink =
            {
                24: "fire-safety-policy-document",
                26: "health-and-safety-policy",
                27: "fire-door-compliance",
                36: "fire-extinguiser",
                37: "emergency-lighting",
            }[row.form_id] || ""; */
        const formType = getFormType(row.form_id);
        if (row?.ad_id) {
            return (
                <Link
                    className="table-links"
                    to={`/${formType.slug}?ad_id=${row.ad_id}&is_audited=1`}
                >
                    {row.ad_id}
                </Link>
            );
        }
        return row.ad_id;
    };
    const AddressRowFormatter = (cell, row) => {
        return <div dangerouslySetInnerHTML={{ __html: cell }} />;
    };

    const FormTypeFormatter = (cell, row) => {
        /* const formType =
            {
                24: "Fire Safety Policy Focument",
                26: "Health And Safety Policy",
                27: "Fire door Compliance",
                36: "Fire Extinguiser",
                37: "Emergency Lighting",
            }[row.form_id] || "Unknown"; */
            const formType = getFormType(row.form_id);

            if (formType) {
                return (
                    <Link
                        className="table-links"
                        to={`/${formType.slug}?ad_id=${row.ad_id}&is_audited=1`}
                    >
                        {formType.name}
                    </Link>
                );
            }
    };

    const statusStyle = (cell, row) => {
        let backgroundColor = "#198754";
        const status = assessmentAudit ? row.ad_audit_status : row.ad_status;
        if (status === 1) {
            backgroundColor = assessmentAudit ? "#dc3545" : "#ffc107";
        } else if (status === 2) {
            backgroundColor = assessmentAudit ? "#ffc107" : "#dc3545";
        } else if (status === 4) {
            backgroundColor = "#ffc107";
        }

        return {
            color: "#ffffff",
            backgroundColor: backgroundColor,
        };
    };

    const getClientBussinessNameFormatter = (cell, row) => {
        if (row.hasOwnProperty("ad_client")) {
            return row.ad_client;
        }
        if (
            row.hasOwnProperty(
                "ad_whatisthenameofthebusinessoutlinedinthepolicy"
            )
        ) {
            return row.ad_whatisthenameofthebusinessoutlinedinthepolicy;
        }
        if (
            row.hasOwnProperty(
                "ad_whatisthenameofthebusinessoutlinedinthispolicy"
            )
        ) {
            return row.ad_whatisthenameofthebusinessoutlinedinthispolicy;
        }
        if (row.hasOwnProperty("ad_businessname")) {
            return row.ad_businessname;
        }
    };
    const getAddressFormatter = (cell, row) => {
        if (row.hasOwnProperty("ad_installationaddress")) {
            return (
                <div
                    dangerouslySetInnerHTML={{
                        __html: row.ad_installationaddress,
                    }}
                />
            );
        }
        if (row.hasOwnProperty("ad_whatisthesiteaddressofthebusiness")) {
            return (
                <div
                    dangerouslySetInnerHTML={{
                        __html: row.ad_whatisthesiteaddressofthebusiness,
                    }}
                />
            );
        }

        if (row.hasOwnProperty("ad_siteaddress")) {
            return (
                <div dangerouslySetInnerHTML={{ __html: row.ad_siteaddress }} />
            );
        }
    };
    let columns = [
        {
            dataField: "ad_id",
            text: t("common.tables.id"),
            formatter: IdRowFormatter,
        },
        {
            dataField: "form_type",
            text: "Form Type",
            formatter: FormTypeFormatter,
        },
        {
            dataField: "ad_client",
            text: t("common.tables.client_buss_name"),
            formatter: getClientBussinessNameFormatter,
        },
        {
            dataField: "ad_installationaddress",
            text: t("common.tables.address"),
            formatter: getAddressFormatter,
        },
        {
            dataField: assessmentAudit
                ? "assessment_audit_status"
                : "assessment_status",
            text: t("common.status"),
            style: statusStyle,
            formatter: (cell, row) =>
                row?.ad_id && (
                    <span className="text-white status-field">
                        {t(`common.status_list.${cell}`)}
                    </span>
                ),
        },
    ];

    columns.push({
        dataField: "ad_rand",
        text: t("common.form.fra_reference_number"),
    });
    columns = [
        ...columns,
        ...[
            {
                dataField: "ad_last_update",
                text: t("common.tables.assessment_date"),
                formatter: (datetime) =>
                    datetime &&
                    moment(datetime).format(t("common.formats.short_date")),
            },
        ],
    ];

    /* if (source !== 'users') {
    columns.push(
      {
        dataField: 'actions',
        text: '',
        formatter: (cell, row) => <FontAwesomeIcon icon={faTrashAlt} className="text-danger" onClick={() => deleteRequest(row.id)} role="button"/>
      }
    )
  } */
    if (source !== "users") {
        if (auth && auth.user && auth.user.branch_user !== true) {
            columns.push({
                dataField: "actions",
                text: "",
                formatter: (cell, row) => (
                    <>
                       {/*  <FontAwesomeIcon
                            icon={faTrashAlt}
                            className="text-danger"
                            onClick={() => deleteRequest(row.id)}
                            role="button"
                            style={{ marginRight: "10px" }}
                        /> */}
                        <a
                            href={`${process.env.REACT_APP_PDF_REPORT_BASE_URL}${row.ad_id}/pdf/${row.form_id}/${userToken}?parent_id=${row.ad_id}`}
                            className="text-primary"
                        >
                            <FontAwesomeIcon icon={faDownload} role="button" />
                        </a>
                    </>
                ),
            });
        }
    }

    return (filter && columns.filter(filter)) || columns;
}

function AssessmentsOutLandex({
    data: recordsProp,
    paginate,
    url,
    query,
    ...props
}) {
    let auth = useAuth();
    const { t } = useTranslation();

    const perPage = query.limit || 20;
    const exclude = props.exclude || [];
    const showPagination = paginate == null ? true : paginate;

    const request = useRef(null);
    const [page, setPage] = useState(0);
    const [totalRecords, setTotal] = useState(false);
    const [recordsDownloaded, setRecords] = useState(false);
    const [userToken, setUserToken] = useState(false);
    const getRecords = () => {
        request?.current?.abort && request.current.abort();

        const critera = { ...(query || {}) };

        critera.take = perPage;
        critera.skip = page * perPage;
        critera.audit_requests = props.auditRequests;
        critera.assessment_audit = props.assessmentAudit;

        request.current = auth.getRequest(
            url || LOCATION.ASSESSMENTS.AUDIT.API.path,
            critera
        );

        setRecords(false);

        /* request.current.then((response) => {
            setRecords(response.data.data);
            setTotal(response.data.length);
            if (props.updateStats) {
                props.updateStats(response.data.data);
            }
        }); */
        request.current.then((response) => {
            let allRecords = [];
            Object.keys(response.data.data).forEach((category) => {
                allRecords = allRecords.concat(response.data.data[category]);
            });
            console.log(allRecords);
            setRecords(allRecords);
            setTotal(allRecords.length);
            setUserToken(response.data.t);
            if (props.updateStats) {
                props.updateStats(allRecords);
            }
        });
    };

    const columns = useColumns(
        (_) => !exclude.includes(_?.dataField) && !exclude.includes(_?.name),
        props.assessmentAudit,
        props.source,
        props.deleteRequest,
        userToken
    );

    const changePage = (event) => {
        setPage(event.selected);
    };

    const records = recordsProp || recordsDownloaded;

    useEffect(() => {
        if (!recordsProp) {
            getRecords();
        }

        return () => request?.current?.abort && request.current.abort();
    }, [page, query, url]);

    useEffect(() => {
        props.onData && props.onData(records);
    }, [records]);

    useEffect(() => {
        props.onTotal && props.onTotal(totalRecords);
    }, [totalRecords]);

    return (
        <div className="fra-table">
            <BootstrapTable
                keyField="id"
                responsive
                hover
                columns={columns || []}
                data={records || []}
                noDataIndication={
                    records instanceof Array
                        ? t("common.no_data")
                        : t("common.loading")
                }
                {...props}
            />
            {(showPagination && records && totalRecords && (
                <Paginate {...{ changePage, totalRecords, page, perPage }} />
            )) ||
                ""}
        </div>
    );
}

export default AssessmentsOutLandex;

export { useColumns };
