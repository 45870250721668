import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import LOCATION from "@/constants/Location";
import { Form, Row } from "react-bootstrap";
import { useAuth } from "@/services/Auth";
import React, { useEffect, useState } from "react";
import LoadingButton from "@/components/LoadingButton";
import OrganizationBranchForm from "./Forms/OrganizationBranchForm";
import LoadingCover from "@/components/LoadingCover";
import ConfirmModal from "@/components/modals/ConfirmModal";
import OrganizationUsersForm from "./Forms/OrganizationUsersForm";

function OrganizationDetails() {
    const { t } = useTranslation();
    const auth = useAuth();

    const [organization, setOrganization] = useState(null);
    const [updatingDetails, setUpdatingDetails] = useState(false);
    const [loading, setLoading] = useState(false);

    const [showSuccess, setShowSuccess] = useState(false);
    const [title, setTitle] = useState("");

    const companyCommonInformation = {
        phone_number: yup.string().required(
            t("common.form_validation.is_required", {
                attribute: t("common.form.phone_number"),
            })
        ),
        mobile_number: yup.string().required(
            t("common.form_validation.is_required", {
                attribute: t("common.form.mobile_number"),
            })
        ),
        email: yup
            .string()
            .email(t("common.form_validation.invalid_email_address"))
            .required(
                t("common.form_validation.is_required", {
                    attribute: t("common.form.email"),
                })
            ),
        website: yup
            .string()
            .url(t("common.form_validation.invalid_url"))
            .required(
                t("common.form_validation.is_required", {
                    attribute: t("common.form.website"),
                })
            ),
        /*   address: yup.string().required(
            t("common.form_validation.is_required", {
                attribute: t("common.form.address"),
            })
        ),
        post_code: yup.string().required(
            t("common.form_validation.is_required", {
                attribute: t("common.form.post_code"),
            })
        ), */
    };

    const schema = yup.object().shape({
        name: yup.string().required(
            t("common.form_validation.is_required", {
                attribute: t("common.form.company_name"),
            })
        ),
        registration_number: yup.number().required(
            t("common.form_validation.is_required", {
                attribute: t("common.form.registration_number"),
            })
        ),
        ...companyCommonInformation,
        branches: yup.array().of(
            yup.object().shape({
                name: yup.string().required(
                    t("common.form_validation.is_required", {
                        attribute: t("common.form.branch_name"),
                    })
                ),
                ...companyCommonInformation,
                users: yup.array().of(
                    yup.object().shape({
                        first_name: yup
                            .string()
                            .required(
                                t(
                                    "common.form_validation.first_name_is_required"
                                )
                            ),
                        last_name: yup
                            .string()
                            .required(
                                t(
                                    "common.form_validation.last_name_is_required"
                                )
                            ),
                        phone_number: yup
                            .string()
                            .required(
                                t(
                                    "common.form_validation.phone_number_is_required"
                                )
                            ),
                        email: yup
                            .string()
                            .email(
                                t(
                                    "common.form_validation.invalid_email_address"
                                )
                            )
                            .required(
                                t(
                                    "common.form_validation.email_address_is_required"
                                )
                            ),
                    })
                ),
            })
        ),
    });

    const {
        handleSubmit,
        register,
        unregister,
        setValue,
        watch,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: organization,
        resolver: yupResolver(schema),
    });

    const updateUserData = () => {
        let update = auth.checkToken();
    };

    const onSave = (values) => {
        console.log("values", values);
        setUpdatingDetails(true);
        let request;

        let updateAction = true;

        if (organization?.id) {
            request = auth.putRequest(
                `${LOCATION.ORGANIZATIONS.API.path}/${organization.id}`,
                values
            );
        } else {
            request = auth.postRequest(
                `${LOCATION.ORGANIZATIONS.API.path}`,
                values
            );
            updateAction = false;
        }

        request
            .then((response) => {
                setUpdatingDetails(false);
                getOrganization(response.data.id);
                updateUserData();

                setUpdatingDetails(false);
                setShowSuccess(true);
                if (updateAction) {
                    setTitle(t("common.dialogs.titles.organization_updated"));
                } else {
                    setTitle(t("common.dialogs.titles.organization_created"));
                }
            })
            .catch((error) => {
                setUpdatingDetails(false);
            });
    };

    const getOrganization = (organizationId) => {
        setLoading(true);
        if (organizationId === null) {
            setLoading(false);
            return false;
        }
        let request = auth.getRequest(
            `${LOCATION.ORGANIZATIONS.API.path}/${organizationId}`
        );

        request
            .then((response) => {
                setOrganization(response.data);
                reset(response.data);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });

        return request;
    };

    const handleConfirm = () => {
        setShowSuccess(false);
        setTitle(null);
    };

    useEffect(() => {
        if (auth.user?.organization_id) {
            let request = getOrganization(auth.user?.organization_id);
            return () => request?.abort && request.abort();
        }
    }, []);

    if (loading) {
        return <LoadingCover />;
    }

    return (
        <>
            <ConfirmModal
                show={showSuccess}
                title={title}
                confirmText={t("common.dialogs.actions.okay")}
                handleConfirm={handleConfirm}
            />

            <Form noValidate onSubmit={handleSubmit(onSave)} className="row">
                <Row className="mt-2">
                    <h5 className="">
                        {t("pages.my_account.organisation_details")}
                    </h5>

                    <Form.Group
                        className="col col-sm-6 col-md-3 col-12 mt-3"
                        controlId="formBasicCompanyName"
                    >
                        <Form.Label>{t("common.form.company_name")}</Form.Label>
                        <Form.Control
                            placeholder={t("common.form.placeholder_enter", {
                                attribute: t("common.form.company_name"),
                            })}
                            {...register("name")}
                            isInvalid={!!errors.name}
                        />

                        <Form.Control.Feedback type="invalid">
                            {errors.name && errors.name.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                        className="col col-sm-6 col-md-3 col-12 mt-3"
                        controlId="formBasicRegistrationNumber"
                    >
                        <Form.Label>
                            {t("common.form.registration_number")}
                        </Form.Label>
                        <Form.Control
                            type="number"
                            placeholder={t("common.form.placeholder_enter", {
                                attribute: t("common.form.registration_number"),
                            })}
                            {...register("registration_number")}
                            isInvalid={!!errors.registration_number}
                        />

                        <Form.Control.Feedback type="invalid">
                            {errors.registration_number &&
                                errors.registration_number.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                        className="col col-sm-6 col-md-3 col-12 mt-3"
                        controlId="formBasicPhoneNumber"
                    >
                        <Form.Label>{t("common.form.phone_number")}</Form.Label>
                        <Form.Control
                            placeholder={t("common.form.placeholder_enter", {
                                attribute: t("common.form.phone_number"),
                            })}
                            {...register("phone_number")}
                            isInvalid={!!errors.phone_number}
                        />

                        <Form.Control.Feedback type="invalid">
                            {errors.phone_number && errors.phone_number.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                        className="col col-sm-6 col-md-3 col-12 mt-3"
                        controlId="formBasicMobilePhoneNumber"
                    >
                        <Form.Label>
                            {t("common.form.mobile_number")}
                        </Form.Label>
                        <Form.Control
                            placeholder={t("common.form.placeholder_enter", {
                                attribute: t("common.form.mobile_number"),
                            })}
                            {...register("mobile_number")}
                            isInvalid={!!errors.mobile_number}
                        />

                        <Form.Control.Feedback type="invalid">
                            {errors.mobile_number &&
                                errors.mobile_number.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                        className="col col-sm-6 col-md-3 col-12 mt-3"
                        controlId="formBasicEmail"
                    >
                        <Form.Label>{t("common.form.email")}</Form.Label>
                        <Form.Control
                            placeholder={t("common.form.placeholder_enter", {
                                attribute: t("common.form.email"),
                            })}
                            {...register("email")}
                            isInvalid={!!errors.email}
                        />

                        <Form.Control.Feedback type="invalid">
                            {errors.email && errors.email.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                        className="col col-sm-6 col-md-3 col-12 mt-3"
                        controlId="formBasicWebsite"
                    >
                        <Form.Label>{t("common.form.website")}</Form.Label>
                        <Form.Control
                            placeholder={t("common.form.placeholder_enter", {
                                attribute: t("common.form.website"),
                            })}
                            {...register("website")}
                            isInvalid={!!errors.website}
                        />

                        <Form.Control.Feedback type="invalid">
                            {errors.website && errors.website.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                        className="col col-sm-6 col-md-3 col-12 mt-3"
                        controlId="formBasicWebsite"
                    >
                        <Form.Label>{t("common.form.address")}</Form.Label>
                        <Form.Control
                            placeholder={t("common.form.placeholder_enter", {
                                attribute: t("common.form.address"),
                            })}
                            {...register("address")}
                            isInvalid={!!errors.address}
                        />

                        <Form.Control.Feedback type="invalid">
                            {errors.address && errors.address.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                        className="col col-sm-6 col-md-3 col-12 mt-3"
                        controlId="formBasicMobilePhoneNumber"
                    >
                        <Form.Label>{t("common.form.post_code")}</Form.Label>
                        <Form.Control
                            placeholder={t("common.form.placeholder_enter", {
                                attribute: t("common.form.post_code"),
                            })}
                            {...register("post_code")}
                            isInvalid={!!errors.post_code}
                        />

                        <Form.Control.Feedback type="invalid">
                            {errors.post_code && errors.post_code.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>

                <OrganizationUsersForm
                    setValue={setValue}
                    register={register}
                    unregister={unregister}
                    //organization={organization}
                    watch={watch}
                    errors={errors}
                />
                <OrganizationBranchForm
                    setValue={setValue}
                    register={register}
                    unregister={unregister}
                    watch={watch}
                    organization={organization}
                    errors={errors}
                />
                <Row className="mt-2">
                    <Form.Group className="d-grid gap-2 mt-4">
                        <LoadingButton
                            type="submit"
                            className="text-white mt-4"
                            titleTranslationKey="pages.my_account.buttons.save_organisation_details"
                            loading={updatingDetails}
                        />
                    </Form.Group>
                </Row>
            </Form>
        </>
    );
}

export default OrganizationDetails;
