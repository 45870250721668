import { Button, Card, Form, Modal, Image } from "react-bootstrap";

import { Document, Page } from "react-pdf";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useTranslation } from "react-i18next";
import Question from "./components/Question";
import ArrowRight from "@/components/icons/ArrowRight";
import AddressField from "./components/AddressField";
import { Link } from "react-router-dom";
import LOCATION from "@/constants/Location";
import LoadingButton from "@/components/LoadingButton";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "@/services/Auth";
import { isNull } from "lodash";
import LoadingCover from "@/components/LoadingCover";
//import Modal from 'react-bootstrap/Modal';
function AssessmentSectionFormSignatureByAuditor({
    sectionIndex,
    section,
    lastSection,
    saveSection,
    firstSlide,
    auditMode,
    submitFormRef,
    assessment,
    assessmentTemplate,
    responseError,
    validationErrors,
    saveDraft,
    submitting,
    assessorName,
    assessorSignUrl,
    finalSignOffDate,
    //...useParams
}) {
    const { t } = useTranslation();
    const auth = useAuth();

    const [questions, setQuestions] = useState([]);
    const [totalSection, setTotalSection] = useState(0);

    const [show, setShow] = useState(false);
    const [pdfFile, setPdfFile] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const baseUrl = auth.api.defaults.baseURL;
    const [adminSign, setAdminSign] = useState(
        "/assets/images/sign-placeholder.png"
    );
    const [userSign, setUserSing] = useState(
        auth.user.user_signature
            ? `${baseUrl}/${auth.user.user_signature}`
            : "/assets/images/sign-placeholder.png"
    );

    const [uploading, setUploading] = useState({
        user_signature: false,
    });
    const [serverErrors, setServerErrors] = useState({
        user_signature: [],
    });

    const setLoadingState = (type, value) => {
        let uploadingHolder = { ...uploading };
        uploadingHolder[type] = value;
        setUploading(uploadingHolder);
    };

    const uploadImage = (data, type) => {
        setLoadingState(type, true);
        setServerErrorsState(null, []);

        let imageData = data.target.files[0];
        let form = new FormData();
        form.append("image", imageData);
        form.append("type", type);
        //form.append("user_id", user.id);

        auth.postRequest(`${LOCATION.USERS.API.path}/upload-images`, form)
            .then((response) => {
                let uploadedLink = `${baseUrl}/${response.data}`;
                //setAdminSign(uploadedLink);
                setUserSing(uploadedLink);
                setLoadingState(type, false);
            })
            .catch((error) => {
                setLoadingState(type, false);

                if (error.response.status === 422) {
                    let serverErrors = [];
                    for (const key in error.response.data.errors) {
                        serverErrors.push(error.response.data.errors[key][0]);
                    }
                    setServerErrorsState(type, serverErrors);
                }
            });
    };
    const setServerErrorsState = (type, newErrors) => {
        let errors = { ...serverErrors };
        errors.user_signature = [];

        if (type != null) {
            errors[type] = newErrors;
        }
        setServerErrors(errors);
    };
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const handleItemHover = (hoveredPdfUrl) => {
        setPdfFile(hoveredPdfUrl);
        handleShow();
    };
    const questionSchema = {
        required: yup.string(),
        value: yup
            .mixed()
            .typeError(
                t("common.form_validation.is_required", {
                    attribute: t("common.form.this_question"),
                })
            )
            .when(["required", "type"], function (required, type, schema) {
                return parseInt(required) === 1 &&
                    ![2, 3, 6].includes(parseInt(type))
                    ? schema.required(
                          t("common.form_validation.is_required", {
                              attribute: t("common.form.this_question"),
                          })
                      )
                    : schema.nullable();
            }),
        explain_other: yup.string().nullable(),
        add_own_rating: yup.string().nullable(),
        other_answer: yup
            .string()
            .typeError(
                t("common.form_validation.is_required", {
                    attribute: t("common.form.this_question"),
                })
            )
            .when(["explain_other"], function (explain_other, schema) {
                return explain_other == "true"
                    ? schema.required(
                          t("common.form_validation.is_required", {
                              attribute: t("common.form.this_question"),
                          })
                      )
                    : schema.nullable();
            }),
        user_choice_score: yup
            .string()
            .typeError(
                t("common.form_validation.is_required", {
                    attribute: t("common.form.this_question"),
                })
            )
            .when(
                ["explain_other", "add_own_rating"],
                function (explain_other, add_own_rating, schema) {
                    return explain_other == "true" && add_own_rating == "true"
                        ? schema.required(
                              t("common.form_validation.is_required", {
                                  attribute: t("common.form.this_question"),
                              })
                          )
                        : schema.nullable();
                }
            ),
    };

    const schema = yup.object().shape({
        /* address:
            sectionIndex === 0 &&
            assessmentTemplate?.assessment_template_type === 1
                ? yup
                      .string()
                      .typeError(
                          t("common.form_validation.is_required", {
                              attribute: t("common.form.address"),
                          })
                      )
                      .required(
                          t("common.form_validation.is_required", {
                              attribute: t("common.form.address"),
                          })
                      )
                : yup.string().nullable(), */
        assessor_name: yup.string().required(
            t("common.form_validation.is_required", {
                attribute: t("common.form.assessor_name"),
            })
        ),

        final_sign_off_date: yup.string().required(
            t("common.form_validation.is_required", {
                attribute: t("common.form.final_sign_off_date"),
            })
        ),
    });

    const {
        resetField,
        handleSubmit,
        register,
        setValue,
        watch,
        unregister,
        formState: { errors },
    } = useForm({
        defaultValues: ((_) => {
            return {
                assessor_name: assessorName,
                assessor_sign_url: assessorSignUrl,
                final_sign_off_date: finalSignOffDate,
            };
        })(),
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (section && section.questions) {
            setQuestions(section.questions);
        }
        //useParams.setName("i'm from Parent");
        setTotalSection(assessmentTemplate?.assessment_sections?.length);
    }, [section]);

    useEffect(() => {
        if (assessorName) {
            setValue("assessor_name", assessorName);
        }

        if (assessorSignUrl) {
            setValue("assessor_sign_url", assessorSignUrl);
        }

        if (finalSignOffDate) {
            setValue("final_sign_off_date", finalSignOffDate);
        }

        /* const userSignature = response.data.user_signature.trim();
                if (userSignature !== "") {
                    setUserSing(`${baseUrl}/${response.data.user_signature}`);
                } */
    }, [assessorName, assessorSignUrl, finalSignOffDate]);

    return (
        <>
            <br></br>
            <Form noValidate onSubmit={handleSubmit(saveSection)} className="">
                <Card className="border-0 radius-0 section-form  mx-auto mx-lg-5">
                    <Card.Body className="client" id="full-report">
                        {firstSlide &&
                            assessmentTemplate?.assessment_template_type ===
                                1 && (
                                <>
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th
                                                    colSpan="2"
                                                    className="title-bg"
                                                >
                                                    <h5 className="m-0">
                                                        {t(
                                                            "pages.assessments.audited_by"
                                                        )}
                                                    </h5>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td
                                                    colSpan="2"
                                                    className="w-100 bg-tabel-details height-border"
                                                >
                                                    {t(
                                                        "pages.assessments.audited_by_text"
                                                    )}
                                                </td>
                                            </tr>

                                            <tr>
                                                <th className="w-50 bg-tabel-details height-border">
                                                    {t(
                                                        "common.form.assessor_name"
                                                    )}
                                                </th>
                                                <td className="w-50 height-border">
                                                    <Form.Control
                                                        {...register(
                                                            "assessor_name"
                                                        )}
                                                        isInvalid={
                                                            !!errors.assessor_name
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.assessor_name &&
                                                            errors.assessor_name
                                                                .message}
                                                    </Form.Control.Feedback>
                                                </td>
                                            </tr>

                                            <tr>
                                                <th className="w-50 bg-tabel-details height-border">
                                                    {t("common.form.signed")}
                                                </th>
                                                <td className="w-50 height-border">
                                                    <Form.Control
                                                        className="mb-2"
                                                        onChange={(e) =>
                                                            uploadImage(
                                                                e,
                                                                "user_signature"
                                                            )
                                                        }
                                                        type="file"
                                                        accept="image/*,.jpg,.png,.jpeg"
                                                    />
                                                    {serverErrors.user_signature
                                                        .length !== 0 && (
                                                        <div className="form-group mt-4">
                                                            {serverErrors.user_signature.map(
                                                                (
                                                                    error,
                                                                    index
                                                                ) => (
                                                                    <p
                                                                        className="text-danger font-weight-bold"
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {error}
                                                                    </p>
                                                                )
                                                            )}
                                                        </div>
                                                    )}
                                                    {/* <Image
                                                        className="company-logo sign-uploads"
                                                        src={assessorSignUrl}
                                                    /> */}
                                                    {uploading.user_signature ? (
                                                        <div className="sign-uploads">
                                                            <LoadingCover />
                                                        </div>
                                                    ) : (
                                                        <Image
                                                            className="company-logo sign-uploads"
                                                            src={userSign}
                                                        />
                                                    )}
                                                    {/* <Form.Control
                                                        {...register("signed")}
                                                        isInvalid={
                                                            !!errors.signed
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.signed &&
                                                            errors.signed
                                                                .message}
                                                    </Form.Control.Feedback> */}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="w-50 bg-tabel-details height-border">
                                                    {t(
                                                        "common.form.final_sign_off_date"
                                                    )}
                                                </th>
                                                <td className="w-50 height-border">
                                                    <Form.Control
                                                        type="date"
                                                        {...register(
                                                            "final_sign_off_date"
                                                        )}
                                                        isInvalid={
                                                            !!errors.final_sign_off_date
                                                        }
                                                    />

                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.final_sign_off_date &&
                                                            errors
                                                                .final_sign_off_date
                                                                .message}
                                                    </Form.Control.Feedback>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </>
                            )}
                    </Card.Body>
                </Card>
                {responseError && (
                    <h6 className="text-danger my-4">{t(responseError)}</h6>
                )}
                {validationErrors.length !== 0 && (
                    <div className="form-group mb-2">
                        {validationErrors.map((error, index) => (
                            <p
                                className="text-danger font-weight-bold"
                                key={index}
                            >
                                {t(error)}
                            </p>
                        ))}
                    </div>
                )}
                <Form.Group className="d-flex justify-content-between mt-3 section-form  mx-auto mx-lg-5">
                    <LoadingButton
                        refName={submitFormRef}
                        variant="success"
                        size="sm"
                        type="submit"
                        className="text-white"
                        loading={submitting}
                        titleTranslationKey={t(
                            "pages.assessments.buttons.complete_report"
                        )}
                    />
                    {/* <>
                        <Link
                            to={`${LOCATION.ASSESSMENTS.LIST.path}/${assessment?.id}/request-audit`}
                            className="btn btn-primary text-white btn-sm"
                        >
                            <span className="me-1">
                                {t("pages.assessments.buttons.sent_to_audit")}
                            </span>
                        </Link>
                    </> */}
                    {/* {auditMode ? (
                        <>
                            <Link
                                to={`${LOCATION.ASSESSMENTS.LIST.path}/${assessment?.id}/1/report`}
                                className="btn btn-primary text-white btn-sm"
                            >
                                <span className="me-1">
                                    {t(
                                        "pages.assessments.buttons.sent_to_audit"
                                    )}
                                </span>
                                <ArrowRight />
                            </Link>
                            <button
                                type="submit"
                                ref={submitFormRef}
                                className="d-none"
                            ></button>
                        </>
                    ) : (
                        <LoadingButton
                            refName={submitFormRef}
                            variant="primary"
                            size="sm"
                            type="submit"
                            className="text-white"
                            loading={submitting}
                            titleTranslationKey={
                                lastSection
                                    ? t(
                                          "pages.assessments.buttons.go_to_ratings_report"
                                      )
                                    : t(
                                          "pages.assessments.buttons.sent_to_audit"
                                      )
                            }
                            //icon={<ArrowRight />}
                        />
                    )} */}
                </Form.Group>
            </Form>
        </>
    );
}

export default AssessmentSectionFormSignatureByAuditor;
