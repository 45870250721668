import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import * as moment from "moment";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/services/Auth";
import LOCATION from "@/constants/Location";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPencil } from "@fortawesome/free-solid-svg-icons";
import Paginate from "../Paginate";
function useColumnsReport(filter, source, userToken) {
    let auth = useAuth();
    const { t } = useTranslation();

    const AddressRowFormatter = (cell, row) => {
        return <div dangerouslySetInnerHTML={{ __html: cell }} />;
    };
    let columns = [
        {
            dataField: "id",
            text: t("common.tables_pdf_report_fire_safety.id"),
        },

        {
            dataField: "nameofbusiness",
            text: t("common.tables_pdf_report_fire_safety.business_name"),
        },
        {
            dataField: "siteaddress",
            text: t("common.tables_pdf_report_fire_safety.site_address"),
            formatter: AddressRowFormatter,
        },
        {
            dataField: "whoisresponsible",
            text: t(
                "common.tables_pdf_report_fire_safety.responsibel_for_person"
            ),
            formatter: AddressRowFormatter,
        },
    ];
    columns = [
        ...columns,
        ...[
            {
                dataField: "added",
                text: t("common.tables.assessment_date"),
                formatter: (datetime) =>
                    datetime &&
                    moment(datetime).format(t("common.formats.short_date")),
            },
        ],
    ];
    columns.push({
        dataField: "actions",
        text: "",
        formatter: (cell, row) => (
            <>
                <a
                    href={`${process.env.REACT_APP_PDF_REPORT_BASE_URL}${row.id}/pdf/${row.form_id}/${userToken}`}
                    className="text-success"
                >
                    <FontAwesomeIcon icon={faDownload} role="button" />
                </a>{" "}
                {/* &nbsp;
                {row.form_id == 24 && (
                    <Link
                        to={`/fire-safety-policy-document?ad_id=${row.id}`}
                        className="text-info"
                    >
                        <FontAwesomeIcon icon={faPencil} role="button" />
                    </Link>
                )}
                {row.form_id == 26 && (
                    <Link
                        to={`/health-and-safety-policy?ad_id=${row.id}`}
                        className="text-info"
                        tooltip
                    >
                        <FontAwesomeIcon icon={faPencil} role="button" />
                    </Link>
                )}
                {row.form_id == 27 && (
                    <Link
                        to={`/fire-door-compliance?ad_id=${row.id}`}
                        className="text-info"
                    >
                        <FontAwesomeIcon icon={faPencil} role="button" />
                    </Link>
                )} */}
            </>
        ),
    });
    return (filter && columns.filter(filter)) || columns;
}

function TableReport({ data: recordsProp, paginate, url, query, ...props }) {
    let auth = useAuth();
    const { t } = useTranslation();

    const perPage = 25; //query.limit || 20;
    const exclude = props.exclude || [];
    const showPagination = paginate == null ? true : paginate;

    const request = useRef(null);
    const [page, setPage] = useState(0);
    const [totalRecords, setTotal] = useState(false);
    const [recordsDownloaded, setRecords] = useState(false);
    const [userToken, setUserToken] = useState(false);
    const getRecords = () => {
        request?.current?.abort && request.current.abort();
        query.limit = 25;
        const critera = { ...(query || {}) };

        critera.take = perPage;
        critera.skip = page * perPage;
        critera.page = page;

        request.current = auth.getRequest(
            url || LOCATION.ASSESSMENTS.PDF_REPORTS.COMBINED_REPORT.API.path,
            critera
        );
        setRecords(false);
        request.current.then((response) => {
            setRecords(response.data.data);
            setTotal(response.data.length);
            setUserToken(response.data.t);
            if (props.updateStats) {
                props.updateStats(response.data.data);
            }
        });
    };

    const columns = useColumnsReport(
        (_) => !exclude.includes(_?.dataField) && !exclude.includes(_?.name),
        props.source,
        userToken
    );

    const changePage = (event) => {
        setPage(event.selected);
    };
    const records = recordsProp || recordsDownloaded;
    useEffect(() => {
        if (!recordsProp) {
            getRecords();
        }

        return () => request?.current?.abort && request.current.abort();
    }, [page, query, url]);

    useEffect(() => {
        props.onData && props.onData(records);
    }, [records]);

    useEffect(() => {
        props.onTotal && props.onTotal(totalRecords);
    }, [totalRecords]);

    return (
        <div className="fra-table">
            <BootstrapTable
                keyField="id"
                responsive
                hover
                columns={columns || []}
                data={records || []}
                noDataIndication={
                    records instanceof Array
                        ? t("common.no_data")
                        : t("common.loading")
                }
                {...props}
            />
            {(showPagination && records && totalRecords && (
                <Paginate {...{ changePage, totalRecords, page, perPage }} />
            )) ||
                ""}
        </div>
    );
}

export default TableReport;

export { useColumnsReport };
