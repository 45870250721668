import { Link, useHistory, useLocation } from 'react-router-dom'
import LOCATION from '@/constants/Location'
import { useTranslation } from 'react-i18next'
import Plus from '@/components/icons/Plus'
import { useEffect, useState } from 'react'
import { useAuth } from '@/services/Auth'
import LoadingCover from '@/components/LoadingCover'
import AssessmentFilters from '@/views/components/AssessmentFilters'

import Table, { useColumns } from '@/views/components/Tables/Assessments'
import UserCreditInformation from '@/views/components/UserCreditInformation'
import ConfirmModal from '@/components/modals/ConfirmModal'

function MyAssessments () {

  const history = useHistory()
  const route = useLocation()
  const auth = useAuth()
  const { t } = useTranslation()

  const [assessments, setAssessments] = useState(null)
  const [assessmentTemplates, setAssessmentTemplates] = useState([])
  const [assessmentStatus, setAssessmentStatus] = useState([])
  const [assessmentToDelete, setAssessmentToDelete] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)

  const [query, setQuery] = useState(route.state && route.state.query || { page: 0, skip: 0, limit: 15 });

  const [searching, setSearching] = useState(false)

  const deleteRequest = (assessmentToDelete) => {
    setAssessmentToDelete(assessmentToDelete);
    setShowModal(true)
  }

  const handleCancel = () => {
    setShowModal(false)
    setAssessmentToDelete(null);
  }

  const handleConfirm = () => {
    setShowModal(false)
    setLoading(true);
    auth.deleteRequest(`${LOCATION.ASSESSMENTS.API.path}/${assessmentToDelete}`)
      .then(response => {
        setLoading(false);
        setAssessmentToDelete(null);
      })
      .catch(error => {
      })
  }

  useEffect(() => {
    let request = auth.getRequest(LOCATION.ASSESSMENT_TEMPLATES.API.path)

    request
      .then(response => {
        setAssessmentTemplates(response.data.data)
        setLoading(false)
      })
      .catch(error => {
      })

    return () => request?.abort && request.abort()

  }, [])

  useEffect(() => {
    let request = auth.getRequest(`${LOCATION.ASSESSMENT_STATUS.API.path}`)

    request
      .then(response => {
        setAssessmentStatus(response.data.assessment_status)
      })
      .catch(error => {
      })

    return () => request?.abort && request.abort()

  }, [])

  useEffect(() => {
    history.replace(
      route.pathname,
      {
        ...route.state,

        query,
      }
    )
  }, [query])


  if (loading) return <LoadingCover/>

  return (
    <>
      <ConfirmModal
        show={showModal}
        title={t('pages.assessments.notifications.delete_assessment.title')}
         body={t('pages.assessments.notifications.delete_assessment.body')}
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        cancelText={t('common.dialogs.actions.cancel')}
        confirmText={t('common.dialogs.actions.delete')}
      />
      <div className="content">
        <div className="section-header d-flex justify-content-between flex-md-row flex-column mb-4">
          <h4>{t('pages.my_assessments.title')}</h4>
          <div className="d-flex align-items-center justify-content-start justify-content-md-between flex-column flex-md-row">
            <UserCreditInformation/>
            <div className="d-grid gap-2 w-100 mt-3 mt-md-0">

              <Link
                to={LOCATION.ASSESSMENTS.CREATE.path}
                className="btn btn-primary text-white"
              >
                <span className="me-1">{t('pages.my_assessments.new_assessment')}</span>
                <Plus/>
              </Link>
            </div>
          </div>
        </div>
        <AssessmentFilters
          searching={searching}
          values={query}
          loadAssessments={setQuery}
          assessmentStatus={assessmentStatus}
          assessmentTemplates={assessmentTemplates}
        />
        <div className="my-4">
          <Table query={query} showPagination={true} onData={_ => setSearching(!_)} deleteRequest={deleteRequest}/>
        </div>
      </div>
    </>
  )
}

export default MyAssessments
