import { Button, Card, Form } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import QuestionFormBuilder from "./QuestionFormBuilder";
import Plus from "@/components/icons/Plus";
import ConfirmModal from "@/components/modals/ConfirmModal";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import HorizontalLine from "@/components/icons/HorizontalLine";
import { useAuth } from "@/services/Auth";
import LOCATION from "@/constants/Location";
import LoadingButton from "@/components/LoadingButton";
import ExpandCollapseIcon from "@/components/icons/ExpandCollapseIcon";
import Question from "./../../Assessments/Forms/components/Question";

function SectionFormBuilder({
    section,
    saveSection,
    questionFields,
    fieldTypes,
    saveDraft,
    submitFormRef,
    deleteSection,
    currentSection,
    assessment,
    validationErrors,
    savingTemplate,
}) {
    const { t } = useTranslation();
    const auth = useAuth();
    const [expandedIndexs, setExpandedIndexs] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [questionList, setQuestionList] = useState([]);
    const [showConfirm, setShowConfirm] = useState(false);
    const [questionIndexToRemove, setQuestionIndexToRemove] = useState(false);
    const [dragStarted, setDragStarted] = useState(false);
    const [dragging, setDragging] = useState(false);
    const [sectionType, setSectionType] = useState(null);
    const removeQuestionTitle = t(
        "pages.assessment_templates.dialogs.titles.confirm_heading"
    );
    const removeQuestionBody = t(
        "pages.assessment_templates.dialogs.body.remove_question"
    );

    let questionSchemaShape = {
        label: yup.string().required(
            t("common.form_validation.is_required", {
                attribute: t("common.form.field_label"),
            })
        ),
        type: yup.number().required(
            t("common.lform_vaidation.is_required", {
                attribute: t("common.form.field_type"),
            })
        ),
        help_text: yup
            .string()
            .typeError(
                t("common.form_validation.is_required", {
                    attribute: t("common.form.help_text"),
                })
            )
            .when(["has_help_text"], function (hasHelpText, schema) {
                return hasHelpText
                    ? schema.required(
                          t("common.form_validation.is_required", {
                              attribute: t("common.form.help_text"),
                          })
                      )
                    : schema.nullable();
            }),
        description: yup
            .string()
            .typeError(
                t("common.form_validation.is_required", {
                    attribute: t("common.form.description"),
                })
            )
            .when(["type"], function (type, schema) {
                return [10].includes(parseInt(type))
                    ? schema.required(
                          t("common.form_validation.is_required", {
                              attribute: t("common.form.description"),
                          })
                      )
                    : schema.nullable();
            }),
        question_choices: yup.array().when(["type"], function (type, schema) {
            return [8, 9].includes(parseInt(type))
                ? schema.of(
                      yup.object().shape({
                          answer: yup.string().required(
                              t("common.form_validation.is_required", {
                                  attribute: t("common.form.answer"),
                              })
                          ),
                          score: yup
                              .number()
                              .typeError(
                                  t(
                                      "common.form_validation.should_be_a_number",
                                      { attribute: t("common.form.score") }
                                  )
                              )
                              .transform((value) =>
                                  isNaN(value) ? undefined : value
                              )
                              .required(
                                  t("common.form_validation.is_required", {
                                      attribute: t("common.form.score"),
                                  })
                              ),
                      })
                  )
                : schema.nullable();
        }),
    };

    let questionSchema = yup.array().of(
        yup.object().shape({
            ...questionSchemaShape,
            children: yup.array().when(["type"], function (type, schema) {
                return [3, 6].includes(parseInt(type))
                    ? schema.of(
                          yup.object().shape({
                              ...questionSchemaShape,
                          })
                      )
                    : schema.nullable();
            }),
        })
    );

    const schema = yup.object().shape({
        name: yup.string().required(
            t("common.form_validation.is_required", {
                attribute: t("common.form.section_name"),
            })
        ),
        weight: yup
            .number()
            .max(
                10,
                t("common.form_validation.max", {
                    field: t("common.form.weight"),
                    max: 10,
                })
            )
            .required(
                t("common.form_validation.is_required", {
                    attribute: t("common.form.weight"),
                })
            ),

        section_type: yup.string().required(
            t("common.form_validation.is_required", {
                attribute: t("common.form.section_type"),
            })
        ),

        sd_title: yup.string().when("section_type", {
            //is: "supporting-document",
            is: (value) =>
                value == "supporting-document" || value == "" || value == null,
            then: yup.string().required(
                t("common.form_validation.is_required", {
                    attribute: t("common.form.title"),
                })
            ),
            otherwise: yup.string().notRequired(),
        }),
        sd_description: yup.string().when("section_type", {
            is: (value) =>
                value === "supporting-document" ||
                value === "" ||
                value === null,
            then: yup.string().required(
                t("common.form_validation.is_required", {
                    attribute: t("common.form.description"),
                })
            ),
            otherwise: yup.string().notRequired(),
        }),
        questions: questionSchema,
    });

    const {
        handleSubmit,
        register,
        setValue,
        watch,
        unregister,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: ((_) => {
            return {
                id: section?.id,
                revision_id: section?.revision_id,
                name: section?.name,
                weight:
                    assessment.assessment_template_type === 2
                        ? 1
                        : section?.weight,
                questions: section?.questions,
                section_type: section?.section_type,
                sd_description: section?.sd_description,
                sd_title: section?.sd_title,
            };
        })(),
        resolver: yupResolver(schema),
    });

    const { fields, append, prepend, remove, swap, move, insert, replace } =
        useFieldArray({
            control, // control props comes from useForm (optional: if you are using FormContext)
            name: "questions", // unique name for your Field Array
        });

    const updatedQuestions = watch("questions");

    const addQuestion = (type) => {
        if (type === 1) {
            questionFields.type = 14;
        } else {
            questionFields.type = 4;
        }
        append(questionFields);
        let questionsList = questions;
        questionsList.push({ ...questionFields, order: questions.length + 1 });
        //console.log("questionsList ", questionsList);
        setQuestions(questionsList);
    };

    const removeQuestion = (indexToRemove) => {
        setQuestionIndexToRemove(indexToRemove);
        setShowConfirm(true);
    };

    useEffect(() => {
        if (section && section.questions && section.questions.length !== 0) {
            setQuestions(section.questions);
            const qlistIndexArray = Array(section.questions.length).fill(false);
            setExpandedIndexs(qlistIndexArray);
        } else {
            addQuestion();
        }
    }, [section]);

    const toggleQuestionExpansion = (index) => {
        setExpandedIndexs((prevExpandedIndexs) => {
            const updatedExpandedIndexs = [...prevExpandedIndexs];
            updatedExpandedIndexs[index] = !updatedExpandedIndexs[index];
            return updatedExpandedIndexs;
        });
    };
    const toggleQuestionExpansionAll = (value) => {
        setExpandedIndexs(Array(questions.length).fill(!value));
    };
    useEffect(() => {
        setValue("section_id", section.id);
        setSectionType(section.section_type);
    }, [section]);

    const onSave = (values) => {
        console.log("ONSAVE ",values);
        console.log("ONSAVE Questions",questions);
        saveSection(values);
    };

    const handleConfirm = () => {
        setShowConfirm(false);
        remove(questionIndexToRemove);
        let questionList = questions.filter(
            (_, index) => index !== questionIndexToRemove
        );
        setQuestions([].concat(questionList));
    };

    const handleCancel = () => {
        setShowConfirm(false);
    };

    const handleDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        let updatedList = [...updatedQuestions];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State
        replace(updatedList);
        setQuestions(updatedList);
        updateQuestionsOrder(updatedList);
    };

    const updateQuestionsOrder = (updatedList) => {
        let questionOrders = [];
        updatedList.forEach((question, index) => {
            let order = index + 1;
            setValue(`questions[${index}].order`, order);
            questionOrders.push({
                id: question?.question_id ?? null,
                order,
            });
        });

        auth.postRequest(
            `${LOCATION.ASSESSMENT_QUESTIONS.API.path}/update-orders`,
            {
                questions: questionOrders,
            }
        )
            .then((response) => {})
            .catch((error) => {});
    };

    useEffect(() => {
        // Access expandedIndexs here, it should be defined
        console.log("expandedIndexs", expandedIndexs);
    }, [expandedIndexs]);
    return (
        <>
            <ConfirmModal
                show={showConfirm}
                title={removeQuestionTitle}
                body={removeQuestionBody}
                confirmText={t("common.dialogs.actions.remove")}
                cancelText={t("common.dialogs.actions.cancel")}
                handleConfirm={handleConfirm}
                handleCancel={handleCancel}
            />

            <Form noValidate onSubmit={handleSubmit(onSave)}>
                <input
                    type="hidden"
                    name="section_id"
                    {...register("section_id")}
                />
                <input
                    type="hidden"
                    name="revision_id"
                    {...register("revision_id")}
                    value={section?.revision_id}
                />
                <input
                    type="hidden"
                    name="order"
                    value={section?.order ?? currentSection}
                    {...register("order")}
                />
                <Card className="border-0 radius-0 section-form mx-auto mx-lg-5">
                    <Card.Body>
                        <Form.Group className="mb-4 mt-4">
                            <Form.Label>
                                {t("common.form.section_name")}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                placeholder={t(
                                    "common.form.section_name_placeholder"
                                )}
                                {...register("name")}
                                isInvalid={!!errors.name}
                            />

                            <Form.Control.Feedback type="invalid">
                                {errors.name && errors.name.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                        {assessment.assessment_template_type === 2 ? (
                            <input
                                type="hidden"
                                name="weight"
                                value="1"
                                {...register("weight")}
                            />
                        ) : (
                            <>
                                <Form.Group className="mb-4 mt-4">
                                    <Form.Label>
                                        {t("common.form.weight")}
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder={t(
                                            "common.form.weight_placeholder"
                                        )}
                                        {...register("weight")}
                                        isInvalid={!!errors.weight}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        {errors.weight && errors.weight.message}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-4 mt-4">
                                    <Form.Label>
                                        {t("common.form.section_type")}
                                    </Form.Label>
                                    <Form.Select
                                        type="text"
                                        aria-label={t(
                                            "common.form.section_type"
                                        )}
                                        {...register("section_type")}
                                        // isInvalid={!!errors.section_type}
                                        onChange={(e) => {
                                            setSectionType(e.target.value);
                                        }}
                                    >
                                        <option value="normal" key="normal">
                                            Normal
                                        </option>
                                        <option
                                            value="supporting-document"
                                            key="supporting-document"
                                        >
                                            Supporting Document
                                        </option>
                                    </Form.Select>

                                    <Form.Control.Feedback type="invalid">
                                        {errors.section_type &&
                                            errors.section_type.message}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                {sectionType === "supporting-document" ? (
                                    <>
                                        <Form.Group className="mb-4 mt-4">
                                            <Form.Label>
                                                {t("common.form.title")}
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </Form.Label>
                                            <Form.Control
                                                placeholder={t(
                                                    "common.form.title_placeholder"
                                                )}
                                                {...register("sd_title")}
                                                isInvalid={!!errors.sd_title}
                                            />

                                            <Form.Control.Feedback type="invalid">
                                                {errors.sd_title &&
                                                    errors.sd_title.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group className="mb-4 mt-4">
                                            <Form.Label>
                                                {t("common.form.description")}
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                placeholder={t(
                                                    "common.form.description_placeholder"
                                                )}
                                                {...register("sd_description")}
                                                isInvalid={
                                                    !!errors.sd_description
                                                }
                                                style={{ height: "200px" }}
                                            />

                                            <Form.Control.Feedback type="invalid">
                                                {errors.sd_description &&
                                                    errors.sd_description
                                                        .message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </>
                                ) : (
                                    ""
                                )}
                            </>
                        )}

                        <Form.Group className="mb-4 mt-4 d-flex justify-content-end">
                            <Button
                                variant="secondary"
                                className="text-white"
                                //onClick={() => setDragStarted(!dragStarted)}
                                //onClick={toggleExpand}
                                onClick={() => {
                                    setDragStarted(!dragStarted);
                                    toggleQuestionExpansionAll(dragStarted);
                                }}
                            >
                                {dragStarted
                                    ? t(
                                          "pages.assessment_templates.buttons.expand_questions"
                                      )
                                    : t(
                                          "pages.assessment_templates.buttons.collapse_questions_and_reorder"
                                      )}
                            </Button>
                        </Form.Group>

                        <DragDropContext
                            onDragEnd={handleDrop}
                            onDragStart={() => {
                                setDragStarted(true);
                                setDragging(true);
                            }}
                        >
                            <Droppable droppableId="questions-container">
                                {(provided) => (
                                    <div
                                        className="list-container"
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {fields.map((question, index) => (
                                            <Draggable
                                                key={question.id}
                                                draggableId={`${question.id}`}
                                                index={index}
                                            >
                                                {(provided) => (
                                                    <div
                                                        className="item-container"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                    >
                                                        <div className="position-relative">
                                                            <div
                                                                {...provided.dragHandleProps}
                                                            >
                                                                {dragStarted && (
                                                                    <>
                                                                        <HorizontalLine className="question-drag" />
                                                                    </>
                                                                )}
                                                            </div>

                                                            {expandedIndexs[
                                                                index
                                                            ] && (
                                                                <>
                                                                    <div
                                                                        className={
                                                                            "test-dev"
                                                                        }
                                                                        onClick={() =>
                                                                            toggleQuestionExpansion(
                                                                                index
                                                                            )
                                                                        }
                                                                    >
                                                                        <ExpandCollapseIcon
                                                                            expanded={
                                                                                true
                                                                            }
                                                                        />
                                                                    </div>
                                                                </>
                                                            )}
                                                            {!expandedIndexs[
                                                                index
                                                            ] && (
                                                                <>
                                                                    <div
                                                                        className={
                                                                            "test-dev"
                                                                        }
                                                                        onClick={() =>
                                                                            toggleQuestionExpansion(
                                                                                index
                                                                            )
                                                                        }
                                                                    >
                                                                        <ExpandCollapseIcon
                                                                            expanded={
                                                                                false
                                                                            }
                                                                        />
                                                                    </div>
                                                                </>
                                                            )}
                                                            <QuestionFormBuilder
                                                                key={index}
                                                                errors={
                                                                    errors.questions
                                                                }
                                                                setValue={
                                                                    setValue
                                                                }
                                                                register={
                                                                    register
                                                                }
                                                                unregister={
                                                                    unregister
                                                                }
                                                                question={
                                                                    question
                                                                }
                                                                index={index}
                                                                removeQuestion={
                                                                    removeQuestion
                                                                }
                                                                fieldTypes={
                                                                    fieldTypes
                                                                }
                                                                watch={watch}
                                                                questionFields={
                                                                    questionFields
                                                                }
                                                                inputSuffix={`questions[${index}]`}
                                                                assessment={
                                                                    assessment
                                                                }
                                                                dragStarted={
                                                                    dragStarted
                                                                }
                                                                expandedIndexs={
                                                                    expandedIndexs
                                                                }
                                                                sectionType={
                                                                    sectionType
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>

                        {validationErrors?.length !== 0 && (
                            <div className="form-group mb-2">
                                {validationErrors.map((error, index) => (
                                    <p
                                        className="text-danger font-weight-bold"
                                        key={index}
                                    >
                                        {t(error)}
                                    </p>
                                ))}
                            </div>
                        )}
                    </Card.Body>
                    <Card.Footer>
                        <div className="d-grid gap-2">
                            {sectionType !== "supporting-document" ? (
                                <>
                                    <Button
                                        variant="danger"
                                        onClick={() => addQuestion(0)}
                                    >
                                        <span className="me-1">
                                            {t(
                                                "pages.assessments.buttons.add_question"
                                            )}
                                        </span>
                                        <Plus />
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button
                                        variant="danger"
                                        onClick={() => addQuestion(1)}
                                    >
                                        <span className="me-1">
                                            {t(
                                                "pages.assessments.buttons.add_category"
                                            )}
                                        </span>
                                        <Plus />
                                    </Button>
                                </>
                            )}
                            {/* <Button
                                variant="danger"
                                onClick={() => addQuestion()}
                            >
                                <span className="me-1">
                                    {sectionType !== "supporting-document" ? (
                                        <>
                                            {t(
                                                "pages.assessments.buttons.add_question"
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {t(
                                                "pages.assessments.buttons.add_category"
                                            )}
                                        </>
                                    )}
                                </span>
                                <Plus />
                            </Button> */}
                        </div>
                    </Card.Footer>
                </Card>

                <div className="d-flex justify-content-between mt-4 section-form mx-auto mx-lg-5">
                    {section.id !== "new_section" ? (
                        <Button
                            variant="danger"
                            size="sm"
                            onClick={() => deleteSection(section.id)}
                        >
                            <span className="ms-1">
                                {t("pages.new_assessment.delete_section")}
                            </span>
                        </Button>
                    ) : (
                        <LoadingButton
                            loading={savingTemplate}
                            onSubmit={saveDraft}
                            variant="secondary"
                            size="sm"
                            type="button"
                            className="text-white ms-4"
                            titleTranslationKey={t(
                                "pages.new_assessment.save_draft"
                            )}
                        />
                    )}
                    <LoadingButton
                        loading={savingTemplate}
                        refName={submitFormRef}
                        variant="success"
                        size="sm"
                        type="submit"
                        className="text-white ms-4"
                        titleTranslationKey={t(
                            "pages.new_assessment.save_continue"
                        )}
                    />
                </div>
            </Form>
        </>
    );
}

export default SectionFormBuilder;
