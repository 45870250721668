const Plans = {
  select_plan: 'Select Plan',
  select_plan_out_of_tokens: 'Sorry, you’ve run out of Credits.',
  token_instruction: 'To use the platform you will need credits. Each credit allows for one assessment to be completed.',
  free_trial: 'Free Trial',
  unlimited_tokens: 'Subscribe for Unlimited Tokens',
  individual_tokens: 'Buy Tokens Individually',
  buy_now: 'Buy Now',
  credits_one: '1 Credit',
  credits: '{{count}} credits',
  subscription_form: 'FRA Wizard Subscription',
  card: '{{brand}} ending in {{cardNumber}}',
  payment_method: 'Payment Method',
  credit_status: 'Credits (Used/Left)',
  your_payment_methods_choose_one: 'Your Payment Methods, Choose one and pay',
  credit_plans: {
    pay_as_you_go: '1 Credit for a one-time payment',
    month: '{{credits}} Credits per month',
    yearly: '{{credits}} Credits and {{freeAudit}} Free Audit per year',
  },
  headers: {
    free_trial: 'Try out an Assessment',
    unlimited_tokens: '£500.00 per month',
    individual_tokens: '£25.00 per token',
  },
  descriptions: {
    free_trial: 'See how the process works.',
    unlimited_tokens: 'Unlimited Tokens and 1 Free Audit.',
    individual_tokens: 'Pay for them individually or in bulk.',
  },
  buttons: {
    start_free_trial: 'Start Free Trial',
    subscribe: 'Subscribe',
    buy_credit: 'Buy Credit',
    select_token_amount: 'Select Token Amount',
    pay_now: 'Pay Now',
    get_started: 'Get Started',
    pay_and_subscribe: 'Pay {{currency}}{{price}} and Subscribe',
  },
  buy_tokens: {
    title: 'Select Token Amount',
    description: 'Each token allows for one assessment to be completed.',
    number_of_tokens_one: '{{count}} Token',
    number_of_tokens_other: '{{count}} Tokens',
  },
  payments: {
    token_success: {
      title: 'Payment succeeded!',
      message_one: 'You have purchased one token, you can use it now to create assessment',
      message_other: 'You have purchased {{count}} tokens, you can use your tokens now to create assessments',
    }
  },
  names: {
    pay_as_you_go: 'Pay as you Go',
    pay_monthly: 'Pay Monthly',
    pay_yearly: 'Pay Yearly',
  },
  durations: {
    credit: 'credit',
    monthly: 'month',
    yearly: 'year',
  },
  credit_use: {
    pay_as_you_go: 'Use once on any service',
    subscription: 'Get {{credits}} credits to use on any service',
    price_per_service: 'Works out at £{{pricePerService}} per service',
    pay_as_you_go_per_service: 'For Assessments or Forms',
  },
  subscriptions: {
    payment_method_for_subscription: 'Set your payment method for subscription',
    choose_method_and_subscribe: 'Choose Payment Method and Subscribe',
    expires: 'Expires {{date}}',
    expiry_date: 'Expiry Date',
    success: {
      title: 'Thank you for your subscription',
      description: 'You have unlimited tokens  and 1 free audit',
    },
    renew_type: 'Monthly',
    renew_date_monthly: 'N/A (Monthly Payments)',
    cancel: 'Cancel Subscription',
    no_active_subscription: 'No Active Subscription',
    subscriber_since: 'Subscriber Since',
  },
  fra_wizard_chatbox: 'Access the FRA Wizard Chatbot',
  fra_wizard_toolbox: 'Access the FRA Toolbox',
  cancel_any_time: 'Cancel any time',
  free_audit: '1 Free Audit (Cost £35)',
  form: {
    card_number: 'Card Number',
    expire: 'Expire Date',
    ccv: 'CCV',
  }
}
export default Plans