import PageActionSection from "@/components/PageActionSection";
import { useTranslation } from "react-i18next";
import React, { useState, useRef } from "react";
import { useAuth } from "@/services/Auth";
import LOCATION from "@/constants/Location";
//import FormBuilder from './Forms/FormBuilder'
import { useHistory, useParams } from "react-router-dom";
import ArrowRight from "@/components/icons/ArrowRight";
import ArrowLeft from "@/components/icons/ArrowLeft";
import ConfirmModal from "@/components/modals/ConfirmModal";
import { getValidationErrors } from "@/Utility";
import { Button, Card, Form, Image } from "react-bootstrap";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
function Create(props) {
    const { t } = useTranslation();
    const auth = useAuth();
    const history = useHistory();

    const query = new URLSearchParams(props.location.search);
    const [validationErrors, setValidationErrors] = useState([]);

    const [showSuccess, setShowSuccess] = useState(false);
    const [title, setTitle] = useState("");

    const submitFormRef = useRef(null);

    const goToBack = () => {
        history.push(`${LOCATION.PRICING_PLANS.LIST.path}`);
    };

    const handleConfirm = () => {
        setShowSuccess(false);
        setTitle(null);
        history.push(`${LOCATION.PRICING_PLANS.LIST.path}`);
    };
    const requestSave = (values) => {
        setValidationErrors([]);
        auth.postRequest(LOCATION.PRICING_PLANS.API.path, values)
            .then((response) => {
                setTitle(t("pages.pricing_plan.notifications.plan_created"));
                setShowSuccess(true);
            })
            .catch((error) => {
                setValidationErrors(getValidationErrors(error));
            });
    };

    const schema = yup.object().shape({
        plan_name: yup.string().required(
            t("common.form_validation.is_required", {
                attribute: t("pages.pricing_plan.form.plan_name"),
            })
        ),
        credits: yup.string().required(
            t("common.form_validation.is_required", {
                attribute: t("pages.pricing_plan.form.credits"),
            })
        ),
        price: yup.string().required(
            t("common.form_validation.is_required", {
                attribute: t("pages.pricing_plan.form.price"),
            })
        ),
        valid_for: yup.string().required(
            t("common.form_validation.is_required", {
                attribute: t("pages.pricing_plan.form.valid_for"),
            })
        ),
        plan_type: yup.string().required(
            t("common.form_validation.is_required", {
                attribute: t("pages.pricing_plan.form.plan_type"),
            })
        ),
    });
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm({
        defaultValues: {
            plan_name: "",
            credits: "",
            plan_type: "",
            price: "",
            valid_for: "",
        },
        resolver: yupResolver(schema),
    });

    const onSave = (values) => {
        requestSave(values);
    };
    return (
        <>
            <ConfirmModal
                show={showSuccess}
                title={title}
                confirmText={t("common.dialogs.actions.okay")}
                handleConfirm={handleConfirm}
            />

            <PageActionSection
                prepend={[
                    <Button variant="dark" size="sm" onClick={() => goToBack()}>
                        <ArrowLeft />
                        <span className="ms-1">
                            {t("pages.pricing_plan.button.back")}
                        </span>
                    </Button>,
                ]}
                title={t("pages.pricing_plan.new_plan_create")}
                type="dark"
            />
            <div className="content">
                <Form noValidate onSubmit={handleSubmit(onSave)}>
                    <Card className="border-0 radius-0 section-form mx-auto mx-lg-5">
                        <Card.Body>
                            <Form.Group
                                className="mb-4 mt-4"
                                controlId="formBasicTitle"
                            >
                                <Form.Label>
                                    {t("pages.pricing_plan.form.plan_name")}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t(
                                        "pages.pricing_plan.form.placeholder_plan_name"
                                    )}
                                    {...register("plan_name")}
                                    isInvalid={!!errors.plan_name}
                                />

                                <Form.Control.Feedback type="invalid">
                                    {errors.plan_name &&
                                        errors.plan_name.message}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group
                                className="mb-4 mt-4"
                                controlId="formBasicTitle"
                            >
                                <Form.Label>
                                    {t("pages.pricing_plan.form.credits")}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t(
                                        "pages.pricing_plan.form.placeholder_credits"
                                    )}
                                    {...register("credits")}
                                    isInvalid={!!errors.credits}
                                />

                                <Form.Control.Feedback type="invalid">
                                    {errors.credits && errors.credits.message}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group
                                className="mb-4 mt-4"
                                controlId="formBasicTitle"
                            >
                                <Form.Label>
                                    {t("pages.pricing_plan.form.price")}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="price"
                                    placeholder={t(
                                        "pages.pricing_plan.form.placeholder_price"
                                    )}
                                    {...register("price")}
                                    isInvalid={!!errors.price}
                                />

                                <Form.Control.Feedback type="invalid">
                                    {errors.price && errors.price.message}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group
                                className="mb-4 mt-4"
                                controlId="formBasicTitle"
                            >
                                <Form.Label>
                                    {t("pages.pricing_plan.form.valid_for")}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="price"
                                    placeholder={t(
                                        "pages.pricing_plan.form.placeholder_valid_for"
                                    )}
                                    {...register("valid_for")}
                                    isInvalid={!!errors.valid_for}
                                />

                                <Form.Control.Feedback type="invalid">
                                    {errors.valid_for &&
                                        errors.valid_for.message}
                                </Form.Control.Feedback>
                            </Form.Group>

                            {/* <Form.Group
                                className="mb-4 mt-4"
                                controlId="formBasicTitle"
                            >
                                <Form.Label>
                                    {t("pages.pricing_plan.form.plan_type")}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t(
                                        "pages.pricing_plan.form.placeholder_plan_type"
                                    )}
                                    {...register("plan_type")}
                                    isInvalid={!!errors.plan_type}
                                />

                                <Form.Control.Feedback type="invalid">
                                    {errors.plan_type &&
                                        errors.plan_type.message}
                                </Form.Control.Feedback>
                            </Form.Group> */}
                            <Form.Group
                                className="mb-4 mt-4"
                                controlId="formBasicTitle"
                            >
                                <Form.Label>
                                    {t("pages.pricing_plan.form.plan_type")}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register("plan_type")}
                                    isInvalid={!!errors.plan_type}
                                >
                                    <option value="">
                                        {t(
                                            "pages.pricing_plan.form.placeholder_plan_type"
                                        )}
                                    </option>
                                    <option value="1">
                                        {t(
                                            "pages.pricing_plan.form.one_time_payment"
                                        )}
                                    </option>
                                    <option value="2">
                                        {t(
                                            "pages.pricing_plan.form.subscription"
                                        )}
                                    </option>
                                </Form.Control>

                                <Form.Control.Feedback type="invalid">
                                    {errors.plan_type &&
                                        errors.plan_type.message}
                                </Form.Control.Feedback>
                            </Form.Group>

                            {validationErrors.length !== 0 && (
                                <div className="form-group mb-2">
                                    {validationErrors.map((error, index) => (
                                        <p
                                            className="text-danger font-weight-bold"
                                            key={index}
                                        >
                                            {t(error)}
                                        </p>
                                    ))}
                                </div>
                            )}

                            <Form.Group
                                className="mb-4 mt-4"
                                controlId="formBasicTitle"
                            >
                                <div className="d-flex justify-content-end mt-4 section-form  mx-auto mx-lg-5">
                                    <Button
                                        variant="secondary"
                                        size="sm"
                                        onClick={() => goToBack()}
                                    >
                                        <span className="ms-1">
                                            {t(
                                                "pages.pricing_plan.button.back"
                                            )}
                                        </span>
                                    </Button>
                                    <Button
                                        ref={submitFormRef}
                                        variant="success"
                                        size="sm"
                                        type="submit"
                                        className="text-white ms-4"
                                    >
                                        <span className="me-1">
                                            {t(
                                                "pages.pricing_plan.button.save"
                                            )}
                                        </span>
                                    </Button>
                                </div>
                            </Form.Group>
                        </Card.Body>
                    </Card>
                </Form>
            </div>
        </>
    );
}

export default Create;
