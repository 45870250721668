const Course = {
    title: "Learning Hub",
    all_course: "All Courses",
    my_course: "My Courses",
    no_record: "No records found.",
    quizze: "Quizze",
    lessons: "Lessons",
    duration: "Duration",
    price: "Price",
    table: {
        title: "Course Name",
        status: "Status",
        credits: "Credits",
    },
    button: {
        new_license_key: "Buy",
        back: "Back",
        save: "Save",
    },
};
export default Course;
