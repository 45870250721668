import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAuth } from "@/services/Auth";
import LOCATION from "@/constants/Location";
import { loadStripe } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRollbar } from "@rollbar/react";
import { Link, useParams } from "react-router-dom";
import SubscriptionForm from "./Forms/SubscriptionForm";
import PaymentMethodForm from "./Forms/PaymentMethodForm";
import ConfirmModal from "../../../components/modals/ConfirmModal";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

function PaymentMethod({ source, plan, assessment, AuditPrice, ...props }) {
    const auth = useAuth();
    const { t } = useTranslation();

    const rollbar = useRollbar();

    const stripeSetupFormRef = useRef(null);
    const [loading, setLoading] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [body, setModalBody] = useState(null);
    const [subscriptionDuration, setSubscriptionDuration] = useState("year");

    let confirmText = t("common.okay");

    const schema = Yup.object().shape({});

    const {
        handleSubmit: processSubmit,
        register,
        formState: { errors },
        watch,
        setValue,
        setError,
    } = useForm({
        resolver: yupResolver(schema),
    });
    const makePayment = ({ payment_method_id, plan_id, assessment_id }) => {
        setLoading(true);
        auth.postRequest(`${LOCATION.TRANSACTIONS.API.path}`, {
            user_id: auth.user.id,
            payment_method_id,
            plan_id,
            assessment_id,
        })
            .then((response) => {
                auth.updateUser(response.data);
                if (source === "buy-credits") {
                    setModalTitle(
                        t("pages.payments.notifications.credit_purchased.title")
                    );
                    setModalBody(
                        // t("pages.payments.notifications.credit_purchased.body")
                        t(
                            "pages.payments.notifications.credit_purchased.body",
                            {
                                credits: plan.credits,
                            }
                        )
                    );
                    setShowModal(true);
                } else {
                    window.location.href = `${LOCATION.ASSESSMENTS.LIST.path}/${assessment?.id}/request-audit/success`;
                }
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const subscribe = ({ payment_method_id, plan_id }) => {
        setLoading(true);
        let user_id = auth.user.id;
        auth.postRequest(`${LOCATION.SUBSCRIPTIONS.API.path}`, {
            payment_method_id,
            plan_id,
            user_id,
        })
            .then((response) => {
                if (response.data) {
                    setModalTitle(t("common.notifications.subscribed.title"));
                    setModalBody(
                        t(
                            "pages.payments.notifications.credit_purchased.body",
                            {
                                credits: plan.credits,
                            }
                        )
                    );
                    setLoading(false);
                    setShowModal(true);

                    auth.updateUser(response.data);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const saveSquarePaymentMethod = (token, buyer) => {
        console.log("SQP TOKEN ", token, buyer);
        auth.postRequest(
            `${LOCATION.USERS.API.path}/${auth.user.id}/${LOCATION.PAYMENT_METHODS.NAME}`,
            {
                plan_id: plan?.id,
                assessment_id: assessment?.id,
                source: source,
                ...token,
                card_number: token["details"]["card"]["last4"],
                expire:
                    token["details"]["card"]["expMonth"] +
                    "/" +
                    token["details"]["card"]["expYear"],
                ccv: token["token"],
                card_id: token["token"],
                brand: token["details"]["card"]["brand"],

                verify_token: buyer.token,
                //card_id: buyer.token,
                //ccv: buyer.token,
            }
        )
            .then((response) => {
                auth.updateUser(response.data);
                setLoading(false);
                if (source === "subscribe") {
                    setModalTitle(t("common.notifications.subscribed.title"));
                    setModalBody(
                        t("common.notifications.subscribed.body", {
                            credits: plan.credits,
                            duration: t(
                                `pages.plans.durations.${plan.duration}`
                            ),
                        })
                    );
                } else if (source === "buy-credits") {
                    setModalTitle(
                        t("pages.payments.notifications.credit_purchased.title")
                    );
                    setModalBody(
                        //t("pages.payments.notifications.credit_purchased.body")
                        t(
                            "pages.payments.notifications.credit_purchased.body",
                            {
                                credits: plan.credits,
                            }
                        )
                    );
                } else {
                    window.location.href = `${LOCATION.ASSESSMENTS.LIST.path}/${assessment?.id}/request-audit/success`;
                }
                setShowModal(true);
            })
            .catch((error) => {
                console.log("ERROR MSG  ", error);
                setLoading(false);
                switch (error?.error?.code) {
                    case "incomplete_number":
                        setError("card_number", {
                            message: t("form_validation.is_required", {
                                attribute: t("card_number"),
                            }),
                        });
                        break;

                    case "invalid_number":
                        setError("card_number", {
                            message: t("form_validation.invalid_card_number"),
                        });
                        break;

                    case "incomplete_expiry":
                        setError("expire", {
                            message: t("form_validation.is_required", {
                                attribute: t("expire"),
                            }),
                        });
                        break;

                    case "invalid_expiry_month_past":
                    case "invalid_expiry_year_past":
                    case "invalid_expiry":
                        setError("expire", {
                            message: t("form_validation.invalid_expire"),
                        });
                        break;

                    case "incomplete_cvc":
                        setError("ccv", {
                            message: t("form_validation.is_required", {
                                attribute: t("ccv"),
                            }),
                        });
                        break;

                    case "invalid_cvc":
                        setError("ccv", {
                            message: t("form_validation.invalid_ccv"),
                        });
                        break;

                    default:
                        console.error(error);
                        rollbar.error(error);
                }
            });
    };

    const savePaymentMethod = useCallback(
        (values, buyer) => {
            setLoading(true);
            values.interval = subscriptionDuration;

            saveSquarePaymentMethod(values, buyer);
            return false;
            if (stripeSetupFormRef.current)
                stripeSetupFormRef
                    .current(values)
                    .then((payload) => {
                        console.log("values  ", values);
                        console.log("payload ", payload);
                        //  return false;
                        auth.postRequest(
                            `${LOCATION.USERS.API.path}/${auth.user.id}/${LOCATION.PAYMENT_METHODS.NAME}`,
                            {
                                plan_id: plan?.id,
                                assessment_id: assessment?.id,
                                source: source,
                                ...payload,
                            }
                        )
                            .then((response) => {
                                auth.updateUser(response.data);

                                setLoading(false);

                                if (source === "subscribe") {
                                    setModalTitle(
                                        t(
                                            "common.notifications.subscribed.title"
                                        )
                                    );
                                    setModalBody(
                                        t(
                                            "common.notifications.subscribed.body",
                                            {
                                                credits: plan.credits,
                                                duration: t(
                                                    `pages.plans.durations.${plan.duration}`
                                                ),
                                            }
                                        )
                                    );
                                } else if (source === "buy-credits") {
                                    setModalTitle(
                                        t(
                                            "pages.payments.notifications.credit_purchased.title"
                                        )
                                    );
                                    setModalBody(
                                        t(
                                            "pages.payments.notifications.credit_purchased.body"
                                        )
                                    );
                                } else {
                                    window.location.href = `${LOCATION.ASSESSMENTS.LIST.path}/${assessment?.id}/request-audit/success`;
                                }
                                setShowModal(true);
                            })
                            .catch((error) => {
                                setLoading(false);
                            });
                    })
                    .catch((error) => {
                        setLoading(false);
                        switch (error?.error?.code) {
                            case "incomplete_number":
                                setError("card_number", {
                                    message: t("form_validation.is_required", {
                                        attribute: t("card_number"),
                                    }),
                                });
                                break;

                            case "invalid_number":
                                setError("card_number", {
                                    message: t(
                                        "form_validation.invalid_card_number"
                                    ),
                                });
                                break;

                            case "incomplete_expiry":
                                setError("expire", {
                                    message: t("form_validation.is_required", {
                                        attribute: t("expire"),
                                    }),
                                });
                                break;

                            case "invalid_expiry_month_past":
                            case "invalid_expiry_year_past":
                            case "invalid_expiry":
                                setError("expire", {
                                    message: t(
                                        "form_validation.invalid_expire"
                                    ),
                                });
                                break;

                            case "incomplete_cvc":
                                setError("ccv", {
                                    message: t("form_validation.is_required", {
                                        attribute: t("ccv"),
                                    }),
                                });
                                break;

                            case "invalid_cvc":
                                setError("ccv", {
                                    message: t("form_validation.invalid_ccv"),
                                });
                                break;

                            default:
                                console.error(error);
                                rollbar.error(error);
                        }
                    });
        },
        [stripeSetupFormRef.current]
    );

    const handleConfirm = () => {
        setShowModal(false);
        window.location.href = "/";
    };

    useEffect(() => {
        register("country_id");
        register("card_number");
        register("expire");
        register("ccv");
    }, []);

    return (
        <>
            <ConfirmModal
                show={showModal}
                title={modalTitle}
                body={body}
                confirmText={confirmText}
                handleConfirm={handleConfirm}
            />
            <>
                {source === "subscribe" ? (
                    {
                        /* <SubscriptionForm
              processSubmit={processSubmit}
              savePaymentMethod={savePaymentMethod}
              stripePromise={stripePromise}
              stripeSetupFormRef={stripeSetupFormRef}
              errors={errors}
              loading={loading}
              subscribe={subscribe}
              subscriptionDuration={subscriptionDuration}
              setSubscriptionDuration={setSubscriptionDuration}
              plan={plan}
            /> */
                    }
                ) : (
                    <PaymentMethodForm
                        processSubmit={processSubmit}
                        savePaymentMethod={savePaymentMethod}
                        stripePromise={stripePromise}
                        stripeSetupFormRef={stripeSetupFormRef}
                        errors={errors}
                        loading={loading}
                        source={source}
                        makePayment={makePayment}
                        plan={plan}
                        assessment={assessment}
                        AuditPrice={AuditPrice}
                    />
                )}
            </>
        </>
    );
}

export default PaymentMethod;
