const VerifyEmail = {
  title: 'Verify Email',
  instructions: 'Please provide your new password below',
  reset: 'Reset Your Password',
  success: {
    title: 'Your password was updated',
    description: 'Continue to login with your new password',
  },
}

export default VerifyEmail;
