import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useAuth } from "@/services/Auth";
import LoadingCover from "@/components/LoadingCover";
//import UserFilters from "./components/UserFilters";
import Plus from "@/components/icons/Plus";
import TableTransaction from "@/views/components/Tables/Transactions";
import LOCATION from "@/constants/Location";
import ConfirmModal from "@/components/modals/ConfirmModal";
function UsersIndex() {
    const history = useHistory();
    const route = useLocation();
    const auth = useAuth();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);
    const [transactions, setTransactions] = useState([]);

    const [query, setQuery] = useState(
        (route.state && route.state.query) || { page: 0, skip: 0, limit: 15 }
    );

    const [searching, setSearching] = useState(false);
    const [licenseKeyToDelete, setLicenseKeyToDelete] = useState(null);
    const [showModal, setShowModal] = useState(false);
    /*  const deleteRequest = (licenseKeyToDelete) => {
        setLicenseKeyToDelete(licenseKeyToDelete);
        setShowModal(true);
    };

    const handleCancel = () => {
        setShowModal(false);
        setLicenseKeyToDelete(null);
    };
    const handleConfirm = () => {
        setShowModal(false);
        setLoading(true);
        auth.deleteRequest(
            `${LOCATION.LICENSE_KEY.API.path}/${licenseKeyToDelete}`
        )
            .then((response) => {
                setLoading(false);
                setLicenseKeyToDelete(null);
            })
            .catch((error) => {});
    };
    useEffect(() => {
        history.replace(route.pathname, {
            ...route.state,

            query,
        });
    }, [query]); */

    useEffect(() => {
        let request = auth.getRequest(LOCATION.TRANSACTIONS.API.path);

        request
            .then((response) => {
                setTransactions(response.data.data);
                setLoading(false);
            })
            .catch((error) => {});

        return () => request?.abort && request.abort();
    }, []);

    if (loading) return <LoadingCover />;

    return (
        <>
            {/* <ConfirmModal
                show={showModal}
                title={t(
                    "pages.transactions.notifications.delete_transaction.title"
                )}
                body={t(
                    "pages.transactions.notifications.delete_transactions.body"
                )}
                handleCancel={handleCancel}
                handleConfirm={handleConfirm}
                cancelText={t("common.dialogs.actions.cancel")}
                confirmText={t("common.dialogs.actions.delete")}
            /> */}
            <div className="content">
                <div className="section-header d-flex justify-content-between flex-md-row flex-column mb-4">
                    <h4>{t("pages.transactions.title")}</h4>
                </div>

                <div className="my-4">
                    <TableTransaction
                        query={query}
                        showPagination={true}
                        onData={(_) => setSearching(!_)}
                        // deleteRequest={deleteRequest}
                    />
                </div>
            </div>
        </>
    );
}
export default UsersIndex;
