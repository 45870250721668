import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoadingButton from "../../../components/LoadingButton";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "@/services/Auth";
import LOCATION from "@/constants/Location";
//import {Helmet} from "react-helmet";
import Safe from "react-safe";
import $ from "jquery";
function HealthSafetyPolicy(props) {
    const auth = useAuth();
    //console.log("AUTH ", auth);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const [serverError, setServerError] = useState("");
    const [serverErrors, setServerErrors] = useState([]);

    const [htmlData, setHtmlData] = useState([]);
    const baseUrl = auth.api.defaults.baseURL;
    let token = localStorage.getItem("token") || null;

    const [iframeURL, setIframeURL] = useState("");
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const adId = urlParams.get("ad_id");
        let iUrl =
        baseUrl + LOCATION.HEALTH_SAFETY_POLICY.API.path + "&t=" + token;
        if (adId > 0) {
            iUrl =
                baseUrl +
                LOCATION.HEALTH_SAFETY_POLICY.API.path +
                "&t=" +
                token +
                "&ad_id=" +
                adId;
        }

        setIframeURL(iUrl);
    }, []);
    return (
        <div className="container h-100 d-flex flex-column align-items-center flex-grow-1">
            <div className="mt-5 p-2">
                <h2 className="fw-bold text-primary">
                    {t("pages.health_safety_policy.title")}
                </h2>

                <Row>
                    {/* <div dangerouslySetInnerHTML={{ __html: htmlData }}></div> */}
                    {/* <Safe.script src="https://use.typekit.net/foobar.js"></Safe.script>
                    <Safe.script src="https://dev.laughing-ganguly.3-9-11-18.plesk.page/assets/js/script_container.js"></Safe.script>

                    <Safe.script>
                        {`try{Typekit.load({ async: true });}catch(e){}`}
                    </Safe.script>

                    <link
                        rel="stylesheet"
                        href="https://dev.laughing-ganguly.3-9-11-18.plesk.page/public/css/app.css"
                    />
                    <link
                        rel="stylesheet"
                        href="https://dev.laughing-ganguly.3-9-11-18.plesk.page/public/extjs/extjs/ext-all.css"
                    />
                    <link
                        rel="stylesheet"
                        href="https://dev.laughing-ganguly.3-9-11-18.plesk.page/assets/css/timeline.css"
                    />
                    <link
                        rel="stylesheet"
                        href="https://dev.laughing-ganguly.3-9-11-18.plesk.page/assets/css/misc.css"
                    />
                    <link
                        rel="stylesheet"
                        href="https://dev.laughing-ganguly.3-9-11-18.plesk.page/assets/css/font-awesome.css"
                    /> */}

                    {/* <iframe
                        src={
                            "https://project.frawizard.co.uk/lv/public/api/health-safety-policy/detail-iframe?uId=" +
                            auth.user.id
                        }
                        width={"1500px"}
                        height={"800px"}
                        style={{ width: "1800px", height: "800px" }}
                    ></iframe> */}
                    <div class="col-md-12">
                        <iframe
                            src={
                                iframeURL
                            }
                            /* src={
                            "https://project.frawizard.co.uk/lv/public/api/page-detail?id=26&type=i&uId=" +
                            auth.user.id
                        } */
                            frameborder={"0"}
                            style={{
                                position: "fixed",

                                top: "0",
                                right: "0",
                                bottom: "0",
                                left: "0",
                                width: "80%",
                                height: "95%",

                                marginTop: "5%",
                                marginLeft: "15%",
                            }}
                        ></iframe>
                    </div>
                    {/* https://dev.laughing-ganguly.3-9-11-18.plesk.page/public/js/app.js */}
                    {/* <div id="wrapper">

                        <div className="form-wrapper">
                            <div id="myform">

                                <Safe.div>{htmlData}</Safe.div>

                            </div>
                        </div>
                        <div id="myreport"></div>
                    </div> */}
                </Row>
            </div>
        </div>
    );
}

export default HealthSafetyPolicy;
