import errors from './Errors'
import common from './Common'
import pages from './pages'

const translations = {
  translation: {
    common,
    errors,
    pages,
  }
}

export default translations
