import PageActionSection from '@/components/PageActionSection'
import { useTranslation } from 'react-i18next'
import React, { useState, useRef } from 'react'
import { useAuth } from '@/services/Auth'
import LOCATION from '@/constants/Location'
import FormBuilder from './Forms/FormBuilder'
import { useHistory, useParams } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import ArrowRight from '@/components/icons/ArrowRight'
import ArrowLeft from '@/components/icons/ArrowLeft'
import ConfirmModal from '@/components/modals/ConfirmModal'
import { getValidationErrors } from '@/Utility'

function Create (props) {
  const { t } = useTranslation()
  const auth = useAuth()
  const history = useHistory()

  const query = new URLSearchParams(props.location.search)
  const assessment_template_type = query.get('type');

  const [saveAsDraft, setSaveAsDraft] = useState(false)
  const [createdAssessment, setCreatedAssessment] = useState(null)
  const [validationErrors, setValidationErrors] = useState([])

  const [showSuccess, setShowSuccess] = useState(false)
  const [title, setTitle] = useState('')

  const submitFormRef = useRef(null)

  const saveDraft = () => {
    setSaveAsDraft(true)
    submitFormRef.current.click()
  }

  const requestSave = (values) => {

    setValidationErrors([]);

    let form = new FormData()
    form.append('title', values.title)
    form.append('description', values.description)
    form.append('featured_image_file', values.featured_image)
    form.append('assessment_template_type', assessment_template_type)

    auth.postRequest(LOCATION.ASSESSMENT_TEMPLATES.API.path, form)
      .then(response => {
        setCreatedAssessment(response.data)
        setTitle(t('pages.assessment_templates.dialogs.titles.template_created'))
        setShowSuccess(true)
      })
      .catch(error => {
        setValidationErrors(getValidationErrors(error));
      })
  }

  const handleConfirm = () => {
    setShowSuccess(false)
    setTitle(null)

    let successPage = `${LOCATION.ASSESSMENT_TEMPLATES.LIST.path}/${createdAssessment.id}/edit`
    if (saveAsDraft) {
      successPage = `${LOCATION.ASSESSMENT_TEMPLATES.LIST.path}`
    }
    history.push(successPage)
  }

  return (
    <>

      <ConfirmModal
        show={showSuccess}
        title={title}
        confirmText={t('common.dialogs.actions.okay')}
        handleConfirm={handleConfirm}
      />

      <PageActionSection
        prepend={[
          <Button variant="dark" size="sm" onClick={() => saveDraft()}>
            <ArrowLeft/>
            <span className="ms-1">{t('pages.new_assessment.save_draft')}</span>
          </Button>
        ]}
        append={[
          <Button
            variant="success"
            onClick={() => submitFormRef.current.click()}
            size="sm"
            type="submit"
            className={`text-white`}
          >
            <span className="me-1">{t('pages.new_assessment.save_continue')}</span>
            <ArrowRight/>
          </Button>
        ]}
        title={t('pages.assessment_templates.new_assessment_template')}
        type="dark"
      />
      <div className="content">
        <FormBuilder
          saveDraft={saveDraft}
          requestSave={requestSave}
          submitFormRef={submitFormRef}
          validationErrors={validationErrors}
        />
      </div>
    </>
  )
}

export default Create
