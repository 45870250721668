import { Link, useParams } from 'react-router-dom'
import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import LOCATION from '@/constants/Location'
import LoadingCover from '@/components/LoadingCover'
import BackButton from '@/views/components/Buttons/BackButton'
import PageActionSection from '@/components/PageActionSection'
import AssessmentCertificate from '@/views/components/assessments/AssessmentCertificate'
import PrintButton from '@/views/components/Buttons/PrintButton'
import DownloadButton from '@/views/components/Buttons/DownloadButton'

function Certificate () {

  const { t } = useTranslation()
  const auth = useAuth()
  const { assessment_id, audit } = useParams()

  const [loading, setLoading] = useState(true)
  const [assessment, setAssessment] = useState(null)
  const reportTemplateRef = useRef(null);
  const [downloadURL, setDownloadURL] = useState("");
  const onBackButton = () => {

  }

  useEffect(() => {
    let request = auth.getRequest(`${LOCATION.ASSESSMENTS.API.path}/${assessment_id}`)
    request
      .then(response => {
        setAssessment(response.data)
        setLoading(false)
        let iUrl = "https://firesafety.project.frawizard.co.uk/Post/gen_pdf/1/"+assessment_id+"/pdf/38/"+response.data?.t_cert+"?path=fracertificate";
        setDownloadURL(iUrl);
      })
      .catch(error => {
      })

    return () => request?.abort && request.abort()

  }, [])
 
  if (loading || !assessment) {
    return (
      <LoadingCover/>
    )
  }

  return (
    <>
      <PageActionSection
        prepend={[<BackButton fillColor='white' onBackButton={onBackButton}/>]}
        append={
          audit ?
            [
              <Link  to={`${LOCATION.ASSESSMENTS.LIST.path}/${assessment.id}/1/send-audit-report`} className="text-primary btn btn-light btn-sm">
                {t('pages.assessments.buttons.send_audit_report_to_client')}
              </Link>
            ]
            :
            [
              <PrintButton elementName="assessment-certificate"/>,
              <DownloadButton
                element=".assessment-certificate"
                fileName={assessment?.assessment_template.title}
                width={446}
                height={630}
                orientation="p"
                adminComment = {assessment?.admin_comment}
                pdfWatermark ={assessment?.user?.userImageBanner}
                downloadURL = {downloadURL}
              />
            ]}
        title={t('pages.assessments.report.report_certificate')}
        type="primary"
      />
      <AssessmentCertificate
        reportTemplateRef={reportTemplateRef}
        assessment={assessment}
        pdfWatermark ={assessment?.user?.userImageBanner}
      />

    </>
  )
}

export default Certificate
