import LOCATION from "@/constants/Location";

import PricingPlanPage from "@/views/pages/PricingPlan";
import Create from "@/views/pages/PricingPlan/Create";
import Update from "@/views/pages/PricingPlan/Update";

const PricingPlan = [
    {
        path: LOCATION.PRICING_PLANS.LIST.path,
        component: PricingPlanPage,
        exact: true,
        translation_key: "pages.pricing_plan.title",
        main: true,
        gate: "pricing_plan",
    },
    {
        path: LOCATION.PRICING_PLANS.CREATE.path,
        component: Create,
        exact: true,
        main: false,
        gate: "pricing_plan",
    },

    {
        path: LOCATION.PRICING_PLANS.UPDATE.path,
        component: Update,
        exact: true,
        main: false,
        gate: "pricing_plan",
    },
];

export default PricingPlan;
