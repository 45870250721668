import { Button, Form, ListGroup } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import SectionIcon from "@/components/icons/SectionIcon";
import { useTranslation } from "react-i18next";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import HorizontalLine from "@/components/icons/HorizontalLine";
import { useAuth } from "@/services/Auth";
import LOCATION from "@/constants/Location";

function AssessmentSectionList({
    currentSectionIndex,
    sections,
    changeSection,
    titleSection,
    disableOthers,
    auditMode,
    submitSectionAudit,
    sectionScores,
    maxSectionIndex,
    listRecommendation,
    fromAudit,
    assessmentTemplateType
}) {
    const auth = useAuth();
    const { t } = useTranslation();

    const [sectionList, setSectionList] = useState([]);
    const audited = (section) => {
        if (sectionScores) {
            let sectionScore = sectionScores.find(
                (_) => _.assessment_template_section_id === section.id
            );
            if (sectionScore) {
                return sectionScore.audited;
            }
        }
        return false;
    };

    const handleDrop = (droppedItem) => {
        if (!droppedItem.destination) return;

        let updatedList = [...sectionList];
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);

        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);

        setSectionList(updatedList);
        updateSections(updatedList);
    };

    const updateSections = (updatedList) => {
        let sectionOrders = [];
        updatedList.forEach((section, index) => {
            sectionOrders.push({
                id: section.id,
                order: index + 1,
            });
        });

        auth.postRequest(
            `${LOCATION.ASSESSMENT_SECTION.API.path}/update-orders`,
            {
                sections: sectionOrders,
            }
        )
            .then((response) => {})
            .catch((error) => {});
    };

    useEffect(() => {
        setSectionList(sections);
    }, [sections]);

    return (
        <div
            className={`assessment-section-container ${
                auth.user.client ? "client" : ""
            }`}
        >
            {titleSection && (
                <ListGroup className="assessment-sections">
                    <ListGroup.Item
                        as="li"
                        className="border-0 p-0 d-grid gap-2"
                    >
                        <Button
                            onClick={() => changeSection(null)}
                            className={`d-flex text-dark justify-content-start ${
                                currentSectionIndex != null
                                    ? "inactive-btn"
                                    : ""
                            }`}
                            variant="light"
                        >
                            <SectionIcon />
                            <span className="ms-3">
                                {t(
                                    "pages.new_assessment.title_and_description"
                                )}
                            </span>
                        </Button>
                    </ListGroup.Item>
                </ListGroup>
            )}

            <DragDropContext onDragEnd={handleDrop}>
                <Droppable
                    droppableId="list-container"
                    isDropDisabled={auditMode || auth?.user?.client}
                >
                    {(provided) => (
                        <div
                            className="list-container"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {sectionList.map((section, index) => (
                                <Draggable
                                    key={section.id}
                                    draggableId={`${section.id}`}
                                    index={index}
                                    isDragDisabled={
                                        auditMode || auth?.user?.client
                                    }
                                >
                                    {(provided) => (
                                        <div
                                            className="item-container"
                                            ref={provided.innerRef}
                                            {...provided.dragHandleProps}
                                            {...provided.draggableProps}
                                        >
                                            <div className="d-flex align-items-center bg-light">
                                                <div className="d-grid gap-2 w-100">
                                                    <Button
                                                        disabled={
                                                            disableOthers
                                                                ? index >
                                                                  maxSectionIndex
                                                                : false
                                                        }
                                                        onClick={() =>
                                                            changeSection(index)
                                                        }
                                                        className={`d-flex justify-content-start  text-dark ${
                                                            index >
                                                            maxSectionIndex
                                                                ? "inactive-btn"
                                                                : index <
                                                                  maxSectionIndex
                                                                ? index ===
                                                                  currentSectionIndex
                                                                    ? "fw-bold fst-italic"
                                                                    : "fst-italic"
                                                                : index ===
                                                                  currentSectionIndex
                                                                ? "fw-bold"
                                                                : ""
                                                        }`}
                                                        variant="light"
                                                    >
                                                        <SectionIcon />
                                                        <span
                                                            className={`ms-2 text-start ${
                                                                (auditMode ||
                                                                    fromAudit) &&
                                                                audited(section)
                                                                    ? "text-decoration-line-through"
                                                                    : ""
                                                            }`}
                                                        >{`${index + 1}. ${
                                                            section.name ??
                                                            "Section #"
                                                        } `}</span>
                                                    </Button>
                                                </div>
                                                {fromAudit ? (
                                                    <>
                                                        {false && (
                                                            <Form.Check
                                                                disabled={
                                                                    index !==
                                                                        currentSectionIndex ||
                                                                    audited(
                                                                        section
                                                                    )
                                                                }
                                                                className="px-3 bg-light"
                                                                type="checkbox"
                                                                checked={audited(
                                                                    section
                                                                )}
                                                                value={
                                                                    section.id
                                                                }
                                                                onChange={(e) =>
                                                                    submitSectionAudit(
                                                                        e.target
                                                                            .checked,
                                                                        section.id
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    </>
                                                ) : auth?.user?.admin ? (
                                                    <HorizontalLine />
                                                ) : null}
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                {/* assessmentTemplate?.assessment_template_type === 1 */}
                {(true &&  assessmentTemplateType === 1) && (
                    <>
                        <div className="item-container">
                            <div className="d-flex align-items-center bg-light">
                                <div className="d-grid gap-2 w-100">
                                    <Button
                                        className={`d-flex justify-content-start  text-dark ${
                                            currentSectionIndex >
                                            maxSectionIndex
                                                ? "inactive-btn"
                                                : "-2" < maxSectionIndex
                                                ? "-2" === currentSectionIndex
                                                    ? "fw-bold fst-italic"
                                                    : "fst-italic"
                                                : "-2" === currentSectionIndex
                                                ? "fw-bold"
                                                : ""
                                        }`}
                                        variant="light"
                                        onClick={() => changeSection("-2")}
                                    >
                                        <SectionIcon />
                                        <span className={`ms-2 text-start`}>
                                            {t(
                                                "pages.assessments.fra_signature_section"
                                            )}
                                        </span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {listRecommendation && (
                    <>
                        <div className="item-container">
                            <div className="d-flex align-items-center bg-light">
                                <div className="d-grid gap-2 w-100">
                                    <Button
                                        className={`d-flex justify-content-start  text-dark ${
                                            currentSectionIndex >
                                            maxSectionIndex
                                                ? "inactive-btn"
                                                : "-1" < maxSectionIndex
                                                ? "-1" === currentSectionIndex
                                                    ? "fw-bold fst-italic"
                                                    : "fst-italic"
                                                : "-1" === currentSectionIndex
                                                ? "fw-bold"
                                                : ""
                                        }`}
                                        variant="light"
                                        onClick={() => changeSection("-1")}
                                    >
                                        <SectionIcon />
                                        <span className={`ms-2 text-start`}>
                                            {t(
                                                "pages.assessments.report.recommendations_for_improvement"
                                            )}
                                        </span>
                                    </Button>
                                </div>
                            </div>
                            <div className=" align-items-center bg-light d-flex1 p-3 mlp-7">
                                {/*  {listRecommendation.map((row, index) => (
                                    <>
                                        <div
                                            className="d-grid gap-2"
                                            key={index}
                                        >
                                            <span className="fw-bold1">
                                                {row.que_no} {row.name}
                                            </span>
                                            <p>{row.recommendation}</p>
                                        </div>
                                    </>
                                ))} */}

                                {/* <div className="d-grid gap-2 w-100">222</div> */}
                            </div>
                        </div>
                    </>
                )}
            </DragDropContext>
        </div>
    );
}

export default AssessmentSectionList;
