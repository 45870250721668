import LOCATION from "@/constants/Location";

import DirectoryPage from "@/views/pages/Directory";

const Directory = [
    {
        path: LOCATION.DIRECTORY.LIST.ALL_DIRECTORY.path,
        component: DirectoryPage,
        exact: true,
        translation_key: "pages.directory.title",
        main: false,
        gate: "user_course",
    },
    {
        path: LOCATION.DIRECTORY.PAGE.ALL_DIRECTORY.path,
        component: DirectoryPage,
        exact: true,
        translation_key: "pages.directory.title",
        main: false,
        gate: "user_course",
    },
];

export default Directory;
