const NewAssessment = {
    title: "New Assessment",
    save_draft: "Save Draft & Exit",
    save_continue: "Save & Continue",
    title_and_description: "Title and Description",
    add_section: "Add Section",
    delete_section: "Delete Section",
    dialogs: {
        titles: {
            section_saved: "Section Saved",
            assessment_completed: "Assessment Completed",
            processing: "Processing, please wait..",
            audit_saved: "Your Audit changes saved",
            
        },
        body: {
            section_saved: "Section Saved, please continue to the next section",
            //assessment_completed:"You have completed the assessment, continue to your assessment report",
            assessment_completed:"You have completed the assessment, continue to next Audit section",
            audit_sign_section:
                "Section saved, continue to your assessment report",
        },
    },
};
export default NewAssessment;
