const LOCATION = {
    ROOT: { path: "/" },

    AUTH: {
        SIGN_IN: { path: "/login" },
        SIGN_OUT: { path: "/sign-out" },
        SIGN_UP: { path: "/sign-up" },
        INVITED: { path: "/r/:profile" },
        FORGOT_PASSWORD: { path: "/password/forgot" },
        RESET_PASSWORD: { path: "/password/reset/:token" },
        VERIFY_EMAIL: {
            path: "/email-verify/:token",
            API: { path: "/email-verify" },
        },
    },
    ASSESSMENT_TEMPLATES: {
        LIST: { path: "/assessments-templates" },
        CREATE: { path: "/new-assessment-template" },
        EDIT: { path: "/assessments-templates/:id/edit" },
        API: { path: "/assessment-templates" },
        SERVICE: { path: "/services" },
        SERVICE_CREDIT_UPDATE: {
            API: { path: "/assessment-templates/update-credit" },
        },
    },
    ASSESSMENTS: {
        LIST: { path: "/assessments" },
        API: { path: "/assessments" },
        AUDIT: { API: { path: "/assessments/get-audit-out-forms" }},

        CREATE: { path: "/new-assessment" },
        FORM: { path: "/new-assessment/:template_id" },
        EDIT_FORM: { path: "/new-assessment/:template_id/:assessment_id" },
        REPORT: { path: "/assessments/:id/report" },
        REPORT_AUDIT: { path: "/assessments/:id/:audit/report" },
        FULL_REPORT: { path: "/assessments/:assessment_id/full-report" },
        FULL_REPORT_SHARED: {
            path: "/assessments/:reference_number/:share_code",
        },
        CERTIFICATE: { path: "/assessments/:assessment_id/certificate",API: { path: "/assessment-certificate?type=i" } },
        CERTIFICATE_AUDIT: {
            path: "/assessments/:assessment_id/:audit/certificate",
        },
        AUDIT_REQUEST: { path: "/assessments/:id/request-audit" },
        AUDIT_REQUEST_PAYMENT: {
            path: "/assessments/:id/request-audit/payment",
        },
        AUDIT_REQUEST_SUCCESS: {
            path: "/assessments/:id/request-audit/success",
        },
        SEND_AUDIT_REPORT: {
            path: "/assessments/:id/:audit/send-audit-report",
        },
        PDF_REPORTS: {
            FIRE_DOOR_COMPLIANCE: {
                //Fire Door Compliance (27)
                path: "/assessments/fdc-pdf-reports",
                API: { path: "/assessment-pdf-reports?type=i" },
                DOWNLOAD: {
                    API: { path: "/assessment-pdf-report-download?type=i" },
                },
            },
            FIRE_SAFETY_POLICY_DOCUMENT: {
                // Fire Safety Policy Docs (24)
                path: "/assessments/fspd-pdf-reports",
                API: { path: "/assessment-pdf-reports?type=i" },
                DOWNLOAD: {
                    API: { path: "/assessment-pdf-report-download?type=i" },
                },
            },
            HEALTH_AND_SAFETY_POLICY: {
                //Health and Safety Policy (26)
                path: "/assessments/hasp-pdf-reports",
                API: { path: "/assessment-pdf-reports?type=i" },
                DOWNLOAD: {
                    API: { path: "/assessment-pdf-report-download?type=i" },
                },
            },
            EMERGENCY_LIGHTING: {
                //EmergencyLighting (37)
                path: "/assessments/emergency-lighting-reports",
                API: { path: "/assessment-pdf-reports?type=i" },
                DOWNLOAD: {
                    API: { path: "/assessment-pdf-report-download?type=i" },
                },
            },
            FIRE_EXTINGUISER: {
                //FireExtinguiser (36)
                path: "/assessments/fire-extinguiser-reports",
                API: { path: "/assessment-pdf-reports?type=i" },
                DOWNLOAD: {
                    API: { path: "/assessment-pdf-report-download?type=i" },
                },
            },
            COMBINED_REPORT: {
                API: { path: "/assessment-combined-reports" },
            },
        },

        RECOMMENDATION_UPDATE: {
            API: { path: "/assessments/update-answer-recommendation" },
        },
        RECOMMENDATION_LIST: {
            API: { path: "/assessments/get-answer-recommendation" },
        },
        INTERNAL_AUDIT: { API: { path: "/assessments/internal-audit" } },
        AUDITOR_AUDIT: { API: { path: "/assessments/auditor-audit" } },
    },
    AUDIT_REQUEST: {
        LIST: { path: "/audit-requests" },
        API: { path: "/audit-requests" },
    },
    ASSESSMENT_SECTION: {
        API: { path: "/assessment-template-sections" },
    },
    ASSESSMENT_QUESTIONS: {
        API: { path: "/questions" },
    },
    FIELD_TYPES: {
        API: { path: "/field-types" },
    },
    ASSESSMENT_SECTION_SCORES: {
        API: { path: "/assessment-section-scores" },
    },
    ASSESSMENT_STATUS: {
        API: { path: "/assessment-status" },
    },
    ASSESSMENT_AUDI_STATUS: {
        API: { path: "/assessment-audit-status" },
    },
    ASSESSMENTS_AUDIT: {
        LIST: { path: "/assessment-audit" },
        AUDIT: { path: "/assessment-audit/:template_id/:assessment_id" },
        API: { path: "/audit-requests" },
    },
    COMPETENCY_TEMPLATES: {
        LIST: { path: "/competency-templates" },
        CREATE: { path: "/new-competency-template" },
        EDIT: { path: "/competency-templates/:id/edit" },
        API: { path: "/competency-templates" },
    },
    COMPETENCY_TESTS: {
        LIST: { path: "/competency-tests" },
        CREATE: { path: "/new-competency-test" },
        CERTIFICATE: { path: "/competency-tests/:id/certificate" },
        EDIT: { path: "/competency-tests/:id/edit" },
        API: { path: "/competency-tests" },
    },
    USERS: {
        LIST: { path: "/users" },
        SHOW: { path: "/users/:id" },
        API: { path: "/users" },
    },
    My_ACCOUNT: {
        LIST: { path: "/my-account" },
    },
    OUR_NETWORK: {
        API: { path: "/business-listing" },
        LIST: { path: "/our-network" },
        SHOW: { path: "/our-network/:id" },
    },
    ORGANIZATIONS: {
        API: { path: "/organizations" },
        CUSTOMISE: {
            API: { path: "/users/organizations/customise" },
        },
        USERS: {
            LIST: { API: { path: "/users/organizations" } },
            CREATE: { API: { path: "/users/organizations" } },
            STATUS: { API: { path: "/users/status-update" } },
            UPDATE: { API: { path: "/users/organizations" } },
        },
    },
    COMPANIES: {
        API: { path: "/companies" },
    },
    PROMOTION_REQUESTS: {
        API: { path: "/promotion-requests" },
    },
    TOKEN_BUNDLES: {
        API: { path: "/token-bundles" },
    },
    TRANSACTIONS: {
        LIST: { path: "/transcations", API: "/transcations" },
        API: { path: "/transactions" },
    },
    PAYMENT_METHODS: {
        NAME: "payment-methods",
        API: { path: "/payment-methods" },
    },
    SUBSCRIPTIONS: {
        API: { path: "/subscriptions" },
    },
    PAYMENTS: {
        API: { path: "/payments" },
        PRICING_AND_PLANS: { path: "/pricing-and-plans" },
        PRICING_AND_PLANS_OUT_OF_TOKENS: {
            path: "/pricing-and-plans/:out_of_tokens",
        },
        TOKEN_CHECKOUT_LINK: { path: "/buy-tokens" },
        TOKEN_CHECKOUT: { path: "/buy-tokens/:token_bundle_id" },
        TOKEN_CHECKOUT_SUCCESS: {
            path: "/buy-tokens/:token_bundle_id/:number_of_tokens/success",
        },

        SUBSCRIBE: { path: "/subscribe" },
        SUBSCRIBE_PROCESSING: { path: "/subscribe/processing-payment" },
    },
    PLANS: {
        API: { path: "/plans" },
        SUBSCRIBE: { path: "/plans/:id/subscribe" },
        BUY_CREDIT: { path: "/plans/:id/buy-credit" },
    },

    FIRE_SAFETY_POLICY_DOCUMENT: {
        API: { path: "/page-detail?id=24&type=i" },
        LIST: { path: "/fire-safety-policy-document" },
    },
    HEALTH_SAFETY_POLICY: {
        API: { path: "/page-detail?id=26&type=i" },
        //API: { path: "/health-safety-policy/detail" },
        LIST: { path: "/health-and-safety-policy" },
    },
    FIRE_DOOR_COMPLIANCE: {
        API: { path: "/page-detail?id=27&type=i" },
        LIST: { path: "/fire-door-compliance" },
    },
    DOCUMENT_TEMPLATE: {
        API: { path: "/document-template?type=i" },
        LIST: { path: "/document-template" },
    },
    CUSTOMISE: {
        API: { path: "/customise?type=i" },
        LIST: { path: "/customise" },
    },
    OCCUPANCY_CALCULATOR: {
        LIST: { path: "/occupancy-calculator" },
    },
    LICENSE_KEY: {
        LIST: { path: "/license-keys", API: "/license-keys" },
        CREATE: { path: "/new-license-key", API: "/license-keys" },
        REDEEM: { path: "/license-key-redeem", API: "/license-key-redeem" },
        API: { path: "/license-keys" },
    },

    COURSE: {
        LIST: {
            COURSE: { path: "/courses/list", API: "/all-courses" },
            ALL_COURSE: { path: "/courses", API: "/all-courses" },
            MY_COURSE: { path: "/courses", API: "/my-courses" },
        },
        PAGE: {
            ALL_COURSE: { path: "/courses/:type", API: "/all-courses" },
            MY_COURSE: { path: "/courses/:type", API: "/my-courses" },
        },
    },

    DIRECTORY: {
        LIST: {
            ALL_DIRECTORY: { path: "/directory", API: "/all-directory" },
            MY_DIRECTORY: { path: "/directory", API: "/my-directory" },
        },
        PAGE: {
            ALL_DIRECTORY: { path: "/directory/:type", API: "/all-directory" },
            MY_DIRECTORY: { path: "/directory/:type", API: "/my-directory" },
        },
    },
    ADMIN_USERS: {
        LIST: { path: "/admin-users" },
        CREATE: { path: "/add-admin-users", API: "/users/add-user" },
        API: { path: "/users" },
    },

    PRICING_PLANS: {
        LIST: { path: "/pricing-plans" },
        CREATE: { path: "/new-pricing-plan" },
        UPDATE: { path: "/pricing-plans/:id" },
        API: { path: "/pricing-plans" },
    },

    SERVICES: {
        LIST: { path: "/services" },
        UPDATE: { path: "/services" },
        API: { path: "/services" },
    },

    EMERGENCY_LIGHTING: {
        API: { path: "/page-detail?id=37&type=i" },
        LIST: { path: "/emergency-lighting" },
    },
    FIRE_EXTINGUISER: {
        API: { path: "/page-detail?id=36&type=i" },
        LIST: { path: "/fire-extinguiser" },
    },
};

export default LOCATION;
