const AdminUsers = {
    title: "Admin Users",
    add: "Add Admin User",
    update_token: "Update Token",

    labels: {
        organization: "Organisation(s)",
        subscription: "Subscription",
        subscription_plan: "Plan",
    },
    subscription_status: {
        inactive: "Inactive",
        active: "Active",
        all: "All",
    },
    dialogs: {
        titles: {
            token_updated: "Tokens updated!",
        },
    },
    buttons: {
        back: "Back",
        save: "Save",
    },
    form: {
        first_name: "First name",
        last_name: "Last name",
        email: "Email",
        password: "Password",
        admin_user_created: "Admin user created",
        password_confirmation: "Confirmation Password",
        phone_number: "Phone number",
        full_name: "Fullname",
        no:"#No."
    },
};
export default AdminUsers;
