import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/services/Auth";
import LOCATION from "@/constants/Location";
import LoadingCover from "@/components/LoadingCover";
import { Accordion, Button, Card, Col, Form, Row } from "react-bootstrap";
import { getScoreColor } from "@/Utility";
import AssessmentScoreIcon from "@/views/components/AssessmentScoreIcon";
import PageActionSection from "@/components/PageActionSection";
import BackButton from "@/views/components/Buttons/BackButton";
import EyeFill from "@/components/icons/EyeFill";
import SectionRecommendationForm from "./Forms/SectionRecommendationForm";
import ArrowLeft from "@/components/icons/ArrowLeft";
import {
    getScoreColorValue,
    getSectionScore,
    getScoreColorCompetency,
    getScoreColorValueCompetency,
    getSectionScoreLikelihood,
    getSectionScoreSeverity,
} from "@/Utility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faShare, faShareAlt } from "@fortawesome/free-solid-svg-icons";
import LoadingButton from "../../../components/LoadingButton";
function Report() {
    const { t } = useTranslation();
    const auth = useAuth();
    let { id, audit } = useParams();

    const [loading, setLoading] = useState(true);
    const [assessment, setAssessment] = useState(null);
    const [assessmentAudited, setAssessmentAudited] = useState(false);
    const [listRecommendation, setListRecommendation] = useState(null);

    const [desiredSectionNo, setDesiredSectionNo] = useState(1);

    const [showToast, setShowToast] = useState(false);
    const [generatingLink, setGeneratingLink] = useState(false);
    const copyShareLink = () => {
        const shareLink = `${process.env.REACT_APP_URL}${LOCATION.ASSESSMENTS.API.path}/${assessment?.fra_reference_number}/${assessment.share_code}`;
        navigator.clipboard.writeText(shareLink);
        setShowToast(true);
    };

    const generateLink = () => {
        setGeneratingLink(true);
        auth.getRequest(`${LOCATION.ASSESSMENTS.API.path}/${id}/share-link`)
            .then((response) => {
                setAssessment(response.data);
                setGeneratingLink(false);
            })
            .catch((error) => {});
    };
    const getListRecommendation = async (data) => {
        var recArray = [];
        var selectedAnswerIds = [];
        if (data.answers.length) {
            for (
                let answerRow = 0;
                answerRow < data.answers.length;
                answerRow++
            ) {
                if (data.answers[answerRow].question_choice_id !== null) {
                    selectedAnswerIds.push(
                        parseInt(data.answers[answerRow].question_choice_id)
                    );
                }
            }
        }
        if (data.assessment_template) {
            if (data.assessment_template.assessment_sections) {
                /* console.log(
                " getListRecommendation ",
                data.assessment_template.assessment_sections.length,
                data.assessment_template.assessment_sections
            ); */
                for (
                    let sections = 0;
                    sections <
                    data.assessment_template.assessment_sections.length;
                    sections++
                ) {
                    if (
                        data.assessment_template.assessment_sections[sections]
                            .questions.length > 0
                    ) {
                        for (
                            let rowQuestion = 0;
                            rowQuestion <
                            data.assessment_template.assessment_sections[
                                sections
                            ].questions.length;
                            rowQuestion++
                        ) {
                            for (
                                let rawQusChoice = 0;
                                rawQusChoice <
                                data.assessment_template.assessment_sections[
                                    sections
                                ].questions[rowQuestion].question_choices
                                    .length;
                                rawQusChoice++
                            ) {
                                let isFound = selectedAnswerIds.includes(
                                    data.assessment_template
                                        .assessment_sections[sections]
                                        .questions[rowQuestion]
                                        .question_choices[rawQusChoice].id
                                );
                                if (
                                    isFound === true &&
                                    data.assessment_template
                                        .assessment_sections[sections]
                                        .questions[rowQuestion]
                                        .question_choices[rawQusChoice]
                                        .recommendation != null
                                ) {
                                    recArray.push({
                                        section_no: sections + 1,
                                        que_no:
                                            sections +
                                            1 +
                                            "." +
                                            (rowQuestion + 1),
                                        name: data.assessment_template
                                            .assessment_sections[sections]
                                            .questions[rowQuestion].label,
                                        parent_id:
                                            data.assessment_template
                                                .assessment_sections[sections]
                                                .id,
                                        parent_name:
                                            data.assessment_template
                                                .assessment_sections[sections]
                                                .name,
                                        answer: data.assessment_template
                                            .assessment_sections[sections]
                                            .questions[rowQuestion]
                                            .question_choices[rawQusChoice]
                                            .answer,
                                        recommendation:
                                            data.assessment_template
                                                .assessment_sections[sections]
                                                .questions[rowQuestion]
                                                .question_choices[rawQusChoice]
                                                .recommendation,
                                    });
                                }
                            }
                        }
                    }
                }
            }
        }

        await setListRecommendation(recArray);
    };
    useEffect(() => {
        let request = auth.getRequest(`${LOCATION.ASSESSMENTS.API.path}/${id}`);
        request
            .then((response) => {
                setAssessment(response.data);
                getListRecommendation(response.data);
                let sectionScores = response.data.section_scores;
                sectionScores.forEach((sectionScore) => {
                    if (sectionScore.audited) {
                        setAssessmentAudited(true);
                    }
                });

                setLoading(false);
            })
            .catch((error) => {});

        return () => request?.abort && request.abort();
    }, []);

    if (loading || !assessment) {
        return <LoadingCover />;
    }

    const filteredRecommendations = listRecommendation.filter(
        (recommendation) => recommendation.section_no === desiredSectionNo
    );

    return (
        <>
            <PageActionSection
                prepend={[<BackButton fillColor="white" />]}
                append={
                    audit
                        ? [
                              <Link
                                  to={`${LOCATION.ASSESSMENTS.LIST.path}/${assessment.id}/full-report`}
                                  className="btn btn-light btn-sm text-primary"
                              >
                                  <span className="me-1">
                                      {t(
                                          "pages.assessments.buttons.view_full_report"
                                      )}
                                  </span>
                                  <EyeFill />
                              </Link>,
                              <Link
                                  to={`${LOCATION.ASSESSMENTS.LIST.path}/${assessment.id}/1/certificate`}
                                  className="btn btn-light btn-sm text-primary"
                              >
                                  <span className="me-1">
                                      {t(
                                          "pages.assessments.buttons.continue_view_certificate"
                                      )}
                                  </span>
                                  <EyeFill />
                              </Link>,
                              <>
                                  {assessment?.share_code && auth.user ? (
                                      <Button
                                          size="sm"
                                          variant="light"
                                          className="text-primary"
                                          onClick={() => copyShareLink()}
                                      >
                                          <span className="me-1">
                                              {t(
                                                  "pages.assessments.buttons.copy_share_link"
                                              )}
                                          </span>
                                          <FontAwesomeIcon
                                              size="sm"
                                              icon={faCopy}
                                          />
                                      </Button>
                                  ) : auth.user ? (
                                      <LoadingButton
                                          loading={generatingLink}
                                          onSubmit={generateLink}
                                          variant="light"
                                          className="text-primary btn-sm"
                                          titleTranslationKey="pages.assessments.buttons.generate_share_link"
                                          type="button"
                                          icon={
                                              <FontAwesomeIcon
                                                  size="sm"
                                                  icon={faShare}
                                              />
                                          }
                                          size="sm"
                                      />
                                  ) : null}
                              </>,
                          ]
                        : assessment?.assessment_template
                              ?.assessment_template_type === 2
                        ? [
                              assessment?.competency_passed && (
                                  <Link
                                      to={`${LOCATION.COMPETENCY_TESTS.LIST.path}/${assessment?.id}/certificate`}
                                      className="btn btn-light btn-sm text-primary"
                                  >
                                      <span className="me-1">
                                          {t(
                                              "pages.competency_templates.buttons.competency_certificate"
                                          )}
                                      </span>
                                  </Link>
                              ),
                          ]
                        : [
                              <Link
                                  to={`${LOCATION.ASSESSMENTS.CREATE.path}/${assessment.assessment_template_id}/${assessment.id}`}
                                  className="btn btn-light btn-sm text-primary"
                              >
                                  <span className="">
                                      {t(
                                          "pages.assessments.buttons.edit_assessment"
                                      )}
                                  </span>
                              </Link>,
                              auth.user.client &&
                              !assessment.audit_requested ? (
                                  <Link
                                      to={`${LOCATION.ASSESSMENTS.LIST.path}/${assessment.id}/request-audit`}
                                      className="text-primary btn btn-light btn-sm"
                                  >
                                      {t(
                                          "pages.assessments.buttons.request_audit_for_this_assessment"
                                      )}
                                  </Link>
                              ) : null,

                              <Link
                                  to={`${LOCATION.ASSESSMENTS.LIST.path}/${assessment.id}/full-report`}
                                  className="btn btn-light btn-sm text-primary"
                              >
                                  <span className="me-1">
                                      {t(
                                          "pages.assessments.buttons.view_full_report"
                                      )}
                                  </span>
                                  <EyeFill />
                              </Link>,

                              <Link
                                  to={`${LOCATION.ASSESSMENTS.LIST.path}/${assessment.id}/certificate`}
                                  className="btn btn-light btn-sm text-primary"
                              >
                                  <span className="me-1">
                                      {t(
                                          "pages.assessments.buttons.view_certificate"
                                      )}
                                  </span>
                                  <EyeFill />
                              </Link>,
                          ]
                }
                title={
                    audit
                        ? t("pages.assessments.report.audit_rating_report")
                        : assessment?.assessment_template
                              ?.assessment_template_type === 2
                        ? t("pages.competency_templates.report.title")
                        : t("pages.assessments.report.title")
                }
                type="primary"
            />

            <div
                className="container report-container"
                style={{ maxWidth: "95%" }}
            >
                <Row>
                    <Col className="col-12 col-xl-4 mt-xl-0 p-xl-2 mt-5 ">
                        <Card className="border-0 my-4">
                            <Card.Body className="d-flex1 justify-content-between align-items-center1">
                                <div>
                                    <h2 className="fw-bold">
                                        <span>
                                            {t(
                                                "pages.assessments.report.how_score_work"
                                            )}
                                        </span>
                                    </h2>
                                </div>
                                <div className="my-4 d-flex1 justify-content-between">
                                    {/* <div className="text-center my-4 score-box bg-black">
                                        Black: No/Blank Rating
                                    </div> */}
                                    <div className="text-left my-4 score-box bg-no">
                                        {t(
                                            "pages.assessments.report.score_green_text"
                                        )}
                                    </div>
                                    <div className="text-left my-4 bg-low score-box ">
                                        {t(
                                            "pages.assessments.report.score_yellow_text"
                                        )}
                                    </div>

                                    <div className="text-left my-4 score-box bg-medium">
                                        {t(
                                            "pages.assessments.report.score_orange_text"
                                        )}
                                    </div>
                                    <div className="text-left my-4 score-box bg-high">
                                        {t(
                                            "pages.assessments.report.score_red_text"
                                        )}
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="col-12 col-xl-8 mt-xl-0 p-xl-2 mt-5 d-flex1 align-items-center1">
                        <Card className="border-0 my-4">
                            <Card.Body className="d-flex justify-content-between align-items-center">
                                <div>
                                    <h2 className="fw-bold">
                                        {assessmentAudited && audit ? (
                                            <span>
                                                {t(
                                                    "pages.assessments.report.their_updated_rating_is"
                                                )}
                                            </span>
                                        ) : (
                                            <span>
                                                {t(
                                                    "pages.assessments.report.your_rating_is"
                                                )}
                                            </span>
                                        )}
                                        {assessment?.assessment_template
                                            ?.assessment_template_type === 2 ? (
                                            <span
                                                className={getScoreColorCompetency(
                                                    (assessment.score /
                                                        assessment.out_of) *
                                                        10
                                                )}
                                            >
                                                {assessment.score}/
                                                {assessment.out_of}
                                            </span>
                                        ) : (
                                            <span
                                                className={getScoreColor(
                                                    assessment.score
                                                )}
                                            >
                                                {assessment.score}/10
                                            </span>
                                        )}
                                    </h2>
                                    {assessment?.assessment_template
                                        ?.assessment_template_type === 2 ? (
                                        <>
                                            <p className="rating-improvement-statement mt-3">
                                                {t(
                                                    "pages.competency_templates.you_need_to_pass"
                                                )}
                                            </p>
                                            {!assessment?.competency_passed ? (
                                                <Link
                                                    style={{ width: "150px" }}
                                                    className="btn btn-primary btn-sm mt-3"
                                                    to={`${LOCATION.ASSESSMENTS.CREATE.path}/${assessment?.assessment_template_id}`}
                                                >
                                                    <ArrowLeft />
                                                    <span className="ms-1">
                                                        {t(
                                                            "pages.competency_templates.buttons.restart_test"
                                                        )}
                                                    </span>
                                                </Link>
                                            ) : (
                                                <Link
                                                    className="btn btn-primary btn-sm mt-3"
                                                    to={`${LOCATION.COMPETENCY_TESTS.LIST.path}/${assessment?.id}/certificate`}
                                                >
                                                    <span className="ms-1">
                                                        {t(
                                                            "pages.competency_templates.buttons.competency_certificate"
                                                        )}
                                                    </span>
                                                </Link>
                                            )}
                                        </>
                                    ) : assessmentAudited && audit ? (
                                        <p className="rating-improvement-statement mt-3">
                                            {t(
                                                "pages.assessments.report.show_how_it_can_be_improved_below"
                                            )}
                                        </p>
                                    ) : (
                                        <p className="rating-improvement-statement mt-3">
                                            {t(
                                                "pages.assessments.report.see_how_it_can_be_improved_below"
                                            )}
                                        </p>
                                    )}
                                </div>
                                {assessment?.assessment_template
                                    ?.assessment_template_type === 1 ? (
                                    <AssessmentScoreIcon
                                        color={getScoreColorValue(
                                            getSectionScore(assessment, false)
                                        )}
                                        score={getSectionScore(
                                            assessment,
                                            false
                                        )}
                                        size={138}
                                        value={100}
                                    />
                                ) : (
                                    <AssessmentScoreIcon
                                        color={getScoreColorValueCompetency(
                                            getSectionScore(assessment, false) *
                                                10
                                        )}
                                        score={getSectionScore(
                                            assessment,
                                            true
                                        )}
                                        size={138}
                                        suffix="%"
                                        value={getSectionScore(
                                            assessment,
                                            true
                                        )}
                                    />
                                )}
                            </Card.Body>
                        </Card>

                        <Card className="border-0 my-4">
                            <Card.Body className="d-flex justify-content-between align-items-center">
                                <div>
                                    <h2 className="fw-bold">
                                        {assessmentAudited && audit ? (
                                            <span>
                                                {t(
                                                    "pages.assessments.report.your_updated_likelihood_rating_is"
                                                )}
                                            </span>
                                        ) : (
                                            <span>
                                                {t(
                                                    "pages.assessments.report.your_likelihood_rating_is"
                                                )}
                                            </span>
                                        )}
                                        {assessment?.assessment_template
                                            ?.assessment_template_type === 2 ? (
                                            <span
                                                className={getScoreColorCompetency(
                                                    (assessment.score_likelihood /
                                                        assessment.out_of) *
                                                        10
                                                )}
                                            >
                                                {assessment.score_likelihood}/
                                                {assessment.out_of}
                                            </span>
                                        ) : (
                                            <span
                                                className={getScoreColor(
                                                    assessment.score_likelihood
                                                )}
                                            >
                                                {assessment.score_likelihood}/10
                                            </span>
                                        )}
                                    </h2>
                                    {assessment?.assessment_template
                                        ?.assessment_template_type === 2 ? (
                                        <>
                                            <p className="rating-improvement-statement mt-3">
                                                {t(
                                                    "pages.competency_templates.you_need_to_pass"
                                                )}
                                            </p>
                                            {!assessment?.competency_passed ? (
                                                <Link
                                                    style={{ width: "150px" }}
                                                    className="btn btn-primary btn-sm mt-3"
                                                    to={`${LOCATION.ASSESSMENTS.CREATE.path}/${assessment?.assessment_template_id}`}
                                                >
                                                    <ArrowLeft />
                                                    <span className="ms-1">
                                                        {t(
                                                            "pages.competency_templates.buttons.restart_test"
                                                        )}
                                                    </span>
                                                </Link>
                                            ) : (
                                                <Link
                                                    className="btn btn-primary btn-sm mt-3"
                                                    to={`${LOCATION.COMPETENCY_TESTS.LIST.path}/${assessment?.id}/certificate`}
                                                >
                                                    <span className="ms-1">
                                                        {t(
                                                            "pages.competency_templates.buttons.competency_certificate"
                                                        )}
                                                    </span>
                                                </Link>
                                            )}
                                        </>
                                    ) : assessmentAudited && audit ? (
                                        <p className="rating-improvement-statement mt-3">
                                            {/* {t(
                                                "pages.assessments.report.show_how_it_can_be_improved_below"
                                            )} */}
                                        </p>
                                    ) : (
                                        <p className="rating-improvement-statement mt-3">
                                            {/* {t(
                                                "pages.assessments.report.see_how_it_can_be_improved_below"
                                            )} */}
                                        </p>
                                    )}
                                </div>
                                {assessment?.assessment_template
                                    ?.assessment_template_type === 1 ? (
                                    <AssessmentScoreIcon
                                        color={getScoreColorValue(
                                            getSectionScoreLikelihood(
                                                assessment,
                                                false
                                            )
                                        )}
                                        score={getSectionScoreLikelihood(
                                            assessment,
                                            false
                                        )}
                                        size={138}
                                        value={100}
                                    />
                                ) : (
                                    <AssessmentScoreIcon
                                        color={getScoreColorValueCompetency(
                                            getSectionScoreLikelihood(
                                                assessment,
                                                false
                                            ) * 10
                                        )}
                                        score={getSectionScoreLikelihood(
                                            assessment,
                                            true
                                        )}
                                        size={138}
                                        suffix="%"
                                        value={getSectionScoreLikelihood(
                                            assessment,
                                            true
                                        )}
                                    />
                                )}
                            </Card.Body>
                        </Card>

                        <Card className="border-0 my-4">
                            <Card.Body className="d-flex justify-content-between align-items-center">
                                <div>
                                    <h2 className="fw-bold">
                                        {assessmentAudited && audit ? (
                                            <span>
                                                {t(
                                                    "pages.assessments.report.your_updated_severity_rating_is"
                                                )}
                                            </span>
                                        ) : (
                                            <span>
                                                {t(
                                                    "pages.assessments.report.your_severity_rating_is"
                                                )}
                                            </span>
                                        )}
                                        {assessment?.assessment_template
                                            ?.assessment_template_type === 2 ? (
                                            <span
                                                className={getScoreColorCompetency(
                                                    (assessment.score_severity /
                                                        assessment.out_of) *
                                                        10
                                                )}
                                            >
                                                {assessment.score_severity}/
                                                {assessment.out_of}
                                            </span>
                                        ) : (
                                            <span
                                                className={getScoreColor(
                                                    assessment.score_severity
                                                )}
                                            >
                                                {assessment.score_severity}/10
                                            </span>
                                        )}
                                    </h2>
                                    {assessment?.assessment_template
                                        ?.assessment_template_type === 2 ? (
                                        <>
                                            <p className="rating-improvement-statement mt-3">
                                                {t(
                                                    "pages.competency_templates.you_need_to_pass"
                                                )}
                                            </p>
                                            {!assessment?.competency_passed ? (
                                                <Link
                                                    style={{ width: "150px" }}
                                                    className="btn btn-primary btn-sm mt-3"
                                                    to={`${LOCATION.ASSESSMENTS.CREATE.path}/${assessment?.assessment_template_id}`}
                                                >
                                                    <ArrowLeft />
                                                    <span className="ms-1">
                                                        {t(
                                                            "pages.competency_templates.buttons.restart_test"
                                                        )}
                                                    </span>
                                                </Link>
                                            ) : (
                                                <Link
                                                    className="btn btn-primary btn-sm mt-3"
                                                    to={`${LOCATION.COMPETENCY_TESTS.LIST.path}/${assessment?.id}/certificate`}
                                                >
                                                    <span className="ms-1">
                                                        {t(
                                                            "pages.competency_templates.buttons.competency_certificate"
                                                        )}
                                                    </span>
                                                </Link>
                                            )}
                                        </>
                                    ) : assessmentAudited && audit ? (
                                        <p className="rating-improvement-statement mt-3">
                                            {/*  {t(
                                                "pages.assessments.report.show_how_it_can_be_improved_below"
                                            )} */}
                                        </p>
                                    ) : (
                                        <p className="rating-improvement-statement mt-3">
                                            {/*  {t(
                                                "pages.assessments.report.see_how_it_can_be_improved_below"
                                            )} */}
                                        </p>
                                    )}
                                </div>
                                {assessment?.assessment_template
                                    ?.assessment_template_type === 1 ? (
                                    <AssessmentScoreIcon
                                        color={getScoreColorValue(
                                            getSectionScoreSeverity(
                                                assessment,
                                                false
                                            )
                                        )}
                                        score={getSectionScoreSeverity(
                                            assessment,
                                            false
                                        )}
                                        size={138}
                                        value={100}
                                    />
                                ) : (
                                    <AssessmentScoreIcon
                                        color={getScoreColorValueCompetency(
                                            getSectionScoreSeverity(
                                                assessment,
                                                false
                                            ) * 10
                                        )}
                                        score={getSectionScoreSeverity(
                                            assessment,
                                            true
                                        )}
                                        size={138}
                                        suffix="%"
                                        value={getSectionScoreSeverity(
                                            assessment,
                                            true
                                        )}
                                    />
                                )}
                            </Card.Body>
                        </Card>

                        <h5 className="mb-2 mt-4 pt-4 fw-bold">
                            {t("pages.assessments.report.individual_ratings")}
                        </h5>
                        <Row>
                            {assessment.section_scores.map((sectionScore) => (
                                <Col
                                    className="col-md-4 col-sm-6 col-12"
                                    key={sectionScore.id}
                                >
                                    <Card className="border-0 my-2">
                                        <Card.Body className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <p className="section-score-title mb-4">
                                                    {
                                                        sectionScore
                                                            .assessment_template_section
                                                            .name
                                                    }
                                                </p>
                                                {assessment?.assessment_template
                                                    ?.assessment_template_type ===
                                                1 ? (
                                                    <p
                                                        className={`fw-bold section-score text-${sectionScore.score_comment}`}
                                                    >
                                                        {t(
                                                            `pages.assessments.report.${sectionScore.score_comment}`
                                                        )}
                                                    </p>
                                                ) : (
                                                    <p
                                                        className={`fw-bold section-score text-${sectionScore.score_comment}-competency`}
                                                    >
                                                        {t(
                                                            `pages.assessments.report.${sectionScore.score_comment}`
                                                        )}
                                                    </p>
                                                )}
                                            </div>
                                            {assessment?.assessment_template
                                                ?.assessment_template_type ===
                                            1 ? (
                                                <AssessmentScoreIcon
                                                    color={getScoreColorValue(
                                                        sectionScore.score
                                                    )}
                                                    score={sectionScore.score}
                                                    size={100}
                                                    hollowSize="40%"
                                                    value={100}
                                                />
                                            ) : (
                                                <AssessmentScoreIcon
                                                    color={getScoreColorValueCompetency(
                                                        (sectionScore.score /
                                                            sectionScore.out_of) *
                                                            10
                                                    )}
                                                    score={(
                                                        (sectionScore.score /
                                                            sectionScore.out_of) *
                                                        100
                                                    ).toFixed(0)}
                                                    size={100}
                                                    suffix="%"
                                                    value={(
                                                        (sectionScore.score /
                                                            sectionScore.out_of) *
                                                        100
                                                    ).toFixed(0)}
                                                />
                                            )}
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>

                        {assessment?.assessment_template
                            ?.assessment_template_type === 1 ? (
                            <>
                                <h5 className="mb-2 mt-4 pt-4 fw-bold">
                                    {t(
                                        "pages.assessments.report.recommendations_for_improvement"
                                    )}
                                </h5>
                                <Row>
                                    <Accordion defaultActiveKey={0} flush>
                                        {assessment.section_scores.map(
                                            (sectionScore, index) => (
                                                <Accordion.Item
                                                    eventKey={index}
                                                    key={index}
                                                >
                                                    <Accordion.Header
                                                        onClick={() =>
                                                            setDesiredSectionNo(
                                                                index + 1
                                                            )
                                                        }
                                                    >
                                                        <div
                                                            className={`score-title-indicator bg-${sectionScore.score_comment}`}
                                                        ></div>
                                                        {
                                                            sectionScore
                                                                .assessment_template_section
                                                                .name
                                                        }{" "}
                                                        - {sectionScore.score}
                                                        /10
                                                    </Accordion.Header>

                                                    <Accordion.Body>
                                                        {assessmentAudited &&
                                                        audit ? (
                                                            <SectionRecommendationForm
                                                                assessment={
                                                                    assessment
                                                                }
                                                                sectionScore={
                                                                    sectionScore
                                                                }
                                                            />
                                                        ) : sectionScore.recommendations &&
                                                          assessment.audit_status ===
                                                              3 ? (
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: sectionScore.recommendations,
                                                                }}
                                                            />
                                                        ) : (
                                                            <p>
                                                                {/* {t(
                                                                    "pages.assessments.report.no_recommendations"
                                                                )} */}
                                                            </p>
                                                        )}

                                                        {desiredSectionNo ===
                                                            index + 1 && (
                                                            <div>
                                                                {listRecommendation
                                                                    .filter(
                                                                        (
                                                                            recommendation
                                                                        ) =>
                                                                            recommendation.section_no ===
                                                                            index +
                                                                                1
                                                                    )
                                                                    .map(
                                                                        (
                                                                            recommendation
                                                                        ) => (
                                                                            <div
                                                                                key={
                                                                                    recommendation.que_no
                                                                                }
                                                                            >
                                                                                <span className="fw-bold1">
                                                                                    {
                                                                                        recommendation.que_no
                                                                                    }{" "}
                                                                                    {
                                                                                        recommendation.name
                                                                                    }
                                                                                </span>
                                                                                <p>
                                                                                    {
                                                                                        recommendation.recommendation
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                {listRecommendation.filter(
                                                                    (
                                                                        recommendation
                                                                    ) =>
                                                                        recommendation.section_no ===
                                                                        index +
                                                                            1
                                                                ).length ===
                                                                    0 && (
                                                                    <p>
                                                                        {t(
                                                                            "pages.assessments.report.no_recommendations"
                                                                        )}
                                                                    </p>
                                                                )}
                                                            </div>
                                                        )}
                                                        {/* {

                                                        filteredRecommendations.map(recommendation => (
                                                                <div key={recommendation.que_no}>
                                                                <span className="fw-bold1">
                                                                                {
                                                                                    recommendation.que_no
                                                                                }{" "}
                                                                                {
                                                                                    recommendation.name
                                                                                }
                                                                            </span>
                                                                            <p>
                                                                                {
                                                                                    recommendation.recommendation
                                                                                }

                                                                            </p>
                                                                </div>
                                                            ))} */}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )
                                        )}
                                    </Accordion>
                                </Row>
                            </>
                        ) : null}
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Report;
