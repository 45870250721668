import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useAuth } from '@/services/Auth'
import { useParams } from 'react-router-dom'
import LOCATION from '@/constants/Location'
import LoadingCover from '@/components/LoadingCover'
import { Button, Col, Row } from 'react-bootstrap'
import BackButton from '@/views/components/Buttons/BackButton'
import Globe from '@/components/icons/Globe'
import Envelope from '@/components/icons/Envelope'
import Phone from '@/components/icons/Phone'

function SingleListing () {

  const { t } = useTranslation()
  const auth = useAuth()

  const { id } = useParams()

  const [listing, setListing] = useState(null)
  const [loading, setLoading] = useState(true)

  const getListing = () => {
    let request = auth.getRequest(`${LOCATION.OUR_NETWORK.API.path}/${id}`)

    request
      .then(response => {
        setListing(response.data)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })

    return request
  }

  useEffect(() => {
    let request = getListing()
    return () => request?.abort && request.abort()
  }, [])

  if (loading) return <LoadingCover/>

  return (
    <div className="our-network">
      <div className="container p-4">
        <Row>
          <Col className="col-12">
            <BackButton variant="light"/>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className="col-lg-10 col-12 d-flex">
            <img className="listing-logo lg" src={listing.logo}/>
            <div className="ms-3">
              <h3 className="fw-bold">{listing.name}</h3>
              <div className="d-flex flex-md-row flex-column">
                <div className="d-flex align-items-center">
                  <Globe/>
                  <a href={listing.external_page_url} className="ms-1" target="_blank">{listing.external_page_url}</a>
                </div>
                <div className="d-flex ms-md-5 ms-0 mt-md-0 mt-2 align-items-center">
                  <Envelope/>
                  <a href={`mailto:${listing.email}`} className="ms-1" target="_blank">{listing.email}</a>
                </div>
                <div className="d-flex ms-md-5 ms-0  mt-md-0 mt-2 align-items-center">
                  <Phone/>
                  <a href={`tel:${listing.phone_number}`} className="ms-1">{listing.phone_number}</a>
                </div>
              </div>
            </div>
          </Col>
          <Col className="col-lg-2 col-12 d-md-flex align-items-md-center d-grid gap-2 my-md-0 my-4">
            <a
              href={listing.external_page_url}
              className="btn btn-dark rounded-4 py-3 px-5 nowrap"
              target="_blank"
            >{t('common.visit_website')}</a>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col className="col-12">
            {listing.description}
          </Col>
        </Row>

        <Row className="mt-1">
          <Col className="col-12">
            <hr/>
          </Col>
        </Row>
        <Row className="mt-3">

          {
            listing.services.map(service => {
              return (
                <Col className="col-md-4 col-6" key={service.id}>
                  <h5 className="fw-semibold">{service.name}</h5>
                  <p>{service.currency}{service.price_format} { t('common.rate_per', {rate : service.rate}) }</p>
                </Col>
              )
            })
          }
        </Row>
      </div>
    </div>
  )

}

export default SingleListing