import { Button, Card, Form, Modal } from "react-bootstrap";
import { Document, Page } from "react-pdf";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useTranslation } from "react-i18next";
import Question from "./components/Question";
import ArrowRight from "@/components/icons/ArrowRight";
import AddressField from "./components/AddressField";
import { Link } from "react-router-dom";
import LOCATION from "@/constants/Location";
import LoadingButton from "@/components/LoadingButton";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "@/services/Auth";

//import Modal from 'react-bootstrap/Modal';
function AssessmentSectionForm({
    sectionIndex,
    section,
    lastSection,
    saveSection,
    firstSlide,
    referenceNumber,
    address,
    auditMode,
    answers,
    submitFormRef,
    responsiblePerson,
    assessment,
    assessmentTemplate,
    responseError,
    validationErrors,
    saveDraft,
    submitting,
    adminComment,
    address_line_1,
    address_line_2,
    town_city,
    country,
    postcode,
    fromAudit,
    //...useParams
}) {
    const { t } = useTranslation();
    const auth = useAuth();

    const [questions, setQuestions] = useState([]);
    const [totalSection, setTotalSection] = useState(0);

    const [show, setShow] = useState(false);
    const [pdfFile, setPdfFile] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const handleItemHover = (hoveredPdfUrl) => {
        setPdfFile(hoveredPdfUrl);
        handleShow();
    };
    useEffect(() => {
        // Load your PDF file here and set it in the state
        const pdfUrl =
            "https://project.frawizard.co.uk/lv/public/images/multipage.pdf";
        setPdfFile(pdfUrl);
    }, []);
    /* useEffect(() => {
        // Load your PDF file here and set it in the state
        const pdfUrl =
            "https://project.frawizard.co.uk/lv/public/images/multipage.pdf";
        fetch(pdfUrl)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to fetch PDF");
                }
                return response.blob();
            })
            .then((pdfBlob) => {
                setPdfFile(URL.createObjectURL(pdfBlob));
            })
            .catch((error) => {
                console.error(error);
                // Handle the error gracefully, e.g., show a message to the user
            });
    }, []); */
    const questionSchema = {
        required: yup.string(),
        value: yup
            .mixed()
            .typeError(
                t("common.form_validation.is_required", {
                    attribute: t("common.form.this_question"),
                })
            )
            .when(["required", "type"], function (required, type, schema) {
                return parseInt(required) === 1 &&
                    ![2, 3, 6].includes(parseInt(type))
                    ? schema.required(
                          t("common.form_validation.is_required", {
                              attribute: t("common.form.this_question"),
                          })
                      )
                    : schema.nullable();
            }),
        explain_other: yup.string().nullable(),
        add_own_rating: yup.string().nullable(),
        other_answer: yup
            .string()
            .typeError(
                t("common.form_validation.is_required", {
                    attribute: t("common.form.this_question"),
                })
            )
            .when(["explain_other"], function (explain_other, schema) {
                return explain_other == "true"
                    ? schema.required(
                          t("common.form_validation.is_required", {
                              attribute: t("common.form.this_question"),
                          })
                      )
                    : schema.nullable();
            }),
        user_choice_score: yup
            .string()
            .typeError(
                t("common.form_validation.is_required", {
                    attribute: t("common.form.this_question"),
                })
            )
            .when(
                ["explain_other", "add_own_rating"],
                function (explain_other, add_own_rating, schema) {
                    return explain_other == "true" && add_own_rating == "true"
                        ? schema.required(
                              t("common.form_validation.is_required", {
                                  attribute: t("common.form.this_question"),
                              })
                          )
                        : schema.nullable();
                }
            ),
    };

    const schema = yup.object().shape({
        /* address:
            sectionIndex === 0 &&
            assessmentTemplate?.assessment_template_type === 1
                ? yup
                      .string()
                      .typeError(
                          t("common.form_validation.is_required", {
                              attribute: t("common.form.address"),
                          })
                      )
                      .required(
                          t("common.form_validation.is_required", {
                              attribute: t("common.form.address"),
                          })
                      )
                : yup.string().nullable(), */
        address_line_1:
        sectionIndex === 0 &&
        assessmentTemplate?.assessment_template_type === 1
            ? yup.string().required(
            t("common.form_validation.is_required", {
                attribute: t("common.form.address_line_1"),
            })
        )
        : yup.string().nullable(),
        address_line_2:
            sectionIndex === 0 &&
            assessmentTemplate?.assessment_template_type === 1
                ? yup.string().nullable()
        : yup.string().nullable(),
        town_city:
        sectionIndex === 0 &&
        assessmentTemplate?.assessment_template_type === 1
            ? yup.string().required(
            t("common.form_validation.is_required", {
                attribute: t("common.form.town_city"),
            })
        ): yup.string().nullable(),
        country:
            sectionIndex === 0 &&
            assessmentTemplate?.assessment_template_type === 1
                ? yup.string().required(
            t("common.form_validation.is_required", {
                attribute: t("common.form.country"),
            })
        ): yup.string().nullable(),
        postcode:
        sectionIndex === 0 &&
        assessmentTemplate?.assessment_template_type === 1
            ? yup.string().required(
            t("common.form_validation.is_required", {
                attribute: t("common.form.postcode"),
            })
        ): yup.string().nullable(),

        responsible_person:
            sectionIndex === 0 &&
            assessmentTemplate?.assessment_template_type === 1
                ? yup.string().required(
                      t("common.form_validation.is_required", {
                          attribute: t("common.form.responsible_person"),
                      })
                  )
                : yup.string().nullable(),
        questions: yup.array().of(
            yup.object().shape({
                ...questionSchema,
                children: yup.array().of(
                    yup.array().of(
                        yup.object().shape({
                            ...questionSchema,
                        })
                    )
                ),
            })
        ),
    });

    const {
        resetField,
        handleSubmit,
        register,
        setValue,
        watch,
        unregister,
        formState: { errors },
    } = useForm({
        defaultValues: ((_) => {
            return {
                fra_reference_number: referenceNumber,
                address:
                    assessmentTemplate?.assessment_template_type === 2
                        ? "none"
                        : address,
                responsible_person:
                    assessmentTemplate?.assessment_template_type === 2
                        ? "none"
                        : responsiblePerson,
                admin_comment:
                    assessmentTemplate?.assessment_template_type <= 0
                        ? "none"
                        : adminComment,
            };
        })(),
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (section && section.questions) {
            setQuestions(section.questions);
        }
        //useParams.setName("i'm from Parent");
        setTotalSection(assessmentTemplate?.assessment_sections?.length);
    }, [section]);

    useEffect(() => {
        if (referenceNumber) {
            setValue("fra_reference_number", referenceNumber);
        }
        if (responsiblePerson) {
            setValue("responsible_person", responsiblePerson);
        }
        if (address) {
            setValue("address", address);
        }
        if (adminComment) {
            setValue("admin_comment", adminComment);
        }
        if (referenceNumber) {
            setValue("fra_reference_number", referenceNumber);
        }
        if (responsiblePerson) {
            setValue("responsible_person", responsiblePerson);
        }
        /* if (address) {
            setValue("address", address);
        } */
        if (adminComment) {
            setValue("admin_comment", adminComment);
        }
        if (address_line_1) {
            setValue("address_line_1", address_line_1);
        }
        if (address_line_2) {
            setValue("address_line_2", address_line_2);
        }
        if (town_city) {
            setValue("town_city", town_city);
        }
        if (country) {
            setValue("country", country);
        }
        if (postcode) {
            setValue("postcode", postcode);
        }
        //setValue("auditMode", auditMode);
    }, [
        referenceNumber,
        address,
        adminComment,
        address_line_1,
        address_line_2,
        town_city,
        country,
        postcode,
        auditMode,
    ]);

    return (
        <>
            <br></br>
            <Form noValidate onSubmit={handleSubmit(saveSection)} className="">
                <Card className="border-0 radius-0 section-form  mx-auto mx-lg-5">
                    <Card.Body className="client">
                        {firstSlide &&
                            assessmentTemplate?.assessment_template_type ===
                                1 && (
                                <>
                                    <Form.Group
                                        className="mb-4"
                                        controlId="formBasicTitle"
                                    >
                                        <Form.Label>
                                            {t(
                                                "common.form.fra_reference_number"
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            disabled={true}
                                            {...register(
                                                "fra_reference_number"
                                            )}
                                            isInvalid={
                                                !!errors.fra_reference_number
                                            }
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.fra_reference_number &&
                                                errors.fra_reference_number
                                                    .message}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    {/* <Form.Group
                                        className="mb-4 mt-4"
                                        controlId="formBasicAddress"
                                    >
                                        <Form.Label>
                                            {t(
                                                "common.form.where_is_your_property_located"
                                            )}
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </Form.Label>
                                        <AddressField
                                            errors={errors}
                                            register={register}
                                            setValue={setValue}
                                            address={address}
                                            auditMode={auditMode}
                                        />
                                    </Form.Group> */}
                                    <Form.Group
                                        className="mb-4 mt-4"
                                        controlId="formBasicAddress1"
                                    >
                                        <Form.Label>
                                            {t("common.form.address_line_1")}
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </Form.Label>
                                        <Form.Control
                                            disabled={auditMode}
                                            {...register("address_line_1")}
                                            isInvalid={!!errors.address_line_1}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.address_line_1 &&
                                                errors.address_line_1.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group
                                        className="mb-4"
                                        controlId="formBasicAddress2"
                                    >
                                        <Form.Label>
                                            {t("common.form.address_line_2")}
                                        </Form.Label>
                                        <Form.Control
                                            disabled={auditMode}
                                            {...register("address_line_2")}
                                            isInvalid={!!errors.address_line_2}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.address_line_2 &&
                                                errors.address_line_2.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group
                                        className="mb-4"
                                        controlId="formBasicTownCity"
                                    >
                                        <Form.Label>
                                            {t("common.form.town_city")}
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </Form.Label>
                                        <Form.Control
                                            disabled={auditMode}
                                            {...register("town_city")}
                                            isInvalid={!!errors.town_city}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.town_city &&
                                                errors.town_city.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group
                                        className="mb-4"
                                        controlId="formBasicCountry"
                                    >
                                        <Form.Label>
                                            {t("common.form.country")}
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </Form.Label>
                                        <Form.Control
                                            disabled={auditMode}
                                            {...register("country")}
                                            isInvalid={!!errors.country}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.country &&
                                                errors.country.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group
                                        className="mb-4"
                                        controlId="formBasicPostcode"
                                    >
                                        <Form.Label>
                                            {t("common.form.postcode")}
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </Form.Label>
                                        <Form.Control
                                            disabled={auditMode}
                                            {...register("postcode")}
                                            isInvalid={!!errors.postcode}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.postcode &&
                                                errors.postcode.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group
                                        className="mb-4"
                                        controlId="formBasicName"
                                    >
                                        <Form.Label>
                                            {t(
                                                "common.form.responsible_person"
                                            )}
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </Form.Label>
                                        <Form.Control
                                            disabled={auditMode}
                                            {...register("responsible_person")}
                                            isInvalid={
                                                !!errors.responsible_person
                                            }
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.responsible_person &&
                                                errors.responsible_person
                                                    .message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </>
                            )}
                        {assessmentTemplate?.assessment_sections[sectionIndex]
                            .section_type === "supporting-document" && (
                            <>
                                {/* <Button variant="primary" onClick={handleShow}>
                                    Open PDF Modal
                                </Button> */}

                                <Modal
                                    show={show}
                                    onHide={handleClose}
                                    size="lg"
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            {t(
                                                "pages.assessments.supportive_document_viewer"
                                            )}
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <iframe
                                            title="PDF Viewer"
                                            src={pdfFile}
                                            width="100%"
                                            height="550px"
                                            frameBorder="0"
                                        ></iframe>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button
                                            variant="secondary"
                                            onClick={handleClose}
                                        >
                                            {t("common.form.close")}
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                <div className="col-12 col-xl-12 mt-xl-12 p-xl-2 mt-51d-flex1 align-items-center1">
                                    <h5 className="mb-2   fw-bold">
                                        {
                                            assessmentTemplate
                                                ?.assessment_sections[
                                                sectionIndex
                                            ].sd_title
                                        }
                                    </h5>
                                    <div className="col-12 col-xl-12 mt-xl-12 p-xl-2 mt-51d-flex1 align-items-center1">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: assessmentTemplate?.assessment_sections[
                                                    sectionIndex
                                                ].sd_description.replace(
                                                    /\n/g,
                                                    "<br>"
                                                ),
                                            }}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        {questions.map((question, index) => (
                            <div key={question.id}>
                                <Question
                                    setValue={setValue}
                                    answers={answers}
                                    auditMode={auditMode}
                                    watch={watch}
                                    repeatIndex={null}
                                    childQuestion={false}
                                    sectionIndex={sectionIndex}
                                    index={index}
                                    key={question.id}
                                    question={question}
                                    errors={errors.questions}
                                    register={register}
                                    unregister={unregister}
                                    resetField={resetField}
                                    inputNameSuffix={`questions[${index}]`}
                                    totalSection={totalSection}
                                    assessmentSection={
                                        assessmentTemplate?.assessment_sections[
                                            sectionIndex
                                        ]
                                    }
                                    lengthOfQuestion={questions?.length ?? 0}
                                    onItemHover={handleItemHover}
                                    //setName={useParams.setName}
                                />
                                {totalSection - 1 === sectionIndex &&
                                auth.user &&
                                auth.user.admin === true ? (
                                    <Form.Group
                                        className="mb-4"
                                        controlId="formBasicTitle"
                                    >
                                        <Form.Label className="my-3 fw-bold">
                                            {t("common.form.admin_comment")}
                                        </Form.Label>
                                        <Form.Control
                                            disabled={false}
                                            as="textarea"
                                            {...register("admin_comment")}
                                            isInvalid={!!errors.admin_comment}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.admin_comment &&
                                                errors.admin_comment.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                ) : null}
                            </div>
                        ))}
                    </Card.Body>
                </Card>
                {responseError && (
                    <h6 className="text-danger my-4">{t(responseError)}</h6>
                )}
                {validationErrors.length !== 0 && (
                    <div className="form-group mb-2">
                        {validationErrors.map((error, index) => (
                            <p
                                className="text-danger font-weight-bold"
                                key={index}
                            >
                                {t(error)}
                            </p>
                        ))}
                    </div>
                )}
                <Form.Group className="d-flex justify-content-between mt-3 section-form  mx-auto mx-lg-5">
                    {auditMode ? (
                        <Link
                            to={`${LOCATION.ASSESSMENTS_AUDIT.LIST.path}`}
                            className="btn btn-secondary btn-sm"
                        >
                            <span>{t("pages.new_assessment.save_draft")}</span>
                        </Link>
                    ) : (
                        <Button
                            variant="secondary"
                            size="sm"
                            className=""
                            onClick={() => saveDraft()}
                        >
                            <span>{t("pages.new_assessment.save_draft")}</span>
                        </Button>
                    )}
                    {auditMode ? (
                        <>
                            <Link
                                to={`${LOCATION.ASSESSMENTS.LIST.path}/${assessment?.id}/1/report`}
                                className="btn btn-primary text-white btn-sm"
                            >
                                <span className="me-1">
                                    {t(
                                        "pages.assessments.buttons.got_to_ratings_report"
                                    )}
                                </span>
                                <ArrowRight />
                            </Link>
                            <button
                                type="submit"
                                ref={submitFormRef}
                                className="d-none"
                            ></button>
                        </>
                    ) : (
                        <LoadingButton
                            refName={submitFormRef}
                            variant="success"
                            size="sm"
                            type="submit"
                            className="text-white"
                            loading={submitting}
                            titleTranslationKey={
                                lastSection
                                    ? t(
                                          "pages.assessments.buttons.continue_next_step"
                                      )
                                    : t(
                                          "pages.assessments.buttons.continue_next_step"
                                      )
                            }
                            icon={<ArrowRight />}
                        />
                    )}
                </Form.Group>
            </Form>
        </>
    );
}

export default AssessmentSectionForm;
