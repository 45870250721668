function ExpandCollapseIcon  ({expanded}) {

  return (
<svg
    width="15"
    height="15"
    viewBox="0 0 20 20"
    className="expand-collapse"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {expanded ? (
      <>
        <rect x="8" width="4" height="20" rx="2" fill="black" />
        <rect x="2" y="8" width="16" height="4" rx="2" fill="black" />
      </>
    ) : (
      <rect x="2" y="8" width="16" height="4" rx="2" fill="black" />
    )}
  </svg>
  )

}

export default ExpandCollapseIcon
