import PageActionSection from "@/components/PageActionSection";
import { useTranslation } from "react-i18next";
import React, { useState, useRef } from "react";
import { useAuth } from "@/services/Auth";
import LOCATION from "@/constants/Location";
//import FormBuilder from './Forms/FormBuilder'
import { useHistory, useParams } from "react-router-dom";
import ArrowRight from "@/components/icons/ArrowRight";
import ArrowLeft from "@/components/icons/ArrowLeft";
import ConfirmModal from "@/components/modals/ConfirmModal";
import { getValidationErrors } from "@/Utility";
import { Button, Card, Form, Image } from "react-bootstrap";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
function Create(props) {
    const { t } = useTranslation();
    const auth = useAuth();
    const history = useHistory();

    const query = new URLSearchParams(props.location.search);
    const [validationErrors, setValidationErrors] = useState([]);

    const [showSuccess, setShowSuccess] = useState(false);
    const [title, setTitle] = useState("");

    const submitFormRef = useRef(null);

    const goToBack = () => {
        history.push(`${LOCATION.LICENSE_KEY.LIST.path}`);
    };

    const handleConfirm = () => {
        setShowSuccess(false);
        setTitle(null);
        history.push(`${LOCATION.LICENSE_KEY.LIST.path}`);
    };
    const requestSave = (values) => {
        setValidationErrors([]);
        auth.postRequest(LOCATION.LICENSE_KEY.CREATE.API, values)
            .then((response) => {
                setTitle(t("pages.license_key.form.license_key_created"));
                setShowSuccess(true);
            })
            .catch((error) => {
                setValidationErrors(getValidationErrors(error));
            });
    };

    const schema = yup.object().shape({
        license_key_no: yup
            .number()
            .typeError(
                t("common.form_validation.is_required", {
                    attribute: t("pages.license_key.form.license_key_no"),
                })
            )
            .required(
                t("common.form_validation.is_required", {
                    attribute: t("pages.license_key.form.license_key_no"),
                })
            )
            .integer(
                t("common.form_validation.must_be_an_integer", {
                    attribute: t("pages.license_key.form.license_key_no"),
                })
            )
            .min(
                1,
                t("common.form_validation.must_be_greater_than_or_equal_to", {
                    attribute: t("pages.license_key.form.license_key_no"),
                    value: 1,
                })
            )
            .max(
                100,
                t("common.form_validation.must_be_less_than_or_equal_to", {
                    attribute: t("pages.license_key.form.license_key_no"),
                    value: 100,
                })
            ),
        credits: yup
            .number()
            .typeError(
                t("common.form_validation.is_required", {
                    attribute: t("pages.license_key.form.credits"),
                })
            )
            .required(
                t("common.form_validation.is_required", {
                    attribute: t("pages.license_key.form.credits"),
                })
            )
            .integer(
                t("common.form_validation.must_be_an_integer", {
                    attribute: t("pages.license_key.form.credits"),
                })
            )
            .min(
                1,
                t("common.form_validation.must_be_greater_than_or_equal_to", {
                    attribute: t("pages.license_key.form.license_key_no"),
                    value: 1,
                })
            ),
    });
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm({
        defaultValues: {
            license_key_no: "",
            credits: "",
        },
        resolver: yupResolver(schema),
    });

    const onSave = (values) => {
        requestSave(values);
    };
    return (
        <>
            <ConfirmModal
                show={showSuccess}
                title={title}
                confirmText={t("common.dialogs.actions.okay")}
                handleConfirm={handleConfirm}
            />

            <PageActionSection
                prepend={[
                    <Button variant="dark" size="sm" onClick={() => goToBack()}>
                        <ArrowLeft />
                        <span className="ms-1">
                            {t("pages.license_key.button.back")}
                        </span>
                    </Button>,
                ]}
                title={t("pages.license_key.new_licensekey_title")}
                type="dark"
            />
            <div className="content">
                <Form noValidate onSubmit={handleSubmit(onSave)}>
                    <Card className="border-0 radius-0 section-form mx-auto mx-lg-5">
                        <Card.Body>
                            <Form.Group
                                className="mb-4 mt-4"
                                controlId="formBasicTitle"
                            >
                                <Form.Label>
                                    {t("pages.license_key.form.license_key_no")}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder={t(
                                        "pages.license_key.form.placeholder_license_key_no"
                                    )}
                                    {...register("license_key_no")}
                                    isInvalid={!!errors.license_key_no}
                                    min="0"
                                    max="100"
                                />

                                <Form.Control.Feedback type="invalid">
                                    {errors.license_key_no &&
                                        errors.license_key_no.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                className="mb-4 mt-4"
                                controlId="formBasicTitle"
                            >
                                <Form.Label>
                                    {t("pages.license_key.form.credits")}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder={t(
                                        "pages.license_key.form.placeholder_credits"
                                    )}
                                    {...register("credits")}
                                    isInvalid={!!errors.credits}
                                    min="0"
                                    max="100"
                                />

                                <Form.Control.Feedback type="invalid">
                                    {errors.credits && errors.credits.message}
                                </Form.Control.Feedback>
                            </Form.Group>

                            {validationErrors.length !== 0 && (
                                <div className="form-group mb-2">
                                    {validationErrors.map((error, index) => (
                                        <p
                                            className="text-danger font-weight-bold"
                                            key={index}
                                        >
                                            {t(error)}
                                        </p>
                                    ))}
                                </div>
                            )}
                        </Card.Body>
                    </Card>

                    <div className="d-flex justify-content-end mt-4 section-form  mx-auto mx-lg-5">
                        <Button
                            variant="secondary"
                            size="sm"
                            onClick={() => goToBack()}
                        >
                            <span className="ms-1">
                                {t("pages.license_key.button.back")}
                            </span>
                        </Button>
                        <Button
                            ref={submitFormRef}
                            variant="success"
                            size="sm"
                            type="submit"
                            className="text-white ms-4"
                        >
                            <span className="me-1">
                                {t("pages.license_key.button.save")}
                            </span>
                        </Button>
                    </div>
                </Form>
            </div>
        </>
    );
}

export default Create;
