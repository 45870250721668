import LOCATION from "@/constants/Location";

import DocumentTemplatePage from "@/views/pages/DocumentTemplate/DocumentTemplate";

const DocumentTemplate = [
    {
        path: LOCATION.DOCUMENT_TEMPLATE.LIST.path,
        component: DocumentTemplatePage,
        exact: true,
        translation_key: "pages.document_template.title",
        main: true,
        gate: "document_template",
    },
];
export default DocumentTemplate;
