import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import LOCATION from "@/constants/Location";
import Plus from "@/components/icons/Plus";
import { useAuth } from "@/services/Auth";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Modal } from "react-bootstrap";
import LoadingCover from "@/components/LoadingCover";
import UserCreditInformation from "../components/UserCreditInformation";
import CircleXMark from "@/components/icons/CircleXMark";
import ConfirmModal from "@/components/modals/ConfirmModal";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import LoadingButton from "../../components/LoadingButton";
function AssessmentTemplatesList({ title, ...props }) {
    const { t } = useTranslation();
    const auth = useAuth();

    const [assessmentTemplates, setAssessmentTemplates] = useState([]);
    const [competencyAssessment, setCompetencyAssessment] = useState(null);
    const [assessmentTemplateToDuplicate, setAssessmentTemplateToDuplicate] =
        useState(null);
    const [assessmentTemplateToDelete, setAssessmentTemplateToDelete] =
        useState(null);
    const [loading, setLoading] = useState(true);

    const [showDialog, setShowDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState(null);
    const [dialogBody, setDialogBody] = useState(null);
    const [confirmText, setConfirmText] = useState(
        t("common.dialogs.actions.okay")
    );
    const [cancelText, setCancelText] = useState(null);

    const competencyTemplates = props?.type === 2;

    const baseUrl = auth.api.defaults.baseURL;
    const listType = props?.listType ? props?.listType : "";
    const [credit, setCredit] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [errors, setErrors] = useState({});
    const [serverError, setServerError] = useState("");
    const [serverErrors, setServerErrors] = useState([]);
    const [formData, setFormData] = useState({
        id: "",
        credit: "",
        title: "",
    });

    const requestUpdateCredit = (assessmentData) => {
        if (assessmentData) {
            setShowDialog(true);
        }

        setErrors({});
        setServerError("");
        setServerErrors([]);
        setFormData({
            id: "",
            credit: "",
            title: "",
        });

        const { id, credit, title } = assessmentData;

        setFormData({
            ...formData,
            id,
            credit,
            title,
        });

        console.log("formData ", formData);
        setIsModalVisible(true);
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validationSchema = yup.object().shape({
        credit: yup
            .number()
            .min(
                0,
                t("common.form_validation.must_be_greater_than_or_equal_to", {
                    attribute: t("pages.my_assessments.form.credit"),
                    value: 0,
                })
            )
            .required(
                t("common.form_validation.is_required", {
                    attribute: t("pages.my_assessments.form.credit"),
                })
            ),
    });

    const handleUpdateCredit = async () => {
        try {
            setServerError("");
            await validationSchema.validate(formData, { abortEarly: false });
            setLoading(true);

            auth.postRequest(
                `${LOCATION.ASSESSMENT_TEMPLATES.SERVICE_CREDIT_UPDATE.API.path}`,
                formData
            )
                .then((response) => {
                    setLoading(false);
                    if (response.data.message === "OK") {
                        setIsModalVisible(false);
                        setAssessmentTemplateToDuplicate(null);
                        setDialogTitle(
                            t("pages.my_assessments.credit_updated_success")
                        );
                        setDialogBody(null);
                        setConfirmText(null);
                        setCancelText(t("common.okay"));
                        setShowDialog(true);
                        getAssessmentTemplates();
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    if (error?.response?.status === 401) {
                        setServerError(error.response.data.message);
                    }

                    if (error.response.status === 422) {
                        let serverErrors = [];
                        for (const key in error.response.data.errors) {
                            serverErrors.push(
                                error.response.data.errors[key][0]
                            );
                        }
                        setServerErrors(serverErrors);
                    }
                });
        } catch (error) {
            const validationErrors = {};
            error.inner.forEach((e) => {
                validationErrors[e.path] = e.message;
            });
            setErrors(validationErrors);
        }
    };
    const getDefaultImage = (e) => {
        e.target.src = `/assets/images/placeholder.png`;
    };

    const requestDuplicateAssessment = (assessment) => {
        if (assessment) {
            setAssessmentTemplateToDuplicate(assessment);
            setDialogTitle(t("pages.assessments.duplicate_assessment"));
            setDialogBody(
                t("pages.assessments.duplicate_assessment_statement", {
                    attribute: assessment.title,
                })
            );
            setConfirmText(t("pages.assessments.buttons.duplicate"));
            setCancelText(t("pages.assessments.buttons.cancel"));
            setShowDialog(true);
        }
    };

    const handleConfirm = () => {
        if (assessmentTemplateToDuplicate) {
            duplicateAssessment();
        }
        handleCancel();
    };

    const handleCancel = () => {
        setShowDialog(false);
        setConfirmText(null);
        setCancelText(null);
        setIsModalVisible(false);
    };

    const duplicateAssessment = () => {
        if (assessmentTemplateToDuplicate?.id) {
            auth.postRequest(
                `${LOCATION.ASSESSMENT_TEMPLATES.API.path}/${assessmentTemplateToDuplicate.id}/duplicate`
            )
                .then((response) => {
                    setAssessmentTemplateToDuplicate(null);
                    getAssessmentTemplates();
                })
                .catch((error) => {});
        }
    };

    const getAssessmentTemplates = () => {
        let getUrl =
            listType === ""
                ? `${LOCATION.ASSESSMENT_TEMPLATES.API.path}?type=${
                      competencyTemplates ? 2 : 1
                  }`
                : `${LOCATION.ASSESSMENT_TEMPLATES.API.path}`;

        let request = auth.getRequest(getUrl);

        request
            .then((response) => {
                setAssessmentTemplates(response.data.data);
                setLoading(false);
            })
            .catch((error) => {});

        return request;
    };

    const getCompetencyAssessment = () => {
        let request = auth.getRequest(
            `${LOCATION.ASSESSMENTS.API.path}?type=2`
        );

        request
            .then((response) => {
                setCompetencyAssessment(
                    response.data.data.length !== 0
                        ? response.data.data[0]
                        : null
                );
            })
            .catch((error) => {});

        return request;
    };

    useEffect(() => {
        let request = getAssessmentTemplates();

        return () => request?.abort && request.abort();
    }, []);

    useEffect(() => {
        if (competencyTemplates) {
            let request = getCompetencyAssessment();

            return () => request?.abort && request.abort();
        }
    }, []);

    if (loading) {
        return <LoadingCover />;
    }

    if (!auth.user.admin && !auth.user.organization_id) {
        return (
            <div className="container request-sent d-flex justify-content-center align-items-center h-100">
                <Card className="border-0">
                    <Card.Body className="text-center">
                        <CircleXMark />
                        <h3 className="mt-4">
                            {t("common.errors.organization.title")}
                        </h3>
                        <p className="my-4">
                            {t("common.errors.organization.message")}
                        </p>
                        <Link
                            className="btn btn-primary text-white"
                            to={LOCATION.My_ACCOUNT.LIST.path}
                        >
                            {t("common.errors.buttons.set_organization")}
                        </Link>
                    </Card.Body>
                </Card>
            </div>
        );
    }

    if (
        auth.user.client &&
        auth.user.remaining_tokens <= 0 &&
        auth.user.subscription_status != "active" &&
        !competencyTemplates
    ) {
        return (
            <Redirect
                to={{
                    pathname: `${LOCATION.PAYMENTS.PRICING_AND_PLANS.path}/1`,
                    state: { from: props.location },
                }}
            />
        );
    }

    return (
        <>
            <ConfirmModal
                show={showDialog}
                title={dialogTitle}
                body={dialogBody}
                confirmText={confirmText}
                cancelText={cancelText}
                handleConfirm={handleConfirm}
                handleCancel={handleCancel}
            />

            <div className="content">
                <div className="section-header d-flex justify-content-between align-items-start flex-column flex-md-row mb-4">
                    <div className="d-flex flex-column">
                        <h4 className="fw-bold">{t(title)}</h4>
                        {!auth.user.admin && competencyTemplates && (
                            <small className="mt-2">
                                {t(
                                    "pages.competency_templates.you_need_to_pass"
                                )}
                            </small>
                        )}
                    </div>
                    <div className="d-flex align-items-center mt-2 mt-md-0">
                        {!auth.user.admin && !competencyTemplates && (
                            <UserCreditInformation />
                        )}
                        {!auth.user.admin && competencyTemplates && (
                            <div className="d-flex flex-md-row flex-column align-items-center">
                                <small className="my-0 fw-semibold">
                                    {t(
                                        "pages.competency_templates.this_does_not_cost_any_tokes"
                                    )}
                                </small>
                                {competencyAssessment && (
                                    <Link
                                        to={`${LOCATION.ASSESSMENTS.LIST.path}/${competencyAssessment?.id}/report`}
                                        className="btn btn-primary btn-sm ms-md-2 ms-0 mt-2 mt-md-0 px-4"
                                    >
                                        {t(
                                            "pages.assessments.buttons.your_competency_report"
                                        )}
                                    </Link>
                                )}
                            </div>
                        )}

                        {auth.user.admin && listType === "" && (
                            <div className="d-grid gap-2 w-100">
                                <Link
                                    to={`${
                                        LOCATION.ASSESSMENT_TEMPLATES.CREATE
                                            .path
                                    }?type=${competencyTemplates ? 2 : 1}`}
                                    className="btn btn-primary text-white"
                                >
                                    <span className="me-1">
                                        {t(
                                            "pages.assessment_templates.create_new_assessment"
                                        )}
                                    </span>
                                    <Plus />
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
                <Row>
                    {assessmentTemplates.map((assessment) => {
                        return (
                            <Col
                                className="col-12 col-md-6 col-lg-4 mb-5"
                                key={assessment.id}
                            >
                                <Card style={{ width: "100%" }}>
                                    <Card.Img
                                        className="template-featured-image"
                                        variant="top"
                                        src={`${baseUrl}/${assessment.featured_image}`}
                                        onError={(e) => getDefaultImage(e)}
                                    />
                                    <Card.Body>
                                        <Card.Title className="fw-bold my-3">
                                            {assessment.title}
                                        </Card.Title>
                                        <Card.Text>
                                            {assessment.description}{" "}
                                        </Card.Text>
                                        {listType === "" ? (
                                            auth.user.admin ? (
                                                <Row>
                                                    <Col className="col-12 col-xl-6 pb-3 pb-xl-0">
                                                        <div className="d-grid gap-2">
                                                            <Link
                                                                to={`${LOCATION.ASSESSMENT_TEMPLATES.LIST.path}/${assessment.id}/edit`}
                                                                className="btn btn-primary text-white"
                                                            >
                                                                {t(
                                                                    "pages.assessments.edit_assessment"
                                                                )}
                                                            </Link>
                                                        </div>
                                                    </Col>
                                                    <Col className="col-12 col-xl-6 d-flex align-items-center">
                                                        <div className="d-grid gap-2 w-100">
                                                            <Button
                                                                variant="outline-primary"
                                                                onClick={() =>
                                                                    requestDuplicateAssessment(
                                                                        assessment
                                                                    )
                                                                }
                                                            >
                                                                {t(
                                                                    "pages.assessments.duplicate_assessment"
                                                                )}
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            ) : (
                                                <div className="d-grid gap-2">
                                                    <Link
                                                        to={`${LOCATION.ASSESSMENTS.CREATE.path}/${assessment.id}`}
                                                        className="btn btn-primary text-white"
                                                    >
                                                        {t(
                                                            "pages.my_assessments.new_assessment_begin"
                                                        )}
                                                    </Link>
                                                </div>
                                            )
                                        ) : (
                                            <Row>
                                                <Col className="col-12 col-xl-6 d-flex align-items-center">
                                                    <div className="d-grid gap-2 w-100">
                                                        <div className="d-flex fw-bold me-5 w-100 px-4 px-md-0">
                                                            <span>
                                                                {t(
                                                                    "pages.my_assessments.credits"
                                                                )}
                                                                :&nbsp;
                                                            </span>
                                                            <span className="text-danger text-nowrap">
                                                                {
                                                                    assessment.credits
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col className="col-12 col-xl-6 pb-3 pb-xl-0">
                                                    <div className="d-grid gap-2">
                                                        <Button
                                                            variant="outline-primary"
                                                            onClick={() =>
                                                                requestUpdateCredit(
                                                                    assessment
                                                                )
                                                            }
                                                        >
                                                            {t(
                                                                "pages.my_assessments.update_credit"
                                                            )}
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        )}

                                        {/* {auth.user.admin ? (
                                            <Row>
                                                <Col className="col-12 col-xl-6 pb-3 pb-xl-0">
                                                    <div className="d-grid gap-2">
                                                        <Link
                                                            to={`${LOCATION.ASSESSMENT_TEMPLATES.LIST.path}/${assessment.id}/edit`}
                                                            className="btn btn-primary text-white"
                                                        >
                                                            {t(
                                                                "pages.assessments.edit_assessment"
                                                            )}
                                                        </Link>
                                                    </div>
                                                </Col>
                                                <Col className="col-12 col-xl-6 d-flex align-items-center">
                                                    <div className="d-grid gap-2 w-100">
                                                        <Button
                                                            variant="outline-primary"
                                                            onClick={() =>
                                                                requestDuplicateAssessment(
                                                                    assessment
                                                                )
                                                            }
                                                        >
                                                            {t(
                                                                "pages.assessments.duplicate_assessment"
                                                            )}
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        ) : (
                                            <div className="d-grid gap-2">
                                                <Link
                                                    to={`${LOCATION.ASSESSMENTS.CREATE.path}/${assessment.id}`}
                                                    className="btn btn-primary text-white"
                                                >
                                                    {t(
                                                        "pages.my_assessments.new_assessment_begin"
                                                    )}
                                                </Link>
                                            </div>
                                        )} */}
                                    </Card.Body>
                                </Card>
                            </Col>
                        );
                    })}
                </Row>
            </div>
            <Modal show={isModalVisible} onHide={handleCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {formData.title}
                        {/* {t("pages.my_assessments.update_credit") } */}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <input type="hidden" name="id" value={formData.id} />
                        <Form.Group
                            className="mb-4 mt-4"
                            controlId="formFirstName"
                        >
                            <Form.Label>
                                {t("pages.my_assessments.form.credit")}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t(
                                    "pages.my_assessments.form.placeholder_credit"
                                )}
                                name="credit"
                                value={formData.credit}
                                onChange={handleInputChange}
                                isInvalid={!!errors.credit}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.credit}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form>

                    {serverError !== "" && (
                        <div className="form-group mb-2">
                            <p className="text-danger py-1 mb-0">
                                {t(serverError)}
                            </p>
                        </div>
                    )}
                    {serverErrors.length !== 0 && (
                        <div className="form-group mb-2">
                            {serverErrors.map((error, index) => (
                                <p
                                    className="text-danger font-weight-bold"
                                    key={index}
                                >
                                    {t(error)}
                                </p>
                            ))}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCancel}>
                        {t("common.cancel")}
                    </Button>
                    <LoadingButton
                        className="auth-btn text-white px-5"
                        loading={loading}
                        variant="primary"
                        titleTranslationKey="common.save"
                        type="button"
                        onSubmit={handleUpdateCredit}
                    />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AssessmentTemplatesList;
