import {
    Button,
    Form,
    Image,
    Col,
    Row,
    Accordion,
    Card,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/services/Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EyeFill from "@/components/icons/EyeFill";
function SupportiveDocumentField({
    type,
    watch,
    register,
    setValue,
    questionIndex,
    question,
    inputNameSuffix,
    errors,
    sectionIndex,
    answer,
    auditMode,
    unregister,
    assessmentSection,
    lengthOfQuestion,
    //selectedChoice,
    //setSelectedChoice
    onItemHover,
}) {
    const auth = useAuth();
    const { t } = useTranslation();
    const [selectedChoice, setSelectedChoice] = useState();
    const [explainOther, setExplainOther] = useState(false);
    const [userScore, setUserScore] = useState(null);
    const [otherAnswer, setOtherAnswer] = useState(null);

    const rangeValue = watch(`${inputNameSuffix}.range_value`);
    const checkedValue = watch(`${inputNameSuffix}.value`);
    const baseUrl = auth.api.defaults.baseURL;
    const baseUrlPDf = process.env.REACT_APP_API_PDF_URL;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [likeList, setLikeList] = useState("");
    //console.log("question  ", question);
    useEffect(() => {
        if (answer && !selectedChoice) {
            if (question.select_multiple) {
                let choice = question.question_choices
                    .filter((_) =>
                        answer?.choice?.map((_) => _.answer).includes(_.answer)
                    )
                    ?.map((_) => `${_.id}`);
                setValue(`${inputNameSuffix}.value`, choice);
                //setRecommendationArray(`${inputNameSuffix}.value`, choice);
            } else {
                let choice = question.question_choices.find(
                    (_) => _.answer === answer?.choice?.answer
                );
                console.log(" choice ", choice);
                if (choice) {
                    setValue(`${inputNameSuffix}.value`, `${choice.id}`);
                }
                setValue(
                    `${inputNameSuffix}.range_value`,
                    answer?.answer?.range_value
                );
            }
        } else {
            setValue(`${inputNameSuffix}.range_value`, null);
        }
    }, [answer]);

    useEffect(() => {
        register(`${inputNameSuffix}.value`);
    });

    useEffect(() => {
        register(`${inputNameSuffix}.explain_other`);
        register(`${inputNameSuffix}.add_own_rating`);

        if (selectedChoice?.answer !== "Other") {
            setValue(`${inputNameSuffix}.explain_other`, "false");
            setValue(`${inputNameSuffix}.add_own_rating`, "false");
        } else if (selectedChoice?.answer !== "Other" && !question.add_other) {
            unregister(`${inputNameSuffix}.other_answer`);
            setExplainOther(false);
        } else if (answer?.answer && answer?.answer?.other_answer) {
            if (answer?.answer?.user_choice_score) {
                setValue(
                    `${inputNameSuffix}.user_choice_score`,
                    answer.answer.user_choice_score
                );
                setUserScore(answer.answer.user_choice_score);
            }

            setValue(
                `${inputNameSuffix}.other_answer`,
                answer.answer.other_answer
            );
            if (selectedChoice?.score === 20) {
                setValue(`${inputNameSuffix}.add_own_rating`, "true");
            }
            setOtherAnswer(answer.answer.other_answer);
            setExplainOther(answer.answer.explain_other);
        } else if (selectedChoice?.answer === "Other" && question.add_other) {
            setValue(`${inputNameSuffix}.explain_other`, "true");

            if (selectedChoice?.score === 20) {
                setValue(`${inputNameSuffix}.add_own_rating`, "true");
            }
        }
    }, [selectedChoice, question, answer]);

    useEffect(() => {
        if (checkedValue) {
            setSelectedChoice(
                question.question_choices.find((_) => _.id == checkedValue)
            );
        }
    }, [checkedValue]);

    const handleChoiceChange = (e) => {
        const selectedChoiceId = e.target.value;
        const queAnswer = question.question_choices.find(
            (_) => _.id == selectedChoiceId
        );
        //setSelectedChoice(selectedChoice);
    };
    const handleItemClick = (choice) => {
        let pdfUrl = choice.pdf;

        if (pdfUrl !== null) {
            pdfUrl = pdfUrl.replace("public/", "");
        }

        pdfUrl = `${baseUrlPDf}${pdfUrl}`;
        onItemHover(pdfUrl);
    };
    const allAccordionItems = [];
    for (let i = 0; i < lengthOfQuestion; i++) {
        allAccordionItems.push(i);
    }
    allAccordionItems.push(1);
    return (
        <>
            <div
                className="container sd-container"
                style={{ maxWidth: "100%" }}
            >
                <Row>
                    <Col className="col-12 col-xl-12 mt-xl-12 p-xl-2 mt-51d-flex1 align-items-center1">
                        <Row>
                            <Accordion
                                defaultActiveKey={allAccordionItems}
                                alwaysOpen
                            >
                                <Accordion.Item eventKey={0}>
                                    <Accordion.Header className="sd-accordion-item">
                                        {question.label}
                                    </Accordion.Header>
                                    <Accordion.Body className="d-flex1">
                                        <Row>
                                            {question.question_choices.map(
                                                (choice, index) => (
                                                    <Col
                                                        className="col-md-4 col-sm-6 col-12"
                                                        key={"C" + index}
                                                    >
                                                        <Card className="border-0 my-2">
                                                            <Card.Body className="d-flex ">
                                                                <Form.Check
                                                                    disabled={
                                                                        auditMode
                                                                    }
                                                                    key={index}
                                                                    type={
                                                                        question.select_multiple
                                                                            ? "checkbox"
                                                                            : "radio"
                                                                    }
                                                                    name={`${inputNameSuffix}.value`}
                                                                    value={
                                                                        choice.id
                                                                    }
                                                                    {...register(
                                                                        `${inputNameSuffix}.value`
                                                                    )}
                                                                    label={
                                                                        choice.answer
                                                                    }
                                                                    className="sd-radio  mr-2 sd-radio-label"
                                                                    /* onChange={(
                                                                        e
                                                                    ) => {
                                                                        setValue(
                                                                            `${inputNameSuffix}.value`,
                                                                            e
                                                                                .target
                                                                                .value
                                                                        );
                                                                    }} */
                                                                />
                                                                &nbsp;&nbsp;&nbsp;
                                                                <div
                                                                    className="sd-radio-icon"
                                                                    onClick={() => handleItemClick(choice)}
                                                                    /* onMouseEnter={() => {
                                                                        let pdfUrl =
                                                                            choice.pdf;

                                                                        if (
                                                                            pdfUrl !==
                                                                            null
                                                                        ) {
                                                                            pdfUrl =
                                                                                pdfUrl.replace(
                                                                                    "public/",
                                                                                    ""
                                                                                );
                                                                        }

                                                                        pdfUrl =
                                                                            `${baseUrlPDf}` +
                                                                            `${pdfUrl}`;
                                                                        onItemHover(
                                                                            pdfUrl
                                                                        );
                                                                    }} */
                                                                >
                                                                    <EyeFill />
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                )
                                            )}
                                        </Row>
                                        {/* {question.question_choices.map(
                                            (choice, index) => (
                                                <Form.Check
                                                    disabled={auditMode}
                                                    key={index}
                                                    type={
                                                        question.select_multiple
                                                            ? "checkbox"
                                                            : "radio"
                                                    }
                                                    name={`${inputNameSuffix}.value`}
                                                    value={choice.id}
                                                    {...register(
                                                        `${inputNameSuffix}.value`
                                                    )}
                                                    label={choice.answer + " " +choice.pdf}
                                                    className="sd-radio col-md-4 col-sm-6 col-12 col"
                                                />
                                            )
                                        )} */}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Row>
                    </Col>
                </Row>
            </div>
            {errors && errors[questionIndex] && errors[questionIndex].value && (
                <div className="invalid-feedback d-block">
                    {errors[questionIndex].value.message}
                </div>
            )}
        </>
    );
}

export default SupportiveDocumentField;
