import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import * as moment from "moment";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/services/Auth";
import LOCATION from "@/constants/Location";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPencil } from "@fortawesome/free-solid-svg-icons";
function useColumns(filter, source,userToken) {
    let auth = useAuth();
    const { t } = useTranslation();

    let columns = [
        {
            dataField: "ad_id",
            text: t("common.tables_pdf_report_fire_safety.id"),
        },

        {
            dataField: "ad_whatisthenameofthebusinessoutlinedinthepolicy",
            text: t("common.tables_pdf_report_fire_safety.business_name"),
        },
    ];

    columns.push({
        dataField: "actions",
        text: "",
        formatter: (cell, row) => (
            <>
                <a
                    href={`${process.env.REACT_APP_PDF_REPORT_BASE_URL}${row.ad_id}/pdf/26/userToken${userToken}`}
                    className="text-success"
                >
                    <FontAwesomeIcon icon={faDownload} role="button" />
                </a>{" "}
                &nbsp;
                <Link
                    to={`/health-and-safety-policy?ad_id=${row.ad_id}`}
                    className="text-info"
                >
                    <FontAwesomeIcon icon={faPencil} role="button" />
                </Link>
            </>
        ),
    });
    return (filter && columns.filter(filter)) || columns;
}

function PdfReportHealthjSafetyPolicy({
    data: recordsProp,
    paginate,
    url,
    query,
    ...props
}) {
    let auth = useAuth();
    const { t } = useTranslation();

    const perPage = query.limit || 20;
    const exclude = props.exclude || [];
    const showPagination = paginate == null ? true : paginate;

    const request = useRef(null);
    const [page, setPage] = useState(0);
    const [totalRecords, setTotal] = useState(false);
    const [recordsDownloaded, setRecords] = useState(false);
    const [userToken, setUserToken] = useState(false);

    const getRecords = () => {
        request?.current?.abort && request.current.abort();
        const critera = { ...(query || {}) };
        request.current = auth.getRequest(
            url ||
                LOCATION.ASSESSMENTS.PDF_REPORTS.FIRE_DOOR_COMPLIANCE.API.path,
            critera
        );
        setRecords(false);
        request.current.then((response) => {
            setRecords(response.data.data);
            setTotal(response.data.length);
            setUserToken(response.data.t);
            if (props.updateStats) {
                props.updateStats(response.data.data);
            }
        });
    };
    const columns = useColumns(
        (_) => !exclude.includes(_?.dataField) && !exclude.includes(_?.name),
        props.source,
        userToken
    );
    const records = recordsProp || recordsDownloaded;
    useEffect(() => {
        if (!recordsProp) {
            getRecords();
        }

        return () => request?.current?.abort && request.current.abort();
    }, [page, query, url]);

    useEffect(() => {
        props.onData && props.onData(records);
    }, [records]);

    useEffect(() => {
        props.onTotal && props.onTotal(totalRecords);
    }, [totalRecords]);

    return (
        <div className="fra-table">
            <BootstrapTable
                keyField="ad_id"
                responsive
                hover
                columns={columns || []}
                data={records || []}
                noDataIndication={
                    records instanceof Array
                        ? t("common.no_data")
                        : t("common.loading")
                }
                {...props}
            />

        </div>
    );
}

export default PdfReportHealthjSafetyPolicy;

export { useColumns };
