import { useTranslation } from "react-i18next";
import { useAuth } from "@/services/Auth";
import LOCATION from "@/constants/Location";
import FormBuilder from "./Forms/FormBuilder";
import React, { useEffect, useState, useRef } from "react";
import PageActionSection from "@/components/PageActionSection";
import { useHistory, useParams } from "react-router-dom";
import LoadingCover from "@/components/LoadingCover";
import { Button } from "react-bootstrap";
import ArrowLeft from "@/components/icons/ArrowLeft";
import ArrowRight from "@/components/icons/ArrowRight";
import ConfirmModal from "@/components/modals/ConfirmModal";
import { getValidationErrors } from "@/Utility";
import LoadingButton from "@/components/LoadingButton";

function Edit() {
    const { t } = useTranslation();
    const auth = useAuth();

    let { id } = useParams();

    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [assessment, setAssessment] = useState(null);
    const [initialSection, setInitialSection] = useState(null);
    const [sectionToDelete, setSectionToDelete] = useState(null);

    const [saveAsDraft, setSaveAsDraft] = useState(false);
    const [savingTemplate, setSavingTemplate] = useState(false);

    const [showSuccess, setShowSuccess] = useState(false);
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");

    const [confirmText, setConfirmText] = useState(
        t("common.dialogs.actions.delete")
    );
    const [cancelText, setCancelText] = useState(
        t("common.dialogs.actions.cancel")
    );
    const [validationErrors, setValidationErrors] = useState([]);

    const submitFormRef = useRef(null);

    const saveDraft = () => {
        setSaveAsDraft(true);
        submitFormRef.current.click();
    };

    const requestSave = (values) => {
        if (!savingTemplate) {
            setSavingTemplate(true);

            setValidationErrors([]);
            let form = new FormData();
            form.append("title", values.title);
            form.append("description", values.description);
            form.append("featured_image_file", values.featured_image);

            let request = auth.putRequest(
                `${LOCATION.ASSESSMENT_TEMPLATES.API.path}/${id}`,
                values
            );

            request
                .then((response) => {
                    setAssessment(response.data);
                    setTitle(
                        t(
                            "pages.assessment_templates.dialogs.titles.template_updated"
                        )
                    );
                    setConfirmText(t("common.dialogs.actions.okay"));
                    setCancelText(null);
                    setBody(null);
                    setShowSuccess(true);

                    setSavingTemplate(false);
                })
                .catch((error) => {
                    setValidationErrors(getValidationErrors(error));
                });
        }
    };

    const saveSection = (values) => {
        if (!savingTemplate) {
            setSavingTemplate(true);
            let sectionId = values.id;
            let payload = {
                name: values.name,
                section_id: values.section_id,
                revision_id: values.revision_id,
                weight: values.weight,
                assessment_template_id: assessment.id,
                questions: values.questions,
                order: values.order,
                section_type: values.section_type,
                sd_title: values.sd_title,
                sd_description: values.sd_description,
            };
            setSavingTemplate(false);
            //console.log("== payload ", payload);return false;
            const currentSectionIndex =
                assessment.assessment_sections.findIndex(
                    (_) => _.id === values.id
                );

            auth.postRequest(LOCATION.ASSESSMENT_SECTION.API.path, payload)
                .then((response) => {
                    setTitle(
                        t(
                            "pages.assessment_templates.dialogs.titles.template_updated"
                        )
                    );
                    setBody(null);
                    setCancelText(null);
                    setConfirmText(t("common.dialogs.actions.okay"));
                    setShowSuccess(true);
                    setSectionToDelete(null);

                    getAssessmentTemplate(currentSectionIndex);

                    setSavingTemplate(false);
                })
                .catch((error) => {});
        }
    };

    const getSection = (sectionId, oldSection) => {
        auth.getRequest(`${LOCATION.ASSESSMENT_SECTION.API.path}/${sectionId}`)
            .then((response) => {
                let sections = assessment.assessment_sections.filter(
                    (_) => _.id !== oldSection
                );
                sections.push(response.data);
                assessment.assessment_sections = sections;
                setAssessment({ ...assessment });
                setInitialSection(assessment.assessment_sections.length - 1);
            })
            .catch((error) => {});
    };

    const confirmSectionDelete = (sectionId) => {
        setSectionToDelete(sectionId);
        setTitle(
            t("pages.assessment_templates.dialogs.titles.confirm_heading")
        );
        setBody(t("pages.assessment_templates.dialogs.body.delete_section"));
        setConfirmText(t("common.dialogs.actions.delete"));
        setCancelText(t("common.dialogs.actions.cancel"));
        setShowSuccess(true);
    };

    const deleteSection = (sectionId) => {
        setSectionToDelete(sectionId);
        auth.deleteRequest(
            `${LOCATION.ASSESSMENT_SECTION.API.path}/${sectionId}`
        )
            .then((response) => {
                getAssessmentTemplate();
            })
            .catch((error) => {});
    };
    const handleConfirm = () => {
        setShowSuccess(false);
        setTitle(null);
        if (sectionToDelete) {
            deleteSection(sectionToDelete);
        }
        if (saveAsDraft) {
            history.push(`${LOCATION.ROOT.path}`);
        }
    };
    const handleCancel = () => {
        setShowSuccess(false);
        setSectionToDelete(null);
        setTitle(null);
    };
    const refClick = () => {
        submitFormRef.current.click();
    };

    const getAssessmentTemplate = (currentSectionIndex) => {
        setLoading(true);
        let request = auth.getRequest(
            `${LOCATION.ASSESSMENT_TEMPLATES.API.path}/${id}`
        );
        request
            .then((response) => {
                setAssessment(response.data);
                setInitialSection(currentSectionIndex ?? null);
                setLoading(false);
            })
            .catch((error) => {});

        return request;
    };

    useEffect(() => {
        let request = getAssessmentTemplate(null);
        return () => request?.abort && request.abort();
    }, []);

    if (loading) {
        return <LoadingCover />;
    }

    return (
        <>
            <ConfirmModal
                show={showSuccess}
                title={title}
                body={body}
                confirmText={confirmText}
                cancelText={cancelText}
                handleConfirm={handleConfirm}
                handleCancel={handleCancel}
            />

            <PageActionSection
                prepend={[
                    <Button
                        variant="dark"
                        size="sm"
                        onClick={() => saveDraft()}
                    >
                        <ArrowLeft />
                        <span className="ms-1">
                            {t("pages.new_assessment.save_draft")}
                        </span>
                    </Button>,
                ]}
                append={[
                    <LoadingButton
                        loading={savingTemplate}
                        variant="success"
                        onSubmit={refClick}
                        size="sm"
                        type="submit"
                        className={`text-white`}
                        titleTranslationKey={t(
                            "pages.new_assessment.save_continue"
                        )}
                        icon={<ArrowRight />}
                    />,
                ]}
                title={assessment.title}
                type="dark"
            />
            <div className="content">
                <FormBuilder
                    deleteSection={confirmSectionDelete}
                    assessment={assessment}
                    requestSave={requestSave}
                    saveSection={saveSection}
                    initialSection={initialSection}
                    saveDraft={saveDraft}
                    submitFormRef={submitFormRef}
                    validationErrors={validationErrors}
                    savingTemplate={savingTemplate}
                />
            </div>
        </>
    );
}

export default Edit;
