import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useAuth } from "@/services/Auth";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import ConfirmModal from "@/components/modals/ConfirmModal";
import LoadingButton from "@/components/LoadingButton";
import LoadingCover from "@/components/LoadingCover";
import { ColorPicker } from "primereact/colorpicker";
import LOCATION from "@/constants/Location";
import InputColor from "react-input-color";

function CustomisingForms() {
    const { t } = useTranslation();
    const auth = useAuth();

    const customisingFields = {
        headerbar_bg_color: "",
        headerbar_text_color: "",
        section_titlebar_bg_color: "",
        section_titlebar_text_color: "",
    };

    const [customisingData, setCustomisingData] = useState(customisingFields);
    const [organization, setOrganization] = useState(null);
    const [updatingDetails, setUpdatingDetails] = useState(false);
    const [loading, setLoading] = useState(true);

    const [showSuccess, setShowSuccess] = useState(false);
    const [title, setTitle] = useState("");

    const schema = yup.object().shape({
        //headerbar_bg_color: yup.string(),
        //headerbar_text_color: yup.string(),
        //section_titlebar_bg_color: yup.string(),
        //section_titlebar_text_color: yup.string(),
    });
    const [color, setColor] = React.useState({});
    const {
        handleSubmit,
        register,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: customisingFields,
        resolver: yupResolver(schema),
    });
    // Get the organization data and preselected values for customizing fields
    const getOrganization = async (organizationId) => {
        try {
            setLoading(true);
            if (organizationId === null) {
                setLoading(false);
                return false;
            }
            const response = await auth.getRequest(
                `${LOCATION.ORGANIZATIONS.API.path}/${organizationId}`
            );
            const organizationData = response.data;
            setOrganization(organizationData);

            setCustomisingData({
                headerbar_bg_color: organizationData.headerbar_bg_color || "",
                headerbar_text_color: organizationData.headerbar_text_color || "",
                section_titlebar_bg_color: organizationData.section_titlebar_bg_color || "",
                section_titlebar_text_color: organizationData.section_titlebar_text_color || "",
              });


            reset(organizationData);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        getOrganization(auth.user?.organization_id);
    }, [auth.user, reset]);

    const onSave = async (values) => {
        setUpdatingDetails(true);

        const data = {
            ...customisingData,
            //headerbar_bg_color: values.headerbar_bg_color,
            //headerbar_text_color: values.headerbar_text_color,
        };
        try {
            const request = await auth.putRequest(
                `${LOCATION.ORGANIZATIONS.CUSTOMISE.API.path}/${organization.id}`,
                data
            );

            setShowSuccess(true);
            setTitle(t("common.dialogs.titles.customise_form_setting_updated"));
        } catch (error) {
            // Handle errors, e.g., display an error message
        }

        setUpdatingDetails(false);
    };

    const handleConfirm = () => {
        setShowSuccess(false);
        setTitle(null);
    };

    useEffect(() => {
        // After fetching data, set the initial values
        if (organization) {
            setCustomisingData({
                headerbar_bg_color: organization.headerbar_bg_color || "",
                headerbar_text_color: organization.headerbar_text_color || "",
                section_titlebar_bg_color: organization.section_titlebar_bg_color || "",
                section_titlebar_text_color: organization.section_titlebar_text_color || "",
              });

        }
    }, [organization]);
    if (loading) {
        return <LoadingCover />;
    }

    return (
        <>
            <ConfirmModal
                show={showSuccess}
                title={title}
                confirmText={t("common.dialogs.actions.okay")}
                handleConfirm={handleConfirm}
            />

            <Row className="mt-2">
                <Col className="col-12">
                    <h5 className="fw-bold">
                        {t("pages.my_account.customising_the_forms")}
                    </h5>
                    <p className="my-4">
                        {t("pages.my_account.customising_the_forms_message")}
                    </p>

                    <Form
                        noValidate
                        onSubmit={handleSubmit(onSave)}
                        className="row"
                    >
                        {/* <ColorPicker
                                className="form-control1 col-6"
                                style={{
                                    maxWidth: "15%",
                                    marginLeft: "10px",
                                }}
                                value={customisingData.headerbar_bg_color}
                                onChange={(e) => {
                                    setCustomisingData({
                                        ...customisingData,
                                        headerbar_bg_color: e.value,
                                    });
                                }}
                            /> */}
                        <Form.Group
                            className="col  col-12 mt-3"
                            controlId="formBasicMessage"
                        >
                            <div className="row">
                                <Form.Label className="col-3">
                                    {t("common.form.headerbar_bg_color")}
                                </Form.Label>

                                <div className="col-6 d-flex align-items-center">
                                    <InputColor
                                        className="form-control1 col-6"
                                        style={{
                                            maxWidth: "15%",
                                            marginLeft: "10px",
                                        }}
                                        initialValue={
                                            customisingData.headerbar_bg_color
                                        }
                                        onChange={(e) => {
                                            setCustomisingData({
                                                ...customisingData,
                                                headerbar_bg_color: e.hex,
                                            });
                                        }}
                                    />
                                    {/* <div
                                style={{
                                width: 50,
                                height: 50,
                                marginTop: 20,
                                backgroundColor: color.rgba,
                                }}
                            /> */}
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    {errors.headerbar_bg_color &&
                                        errors.headerbar_bg_color.message}
                                </Form.Control.Feedback>
                            </div>
                        </Form.Group>
                        <Form.Group
                            className="col col-12 mt-3"
                            controlId="formBasicMessage"
                        >
                            <div className="row">
                                <Form.Label className="col-3">
                                    {t("common.form.headerbar_text_color")}
                                </Form.Label>
                                {/* <ColorPicker
                                className="form-control1"
                                style={{
                                    maxWidth: "15%",
                                    marginLeft: "10px",
                                }}
                                value={customisingData.headerbar_text_color}
                                onChange={(e) => {
                                    setCustomisingData({
                                        ...customisingData,
                                        headerbar_text_color: e.value,
                                    });
                                }}
                            /> */}
                                <div className="col-6 d-flex align-items-center">
                                    <InputColor
                                        className="form-control1 col-6"
                                        style={{
                                            maxWidth: "15%",
                                            marginLeft: "10px",
                                        }}
                                        initialValue={
                                            customisingData.headerbar_text_color
                                        }
                                        onChange={(e) => {
                                            setCustomisingData({
                                                ...customisingData,
                                                headerbar_text_color: e.hex,
                                            });
                                        }}
                                    />
                                    {/* <div
                                            style={{
                                            width: 50,
                                            height: 50,
                                            marginTop: 20,
                                            backgroundColor: color.rgba,
                                            }}
                                        /> */}
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    {errors.headerbar_text_color &&
                                        errors.headerbar_text_color.message}
                                </Form.Control.Feedback>
                            </div>
                        </Form.Group>

                        <Form.Group
                            className="col col-12 mt-3"
                            controlId="formBasicMessage"
                        >
                            <div className="row">
                                <Form.Label className="col-3">
                                    {t("common.form.section_titlebar_bg_color")}
                                </Form.Label>
                                {/* <ColorPicker
                                    className="form-control1"
                                    style={{
                                        maxWidth: "15%",
                                        marginLeft: "10px",
                                    }}
                                    value={
                                        customisingData.section_titlebar_bg_color
                                    }
                                    onChange={(e) => {
                                        setCustomisingData({
                                            ...customisingData,
                                            section_titlebar_bg_color: e.value,
                                        });
                                    }}
                                /> */}
                                <div className="col-6 d-flex align-items-center">
                                    <InputColor
                                        className="form-control1 col-6"
                                        style={{
                                            maxWidth: "15%",
                                            marginLeft: "10px",
                                        }}
                                        initialValue={
                                            customisingData.section_titlebar_bg_color
                                        }
                                        onChange={(e) => {
                                            setCustomisingData({
                                                ...customisingData,
                                                section_titlebar_bg_color:
                                                    e.hex,
                                            });
                                        }}
                                    />
                                    {/* <div
                                            style={{
                                            width: 50,
                                            height: 50,
                                            marginTop: 20,
                                            backgroundColor: color.rgba,
                                            }}
                                        /> */}
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    {errors.section_titlebar_bg_color &&
                                        errors.section_titlebar_bg_color
                                            .message}
                                </Form.Control.Feedback>
                            </div>
                        </Form.Group>

                        <Form.Group
                            className="col col-12 mt-3"
                            controlId="formBasicMessage"
                        >
                            <div className="row">
                                <Form.Label className="col-3">
                                    {t(
                                        "common.form.section_titlebar_text_color"
                                    )}
                                </Form.Label>
                                {/* <ColorPicker
                                className="form-control1"
                                style={{
                                    maxWidth: "15%",
                                    marginLeft: "10px",
                                }}
                                value={
                                    customisingData.section_titlebar_text_color
                                }
                                onChange={(e) => {
                                    setCustomisingData({
                                        ...customisingData,
                                        section_titlebar_text_color: e.value,
                                    });
                                }}
                            /> */}
                                <div className="col-6 d-flex align-items-center">
                                    <InputColor
                                        className="form-control1 col-6"
                                        style={{
                                            maxWidth: "15%",
                                            marginLeft: "10px",
                                        }}
                                        initialValue={
                                            customisingData.section_titlebar_text_color
                                        }
                                        onChange={(e) => {
                                            setCustomisingData({
                                                ...customisingData,
                                                section_titlebar_text_color:
                                                    e.hex,
                                            });
                                        }}
                                    />
                                    {/* <div
                                            style={{
                                            width: 50,
                                            height: 50,
                                            marginTop: 20,
                                            backgroundColor: color.rgba,
                                            }}
                                        /> */}
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    {errors.section_titlebar_text_color &&
                                        errors.section_titlebar_text_color
                                            .message}
                                </Form.Control.Feedback>
                            </div>
                        </Form.Group>
                        <Form.Group className="col col-12 mt-4">
                            <div className="d-grid gap-2">
                                <LoadingButton
                                    type="submit"
                                    className="text-white mt-4"
                                    titleTranslationKey="common.submit"
                                    loading={updatingDetails}
                                />
                            </div>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        </>
    );
}

export default CustomisingForms;
