import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, Row, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoadingButton from "../../../components/LoadingButton";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "./custom.css";
import { useAuth } from "@/services/Auth";
function OccupancyCalculator(props) {
    const { t } = useTranslation();
    const auth = useAuth();
    const [loading, setLoading] = useState(false);

    const [serverError, setServerError] = useState("");
    const [serverErrors, setServerErrors] = useState([]);
    const [measureBy, setMeasureBy] = useState("People");
    const [metricBy, setMetricBy] = useState("Feet");
    const [isShowMetric, setisShowMetric] = useState(0);
    const [sizeNumber, setSizeNumber] = useState("");
    const [banquet_60, setBanquet_60] = useState("0");
    const [banquet_72, setBanquet_72] = useState("0");

    const [conference, setConference] = useState("0");
    const [classroom_18, setClassroom_18] = useState("0");
    const [classroom_30, setClassroom_30] = useState("0");

    const [theatre, setTheatre] = useState("0");
    const [receptionVal, setReceptionVal] = useState("0");
    const [trade_810, setTrade_810] = useState("0");
    const [trade_1010, setTrade_1010] = useState("0");

    const onOptionChangeMetric = (e) => {
        let m = e.target.value;
        setMetricBy(m);
        doCalculation(measureBy, sizeNumber, m);
    };
    const onOptionChange = (e) => {
        let m = e.target.value;
        setMeasureBy(e.target.value);
        if (m === "People") {
            setisShowMetric(0);
            setMetricBy("Feet");
        } else {
            setisShowMetric(1);
        }

        doCalculation(m, 0, metricBy);
    };
    const onSizeChange = (e) => {
        let s = e.target.value;
        if (isNaN(s) || s === "") {
            s = 0;
        }
        setSizeNumber(s);
        doCalculation(measureBy, s, metricBy);
    };

    const doCalculation = (measureBy, sizeNumber, metricBy) => {
        sizeNumber = parseInt(sizeNumber);
        if (measureBy !== "People") {
            if (metricBy === "Feet") {
                setBanquet_60(Math.floor(sizeNumber * 0.083));
                setBanquet_72(Math.floor(sizeNumber * 0.076));
                setConference(Math.floor(sizeNumber * 0.033));

                setClassroom_18(Math.floor(sizeNumber * 0.071));
                setClassroom_30(Math.floor(sizeNumber * 0.058));

                setTheatre(Math.floor(sizeNumber * 0.111));
                setReceptionVal(Math.floor(sizeNumber * 0.111));
                setTrade_810(Math.floor(sizeNumber * 0.006));
                setTrade_1010(Math.floor(sizeNumber * 0.005));
            } else {
                setBanquet_60(Math.floor(sizeNumber * 0.89));
                setBanquet_72(Math.floor(sizeNumber * 0.82));
                setConference(Math.floor(sizeNumber * 0.35));

                setClassroom_18(Math.floor(sizeNumber * 0.76));
                setClassroom_30(Math.floor(sizeNumber * 0.63));

                setTheatre(Math.floor(sizeNumber * 1.19));
                setReceptionVal(Math.floor(sizeNumber * 1.19));
                setTrade_810(Math.floor(sizeNumber * 0.06));
                setTrade_1010(Math.floor(sizeNumber * 0.05));
            }
        } else {
            let b60 = sizeNumber / 8.6;
            let b72 = sizeNumber / 4.8;
            setBanquet_60(Math.round(sizeNumber + b60));
            setBanquet_72(Math.ceil(sizeNumber + b72));

            setConference(Math.ceil(sizeNumber * 2.79));
            setClassroom_18(Math.ceil(sizeNumber * 1.31));
            setClassroom_30(Math.ceil(sizeNumber * 1.577));
            setTheatre(Math.ceil(sizeNumber * 0.84));
            setReceptionVal(Math.ceil(sizeNumber * 0.84));
            setTrade_810(Math.ceil(sizeNumber * 14.865));
            setTrade_1010(Math.round(sizeNumber * 18.6));
            /* setBanquet_60(0);
            setBanquet_72(0);

            setConference(0);
            setClassroom_18(0);
            setClassroom_30(0);
            setTheatre(0);
            setReceptionVal(0);
            setTrade_810(0);
            setTrade_1010(0); */
        }
    };
    //const baseUrl = auth.api.defaults.baseURL;
    //const [badgeOne] = useState("/assets/images/banquet-6.jpg");
    return (
        <div className="container">
            <div className=" d-flex flex-column align-items-center flex-grow-1">
                <div className="mt-5 p-2">
                    <h2 className="fw-bold text-primary">
                        {t("pages.occupancy_calculator.title")}
                    </h2>
                    <p>{t("pages.occupancy_calculator.description")}</p>
                </div>
            </div>
            <div className="row bg-white col1">
                <div
                    className="col-md-4  col-41  border-0 d-flex align-items-center1 justify-content-center left_one card"
                    id="Aa"
                >
                    <section className="main-section ">
                        <div
                            className="side-box"
                            style={{
                                background: "#fff",
                            }}
                        >
                            <Row>
                                <h2 className="box-title">Quick Calculator</h2>
                            </Row>
                            <p>Measure by</p>

                            <label className="container_lbl">
                                People
                                <input
                                    type="radio"
                                    name="measureBy"
                                    value="People"
                                    id="people"
                                    checked={measureBy === "People"}
                                    onChange={onOptionChange}
                                />
                                <span className="checkmark"></span>
                            </label>

                            <label className="container_lbl">
                                Room Size
                                <input
                                    type="radio"
                                    name="measureBy"
                                    value="Room Size"
                                    id="room_size"
                                    checked={measureBy === "Room Size"}
                                    onChange={onOptionChange}
                                />
                                <span className="checkmark"></span>
                            </label>

                            <div className={isShowMetric === 0 ? "hidden" : ""}>
                                <p>Metric or Imperial?</p>
                                <label className="container_lbl">
                                    Feet
                                    <input
                                        type="radio"
                                        name="metric"
                                        value="Feet"
                                        id="feet"
                                        checked={metricBy === "Feet"}
                                        onChange={onOptionChangeMetric}
                                    />
                                    <span className="checkmark"></span>
                                </label>

                                <label className="container_lbl">
                                    Metres
                                    <input
                                        type="radio"
                                        name="metric"
                                        value="Metres"
                                        id="metres"
                                        checked={metricBy === "Metres"}
                                        onChange={onOptionChangeMetric}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <Row>
                                <Form.Group
                                    className="mb-4"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Label className="fw-bold">
                                        {isShowMetric === 0
                                            ? "Enter amount of people *"
                                            : "Enter room size"}
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter size"
                                        onKeyUp={onSizeChange}
                                    />
                                </Form.Group>
                            </Row>
                        </div>
                    </section>
                </div>

                <div className="col-md-8 col-81  d-none1 d-sm-block right_one">
                    <Row className="card">
                        <section className="main-section">
                            <div
                                className="main-innter-box"
                                style={{
                                    background: "#fff",
                                }}
                            >
                                <div className="box-right">
                                    <h2 className="box-title">Banquet Style</h2>

                                    <div className="content-box-1">
                                        <p>60" wide round tables:</p>

                                        <p>
                                            Max. <strong>{banquet_60}</strong>{" "}
                                            {isShowMetric === 0
                                                ? "metres sq."
                                                : "people"}
                                        </p>
                                    </div>

                                    <div className="content-box-2">
                                        <p>72" wide round tables:</p>

                                        <p>
                                            Max. <strong>{banquet_72}</strong>{" "}
                                            {isShowMetric === 0
                                                ? "metres sq."
                                                : "people"}
                                        </p>
                                    </div>

                                    {/* <button className="button-css">
                                        <a href="#">
                                            Find a venue <span>&#8674;</span>
                                        </a>
                                    </button> */}
                                </div>

                                <div className="box-left d-none d-sm-block  ">
                                    {/* <img src={process.env.PUBLIC_URL} /> */}
                                    <Image
                                        src="/assets/images/rc-banquet.jpg"
                                        className="img-right"
                                    />
                                </div>
                            </div>
                        </section>
                    </Row>

                    <Row className="card">
                        <section className="main-section">
                            <div
                                className="main-innter-box"
                                style={{
                                    background: "#fff",
                                }}
                            >
                                <div className="box-right">
                                    <h2 className="box-title">
                                        Conference Style
                                    </h2>

                                    <div className="content-box-1">
                                        <p>Attendees sat on all sides:</p>

                                        <p>
                                            Max. <strong>{conference}</strong>{" "}
                                            {isShowMetric === 0
                                                ? "metres sq."
                                                : "people"}
                                        </p>
                                    </div>

                                    <div className="content-box-2">
                                        <p>
                                            For groups of 25 or more people
                                            consider using 5,6,7,8 sided hollow
                                            set for better sight lines.
                                        </p>
                                    </div>

                                    {/* <button className="button-css">
                                        <a href="#">
                                            Find a venue <span>&#8674;</span>
                                        </a>
                                    </button> */}
                                </div>

                                <div className="box-left d-none d-sm-block  ">
                                    {/* <img src={process.env.PUBLIC_URL} /> */}
                                    <Image
                                        src="/assets/images/rc-conference.jpg"
                                        className="img-right"
                                    />
                                </div>
                            </div>
                        </section>
                    </Row>
                    <Row className="card">
                        <section className="main-section">
                            <div
                                className="main-innter-box"
                                style={{
                                    background: "#fff",
                                }}
                            >
                                <div className="box-right">
                                    <h2 className="box-title">
                                        Classroom Style
                                    </h2>

                                    <div className="content-box-1">
                                        <p>18" wide desks:</p>

                                        <p>
                                            Max. <strong>{classroom_18}</strong>{" "}
                                            {isShowMetric === 0
                                                ? "metres sq."
                                                : "people"}
                                        </p>
                                    </div>

                                    <div className="content-box-2">
                                        <p>30" wide desks:</p>

                                        <p>
                                            Max. <strong>{classroom_30}</strong>{" "}
                                            {isShowMetric === 0
                                                ? "metres sq."
                                                : "people"}
                                        </p>
                                    </div>

                                    {/* <button className="button-css">
                                        <a href="#">
                                            Find a venue <span>&#8674;</span>
                                        </a>
                                    </button> */}
                                </div>

                                <div className="box-left d-none d-sm-block  ">
                                    {/* <img src={process.env.PUBLIC_URL} /> */}
                                    <Image
                                        src="/assets/images/rc-classroom.jpg"
                                        className="img-right"
                                    />
                                </div>
                            </div>
                        </section>
                    </Row>

                    <Row className="card">
                        <section className="main-section">
                            <div
                                className="main-innter-box"
                                style={{
                                    background: "#fff",
                                }}
                            >
                                <div className="box-right">
                                    <h2 className="box-title">
                                        Theatre or Auditorium Style
                                    </h2>

                                    <div className="content-box-1">
                                        <p>Rows of chairs:</p>

                                        <p>
                                            Max. <strong>{theatre}</strong>{" "}
                                            {isShowMetric === 0
                                                ? "metres sq."
                                                : "people"}
                                        </p>
                                    </div>

                                   {/*  <button className="button-css">
                                        <a href="#">
                                            Find a venue <span>&#8674;</span>
                                        </a>
                                    </button> */}
                                </div>

                                <div className="box-left d-none d-sm-block  ">
                                    <Image
                                        src={"/assets/images/rc-theatre.jpg"}
                                        className="img-right"
                                    />
                                </div>
                            </div>
                        </section>
                    </Row>

                    <Row className="card">
                        <section className="main-section">
                            <div
                                className="main-innter-box"
                                style={{
                                    background: "#fff",
                                }}
                            >
                                <div className="box-right">
                                    <h2 className="box-title">
                                        Reception Style
                                    </h2>

                                    <div className="content-box-1">
                                        <p>Standing with poseur tables:</p>

                                        <p>
                                            Max. <strong>{receptionVal}</strong>{" "}
                                            {isShowMetric === 0
                                                ? "metres sq."
                                                : "people"}
                                        </p>
                                    </div>

                                    {/* <button className="button-css">
                                        <a href="#">
                                            Find a venue <span>&#8674;</span>
                                        </a>
                                    </button> */}
                                </div>

                                <div className="box-left d-none d-sm-block  ">
                                    <Image
                                        src={"/assets/images/rc-reception.jpg"}
                                        className="img-right"
                                    />
                                </div>
                            </div>
                        </section>
                    </Row>

                    <Row className="card">
                        <section className="main-section">
                            <div
                                className="main-innter-box"
                                style={{
                                    background: "#fff",
                                }}
                            >
                                <div className="box-right">
                                    <h2 className="box-title">Trade Style</h2>

                                    <div className="content-box-1">
                                        <p>8'x 10' booths:</p>

                                        <p>
                                            Max. <strong>{trade_810}</strong>{" "}
                                            {isShowMetric === 0
                                                ? "metres sq."
                                                : "people"}
                                        </p>
                                    </div>

                                    <div className="content-box-2">
                                        <p>10'x 10' booths:</p>

                                        <p>
                                            Max. <strong>{trade_1010}</strong>{" "}
                                            {isShowMetric === 0
                                                ? "metres sq."
                                                : "people"}
                                        </p>
                                    </div>

                                    {/* <button className="button-css">
                                        <a href="#">
                                            Find a venue <span>&#8674;</span>
                                        </a>
                                    </button> */}
                                </div>

                                <div className="box-left d-none d-sm-block  ">
                                    <Image
                                        src={"/assets/images/rc-trade.jpg"}
                                        className="img-right"
                                    />
                                </div>
                            </div>
                        </section>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default OccupancyCalculator;
