import { useAuth } from '@/services/Auth'
import { useTranslation } from 'react-i18next'

function UserCreditInformation () {

  const { t } = useTranslation()
  const auth = useAuth()

  return (
    <div className="d-flex fw-bold me-5 w-100 px-4 px-md-0">
      <span>{t('common.tokens.title')}:&nbsp;</span>
      <span
        className={`${auth.user.remaining_credits <= 0 ? 'text-danger' : 'text-success'} text-nowrap`}
      >
            {t('common.tokens.remaining', { count: auth.user.remaining_credits })}
          </span>
      <span className="text-nowrap">&nbsp;/&nbsp;{t('common.tokens.used', { count: auth.user.number_of_used_credits })}</span>
    </div>
  )
}

export default UserCreditInformation