import LOCATION from "@/constants/Location";

import LicenseKeyPage from "@/views/pages/LicenseKey";
import Create from "@/views/pages/LicenseKey/Create";
import Redeem from "@/views/pages/LicenseKey/Redeem";

const LicenseKey = [
    {
        path: LOCATION.LICENSE_KEY.LIST.path,
        component: LicenseKeyPage,
        exact: true,
        translation_key: "pages.license_key.title",
        main: true,
        gate: "license_key",
    },
    {
        path: LOCATION.LICENSE_KEY.CREATE.path,
        component: Create,
        exact: true,
        main: false,
        gate: "license_key",
    },
    {
        path: LOCATION.LICENSE_KEY.REDEEM.path,
        component: Redeem,
        translation_key: "pages.license_key.redeem_license_key_title",
        exact: true,
        main: true,
        gate: "license_key_redeem",
        order: 12,
    },
];

export default LicenseKey;
