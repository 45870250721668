import LOCATION from "@/constants/Location";

import CustomisePage from "@/views/pages/Customise/Customise";

const Customise = [
    {
        path: LOCATION.CUSTOMISE.LIST.path,
        component: CustomisePage,
        exact: true,
        translation_key: "pages.customise.title",
        main: true,
        gate: "customise",
    },
];
export default Customise;
