import { Form } from 'react-bootstrap'
import React, { useEffect } from 'react'

function TextBoxField ({
  type,
  register,
  questionIndex,
  question,
  inputNameSuffix,
  errors,
  sectionIndex,
  answer,
  auditMode,
  setValue,
}) {
  useEffect(() => {
    if(answer?.answer ){
      setValue(`${inputNameSuffix}.value`, answer?.answer)
    }
  }, [answer])
  return (
    <>
      {
        type === 4 &&
        <Form.Control
          disabled={auditMode}
          {...register(`${inputNameSuffix}.value`)}
          isInvalid={!!(errors && errors[questionIndex] && errors[questionIndex].value)}
        />
      }
      {
        type === 5 &&
        <Form.Control
          disabled={auditMode}
          as="textarea"
          {...register(`${inputNameSuffix}.value`)}
          isInvalid={!!(errors && errors[questionIndex] && errors[questionIndex].value)}
        />
      }
      {
        type === 7 &&
        <Form.Control
          disabled={auditMode}
          type="date"
          {...register(`${inputNameSuffix}.value`)}
          isInvalid={!!(errors && errors[questionIndex] && errors[questionIndex].value)}
        />
      }

      <Form.Control.Feedback type="invalid">
        {errors && errors[questionIndex] && errors[questionIndex].value && errors[questionIndex].value.message}
      </Form.Control.Feedback>
    </>
  )
}

export default TextBoxField
