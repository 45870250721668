import {useTranslation} from "react-i18next";
import {useAuth} from "@/services/Auth";
import {useEffect} from "react";
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import React from 'react'
import FaceFrown from '@/components/icons/FaceFrown'
import LOCATION from '@/constants/Location'

function Error500() {
    const {t} = useTranslation();
    let auth = useAuth();

    useEffect(() => {
        auth?.errorPage || (auth?.updateErrorPage && auth.updateErrorPage(true));

        return () => {
            auth?.updateErrorPage && auth.updateErrorPage(false);
        }
    }, []);

    return (
      <div className="container request-sent d-flex justify-content-center align-items-center h-100">
          <Card className="border-0 p-5 bg-white">
              <Card.Body className="text-center">
                  <FaceFrown/>
                  <h3 className="mt-4">{t('common.errors.titles.error500')}</h3>
                  <p className="my-4">{t('common.errors.body.error500')}</p>
                  <a className="btn btn-primary text-white" href={`${LOCATION.ROOT.path}`}>
                      {t('common.go_home')}
                  </a>
              </Card.Body>

          </Card>
      </div>
    )
}
export  default Error500;
