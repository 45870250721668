import { Button, Col, ListGroup, Row } from 'react-bootstrap'
import Plus from '@/components/icons/Plus'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import SectionFormBuilder from './SectionFormBuilder'
import AssessmentTemplateFormBuilder from './AssessmentTemplateFormBuilder'
import { useAuth } from '@/services/Auth'

import LOCATION from '@/constants/Location'
import AssessmentSectionList from '@/views/shared/components/AssessmentSectionList'

function AssessmentFormBuilder ({
  assessment,
  requestSave,
  initialSection,
  saveSection,
  deleteSection,
  submitFormRef,
  saveDraft,
  validationErrors,
  savingTemplate,
}) {
  const { t } = useTranslation()
  const auth = useAuth()

  const [sections, setSections] = useState([])
  const [currentSection, setCurrentSection] = useState(null)
  const [fieldTypes, setFieldTypes] = useState([])

  let questionFields = {
    id: null,
    label: '',
    type: 4,
    required: true,
    has_help_text: false,
    help_text: '',
    description: '',
    add_other: false,
    question_choices: [],
  }

  const addSection = () => {
    sections.push({
      id: 'new_section',
      name: null,
      questions: [questionFields],
      order: assessment?.assessment_sections?.length + 1
    })
    setSections(() => sections)

    changeSection(sections.length - 1)
  }

  const changeSection = (index) => {
    setCurrentSection(index)
  }

  useEffect(() => {
    if (assessment && assessment.assessment_sections) {
      setSections(assessment.assessment_sections)
    }
  }, [assessment])

  useEffect(() => {
    changeSection(initialSection)
  }, [initialSection])

  useEffect(() => {
    let request = auth.getRequest(`${LOCATION.FIELD_TYPES.API.path}`)

    request
      .then(response => {
        setFieldTypes(response.data.field_types.filter(_ => (assessment.assessment_template_type === 2 && [8, 9, 13].includes(_.id)) || assessment.assessment_template_type === 1))
      })
      .catch(error => {
      })

  }, [])
  return (
    <Row>
      <Col className="col-12 col-lg-4">
        <div className="sections-list-panel">
          <AssessmentSectionList
            titleSection={true}
            sections={sections}
            currentSectionIndex={currentSection}
            changeSection={changeSection}
          />
          {
            assessment &&
            <ListGroup className="assessment-sections">
              <ListGroup.Item
                as="li"
                className="border-0 p-0 d-grid gap-2"
              >
                <Button
                  disabled={sections.find(_ => _.id === 'new_section')}
                  onClick={() => addSection()}
                  size="sm"
                  className="text-white my-4"
                  variant="primary"
                >
                  <span className="ms-3">{t('pages.new_assessment.add_section')}</span>
                  <Plus/>
                </Button>
              </ListGroup.Item>
            </ListGroup>
          }
        </div>
      </Col>
      <Col className="col-12 col-lg-8">
        {
          currentSection == null ?
            <AssessmentTemplateFormBuilder
              requestSave={requestSave}
              assessment={assessment}
              submitFormRef={submitFormRef}
              saveDraft={saveDraft}
              validationErrors={validationErrors}
            />
            :
            <div className="assessment-questions-container">
              {
                sections.map((section, index) => {
                  return index === currentSection ? (
                    <SectionFormBuilder
                      deleteSection={deleteSection}
                      key={section.id}
                      section={section}
                      saveSection={saveSection}
                      questionFields={questionFields}
                      fieldTypes={fieldTypes}
                      submitFormRef={submitFormRef}
                      saveDraft={saveDraft}
                      currentSection={currentSection}
                      assessment={assessment}
                      validationErrors={validationErrors}
                      savingTemplate={savingTemplate}
                    />
                  ) : null
                })
              }
            </div>
        }
      </Col>
    </Row>
  )
}

export default AssessmentFormBuilder
