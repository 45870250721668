import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useAuth } from "@/services/Auth";
import LOCATION from "@/constants/Location";
import LoadingCover from "@/components/LoadingCover";
import AssessmentFilters from "@/views/components/AssessmentFilters";
import Table, { useColumns } from "@/views/components/Tables/Assessments";
import { Card, Col, Row, Form, Image, } from "react-bootstrap";
import UpdateTokens from "./Forms/UpdateTokens";
import moment from "moment";
import CancelSubscription from "./components/CancelSubscription";
import PromotionButton from "./components/PromotionButton";
import TableReport, {
    useColumnsReport,
} from "@/views/components/Tables/TableReport";
function Show() {
    const history = useHistory();
    const route = useLocation();
    const auth = useAuth();
    const { t } = useTranslation();

    const [assessmentTemplates, setAssessmentTemplates] = useState([]);
    const [assessmentStatus, setAssessmentStatus] = useState([]);
    const [loading, setLoading] = useState(true);
    const [subscription, setSubscription] = useState(null);

    const { id } = useParams();

    const [user, setUser] = useState(null);

    const [query, setQuery] = useState(
        (route.state && route.state.query) || { page: 0, skip: 0, limit: 15 }
    );

    const [searching, setSearching] = useState(false);
    const [activeTab, setActiveTab] = useState("assessments");
    const [assessmentReports, setAssessmentReports] = useState([]);

    const baseUrl = auth.api.defaults.baseURL;
    const [userSign, setUserSing] =useState("/assets/images/sign-placeholder.png");
    /* const [userSign, setUserSing] = useState(
        auth.user.user_signature
            ? `${baseUrl}/${auth.user.user_signature}`
            : "/assets/images/sign-placeholder.png"
    ); */

    const [uploading, setUploading] = useState({
        user_signature: false,
    });
    const [serverErrors, setServerErrors] = useState({
        user_signature: [],
    });


    const getSubscription = () => {
        let request = auth.getRequest(
            `${LOCATION.SUBSCRIPTIONS.API.path}/${id}`
        );

        request
            .then((response) => {
                setSubscription(response.data);
            })
            .catch((error) => {});

        return request;
    };


    const uploadImage = (data, type) => {
        setLoadingState(type, true);
        setServerErrorsState(null, []);

        let imageData = data.target.files[0];
        let form = new FormData();
        form.append("image", imageData);
        form.append("type", type);
        form.append("user_id", user.id);

        auth.postRequest(`${LOCATION.USERS.API.path}/upload-images`, form)
            .then((response) => {
                let uploadedLink = `${baseUrl}/${response.data}`;
               if (type === "user_signature") {
                    setUserSing(uploadedLink);
                }
                setLoadingState(type, false);
            })
            .catch((error) => {
                setLoadingState(type, false);

                if (error.response.status === 422) {
                    let serverErrors = [];
                    for (const key in error.response.data.errors) {
                        serverErrors.push(error.response.data.errors[key][0]);
                    }
                    setServerErrorsState(type, serverErrors);
                }
            });
    };

    const setLoadingState = (type, value) => {
        let uploadingHolder = { ...uploading };
        uploadingHolder[type] = value;
        setUploading(uploadingHolder);
    };

    const setServerErrorsState = (type, newErrors) => {
        let errors = { ...serverErrors };
        errors.user_signature = [];

        if (type != null) {
            errors[type] = newErrors;
        }
        setServerErrors(errors);
    };
    useEffect(() => {
        let request = getSubscription();
    }, []);

    useEffect(() => {
        let request = auth.getRequest(LOCATION.ASSESSMENT_TEMPLATES.API.path);

        request
            .then((response) => {
                setAssessmentTemplates(response.data.data);
            })
            .catch((error) => {});

        return () => request?.abort && request.abort();
    }, []);

    useEffect(() => {
        let request = auth.getRequest(`${LOCATION.ASSESSMENT_STATUS.API.path}`);

        request
            .then((response) => {
                setAssessmentStatus(response.data.assessment_status);
            })
            .catch((error) => {});

        return () => request?.abort && request.abort();
    }, []);

    const getUserDetails = () => {
        let request = auth.getRequest(`${LOCATION.USERS.API.path}/${id}`);
        request
            .then((response) => {
                setUser(response.data);
                setLoading(false);
                let searchQuery = { ...query };
                searchQuery.user_id = response.data.id;
                const userSignature = response.data.user_signature.trim();
                if (userSignature !== "") {
                    setUserSing(`${baseUrl}/${response.data.user_signature}`);
                }


                setQuery(searchQuery);
            })
            .catch((error) => {});
        return () => request?.abort && request.abort();
    };

    useEffect(() => {
        let request = getUserDetails();
    }, []);

    useEffect(() => {
        history.replace(route.pathname, {
            ...route.state,
            query,
        });
    }, [query]);

    useEffect(() => {
        const assessmentReportArray = [
            { id: "all", title: "All" },
            { id: "firesafety", title: "Fire Safety" },
            { id: "healthsafety", title: "Health Safety" },
            { id: "firedoor", title: "Fire Door" },
        ];
        setAssessmentReports(assessmentReportArray);
    }, []);

    if (loading) return <LoadingCover />;

    return (
        <>
            <Row className="h-100">
                <Col className="col-md-8 col-12 p-4">
                    <div className="section-header d-flex justify-content-between mb-4">
                        <h4>{user.name}</h4>
                    </div>
                    <div className="mb-4">
                        <button
                            className={`tab-button ${
                                activeTab === "assessments" ? "active" : ""
                            }`}
                            onClick={(_) => {
                                setActiveTab("assessments");
                                setSearching(!_);
                                setQuery({
                                    page: 0,
                                    skip: 0,
                                    limit: 15,
                                    assessment_template_id: "",
                                    status: "",
                                    start_date: "",
                                    end_date: "",
                                    user_id: user.id
                                });
                            }}
                        >
                            {t("pages.assessments.title")}
                        </button>
                        <button
                            className={`tab-button ${
                                activeTab === "reports" ? "active" : ""
                            }`}
                            onClick={(_) => {
                                setActiveTab("reports");
                                setSearching(!_);
                                setQuery({
                                    page: 0,
                                    skip: 0,
                                    limit: 25,
                                    assessment_template_id: "",
                                    status: "",
                                    start_date: "",
                                    end_date: "",
                                    user_id: user.id
                                });
                            }}
                        >
                            {t("pages.assessments.reports")}
                        </button>
                    </div>

                    {activeTab === "assessments" && (
                        <>
                            <AssessmentFilters
                                hideSearch={true}
                                searching={searching}
                                values={query}
                                loadAssessments={setQuery}
                                assessmentStatus={assessmentStatus}
                                assessmentTemplates={assessmentTemplates}
                            />
                            <div className="my-4 bg-white">
                                <Table
                                    query={query}
                                    showPagination={true}
                                    onData={(_) => setSearching(!_)}
                                    source="users"
                                />
                            </div>
                        </>
                    )}

                    {activeTab === "reports" && (
                        <>
                            <AssessmentFilters
                                hideSearch={true}
                                searching={searching}
                                values={query}
                                loadAssessments={setQuery}
                                assessmentStatus={[]}
                                assessmentTemplates={assessmentReports}
                            />
                            <div className="my-4 bg-white">
                                <TableReport
                                    query={query}
                                    showPagination={true}
                                    onData={(_) => setSearching(!_)}
                                    source="users"
                                />
                            </div>
                        </>
                    )}
                </Col>
                <Col className="col-md-4 col-12 p-0">
                    <div className="user-info p-4 m-4 m-md-0 h-100">
                        <h5>{t("pages.my_account.licence_details")}</h5>
                        <div className="d-flex my-1">
                            <span>{t("common.form.email")}</span>
                            <span>&nbsp;-&nbsp;{user.email}</span>
                        </div>
                        <div className="d-flex my-1">
                            <span>{t("common.form.phone_number")}</span>
                            <span>&nbsp;-&nbsp;{user.phone_number}</span>
                        </div>
                        <div className="d-flex my-1">
                            <span>{t("common.form.serial_number")}</span>
                            <span>&nbsp;-&nbsp;{user.serial_number}</span>
                        </div>
                        <Row className="my-4 pt-4">
                            <Col className="col-8">
                                <h5 className="">
                                    {t(
                                        "pages.my_account.user_signature_for_certification"
                                    )}
                                </h5>
                                <p>
                                    {t(
                                        "pages.my_account.signature_minimum_size"
                                    )}
                                </p>
                                <Form.Control
                                    className="mb-2"
                                    onChange={(e) =>
                                        uploadImage(e, "user_signature")
                                    }
                                    type="file"
                                    accept="image/*,.jpg,.png,.jpeg"
                                />
                                {serverErrors.user_signature.length !== 0 && (
                                    <div className="form-group mt-4">
                                        {serverErrors.user_signature.map(
                                            (error, index) => (
                                                <p
                                                    className="text-danger font-weight-bold"
                                                    key={index}
                                                >
                                                    {error}
                                                </p>
                                            )
                                        )}
                                    </div>
                                )}
                            </Col>
                           </Row>
                           <Row className="">
                            <Col className="col-4">
                                    {uploading.user_signature ? (
                                        <div className="sign-uploads">
                                            <LoadingCover />
                                        </div>
                                    ) : (
                                        <Image
                                            className="company-logo sign-uploads"
                                            src={userSign}
                                        />
                                    )}
                            </Col>
                        </Row>

                        <h5 className="mt-4">
                            {t("pages.my_account.organisation_details")}
                        </h5>
                        <div>{user.organization?.name}</div>
                        <div>{user.organization_branch?.name}</div>

                        <PromotionButton user={user} />

                        <h5 className="mt-4">
                            {t("pages.users.update_token")}
                        </h5>
                        <UpdateTokens user={user} />

                        <h5 className="mt-4">
                            {t("pages.my_account.subscription_details")}
                        </h5>
                        {subscription ? (
                            <>
                                <div className="d-flex my-1">
                                    <span>
                                        {t(
                                            "pages.plans.subscriptions.subscriber_since"
                                        )}
                                    </span>
                                    <span>
                                        &nbsp;-&nbsp;
                                        {moment(subscription.created_at).format(
                                            t("common.formats.short_date")
                                        )}
                                    </span>
                                </div>
                                <div className="d-flex my-1">
                                    <span>
                                        {t(
                                            "pages.plans.subscriptions.expiry_date"
                                        )}
                                    </span>
                                    <span>
                                        &nbsp;-&nbsp;
                                        {moment(subscription.expire_at).format(
                                            t("common.formats.short_date")
                                        )}
                                    </span>
                                </div>
                                <div className="d-flex my-1">
                                    <span>
                                        {t(
                                            "pages.plans.subscriptions.renew_type"
                                        )}
                                    </span>
                                    <span>
                                        &nbsp;-&nbsp;
                                        {t(
                                            "pages.plans.subscriptions.renew_date_monthly"
                                        )}
                                    </span>
                                </div>
                                <div className="w-100 my-1 ">
                                    <div className="d-grid gap-2 mt-2">
                                        <CancelSubscription
                                            id={id}
                                            getSubscription={getSubscription}
                                        />
                                    </div>
                                </div>
                            </>
                        ) : (
                            <h6 className="my-3 text-danger">
                                {t(
                                    "pages.plans.subscriptions.no_active_subscription"
                                )}
                            </h6>
                        )}
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default Show;
