import LOCATION from "@/constants/Location";

import EmergencyLightingPage from "@/views/pages/EmergencyLighting/EmergencyLighting";
//import HealthSafetyPolicy from "@/views/pages/HealthSafetyPolicy/HealthSafetyPolicy";

const EmergencyLighting = [
    {
        path: LOCATION.EMERGENCY_LIGHTING.LIST.path,
        component: EmergencyLightingPage,
        exact: true,
        translation_key: "pages.emergency_lighting.title",
        main: true,
        //gate: "my_account",
        order: 4,
    },
];
export default EmergencyLighting;
