import LOCATION from '@/constants/Location'
import UsersIndex from '@/views/pages/Users'
import Show from '@/views/pages/Users/Show'

const Users = [
  {
    path: LOCATION.USERS.LIST.path,
    component: UsersIndex,
    exact: true,
    translation_key: 'pages.users.title',
    main: true,
    gate: 'users',
  },
  {
    path: LOCATION.USERS.SHOW.path,
    component: Show,
    exact: true,
    translation_key: 'pages.users.title',
    main: false,
    gate: 'users',
  },
]
export default Users
