import { useTranslation } from "react-i18next";
import { useAuth } from "../../services/Auth";
import React, { useState, useEffect } from "react";
import LOCATION from "@/constants/Location";
import CircleCheck from "@/components/icons/CircleCheck";
import CircleXMark from "@/components/icons/CircleXMark";
import { Link, useParams } from "react-router-dom";
import LoadingButton from "@/components/LoadingButton";
import LoadingCover from "@/components/LoadingCover";
import { Row } from "react-bootstrap";
function VerifyEmailPage() {
    const { t } = useTranslation();
    const auth = useAuth();
    const [loading, setLoading] = useState(false);
    const [serverError, setServerError] = useState("");
    const [serverErrors, setServerErrors] = useState([]);
    const [resData, setResData] = useState([]);

    const { token } = useParams();

    const signIn = (token) => {
        setLoading(true);
        let formData = {};
        formData.token = token;
        setServerError("");
        auth.postRequest(`${LOCATION.AUTH.VERIFY_EMAIL.API.path}`, formData)
            .then((response) => {
                setLoading(false);
                setResData(response.data);
            })
            .catch((error) => {
                setLoading(false);
                if (error?.response?.status === 401) {
                    setServerError(error.response.data.message);
                }

                if (error.response.status === 422) {
                    let serverErrors = [];
                    for (const key in error.response.data.errors) {
                        serverErrors.push(error.response.data.errors[key][0]);
                    }
                    setServerErrors(serverErrors);
                }
            });
    };

    useEffect(() => {
        signIn(token);
    }, []);

    if (loading) {
        return <LoadingCover />;
    }
    return (
        <div className="row bg-white" style={{ height: "100%" }}>
            <div className="col-sm-12 col-12 border-0 d-flex align-items-center justify-content-center">
                <div className="login-container p-4">
                    <Row className="text-center">
                        {serverError !== "" && (
                            <div className="form-group mb-2">
                                <p className="text-danger py-1 mb-0">
                                    {t(serverError)}
                                </p>
                            </div>
                        )}
                        {serverErrors.length !== 0 && (
                            <div className="form-group mb-2">
                                {serverErrors.map((error, index) => (
                                    <p
                                        className="text-danger font-weight-bold"
                                        key={index}
                                    >
                                        {t(error)}
                                    </p>
                                ))}
                            </div>
                        )}

                        {resData.status === true ? (
                            <CircleCheck />
                        ) : (
                            <CircleXMark />
                        )}

                        <div
                            dangerouslySetInnerHTML={{
                                __html: resData.message,
                            }}
                        />
                        <Link
                            className="btn btn-primary text-white"
                            to={`${LOCATION.ROOT.path}`}
                        >
                            {t("common.go_home")}
                        </Link>
                    </Row>
                </div>
            </div>
        </div>
    );
}
export default VerifyEmailPage;
