import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useAuth } from "@/services/Auth";
import LoadingCover from "@/components/LoadingCover";
//import UserFilters from "./components/UserFilters";
import Plus from "@/components/icons/Plus";
import TablePricingPlan from "@/views/components/Tables/PricingPlan";
import LOCATION from "@/constants/Location";
import ConfirmModal from "@/components/modals/ConfirmModal";
function UsersIndex() {
    const history = useHistory();
    const route = useLocation();
    const auth = useAuth();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);
    const [pricingPlans, setPricingPlans] = useState([]);

    const [query, setQuery] = useState(
        (route.state && route.state.query) || { page: 0, skip: 0, limit: 15 }
    );

    const [searching, setSearching] = useState(false);
    const [pricingPlanToDelete, setPricingPlanToDelete] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const deleteRequest = (pricingPlanToDelete) => {
        setPricingPlanToDelete(pricingPlanToDelete);
        setShowModal(true);
    };

    const handleCancel = () => {
        setShowModal(false);
        setPricingPlanToDelete(null);
    };
    const handleConfirm = () => {
        setShowModal(false);
        setLoading(true);
        auth.deleteRequest(
            `${LOCATION.PRICING_PLANS.API.path}/${pricingPlanToDelete}`
        )
            .then((response) => {
                setLoading(false);
                setPricingPlanToDelete(null);
            })
            .catch((error) => {});
    };
    useEffect(() => {
        history.replace(route.pathname, {
            ...route.state,

            query,
        });
        setLoading(false);
    }, [query]);

    /*  useEffect(() => {
        let request = auth.getRequest(LOCATION.PRICING_PLANS.API.path);

        request
            .then((response) => {
                setPricingPlans(response.data.data);
                setLoading(false);
            })
            .catch((error) => {});

        return () => request?.abort && request.abort();
    }, []); */

    if (loading) return <LoadingCover />;

    return (
        <>
            <ConfirmModal
                show={showModal}
                title={t(
                    "pages.pricing_plan.notifications.delete_pricing_plan.title"
                )}
                body={t(
                    "pages.pricing_plan.notifications.delete_pricing_plan.body"
                )}
                handleCancel={handleCancel}
                handleConfirm={handleConfirm}
                cancelText={t("common.dialogs.actions.cancel")}
                confirmText={t("common.dialogs.actions.delete")}
            />
            <div className="content">
                <div className="section-header d-flex justify-content-between flex-md-row flex-column mb-4">
                    <h4>{t("pages.pricing_plan.title")}</h4>
                    <div className="d-flex align-items-center justify-content-start justify-content-md-between flex-column flex-md-row">
                        <div className="d-grid gap-2 w-100 mt-3 mt-md-0">
                            <Link
                                to={LOCATION.PRICING_PLANS.CREATE.path}
                                className="btn btn-primary text-white"
                            >
                                <span className="me-1">
                                    {t(
                                        "pages.pricing_plan.button.new_pricing_plan"
                                    )}
                                </span>
                                <Plus />
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="my-4">
                    <TablePricingPlan
                        query={query}
                        showPagination={true}
                        onData={(_) => setSearching(!_)}
                        deleteRequest={deleteRequest}
                    />
                </div>
            </div>
        </>
    );
}
export default UsersIndex;
