import { Button, Form, Image } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import LOCATION from '@/constants/Location'
import LoadingCover from '@/components/LoadingCover'

function ImageUploadField ({
  inputNameSuffix,
  register,
  watch,
  question,
  errors,
  questionIndex,
  setValue,
  resetField,
  answer,
  auditMode,
  fieldName,
  assessment,
  image,
}) {

  const auth = useAuth()
  const baseUrl = auth.api.defaults.baseURL

  const { t } = useTranslation()

  const [imageUrl, setImageUrl] = useState(null)
  const [uploadingImage, setUploadingImage] = useState(false)

  useEffect(() => {
    register(`${inputNameSuffix}.${fieldName ?? 'value'}`)
    if( resetField ){
      resetField(`${inputNameSuffix}.${fieldName ?? 'value'}`)
    }
  }, [])

  const uploadImage = (image) => {
    setUploadingImage(true)
    let imageData = image.target.files[0]
    let form = new FormData()
    form.append('image', imageData)

    auth.postRequest(`${LOCATION.ASSESSMENTS.API.path}/upload-image`, form)
      .then(response => {
        setValue(`${inputNameSuffix}.${fieldName ?? 'value'}`, response.data)
        setImageUrl(response.data)
        setUploadingImage(false)
      })
      .catch(error => {
      })
  }

  const removeImage = () => {
    setValue(`${inputNameSuffix}.${fieldName ?? 'value'}`, '')
    setImageUrl(null);
    register(`${inputNameSuffix}.${fieldName ?? 'value'}`)
    if( resetField ){
      resetField(`${inputNameSuffix}.${fieldName ?? 'value'}`)
    }
  }

  useEffect(() => {
    if (answer?.answer) {
      setImageUrl(answer.answer)
      setValue(`${inputNameSuffix}.${fieldName ?? 'value'}`, answer.answer)
    }
    if( image ){
      setImageUrl(image)
      setValue(`${inputNameSuffix}.${fieldName ?? 'value'}`, image)
    }
  }, [answer])

  return (
    <>
      {
        !auditMode &&
        <>
        <Form.Control
            isInvalid={!!(errors && errors[questionIndex] && errors[questionIndex][`${fieldName ?? 'value'}`] )}
            className="mb-2"
            name={`${inputNameSuffix}.${fieldName ?? 'value'}`}
            onChange={(e) => uploadImage(e)}
            type="file"
            accept="image/*,.jpg,.png,.jpeg"
          />
          <Form.Control.Feedback type="invalid">
            {errors && errors[questionIndex] && errors[questionIndex][`${fieldName ?? 'value'}`] && errors[questionIndex][`${fieldName ?? 'value'}`].message}
          </Form.Control.Feedback>
          { !assessment?.id === 2 &&  <small>{question.description}</small>}

        </>
      }
      {
        uploadingImage &&
        <div className="w-100 mt-2 mb-2" style={{ height: '200px' }}>
          <LoadingCover/>
        </div>
      }
      <div className="w-100 mt-2 mb-2">
        {
          imageUrl &&
          <>
            <Image className="w-100" src={`${baseUrl}/${imageUrl}`}/>
            {
              (!auditMode) &&
              <Button variant="link" className="p-0 mb-1 mt-3" onClick={() => removeImage()}>
                <span className="text-danger">{t('common.buttons.remove')}</span>
              </Button>
            }
          </>
        }
      </div>
    </>
  )
}

export default ImageUploadField
