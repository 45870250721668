import { Link, useParams } from 'react-router-dom'
import { useAuth } from '../../../services/Auth'
import { useTranslation } from 'react-i18next'
import LOCATION from '../../../constants/Location'
import React, { useEffect, useState } from 'react'
import LoadingCover from '../../../components/LoadingCover'
import { Card, Col, Row } from 'react-bootstrap'
import PaymentMethod from '../Payments/PaymentMethod'
import PlanCard from './components/PlanCard'
import CircleXMark from '../../../components/icons/CircleXMark'

function Subscribe () {
  const auth = useAuth()
  const { t } = useTranslation()
  const { id } = useParams()

  const [plan, setPlan] = useState(null)
  const [loading, setLoading] = useState(true)

  const getPlan = () => {
    return auth.getRequest(`${LOCATION.PLANS.API.path}/${id}`)
      .then(response => {
        setPlan(response.data)
        setLoading(false)
      })
      .catch(error => {

      })
  }
  useEffect(() => {
    let request = getPlan()
    return () => request?.abort && request.abort()
  }, [])

  if (loading || !plan) return <LoadingCover/>

  return (
    <div className="container  mt-5">
      <Row>
        <Col className="col-12 col-md-8 d-flex align-items-center px-2 px-md-5">
          <PaymentMethod
            source="buy-credits"
            plan={plan}
          />
        </Col>

        <Col className="col-12 col-md-4 p-2">
          <PlanCard
            plan={plan}
            hideButton={true}
          />
        </Col>
      </Row>
    </div>
  )

}

export default Subscribe
