import login from "./Login";
import signup from "./Signup";
import assessments from "./Assessments";
import assessment_audit from "./AssessmentAudit";
import my_assessments from "./MyAssessments";
import new_assessment from "./NewAssessment";
import my_account from "./MyAccount";
import users from "./Users";
import plans from "./Plans";
import forgot_password from "./ForgotPassword";
import reset_password from "./ResetPassword";
import assessment_templates from "./AssessmentTemplates";
import competency_templates from "./CompetencyTemplates";
import our_network from "./OurNetwork";
import payments from "./Payments";
import fire_safety_policy_documents from "./FireSafetyPolicyDocument";
import health_safety_policy from "./HealthSafetyPolicy";
import occupancy_calculator from "./OccupancyCalculator";
import fire_door_compliance from "./FireDoorCompliance";
import document_template from "./DocumentTemplate";
import customise from "./Customise";
import license_key from "./LicenseKey";
import course from "./Course";
import directory from "./Directory";
import admin_users from "./AdminUsers";
import pricing_plan from "./PricingPlan";
import verify_email from "./VerifyEmail";
import transactions from "./Transactions";
import emergency_lighting from "./EmergencyLighting";
import fire_extinguiser from "./FireExtinguiser";

const pages = {
    login,
    signup,
    forgot_password,
    reset_password,
    assessments,
    assessment_templates,
    assessment_audit,
    my_assessments,
    new_assessment,
    my_account,
    users,
    plans,
    competency_templates,
    our_network,
    payments,
    fire_safety_policy_documents,
    health_safety_policy,
    occupancy_calculator,
    fire_door_compliance,
    document_template,
    customise,
    license_key,
    course,
    directory,
    admin_users,
    pricing_plan,
    verify_email,
    transactions,
    emergency_lighting,
    fire_extinguiser
};

export default pages;
