import LOCATION from "@/constants/Location";

import OccupancyCalculatorPage from "@/views/pages/OccupancyCalculator/OccupancyCalculator";

const OccupancyCalculator = [
    {
        path: LOCATION.OCCUPANCY_CALCULATOR.LIST.path,
        component: OccupancyCalculatorPage,
        exact: true,
        translation_key: "pages.occupancy_calculator.title",
        main: true,
        //gate: "my_account",
        order: 10,
    },
];
export default OccupancyCalculator;
